// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/assetinfo.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { ChassisXBrandType, chassisXBrandTypeFromJSON, chassisXBrandTypeToJSON } from "./chassis_x.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

/**
 * Copied from https://source.corp.google.com/piper///depot/google3/java/com/google/chrome/crosbuilds/backend/hartapi/proto/pubsub/assetinfo/assetinfo.proto
 * and edited
 * TODO(b/297076502): Automate changes with Copybara
 */

/** TODO(anushruth): Use 0 as Undefined inline with https://google.aip.dev/126 */
export enum RequestStatus {
  OK = 0,
  INVALID_MESSAGE = 1,
  SERVER_ERROR = 2,
  NO_ASSET_TAG = 3,
  NO_GPN = 4,
}

export function requestStatusFromJSON(object: any): RequestStatus {
  switch (object) {
    case 0:
    case "OK":
      return RequestStatus.OK;
    case 1:
    case "INVALID_MESSAGE":
      return RequestStatus.INVALID_MESSAGE;
    case 2:
    case "SERVER_ERROR":
      return RequestStatus.SERVER_ERROR;
    case 3:
    case "NO_ASSET_TAG":
      return RequestStatus.NO_ASSET_TAG;
    case 4:
    case "NO_GPN":
      return RequestStatus.NO_GPN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RequestStatus");
  }
}

export function requestStatusToJSON(object: RequestStatus): string {
  switch (object) {
    case RequestStatus.OK:
      return "OK";
    case RequestStatus.INVALID_MESSAGE:
      return "INVALID_MESSAGE";
    case RequestStatus.SERVER_ERROR:
      return "SERVER_ERROR";
    case RequestStatus.NO_ASSET_TAG:
      return "NO_ASSET_TAG";
    case RequestStatus.NO_GPN:
      return "NO_GPN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum RequestStatus");
  }
}

export interface AssetInfo {
  readonly assetTag: string;
  /** User not allowed to update this field. SSW will update this field. */
  readonly serialNumber: string;
  readonly costCenter: string;
  readonly googleCodeName: string;
  readonly model: string;
  readonly buildTarget: string;
  readonly referenceBoard: string;
  readonly ethernetMacAddress: string;
  /** User not allowed to update this field. SSW will update this field. */
  readonly sku: string;
  readonly phase: string;
  /** User not allowed to update this field. SSW will update this field. */
  readonly hwid: string;
  readonly gpn: string;
  readonly referenceDesign: string;
  readonly productStatus: string;
  readonly fingerprintSensor: boolean;
  readonly hwXComplianceVersion: number;
  readonly touchScreen: boolean;
  readonly isCbx: boolean;
  readonly cbxFeatureType: ChassisXBrandType;
  readonly isMixedX: boolean;
  readonly hasWifiBt: boolean;
  readonly wifiBluetooth: string;
}

export interface AssetInfoRequest {
  /** Required. Asset tag(s) to lookup. */
  readonly assetTags: readonly string[];
}

export interface AssetInfoResponse {
  /** Indicator of whether the request call succeed or not. */
  readonly requestStatus: RequestStatus;
  /** A list of asset info retrieved from Hart. */
  readonly assets: readonly AssetInfo[];
  /** A list of asset tags not found in Corporate Asset Management platform. */
  readonly missingAssetTags: readonly string[];
  /** A list of asset tags whose part number not found in Hart. */
  readonly failedAssetTags: readonly string[];
}

function createBaseAssetInfo(): AssetInfo {
  return {
    assetTag: "",
    serialNumber: "",
    costCenter: "",
    googleCodeName: "",
    model: "",
    buildTarget: "",
    referenceBoard: "",
    ethernetMacAddress: "",
    sku: "",
    phase: "",
    hwid: "",
    gpn: "",
    referenceDesign: "",
    productStatus: "",
    fingerprintSensor: false,
    hwXComplianceVersion: 0,
    touchScreen: false,
    isCbx: false,
    cbxFeatureType: 0,
    isMixedX: false,
    hasWifiBt: false,
    wifiBluetooth: "",
  };
}

export const AssetInfo: MessageFns<AssetInfo> = {
  encode(message: AssetInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.assetTag !== "") {
      writer.uint32(10).string(message.assetTag);
    }
    if (message.serialNumber !== "") {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.costCenter !== "") {
      writer.uint32(26).string(message.costCenter);
    }
    if (message.googleCodeName !== "") {
      writer.uint32(34).string(message.googleCodeName);
    }
    if (message.model !== "") {
      writer.uint32(42).string(message.model);
    }
    if (message.buildTarget !== "") {
      writer.uint32(50).string(message.buildTarget);
    }
    if (message.referenceBoard !== "") {
      writer.uint32(58).string(message.referenceBoard);
    }
    if (message.ethernetMacAddress !== "") {
      writer.uint32(66).string(message.ethernetMacAddress);
    }
    if (message.sku !== "") {
      writer.uint32(74).string(message.sku);
    }
    if (message.phase !== "") {
      writer.uint32(82).string(message.phase);
    }
    if (message.hwid !== "") {
      writer.uint32(90).string(message.hwid);
    }
    if (message.gpn !== "") {
      writer.uint32(98).string(message.gpn);
    }
    if (message.referenceDesign !== "") {
      writer.uint32(106).string(message.referenceDesign);
    }
    if (message.productStatus !== "") {
      writer.uint32(114).string(message.productStatus);
    }
    if (message.fingerprintSensor !== false) {
      writer.uint32(120).bool(message.fingerprintSensor);
    }
    if (message.hwXComplianceVersion !== 0) {
      writer.uint32(129).double(message.hwXComplianceVersion);
    }
    if (message.touchScreen !== false) {
      writer.uint32(136).bool(message.touchScreen);
    }
    if (message.isCbx !== false) {
      writer.uint32(144).bool(message.isCbx);
    }
    if (message.cbxFeatureType !== 0) {
      writer.uint32(152).int32(message.cbxFeatureType);
    }
    if (message.isMixedX !== false) {
      writer.uint32(160).bool(message.isMixedX);
    }
    if (message.hasWifiBt !== false) {
      writer.uint32(168).bool(message.hasWifiBt);
    }
    if (message.wifiBluetooth !== "") {
      writer.uint32(178).string(message.wifiBluetooth);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AssetInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.assetTag = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.costCenter = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.googleCodeName = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.buildTarget = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.referenceBoard = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.ethernetMacAddress = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.sku = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.phase = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.hwid = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.gpn = reader.string();
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.referenceDesign = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.productStatus = reader.string();
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.fingerprintSensor = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 129) {
            break;
          }

          message.hwXComplianceVersion = reader.double();
          continue;
        }
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.touchScreen = reader.bool();
          continue;
        }
        case 18: {
          if (tag !== 144) {
            break;
          }

          message.isCbx = reader.bool();
          continue;
        }
        case 19: {
          if (tag !== 152) {
            break;
          }

          message.cbxFeatureType = reader.int32() as any;
          continue;
        }
        case 20: {
          if (tag !== 160) {
            break;
          }

          message.isMixedX = reader.bool();
          continue;
        }
        case 21: {
          if (tag !== 168) {
            break;
          }

          message.hasWifiBt = reader.bool();
          continue;
        }
        case 22: {
          if (tag !== 178) {
            break;
          }

          message.wifiBluetooth = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssetInfo {
    return {
      assetTag: isSet(object.assetTag) ? globalThis.String(object.assetTag) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      costCenter: isSet(object.costCenter) ? globalThis.String(object.costCenter) : "",
      googleCodeName: isSet(object.googleCodeName) ? globalThis.String(object.googleCodeName) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      buildTarget: isSet(object.buildTarget) ? globalThis.String(object.buildTarget) : "",
      referenceBoard: isSet(object.referenceBoard) ? globalThis.String(object.referenceBoard) : "",
      ethernetMacAddress: isSet(object.ethernetMacAddress) ? globalThis.String(object.ethernetMacAddress) : "",
      sku: isSet(object.sku) ? globalThis.String(object.sku) : "",
      phase: isSet(object.phase) ? globalThis.String(object.phase) : "",
      hwid: isSet(object.hwid) ? globalThis.String(object.hwid) : "",
      gpn: isSet(object.gpn) ? globalThis.String(object.gpn) : "",
      referenceDesign: isSet(object.referenceDesign) ? globalThis.String(object.referenceDesign) : "",
      productStatus: isSet(object.productStatus) ? globalThis.String(object.productStatus) : "",
      fingerprintSensor: isSet(object.fingerprintSensor) ? globalThis.Boolean(object.fingerprintSensor) : false,
      hwXComplianceVersion: isSet(object.hwXComplianceVersion) ? globalThis.Number(object.hwXComplianceVersion) : 0,
      touchScreen: isSet(object.touchScreen) ? globalThis.Boolean(object.touchScreen) : false,
      isCbx: isSet(object.isCbx) ? globalThis.Boolean(object.isCbx) : false,
      cbxFeatureType: isSet(object.cbxFeatureType) ? chassisXBrandTypeFromJSON(object.cbxFeatureType) : 0,
      isMixedX: isSet(object.isMixedX) ? globalThis.Boolean(object.isMixedX) : false,
      hasWifiBt: isSet(object.hasWifiBt) ? globalThis.Boolean(object.hasWifiBt) : false,
      wifiBluetooth: isSet(object.wifiBluetooth) ? globalThis.String(object.wifiBluetooth) : "",
    };
  },

  toJSON(message: AssetInfo): unknown {
    const obj: any = {};
    if (message.assetTag !== "") {
      obj.assetTag = message.assetTag;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.costCenter !== "") {
      obj.costCenter = message.costCenter;
    }
    if (message.googleCodeName !== "") {
      obj.googleCodeName = message.googleCodeName;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.buildTarget !== "") {
      obj.buildTarget = message.buildTarget;
    }
    if (message.referenceBoard !== "") {
      obj.referenceBoard = message.referenceBoard;
    }
    if (message.ethernetMacAddress !== "") {
      obj.ethernetMacAddress = message.ethernetMacAddress;
    }
    if (message.sku !== "") {
      obj.sku = message.sku;
    }
    if (message.phase !== "") {
      obj.phase = message.phase;
    }
    if (message.hwid !== "") {
      obj.hwid = message.hwid;
    }
    if (message.gpn !== "") {
      obj.gpn = message.gpn;
    }
    if (message.referenceDesign !== "") {
      obj.referenceDesign = message.referenceDesign;
    }
    if (message.productStatus !== "") {
      obj.productStatus = message.productStatus;
    }
    if (message.fingerprintSensor !== false) {
      obj.fingerprintSensor = message.fingerprintSensor;
    }
    if (message.hwXComplianceVersion !== 0) {
      obj.hwXComplianceVersion = message.hwXComplianceVersion;
    }
    if (message.touchScreen !== false) {
      obj.touchScreen = message.touchScreen;
    }
    if (message.isCbx !== false) {
      obj.isCbx = message.isCbx;
    }
    if (message.cbxFeatureType !== 0) {
      obj.cbxFeatureType = chassisXBrandTypeToJSON(message.cbxFeatureType);
    }
    if (message.isMixedX !== false) {
      obj.isMixedX = message.isMixedX;
    }
    if (message.hasWifiBt !== false) {
      obj.hasWifiBt = message.hasWifiBt;
    }
    if (message.wifiBluetooth !== "") {
      obj.wifiBluetooth = message.wifiBluetooth;
    }
    return obj;
  },

  create(base?: DeepPartial<AssetInfo>): AssetInfo {
    return AssetInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssetInfo>): AssetInfo {
    const message = createBaseAssetInfo() as any;
    message.assetTag = object.assetTag ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.costCenter = object.costCenter ?? "";
    message.googleCodeName = object.googleCodeName ?? "";
    message.model = object.model ?? "";
    message.buildTarget = object.buildTarget ?? "";
    message.referenceBoard = object.referenceBoard ?? "";
    message.ethernetMacAddress = object.ethernetMacAddress ?? "";
    message.sku = object.sku ?? "";
    message.phase = object.phase ?? "";
    message.hwid = object.hwid ?? "";
    message.gpn = object.gpn ?? "";
    message.referenceDesign = object.referenceDesign ?? "";
    message.productStatus = object.productStatus ?? "";
    message.fingerprintSensor = object.fingerprintSensor ?? false;
    message.hwXComplianceVersion = object.hwXComplianceVersion ?? 0;
    message.touchScreen = object.touchScreen ?? false;
    message.isCbx = object.isCbx ?? false;
    message.cbxFeatureType = object.cbxFeatureType ?? 0;
    message.isMixedX = object.isMixedX ?? false;
    message.hasWifiBt = object.hasWifiBt ?? false;
    message.wifiBluetooth = object.wifiBluetooth ?? "";
    return message;
  },
};

function createBaseAssetInfoRequest(): AssetInfoRequest {
  return { assetTags: [] };
}

export const AssetInfoRequest: MessageFns<AssetInfoRequest> = {
  encode(message: AssetInfoRequest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.assetTags) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AssetInfoRequest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetInfoRequest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.assetTags.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssetInfoRequest {
    return {
      assetTags: globalThis.Array.isArray(object?.assetTags)
        ? object.assetTags.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: AssetInfoRequest): unknown {
    const obj: any = {};
    if (message.assetTags?.length) {
      obj.assetTags = message.assetTags;
    }
    return obj;
  },

  create(base?: DeepPartial<AssetInfoRequest>): AssetInfoRequest {
    return AssetInfoRequest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssetInfoRequest>): AssetInfoRequest {
    const message = createBaseAssetInfoRequest() as any;
    message.assetTags = object.assetTags?.map((e) => e) || [];
    return message;
  },
};

function createBaseAssetInfoResponse(): AssetInfoResponse {
  return { requestStatus: 0, assets: [], missingAssetTags: [], failedAssetTags: [] };
}

export const AssetInfoResponse: MessageFns<AssetInfoResponse> = {
  encode(message: AssetInfoResponse, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.requestStatus !== 0) {
      writer.uint32(8).int32(message.requestStatus);
    }
    for (const v of message.assets) {
      AssetInfo.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.missingAssetTags) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.failedAssetTags) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AssetInfoResponse {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAssetInfoResponse() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.requestStatus = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.assets.push(AssetInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.missingAssetTags.push(reader.string());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.failedAssetTags.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AssetInfoResponse {
    return {
      requestStatus: isSet(object.requestStatus) ? requestStatusFromJSON(object.requestStatus) : 0,
      assets: globalThis.Array.isArray(object?.assets) ? object.assets.map((e: any) => AssetInfo.fromJSON(e)) : [],
      missingAssetTags: globalThis.Array.isArray(object?.missingAssetTags)
        ? object.missingAssetTags.map((e: any) => globalThis.String(e))
        : [],
      failedAssetTags: globalThis.Array.isArray(object?.failedAssetTags)
        ? object.failedAssetTags.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: AssetInfoResponse): unknown {
    const obj: any = {};
    if (message.requestStatus !== 0) {
      obj.requestStatus = requestStatusToJSON(message.requestStatus);
    }
    if (message.assets?.length) {
      obj.assets = message.assets.map((e) => AssetInfo.toJSON(e));
    }
    if (message.missingAssetTags?.length) {
      obj.missingAssetTags = message.missingAssetTags;
    }
    if (message.failedAssetTags?.length) {
      obj.failedAssetTags = message.failedAssetTags;
    }
    return obj;
  },

  create(base?: DeepPartial<AssetInfoResponse>): AssetInfoResponse {
    return AssetInfoResponse.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AssetInfoResponse>): AssetInfoResponse {
    const message = createBaseAssetInfoResponse() as any;
    message.requestStatus = object.requestStatus ?? 0;
    message.assets = object.assets?.map((e) => AssetInfo.fromPartial(e)) || [];
    message.missingAssetTags = object.missingAssetTags?.map((e) => e) || [];
    message.failedAssetTags = object.failedAssetTags?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
