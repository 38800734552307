// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/machine_lse.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../../google/protobuf/timestamp.pb";
import { DeviceUnderTest as DeviceUnderTest1 } from "../../../../libs/skylab/inventory/device.pb";
import { Config } from "./chromeos/device/config.pb";
import { Devboard, DeviceUnderTest, Labstation } from "./chromeos/lab/device.pb";
import { DutState } from "./chromeos/lab/dut_state.pb";
import { ServoTopology } from "./chromeos/lab/servo.pb";
import { ManufacturingConfig } from "./chromeos/manufacturing/config.pb";
import { HwidData } from "./hwid.pb";
import { Machine } from "./machine.pb";
import { OwnershipData } from "./ownership.pb";
import { RPMInterface, SwitchInterface } from "./peripherals.pb";
import { Secret } from "./secret.pb";
import { State, stateFromJSON, stateToJSON } from "./state.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

/** LogicalZone denotes a non-physical entity grouping */
export enum LogicalZone {
  LOGICAL_ZONE_UNSPECIFIED = 0,
  LOGICAL_ZONE_DRILLZONE_SFO36 = 1,
}

export function logicalZoneFromJSON(object: any): LogicalZone {
  switch (object) {
    case 0:
    case "LOGICAL_ZONE_UNSPECIFIED":
      return LogicalZone.LOGICAL_ZONE_UNSPECIFIED;
    case 1:
    case "LOGICAL_ZONE_DRILLZONE_SFO36":
      return LogicalZone.LOGICAL_ZONE_DRILLZONE_SFO36;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LogicalZone");
  }
}

export function logicalZoneToJSON(object: LogicalZone): string {
  switch (object) {
    case LogicalZone.LOGICAL_ZONE_UNSPECIFIED:
      return "LOGICAL_ZONE_UNSPECIFIED";
    case LogicalZone.LOGICAL_ZONE_DRILLZONE_SFO36:
      return "LOGICAL_ZONE_DRILLZONE_SFO36";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LogicalZone");
  }
}

/**
 * MachineLSE is the Machine Lab Setup Environment.
 * It refers to the entity in the lab which has Machine(s) associated
 * with it. It also has other components associated with it.
 *
 * Next tag: 28
 */
export interface MachineLSE {
  /**
   * This is the unique hostname of the MachineLSE
   * The format will be machineLSEs/XXX
   */
  readonly name: string;
  /**
   * The prototype that this machine LSE should follow. System will use this
   * prototype to detect if the LSE is completed or valid.
   */
  readonly machineLsePrototype: string;
  /** The hostname is also recorded in DHCP configs */
  readonly hostname: string;
  readonly chromeBrowserMachineLse?: ChromeBrowserMachineLSE | undefined;
  readonly chromeosMachineLse?:
    | ChromeOSMachineLSE
    | undefined;
  /** AttachedDeviceLSE refers to the attached device LSE to be tracked. */
  readonly attachedDeviceLse?:
    | AttachedDeviceLSE
    | undefined;
  /**
   * The machines that this LSE is linked to. No machine is linked if it's empty.
   * A machine cannot be linked to multiple LSEs (no use case for now).
   */
  readonly machines: readonly string[];
  /** Record the last update timestamp of this MachineLSE (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** The nic used to associate with the hostname, as one machine/host may contain multiple nics. */
  readonly nic: string;
  /**
   * It refers to which vlan the host is in.
   * Please note that user can also specify it for auto-assigning a host's IP.
   */
  readonly vlan: string;
  /**
   * It refers to which ip the host is assigned to.
   * Please note that user can also specify it for assigning ip.
   */
  readonly ip: string;
  /** Refers to Rack name */
  readonly rack: string;
  /** Refer to the manufacturer name of the attached machine for the host */
  readonly manufacturer: string;
  /** tags user can attach for easy querying/searching */
  readonly tags: readonly string[];
  /** Refers to Zone */
  readonly zone: string;
  /** The deployment ticket associated with this host. */
  readonly deploymentTicket: string;
  /** A description of this host. */
  readonly description: string;
  /** State of the host resource */
  readonly resourceState: State;
  /**
   * Schedulable field will determine if a MachineLSE should be exposed to the
   * scheduling layer (currently Swarming) or not.
   */
  readonly schedulable: boolean;
  /** Ownership information of the host like ResourceGroup, Security level etc. */
  readonly ownership:
    | OwnershipData
    | undefined;
  /** A virtual (non-physical) grouping */
  readonly logicalZone: LogicalZone;
  /** Realm this machine_lse belongs to. Shouldn't be set by the user */
  readonly realm: string;
  /** The secret for wifi tests. */
  readonly wifiSecret:
    | Secret
    | undefined;
  /**
   * This specifies the maintenance config that this host belongs to.
   * Please note that same device can have different hostname and then has different maintenance requirements.
   * So this maintenance config is attached to a host, instead of a machine.
   */
  readonly maintenanceConfigName: string;
}

/**
 * ChromeBrowserMachineLSE refers to the entity
 * which hosts the vms in Chrome Browser lab
 */
export interface ChromeBrowserMachineLSE {
  /**
   * Indicate if VM is needed to set up
   * number of vms on the host
   */
  readonly vms: readonly VM[];
  /** The allowed vm slots for this browser machine, usually it's manually set based on sysadmin's judgement. */
  readonly vmCapacity: number;
  /** Indicate the os version of the machine itself. */
  readonly osVersion:
    | OSVersion
    | undefined;
  /** The virtual datacenter VMs deployed on this host belong to. */
  readonly virtualDatacenter: string;
}

export interface VM {
  /** A unique vm name */
  readonly name: string;
  readonly osVersion: OSVersion | undefined;
  readonly macAddress: string;
  readonly hostname: string;
  readonly vlan: string;
  /**
   * It refers to which ip the vm is assigned to.
   * Please note that user can also specify it for assigning ip.
   */
  readonly ip: string;
  readonly machineLseId: string;
  /** Record the last update timestamp of this VM (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** tags user can attach for easy querying/searching */
  readonly tags: readonly string[];
  /** Refers to Zone */
  readonly zone: string;
  /** A description of this vm. */
  readonly description: string;
  /** State of the vm resource */
  readonly resourceState: State;
  /** The deployment ticket associated with this vm. */
  readonly deploymentTicket: string;
  /** Ownership information of the host like ResourceGroup, Security level etc. */
  readonly ownership:
    | OwnershipData
    | undefined;
  /** Number of CPUs (eg. 8) */
  readonly cpuCores: number;
  /** Amount of memory in bytes assigned (eg. 8 000 000 000) */
  readonly memory: string;
  /** Disk capacity in bytes assigned (eg. 512 000 000 000) */
  readonly storage: string;
}

/**
 * OSVersion refers to the version of Operating System
 * for machines running in Chrome Browser lab
 */
export interface OSVersion {
  readonly value: string;
  readonly description: string;
  readonly image: string;
}

/**
 * ChromeOSMachineLSE refers to the entity in Chrome OS lab
 * which can be a Device or a server
 */
export interface ChromeOSMachineLSE {
  /** @deprecated */
  readonly dut?:
    | ChromeOSDeviceLSE
    | undefined;
  /** @deprecated */
  readonly server?: ChromeOSServerLSE | undefined;
  readonly deviceLse?: ChromeOSDeviceLSE | undefined;
  readonly serverLse?: ChromeOSServerLSE | undefined;
}

/**
 * ChromeOSDeviceLSE refers to the entity in Chrome OS lab,
 * e.g., DUT, test Bed, Labstation
 */
export interface ChromeOSDeviceLSE {
  /** @deprecated */
  readonly config: DeviceUnderTest | undefined;
  readonly rpmInterface: RPMInterface | undefined;
  readonly networkDeviceInterface: SwitchInterface | undefined;
  readonly dut?: DeviceUnderTest | undefined;
  readonly labstation?: Labstation | undefined;
  readonly devboard?: Devboard | undefined;
}

export interface DutMeta {
  readonly chromeosDeviceId: string;
  readonly hostname: string;
  readonly serialNumber: string;
  readonly hwID: string;
  readonly deviceSku: string;
}

export interface LabMeta {
  readonly chromeosDeviceId: string;
  readonly hostname: string;
  readonly servoType: string;
  readonly smartUsbhub: boolean;
  readonly servoTopology: ServoTopology | undefined;
}

/** NEXT TAG: 11 */
export interface ChromeOSDeviceData {
  readonly labConfig: MachineLSE | undefined;
  readonly machine:
    | Machine
    | undefined;
  /** @deprecated */
  readonly deviceConfig: Config | undefined;
  readonly manufacturingConfig: ManufacturingConfig | undefined;
  readonly hwidData: HwidData | undefined;
  readonly dutState: DutState | undefined;
  readonly dutV1: DeviceUnderTest1 | undefined;
  readonly recoveryConfig:
    | RecoveryConfig
    | undefined;
  /**
   * A map of schedulable labels generated by UFS from LabConfig, StateConfig,
   * HwidData.
   */
  readonly schedulableLabels: { [key: string]: SchedulableLabelValues };
  readonly respectAutomatedSchedulableLabels: boolean;
}

export interface ChromeOSDeviceData_SchedulableLabelsEntry {
  readonly key: string;
  readonly value: SchedulableLabelValues | undefined;
}

/** RecoveryConfig used for PARIS to read addition device info to run actions. */
export interface RecoveryConfig {
  /**
   * String representation value of Config_PowerSupply from field Power.
   * Possible values: POWER_SUPPLY_UNSPECIFIED, POWER_SUPPLY_BATTERY, POWER_SUPPLY_AC_ONLY.
   * Used to decide whether to power off the device under test before booting into recovery mode.
   */
  readonly powerSupply: string;
  /**
   * String representation value of Config_Storage from field Storage.
   * Possible values: STORAGE_SSD, STORAGE_NVME, e.t.c.
   * Used to compare whether the device has a different storage type.
   */
  readonly storage: string;
  /**
   * String representation values of Config_HardwareFeature from field HardwareFeatures.
   * Possible values: POWER_SUPPLY_UNSPECIFIED, POWER_SUPPLY_BATTERY, POWER_SUPPLY_AC_ONLY.
   * Used to determine what component is expected to be found on the device.
   */
  readonly hardwareFeatures: readonly string[];
}

/** SchedulableLabelValues is an array of schedulable label values. */
export interface SchedulableLabelValues {
  readonly labelValues: readonly string[];
}

/**
 * ChromeOSServerLSE refers to the entity in Chrome OS lab
 * which is a server. Servers serve as different roles, e.g. devservers,
 * for caching chromeos images, and drones, for running GKE pods to
 * maintain the swarming bots that maps to the real chromeos devices.
 */
export interface ChromeOSServerLSE {
  /** The vlan that this server is going to serve */
  readonly supportedRestrictedVlan: string;
  readonly servicePort: number;
}

/**
 * AttachedDeviceLSE refers to the entity in either Chrome OS or Browser lab
 * which is a USB-device.
 *
 * Next Tag: 4
 */
export interface AttachedDeviceLSE {
  /**
   * The OS version that is running in the attached device (if applicable). E.g.
   * Android 12, iOS 12.5.3.
   */
  readonly osVersion:
    | OSVersion
    | undefined;
  /**
   * The hostname of the device that the attached device is attached to.
   * Examples:
   *   1. A webcam (attached device) attached to a laptop (host).
   *   2. A phone (attached device) attached to a Dell server (host).
   */
  readonly associatedHostname: string;
  /** The port used of the device that the attached device is attached to. */
  readonly associatedHostPort: string;
}

function createBaseMachineLSE(): MachineLSE {
  return {
    name: "",
    machineLsePrototype: "",
    hostname: "",
    chromeBrowserMachineLse: undefined,
    chromeosMachineLse: undefined,
    attachedDeviceLse: undefined,
    machines: [],
    updateTime: undefined,
    nic: "",
    vlan: "",
    ip: "",
    rack: "",
    manufacturer: "",
    tags: [],
    zone: "",
    deploymentTicket: "",
    description: "",
    resourceState: 0,
    schedulable: false,
    ownership: undefined,
    logicalZone: 0,
    realm: "",
    wifiSecret: undefined,
    maintenanceConfigName: "",
  };
}

export const MachineLSE: MessageFns<MachineLSE> = {
  encode(message: MachineLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.machineLsePrototype !== "") {
      writer.uint32(18).string(message.machineLsePrototype);
    }
    if (message.hostname !== "") {
      writer.uint32(26).string(message.hostname);
    }
    if (message.chromeBrowserMachineLse !== undefined) {
      ChromeBrowserMachineLSE.encode(message.chromeBrowserMachineLse, writer.uint32(34).fork()).join();
    }
    if (message.chromeosMachineLse !== undefined) {
      ChromeOSMachineLSE.encode(message.chromeosMachineLse, writer.uint32(42).fork()).join();
    }
    if (message.attachedDeviceLse !== undefined) {
      AttachedDeviceLSE.encode(message.attachedDeviceLse, writer.uint32(162).fork()).join();
    }
    for (const v of message.machines) {
      writer.uint32(50).string(v!);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(58).fork()).join();
    }
    if (message.nic !== "") {
      writer.uint32(66).string(message.nic);
    }
    if (message.vlan !== "") {
      writer.uint32(146).string(message.vlan);
    }
    if (message.ip !== "") {
      writer.uint32(154).string(message.ip);
    }
    if (message.rack !== "") {
      writer.uint32(82).string(message.rack);
    }
    if (message.manufacturer !== "") {
      writer.uint32(90).string(message.manufacturer);
    }
    for (const v of message.tags) {
      writer.uint32(98).string(v!);
    }
    if (message.zone !== "") {
      writer.uint32(114).string(message.zone);
    }
    if (message.deploymentTicket !== "") {
      writer.uint32(122).string(message.deploymentTicket);
    }
    if (message.description !== "") {
      writer.uint32(130).string(message.description);
    }
    if (message.resourceState !== 0) {
      writer.uint32(136).int32(message.resourceState);
    }
    if (message.schedulable !== false) {
      writer.uint32(168).bool(message.schedulable);
    }
    if (message.ownership !== undefined) {
      OwnershipData.encode(message.ownership, writer.uint32(178).fork()).join();
    }
    if (message.logicalZone !== 0) {
      writer.uint32(192).int32(message.logicalZone);
    }
    if (message.realm !== "") {
      writer.uint32(202).string(message.realm);
    }
    if (message.wifiSecret !== undefined) {
      Secret.encode(message.wifiSecret, writer.uint32(210).fork()).join();
    }
    if (message.maintenanceConfigName !== "") {
      writer.uint32(218).string(message.maintenanceConfigName);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MachineLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachineLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machineLsePrototype = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.chromeBrowserMachineLse = ChromeBrowserMachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.chromeosMachineLse = ChromeOSMachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 20: {
          if (tag !== 162) {
            break;
          }

          message.attachedDeviceLse = AttachedDeviceLSE.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.machines.push(reader.string());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.nic = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.vlan = reader.string();
          continue;
        }
        case 19: {
          if (tag !== 154) {
            break;
          }

          message.ip = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.rack = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.manufacturer = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.zone = reader.string();
          continue;
        }
        case 15: {
          if (tag !== 122) {
            break;
          }

          message.deploymentTicket = reader.string();
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 21: {
          if (tag !== 168) {
            break;
          }

          message.schedulable = reader.bool();
          continue;
        }
        case 22: {
          if (tag !== 178) {
            break;
          }

          message.ownership = OwnershipData.decode(reader, reader.uint32());
          continue;
        }
        case 24: {
          if (tag !== 192) {
            break;
          }

          message.logicalZone = reader.int32() as any;
          continue;
        }
        case 25: {
          if (tag !== 202) {
            break;
          }

          message.realm = reader.string();
          continue;
        }
        case 26: {
          if (tag !== 210) {
            break;
          }

          message.wifiSecret = Secret.decode(reader, reader.uint32());
          continue;
        }
        case 27: {
          if (tag !== 218) {
            break;
          }

          message.maintenanceConfigName = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MachineLSE {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      machineLsePrototype: isSet(object.machineLsePrototype) ? globalThis.String(object.machineLsePrototype) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      chromeBrowserMachineLse: isSet(object.chromeBrowserMachineLse)
        ? ChromeBrowserMachineLSE.fromJSON(object.chromeBrowserMachineLse)
        : undefined,
      chromeosMachineLse: isSet(object.chromeosMachineLse)
        ? ChromeOSMachineLSE.fromJSON(object.chromeosMachineLse)
        : undefined,
      attachedDeviceLse: isSet(object.attachedDeviceLse)
        ? AttachedDeviceLSE.fromJSON(object.attachedDeviceLse)
        : undefined,
      machines: globalThis.Array.isArray(object?.machines) ? object.machines.map((e: any) => globalThis.String(e)) : [],
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      nic: isSet(object.nic) ? globalThis.String(object.nic) : "",
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      rack: isSet(object.rack) ? globalThis.String(object.rack) : "",
      manufacturer: isSet(object.manufacturer) ? globalThis.String(object.manufacturer) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      zone: isSet(object.zone) ? globalThis.String(object.zone) : "",
      deploymentTicket: isSet(object.deploymentTicket) ? globalThis.String(object.deploymentTicket) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      schedulable: isSet(object.schedulable) ? globalThis.Boolean(object.schedulable) : false,
      ownership: isSet(object.ownership) ? OwnershipData.fromJSON(object.ownership) : undefined,
      logicalZone: isSet(object.logicalZone) ? logicalZoneFromJSON(object.logicalZone) : 0,
      realm: isSet(object.realm) ? globalThis.String(object.realm) : "",
      wifiSecret: isSet(object.wifiSecret) ? Secret.fromJSON(object.wifiSecret) : undefined,
      maintenanceConfigName: isSet(object.maintenanceConfigName) ? globalThis.String(object.maintenanceConfigName) : "",
    };
  },

  toJSON(message: MachineLSE): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.machineLsePrototype !== "") {
      obj.machineLsePrototype = message.machineLsePrototype;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.chromeBrowserMachineLse !== undefined) {
      obj.chromeBrowserMachineLse = ChromeBrowserMachineLSE.toJSON(message.chromeBrowserMachineLse);
    }
    if (message.chromeosMachineLse !== undefined) {
      obj.chromeosMachineLse = ChromeOSMachineLSE.toJSON(message.chromeosMachineLse);
    }
    if (message.attachedDeviceLse !== undefined) {
      obj.attachedDeviceLse = AttachedDeviceLSE.toJSON(message.attachedDeviceLse);
    }
    if (message.machines?.length) {
      obj.machines = message.machines;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.nic !== "") {
      obj.nic = message.nic;
    }
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.rack !== "") {
      obj.rack = message.rack;
    }
    if (message.manufacturer !== "") {
      obj.manufacturer = message.manufacturer;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.zone !== "") {
      obj.zone = message.zone;
    }
    if (message.deploymentTicket !== "") {
      obj.deploymentTicket = message.deploymentTicket;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.schedulable !== false) {
      obj.schedulable = message.schedulable;
    }
    if (message.ownership !== undefined) {
      obj.ownership = OwnershipData.toJSON(message.ownership);
    }
    if (message.logicalZone !== 0) {
      obj.logicalZone = logicalZoneToJSON(message.logicalZone);
    }
    if (message.realm !== "") {
      obj.realm = message.realm;
    }
    if (message.wifiSecret !== undefined) {
      obj.wifiSecret = Secret.toJSON(message.wifiSecret);
    }
    if (message.maintenanceConfigName !== "") {
      obj.maintenanceConfigName = message.maintenanceConfigName;
    }
    return obj;
  },

  create(base?: DeepPartial<MachineLSE>): MachineLSE {
    return MachineLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MachineLSE>): MachineLSE {
    const message = createBaseMachineLSE() as any;
    message.name = object.name ?? "";
    message.machineLsePrototype = object.machineLsePrototype ?? "";
    message.hostname = object.hostname ?? "";
    message.chromeBrowserMachineLse =
      (object.chromeBrowserMachineLse !== undefined && object.chromeBrowserMachineLse !== null)
        ? ChromeBrowserMachineLSE.fromPartial(object.chromeBrowserMachineLse)
        : undefined;
    message.chromeosMachineLse = (object.chromeosMachineLse !== undefined && object.chromeosMachineLse !== null)
      ? ChromeOSMachineLSE.fromPartial(object.chromeosMachineLse)
      : undefined;
    message.attachedDeviceLse = (object.attachedDeviceLse !== undefined && object.attachedDeviceLse !== null)
      ? AttachedDeviceLSE.fromPartial(object.attachedDeviceLse)
      : undefined;
    message.machines = object.machines?.map((e) => e) || [];
    message.updateTime = object.updateTime ?? undefined;
    message.nic = object.nic ?? "";
    message.vlan = object.vlan ?? "";
    message.ip = object.ip ?? "";
    message.rack = object.rack ?? "";
    message.manufacturer = object.manufacturer ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.zone = object.zone ?? "";
    message.deploymentTicket = object.deploymentTicket ?? "";
    message.description = object.description ?? "";
    message.resourceState = object.resourceState ?? 0;
    message.schedulable = object.schedulable ?? false;
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipData.fromPartial(object.ownership)
      : undefined;
    message.logicalZone = object.logicalZone ?? 0;
    message.realm = object.realm ?? "";
    message.wifiSecret = (object.wifiSecret !== undefined && object.wifiSecret !== null)
      ? Secret.fromPartial(object.wifiSecret)
      : undefined;
    message.maintenanceConfigName = object.maintenanceConfigName ?? "";
    return message;
  },
};

function createBaseChromeBrowserMachineLSE(): ChromeBrowserMachineLSE {
  return { vms: [], vmCapacity: 0, osVersion: undefined, virtualDatacenter: "" };
}

export const ChromeBrowserMachineLSE: MessageFns<ChromeBrowserMachineLSE> = {
  encode(message: ChromeBrowserMachineLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.vms) {
      VM.encode(v!, writer.uint32(10).fork()).join();
    }
    if (message.vmCapacity !== 0) {
      writer.uint32(16).int32(message.vmCapacity);
    }
    if (message.osVersion !== undefined) {
      OSVersion.encode(message.osVersion, writer.uint32(26).fork()).join();
    }
    if (message.virtualDatacenter !== "") {
      writer.uint32(34).string(message.virtualDatacenter);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeBrowserMachineLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeBrowserMachineLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.vms.push(VM.decode(reader, reader.uint32()));
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.vmCapacity = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.osVersion = OSVersion.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.virtualDatacenter = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeBrowserMachineLSE {
    return {
      vms: globalThis.Array.isArray(object?.vms) ? object.vms.map((e: any) => VM.fromJSON(e)) : [],
      vmCapacity: isSet(object.vmCapacity) ? globalThis.Number(object.vmCapacity) : 0,
      osVersion: isSet(object.osVersion) ? OSVersion.fromJSON(object.osVersion) : undefined,
      virtualDatacenter: isSet(object.virtualDatacenter) ? globalThis.String(object.virtualDatacenter) : "",
    };
  },

  toJSON(message: ChromeBrowserMachineLSE): unknown {
    const obj: any = {};
    if (message.vms?.length) {
      obj.vms = message.vms.map((e) => VM.toJSON(e));
    }
    if (message.vmCapacity !== 0) {
      obj.vmCapacity = Math.round(message.vmCapacity);
    }
    if (message.osVersion !== undefined) {
      obj.osVersion = OSVersion.toJSON(message.osVersion);
    }
    if (message.virtualDatacenter !== "") {
      obj.virtualDatacenter = message.virtualDatacenter;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeBrowserMachineLSE>): ChromeBrowserMachineLSE {
    return ChromeBrowserMachineLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeBrowserMachineLSE>): ChromeBrowserMachineLSE {
    const message = createBaseChromeBrowserMachineLSE() as any;
    message.vms = object.vms?.map((e) => VM.fromPartial(e)) || [];
    message.vmCapacity = object.vmCapacity ?? 0;
    message.osVersion = (object.osVersion !== undefined && object.osVersion !== null)
      ? OSVersion.fromPartial(object.osVersion)
      : undefined;
    message.virtualDatacenter = object.virtualDatacenter ?? "";
    return message;
  },
};

function createBaseVM(): VM {
  return {
    name: "",
    osVersion: undefined,
    macAddress: "",
    hostname: "",
    vlan: "",
    ip: "",
    machineLseId: "",
    updateTime: undefined,
    tags: [],
    zone: "",
    description: "",
    resourceState: 0,
    deploymentTicket: "",
    ownership: undefined,
    cpuCores: 0,
    memory: "0",
    storage: "0",
  };
}

export const VM: MessageFns<VM> = {
  encode(message: VM, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.osVersion !== undefined) {
      OSVersion.encode(message.osVersion, writer.uint32(18).fork()).join();
    }
    if (message.macAddress !== "") {
      writer.uint32(26).string(message.macAddress);
    }
    if (message.hostname !== "") {
      writer.uint32(34).string(message.hostname);
    }
    if (message.vlan !== "") {
      writer.uint32(42).string(message.vlan);
    }
    if (message.ip !== "") {
      writer.uint32(122).string(message.ip);
    }
    if (message.machineLseId !== "") {
      writer.uint32(58).string(message.machineLseId);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(74).fork()).join();
    }
    for (const v of message.tags) {
      writer.uint32(82).string(v!);
    }
    if (message.zone !== "") {
      writer.uint32(90).string(message.zone);
    }
    if (message.description !== "") {
      writer.uint32(98).string(message.description);
    }
    if (message.resourceState !== 0) {
      writer.uint32(104).int32(message.resourceState);
    }
    if (message.deploymentTicket !== "") {
      writer.uint32(114).string(message.deploymentTicket);
    }
    if (message.ownership !== undefined) {
      OwnershipData.encode(message.ownership, writer.uint32(130).fork()).join();
    }
    if (message.cpuCores !== 0) {
      writer.uint32(136).int32(message.cpuCores);
    }
    if (message.memory !== "0") {
      writer.uint32(144).int64(message.memory);
    }
    if (message.storage !== "0") {
      writer.uint32(152).int64(message.storage);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VM {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVM() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.osVersion = OSVersion.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.vlan = reader.string();
          continue;
        }
        case 15: {
          if (tag !== 122) {
            break;
          }

          message.ip = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.machineLseId = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.zone = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.deploymentTicket = reader.string();
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.ownership = OwnershipData.decode(reader, reader.uint32());
          continue;
        }
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.cpuCores = reader.int32();
          continue;
        }
        case 18: {
          if (tag !== 144) {
            break;
          }

          message.memory = reader.int64().toString();
          continue;
        }
        case 19: {
          if (tag !== 152) {
            break;
          }

          message.storage = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VM {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      osVersion: isSet(object.osVersion) ? OSVersion.fromJSON(object.osVersion) : undefined,
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      machineLseId: isSet(object.machineLseId) ? globalThis.String(object.machineLseId) : "",
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      zone: isSet(object.zone) ? globalThis.String(object.zone) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      deploymentTicket: isSet(object.deploymentTicket) ? globalThis.String(object.deploymentTicket) : "",
      ownership: isSet(object.ownership) ? OwnershipData.fromJSON(object.ownership) : undefined,
      cpuCores: isSet(object.cpuCores) ? globalThis.Number(object.cpuCores) : 0,
      memory: isSet(object.memory) ? globalThis.String(object.memory) : "0",
      storage: isSet(object.storage) ? globalThis.String(object.storage) : "0",
    };
  },

  toJSON(message: VM): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.osVersion !== undefined) {
      obj.osVersion = OSVersion.toJSON(message.osVersion);
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.machineLseId !== "") {
      obj.machineLseId = message.machineLseId;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.zone !== "") {
      obj.zone = message.zone;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.deploymentTicket !== "") {
      obj.deploymentTicket = message.deploymentTicket;
    }
    if (message.ownership !== undefined) {
      obj.ownership = OwnershipData.toJSON(message.ownership);
    }
    if (message.cpuCores !== 0) {
      obj.cpuCores = Math.round(message.cpuCores);
    }
    if (message.memory !== "0") {
      obj.memory = message.memory;
    }
    if (message.storage !== "0") {
      obj.storage = message.storage;
    }
    return obj;
  },

  create(base?: DeepPartial<VM>): VM {
    return VM.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<VM>): VM {
    const message = createBaseVM() as any;
    message.name = object.name ?? "";
    message.osVersion = (object.osVersion !== undefined && object.osVersion !== null)
      ? OSVersion.fromPartial(object.osVersion)
      : undefined;
    message.macAddress = object.macAddress ?? "";
    message.hostname = object.hostname ?? "";
    message.vlan = object.vlan ?? "";
    message.ip = object.ip ?? "";
    message.machineLseId = object.machineLseId ?? "";
    message.updateTime = object.updateTime ?? undefined;
    message.tags = object.tags?.map((e) => e) || [];
    message.zone = object.zone ?? "";
    message.description = object.description ?? "";
    message.resourceState = object.resourceState ?? 0;
    message.deploymentTicket = object.deploymentTicket ?? "";
    message.ownership = (object.ownership !== undefined && object.ownership !== null)
      ? OwnershipData.fromPartial(object.ownership)
      : undefined;
    message.cpuCores = object.cpuCores ?? 0;
    message.memory = object.memory ?? "0";
    message.storage = object.storage ?? "0";
    return message;
  },
};

function createBaseOSVersion(): OSVersion {
  return { value: "", description: "", image: "" };
}

export const OSVersion: MessageFns<OSVersion> = {
  encode(message: OSVersion, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.image !== "") {
      writer.uint32(26).string(message.image);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OSVersion {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOSVersion() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.image = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OSVersion {
    return {
      value: isSet(object.value) ? globalThis.String(object.value) : "",
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      image: isSet(object.image) ? globalThis.String(object.image) : "",
    };
  },

  toJSON(message: OSVersion): unknown {
    const obj: any = {};
    if (message.value !== "") {
      obj.value = message.value;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.image !== "") {
      obj.image = message.image;
    }
    return obj;
  },

  create(base?: DeepPartial<OSVersion>): OSVersion {
    return OSVersion.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OSVersion>): OSVersion {
    const message = createBaseOSVersion() as any;
    message.value = object.value ?? "";
    message.description = object.description ?? "";
    message.image = object.image ?? "";
    return message;
  },
};

function createBaseChromeOSMachineLSE(): ChromeOSMachineLSE {
  return { dut: undefined, server: undefined, deviceLse: undefined, serverLse: undefined };
}

export const ChromeOSMachineLSE: MessageFns<ChromeOSMachineLSE> = {
  encode(message: ChromeOSMachineLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dut !== undefined) {
      ChromeOSDeviceLSE.encode(message.dut, writer.uint32(10).fork()).join();
    }
    if (message.server !== undefined) {
      ChromeOSServerLSE.encode(message.server, writer.uint32(18).fork()).join();
    }
    if (message.deviceLse !== undefined) {
      ChromeOSDeviceLSE.encode(message.deviceLse, writer.uint32(26).fork()).join();
    }
    if (message.serverLse !== undefined) {
      ChromeOSServerLSE.encode(message.serverLse, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSMachineLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSMachineLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dut = ChromeOSDeviceLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.server = ChromeOSServerLSE.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.deviceLse = ChromeOSDeviceLSE.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.serverLse = ChromeOSServerLSE.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSMachineLSE {
    return {
      dut: isSet(object.dut) ? ChromeOSDeviceLSE.fromJSON(object.dut) : undefined,
      server: isSet(object.server) ? ChromeOSServerLSE.fromJSON(object.server) : undefined,
      deviceLse: isSet(object.deviceLse) ? ChromeOSDeviceLSE.fromJSON(object.deviceLse) : undefined,
      serverLse: isSet(object.serverLse) ? ChromeOSServerLSE.fromJSON(object.serverLse) : undefined,
    };
  },

  toJSON(message: ChromeOSMachineLSE): unknown {
    const obj: any = {};
    if (message.dut !== undefined) {
      obj.dut = ChromeOSDeviceLSE.toJSON(message.dut);
    }
    if (message.server !== undefined) {
      obj.server = ChromeOSServerLSE.toJSON(message.server);
    }
    if (message.deviceLse !== undefined) {
      obj.deviceLse = ChromeOSDeviceLSE.toJSON(message.deviceLse);
    }
    if (message.serverLse !== undefined) {
      obj.serverLse = ChromeOSServerLSE.toJSON(message.serverLse);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSMachineLSE>): ChromeOSMachineLSE {
    return ChromeOSMachineLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSMachineLSE>): ChromeOSMachineLSE {
    const message = createBaseChromeOSMachineLSE() as any;
    message.dut = (object.dut !== undefined && object.dut !== null)
      ? ChromeOSDeviceLSE.fromPartial(object.dut)
      : undefined;
    message.server = (object.server !== undefined && object.server !== null)
      ? ChromeOSServerLSE.fromPartial(object.server)
      : undefined;
    message.deviceLse = (object.deviceLse !== undefined && object.deviceLse !== null)
      ? ChromeOSDeviceLSE.fromPartial(object.deviceLse)
      : undefined;
    message.serverLse = (object.serverLse !== undefined && object.serverLse !== null)
      ? ChromeOSServerLSE.fromPartial(object.serverLse)
      : undefined;
    return message;
  },
};

function createBaseChromeOSDeviceLSE(): ChromeOSDeviceLSE {
  return {
    config: undefined,
    rpmInterface: undefined,
    networkDeviceInterface: undefined,
    dut: undefined,
    labstation: undefined,
    devboard: undefined,
  };
}

export const ChromeOSDeviceLSE: MessageFns<ChromeOSDeviceLSE> = {
  encode(message: ChromeOSDeviceLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.config !== undefined) {
      DeviceUnderTest.encode(message.config, writer.uint32(10).fork()).join();
    }
    if (message.rpmInterface !== undefined) {
      RPMInterface.encode(message.rpmInterface, writer.uint32(18).fork()).join();
    }
    if (message.networkDeviceInterface !== undefined) {
      SwitchInterface.encode(message.networkDeviceInterface, writer.uint32(26).fork()).join();
    }
    if (message.dut !== undefined) {
      DeviceUnderTest.encode(message.dut, writer.uint32(34).fork()).join();
    }
    if (message.labstation !== undefined) {
      Labstation.encode(message.labstation, writer.uint32(42).fork()).join();
    }
    if (message.devboard !== undefined) {
      Devboard.encode(message.devboard, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSDeviceLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSDeviceLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.config = DeviceUnderTest.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rpmInterface = RPMInterface.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.networkDeviceInterface = SwitchInterface.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.dut = DeviceUnderTest.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.labstation = Labstation.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.devboard = Devboard.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSDeviceLSE {
    return {
      config: isSet(object.config) ? DeviceUnderTest.fromJSON(object.config) : undefined,
      rpmInterface: isSet(object.rpmInterface) ? RPMInterface.fromJSON(object.rpmInterface) : undefined,
      networkDeviceInterface: isSet(object.networkDeviceInterface)
        ? SwitchInterface.fromJSON(object.networkDeviceInterface)
        : undefined,
      dut: isSet(object.dut) ? DeviceUnderTest.fromJSON(object.dut) : undefined,
      labstation: isSet(object.labstation) ? Labstation.fromJSON(object.labstation) : undefined,
      devboard: isSet(object.devboard) ? Devboard.fromJSON(object.devboard) : undefined,
    };
  },

  toJSON(message: ChromeOSDeviceLSE): unknown {
    const obj: any = {};
    if (message.config !== undefined) {
      obj.config = DeviceUnderTest.toJSON(message.config);
    }
    if (message.rpmInterface !== undefined) {
      obj.rpmInterface = RPMInterface.toJSON(message.rpmInterface);
    }
    if (message.networkDeviceInterface !== undefined) {
      obj.networkDeviceInterface = SwitchInterface.toJSON(message.networkDeviceInterface);
    }
    if (message.dut !== undefined) {
      obj.dut = DeviceUnderTest.toJSON(message.dut);
    }
    if (message.labstation !== undefined) {
      obj.labstation = Labstation.toJSON(message.labstation);
    }
    if (message.devboard !== undefined) {
      obj.devboard = Devboard.toJSON(message.devboard);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSDeviceLSE>): ChromeOSDeviceLSE {
    return ChromeOSDeviceLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSDeviceLSE>): ChromeOSDeviceLSE {
    const message = createBaseChromeOSDeviceLSE() as any;
    message.config = (object.config !== undefined && object.config !== null)
      ? DeviceUnderTest.fromPartial(object.config)
      : undefined;
    message.rpmInterface = (object.rpmInterface !== undefined && object.rpmInterface !== null)
      ? RPMInterface.fromPartial(object.rpmInterface)
      : undefined;
    message.networkDeviceInterface =
      (object.networkDeviceInterface !== undefined && object.networkDeviceInterface !== null)
        ? SwitchInterface.fromPartial(object.networkDeviceInterface)
        : undefined;
    message.dut = (object.dut !== undefined && object.dut !== null)
      ? DeviceUnderTest.fromPartial(object.dut)
      : undefined;
    message.labstation = (object.labstation !== undefined && object.labstation !== null)
      ? Labstation.fromPartial(object.labstation)
      : undefined;
    message.devboard = (object.devboard !== undefined && object.devboard !== null)
      ? Devboard.fromPartial(object.devboard)
      : undefined;
    return message;
  },
};

function createBaseDutMeta(): DutMeta {
  return { chromeosDeviceId: "", hostname: "", serialNumber: "", hwID: "", deviceSku: "" };
}

export const DutMeta: MessageFns<DutMeta> = {
  encode(message: DutMeta, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromeosDeviceId !== "") {
      writer.uint32(10).string(message.chromeosDeviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.serialNumber !== "") {
      writer.uint32(26).string(message.serialNumber);
    }
    if (message.hwID !== "") {
      writer.uint32(34).string(message.hwID);
    }
    if (message.deviceSku !== "") {
      writer.uint32(42).string(message.deviceSku);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DutMeta {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDutMeta() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromeosDeviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.hwID = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.deviceSku = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DutMeta {
    return {
      chromeosDeviceId: isSet(object.chromeosDeviceId) ? globalThis.String(object.chromeosDeviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      hwID: isSet(object.hwID) ? globalThis.String(object.hwID) : "",
      deviceSku: isSet(object.deviceSku) ? globalThis.String(object.deviceSku) : "",
    };
  },

  toJSON(message: DutMeta): unknown {
    const obj: any = {};
    if (message.chromeosDeviceId !== "") {
      obj.chromeosDeviceId = message.chromeosDeviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.hwID !== "") {
      obj.hwID = message.hwID;
    }
    if (message.deviceSku !== "") {
      obj.deviceSku = message.deviceSku;
    }
    return obj;
  },

  create(base?: DeepPartial<DutMeta>): DutMeta {
    return DutMeta.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DutMeta>): DutMeta {
    const message = createBaseDutMeta() as any;
    message.chromeosDeviceId = object.chromeosDeviceId ?? "";
    message.hostname = object.hostname ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.hwID = object.hwID ?? "";
    message.deviceSku = object.deviceSku ?? "";
    return message;
  },
};

function createBaseLabMeta(): LabMeta {
  return { chromeosDeviceId: "", hostname: "", servoType: "", smartUsbhub: false, servoTopology: undefined };
}

export const LabMeta: MessageFns<LabMeta> = {
  encode(message: LabMeta, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chromeosDeviceId !== "") {
      writer.uint32(10).string(message.chromeosDeviceId);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.servoType !== "") {
      writer.uint32(26).string(message.servoType);
    }
    if (message.smartUsbhub !== false) {
      writer.uint32(32).bool(message.smartUsbhub);
    }
    if (message.servoTopology !== undefined) {
      ServoTopology.encode(message.servoTopology, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): LabMeta {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabMeta() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.chromeosDeviceId = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.servoType = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.smartUsbhub = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.servoTopology = ServoTopology.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): LabMeta {
    return {
      chromeosDeviceId: isSet(object.chromeosDeviceId) ? globalThis.String(object.chromeosDeviceId) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      servoType: isSet(object.servoType) ? globalThis.String(object.servoType) : "",
      smartUsbhub: isSet(object.smartUsbhub) ? globalThis.Boolean(object.smartUsbhub) : false,
      servoTopology: isSet(object.servoTopology) ? ServoTopology.fromJSON(object.servoTopology) : undefined,
    };
  },

  toJSON(message: LabMeta): unknown {
    const obj: any = {};
    if (message.chromeosDeviceId !== "") {
      obj.chromeosDeviceId = message.chromeosDeviceId;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.servoType !== "") {
      obj.servoType = message.servoType;
    }
    if (message.smartUsbhub !== false) {
      obj.smartUsbhub = message.smartUsbhub;
    }
    if (message.servoTopology !== undefined) {
      obj.servoTopology = ServoTopology.toJSON(message.servoTopology);
    }
    return obj;
  },

  create(base?: DeepPartial<LabMeta>): LabMeta {
    return LabMeta.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<LabMeta>): LabMeta {
    const message = createBaseLabMeta() as any;
    message.chromeosDeviceId = object.chromeosDeviceId ?? "";
    message.hostname = object.hostname ?? "";
    message.servoType = object.servoType ?? "";
    message.smartUsbhub = object.smartUsbhub ?? false;
    message.servoTopology = (object.servoTopology !== undefined && object.servoTopology !== null)
      ? ServoTopology.fromPartial(object.servoTopology)
      : undefined;
    return message;
  },
};

function createBaseChromeOSDeviceData(): ChromeOSDeviceData {
  return {
    labConfig: undefined,
    machine: undefined,
    deviceConfig: undefined,
    manufacturingConfig: undefined,
    hwidData: undefined,
    dutState: undefined,
    dutV1: undefined,
    recoveryConfig: undefined,
    schedulableLabels: {},
    respectAutomatedSchedulableLabels: false,
  };
}

export const ChromeOSDeviceData: MessageFns<ChromeOSDeviceData> = {
  encode(message: ChromeOSDeviceData, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.labConfig !== undefined) {
      MachineLSE.encode(message.labConfig, writer.uint32(10).fork()).join();
    }
    if (message.machine !== undefined) {
      Machine.encode(message.machine, writer.uint32(18).fork()).join();
    }
    if (message.deviceConfig !== undefined) {
      Config.encode(message.deviceConfig, writer.uint32(26).fork()).join();
    }
    if (message.manufacturingConfig !== undefined) {
      ManufacturingConfig.encode(message.manufacturingConfig, writer.uint32(34).fork()).join();
    }
    if (message.hwidData !== undefined) {
      HwidData.encode(message.hwidData, writer.uint32(42).fork()).join();
    }
    if (message.dutState !== undefined) {
      DutState.encode(message.dutState, writer.uint32(50).fork()).join();
    }
    if (message.dutV1 !== undefined) {
      DeviceUnderTest1.encode(message.dutV1, writer.uint32(58).fork()).join();
    }
    if (message.recoveryConfig !== undefined) {
      RecoveryConfig.encode(message.recoveryConfig, writer.uint32(82).fork()).join();
    }
    Object.entries(message.schedulableLabels).forEach(([key, value]) => {
      ChromeOSDeviceData_SchedulableLabelsEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.respectAutomatedSchedulableLabels !== false) {
      writer.uint32(72).bool(message.respectAutomatedSchedulableLabels);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSDeviceData {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSDeviceData() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.labConfig = MachineLSE.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.machine = Machine.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.deviceConfig = Config.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.manufacturingConfig = ManufacturingConfig.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.hwidData = HwidData.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.dutState = DutState.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.dutV1 = DeviceUnderTest1.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.recoveryConfig = RecoveryConfig.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          const entry8 = ChromeOSDeviceData_SchedulableLabelsEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.schedulableLabels[entry8.key] = entry8.value;
          }
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.respectAutomatedSchedulableLabels = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSDeviceData {
    return {
      labConfig: isSet(object.labConfig) ? MachineLSE.fromJSON(object.labConfig) : undefined,
      machine: isSet(object.machine) ? Machine.fromJSON(object.machine) : undefined,
      deviceConfig: isSet(object.deviceConfig) ? Config.fromJSON(object.deviceConfig) : undefined,
      manufacturingConfig: isSet(object.manufacturingConfig)
        ? ManufacturingConfig.fromJSON(object.manufacturingConfig)
        : undefined,
      hwidData: isSet(object.hwidData) ? HwidData.fromJSON(object.hwidData) : undefined,
      dutState: isSet(object.dutState) ? DutState.fromJSON(object.dutState) : undefined,
      dutV1: isSet(object.dutV1) ? DeviceUnderTest1.fromJSON(object.dutV1) : undefined,
      recoveryConfig: isSet(object.recoveryConfig) ? RecoveryConfig.fromJSON(object.recoveryConfig) : undefined,
      schedulableLabels: isObject(object.schedulableLabels)
        ? Object.entries(object.schedulableLabels).reduce<{ [key: string]: SchedulableLabelValues }>(
          (acc, [key, value]) => {
            acc[key] = SchedulableLabelValues.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
      respectAutomatedSchedulableLabels: isSet(object.respectAutomatedSchedulableLabels)
        ? globalThis.Boolean(object.respectAutomatedSchedulableLabels)
        : false,
    };
  },

  toJSON(message: ChromeOSDeviceData): unknown {
    const obj: any = {};
    if (message.labConfig !== undefined) {
      obj.labConfig = MachineLSE.toJSON(message.labConfig);
    }
    if (message.machine !== undefined) {
      obj.machine = Machine.toJSON(message.machine);
    }
    if (message.deviceConfig !== undefined) {
      obj.deviceConfig = Config.toJSON(message.deviceConfig);
    }
    if (message.manufacturingConfig !== undefined) {
      obj.manufacturingConfig = ManufacturingConfig.toJSON(message.manufacturingConfig);
    }
    if (message.hwidData !== undefined) {
      obj.hwidData = HwidData.toJSON(message.hwidData);
    }
    if (message.dutState !== undefined) {
      obj.dutState = DutState.toJSON(message.dutState);
    }
    if (message.dutV1 !== undefined) {
      obj.dutV1 = DeviceUnderTest1.toJSON(message.dutV1);
    }
    if (message.recoveryConfig !== undefined) {
      obj.recoveryConfig = RecoveryConfig.toJSON(message.recoveryConfig);
    }
    if (message.schedulableLabels) {
      const entries = Object.entries(message.schedulableLabels);
      if (entries.length > 0) {
        obj.schedulableLabels = {};
        entries.forEach(([k, v]) => {
          obj.schedulableLabels[k] = SchedulableLabelValues.toJSON(v);
        });
      }
    }
    if (message.respectAutomatedSchedulableLabels !== false) {
      obj.respectAutomatedSchedulableLabels = message.respectAutomatedSchedulableLabels;
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSDeviceData>): ChromeOSDeviceData {
    return ChromeOSDeviceData.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSDeviceData>): ChromeOSDeviceData {
    const message = createBaseChromeOSDeviceData() as any;
    message.labConfig = (object.labConfig !== undefined && object.labConfig !== null)
      ? MachineLSE.fromPartial(object.labConfig)
      : undefined;
    message.machine = (object.machine !== undefined && object.machine !== null)
      ? Machine.fromPartial(object.machine)
      : undefined;
    message.deviceConfig = (object.deviceConfig !== undefined && object.deviceConfig !== null)
      ? Config.fromPartial(object.deviceConfig)
      : undefined;
    message.manufacturingConfig = (object.manufacturingConfig !== undefined && object.manufacturingConfig !== null)
      ? ManufacturingConfig.fromPartial(object.manufacturingConfig)
      : undefined;
    message.hwidData = (object.hwidData !== undefined && object.hwidData !== null)
      ? HwidData.fromPartial(object.hwidData)
      : undefined;
    message.dutState = (object.dutState !== undefined && object.dutState !== null)
      ? DutState.fromPartial(object.dutState)
      : undefined;
    message.dutV1 = (object.dutV1 !== undefined && object.dutV1 !== null)
      ? DeviceUnderTest1.fromPartial(object.dutV1)
      : undefined;
    message.recoveryConfig = (object.recoveryConfig !== undefined && object.recoveryConfig !== null)
      ? RecoveryConfig.fromPartial(object.recoveryConfig)
      : undefined;
    message.schedulableLabels = Object.entries(object.schedulableLabels ?? {}).reduce<
      { [key: string]: SchedulableLabelValues }
    >((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = SchedulableLabelValues.fromPartial(value);
      }
      return acc;
    }, {});
    message.respectAutomatedSchedulableLabels = object.respectAutomatedSchedulableLabels ?? false;
    return message;
  },
};

function createBaseChromeOSDeviceData_SchedulableLabelsEntry(): ChromeOSDeviceData_SchedulableLabelsEntry {
  return { key: "", value: undefined };
}

export const ChromeOSDeviceData_SchedulableLabelsEntry: MessageFns<ChromeOSDeviceData_SchedulableLabelsEntry> = {
  encode(message: ChromeOSDeviceData_SchedulableLabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      SchedulableLabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSDeviceData_SchedulableLabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSDeviceData_SchedulableLabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = SchedulableLabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSDeviceData_SchedulableLabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? SchedulableLabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: ChromeOSDeviceData_SchedulableLabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = SchedulableLabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSDeviceData_SchedulableLabelsEntry>): ChromeOSDeviceData_SchedulableLabelsEntry {
    return ChromeOSDeviceData_SchedulableLabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<ChromeOSDeviceData_SchedulableLabelsEntry>,
  ): ChromeOSDeviceData_SchedulableLabelsEntry {
    const message = createBaseChromeOSDeviceData_SchedulableLabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? SchedulableLabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseRecoveryConfig(): RecoveryConfig {
  return { powerSupply: "", storage: "", hardwareFeatures: [] };
}

export const RecoveryConfig: MessageFns<RecoveryConfig> = {
  encode(message: RecoveryConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.powerSupply !== "") {
      writer.uint32(10).string(message.powerSupply);
    }
    if (message.storage !== "") {
      writer.uint32(18).string(message.storage);
    }
    for (const v of message.hardwareFeatures) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RecoveryConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRecoveryConfig() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.powerSupply = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.storage = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.hardwareFeatures.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RecoveryConfig {
    return {
      powerSupply: isSet(object.powerSupply) ? globalThis.String(object.powerSupply) : "",
      storage: isSet(object.storage) ? globalThis.String(object.storage) : "",
      hardwareFeatures: globalThis.Array.isArray(object?.hardwareFeatures)
        ? object.hardwareFeatures.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: RecoveryConfig): unknown {
    const obj: any = {};
    if (message.powerSupply !== "") {
      obj.powerSupply = message.powerSupply;
    }
    if (message.storage !== "") {
      obj.storage = message.storage;
    }
    if (message.hardwareFeatures?.length) {
      obj.hardwareFeatures = message.hardwareFeatures;
    }
    return obj;
  },

  create(base?: DeepPartial<RecoveryConfig>): RecoveryConfig {
    return RecoveryConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RecoveryConfig>): RecoveryConfig {
    const message = createBaseRecoveryConfig() as any;
    message.powerSupply = object.powerSupply ?? "";
    message.storage = object.storage ?? "";
    message.hardwareFeatures = object.hardwareFeatures?.map((e) => e) || [];
    return message;
  },
};

function createBaseSchedulableLabelValues(): SchedulableLabelValues {
  return { labelValues: [] };
}

export const SchedulableLabelValues: MessageFns<SchedulableLabelValues> = {
  encode(message: SchedulableLabelValues, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.labelValues) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SchedulableLabelValues {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSchedulableLabelValues() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.labelValues.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SchedulableLabelValues {
    return {
      labelValues: globalThis.Array.isArray(object?.labelValues)
        ? object.labelValues.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: SchedulableLabelValues): unknown {
    const obj: any = {};
    if (message.labelValues?.length) {
      obj.labelValues = message.labelValues;
    }
    return obj;
  },

  create(base?: DeepPartial<SchedulableLabelValues>): SchedulableLabelValues {
    return SchedulableLabelValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SchedulableLabelValues>): SchedulableLabelValues {
    const message = createBaseSchedulableLabelValues() as any;
    message.labelValues = object.labelValues?.map((e) => e) || [];
    return message;
  },
};

function createBaseChromeOSServerLSE(): ChromeOSServerLSE {
  return { supportedRestrictedVlan: "", servicePort: 0 };
}

export const ChromeOSServerLSE: MessageFns<ChromeOSServerLSE> = {
  encode(message: ChromeOSServerLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.supportedRestrictedVlan !== "") {
      writer.uint32(10).string(message.supportedRestrictedVlan);
    }
    if (message.servicePort !== 0) {
      writer.uint32(16).int32(message.servicePort);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSServerLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSServerLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.supportedRestrictedVlan = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.servicePort = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSServerLSE {
    return {
      supportedRestrictedVlan: isSet(object.supportedRestrictedVlan)
        ? globalThis.String(object.supportedRestrictedVlan)
        : "",
      servicePort: isSet(object.servicePort) ? globalThis.Number(object.servicePort) : 0,
    };
  },

  toJSON(message: ChromeOSServerLSE): unknown {
    const obj: any = {};
    if (message.supportedRestrictedVlan !== "") {
      obj.supportedRestrictedVlan = message.supportedRestrictedVlan;
    }
    if (message.servicePort !== 0) {
      obj.servicePort = Math.round(message.servicePort);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSServerLSE>): ChromeOSServerLSE {
    return ChromeOSServerLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSServerLSE>): ChromeOSServerLSE {
    const message = createBaseChromeOSServerLSE() as any;
    message.supportedRestrictedVlan = object.supportedRestrictedVlan ?? "";
    message.servicePort = object.servicePort ?? 0;
    return message;
  },
};

function createBaseAttachedDeviceLSE(): AttachedDeviceLSE {
  return { osVersion: undefined, associatedHostname: "", associatedHostPort: "" };
}

export const AttachedDeviceLSE: MessageFns<AttachedDeviceLSE> = {
  encode(message: AttachedDeviceLSE, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.osVersion !== undefined) {
      OSVersion.encode(message.osVersion, writer.uint32(10).fork()).join();
    }
    if (message.associatedHostname !== "") {
      writer.uint32(18).string(message.associatedHostname);
    }
    if (message.associatedHostPort !== "") {
      writer.uint32(26).string(message.associatedHostPort);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AttachedDeviceLSE {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAttachedDeviceLSE() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.osVersion = OSVersion.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.associatedHostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.associatedHostPort = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AttachedDeviceLSE {
    return {
      osVersion: isSet(object.osVersion) ? OSVersion.fromJSON(object.osVersion) : undefined,
      associatedHostname: isSet(object.associatedHostname) ? globalThis.String(object.associatedHostname) : "",
      associatedHostPort: isSet(object.associatedHostPort) ? globalThis.String(object.associatedHostPort) : "",
    };
  },

  toJSON(message: AttachedDeviceLSE): unknown {
    const obj: any = {};
    if (message.osVersion !== undefined) {
      obj.osVersion = OSVersion.toJSON(message.osVersion);
    }
    if (message.associatedHostname !== "") {
      obj.associatedHostname = message.associatedHostname;
    }
    if (message.associatedHostPort !== "") {
      obj.associatedHostPort = message.associatedHostPort;
    }
    return obj;
  },

  create(base?: DeepPartial<AttachedDeviceLSE>): AttachedDeviceLSE {
    return AttachedDeviceLSE.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AttachedDeviceLSE>): AttachedDeviceLSE {
    const message = createBaseAttachedDeviceLSE() as any;
    message.osVersion = (object.osVersion !== undefined && object.osVersion !== null)
      ? OSVersion.fromPartial(object.osVersion)
      : undefined;
    message.associatedHostname = object.associatedHostname ?? "";
    message.associatedHostPort = object.associatedHostPort ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
