// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/storage.proto

/* eslint-disable */

export const protobufPackage = "chromiumos.test.lab.api";

export enum StorageType {
  UNSPECIFIED = 0,
  /** @deprecated */
  UNRECOGNIZED = 1,
  SSD = 2,
  HDD = 3,
  MMC = 4,
  NVME = 5,
  UFS = 6,
  NOT_RECOGNIZED = 7,
}

export function storageTypeFromJSON(object: any): StorageType {
  switch (object) {
    case 0:
    case "UNSPECIFIED":
      return StorageType.UNSPECIFIED;
    case 1:
    case "UNRECOGNIZED":
      return StorageType.UNRECOGNIZED;
    case 2:
    case "SSD":
      return StorageType.SSD;
    case 3:
    case "HDD":
      return StorageType.HDD;
    case 4:
    case "MMC":
      return StorageType.MMC;
    case 5:
    case "NVME":
      return StorageType.NVME;
    case 6:
    case "UFS":
      return StorageType.UFS;
    case 7:
    case "NOT_RECOGNIZED":
      return StorageType.NOT_RECOGNIZED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum StorageType");
  }
}

export function storageTypeToJSON(object: StorageType): string {
  switch (object) {
    case StorageType.UNSPECIFIED:
      return "UNSPECIFIED";
    case StorageType.UNRECOGNIZED:
      return "UNRECOGNIZED";
    case StorageType.SSD:
      return "SSD";
    case StorageType.HDD:
      return "HDD";
    case StorageType.MMC:
      return "MMC";
    case StorageType.NVME:
      return "NVME";
    case StorageType.UFS:
      return "UFS";
    case StorageType.NOT_RECOGNIZED:
      return "NOT_RECOGNIZED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum StorageType");
  }
}
