// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/lab/dut_state.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../../../../google/protobuf/timestamp.pb";
import { ChromeOSDeviceID } from "./chromeos_device_id.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 35 */
export enum PeripheralState {
  /** UNKNOWN - Please keep for all unknown states. */
  UNKNOWN = 0,
  /** WORKING - Device and software on it is working as expected. */
  WORKING = 1,
  /** MISSING_CONFIG - Configuration for device is not provided. */
  MISSING_CONFIG = 5,
  /** WRONG_CONFIG - Configuration contains incorrect information. */
  WRONG_CONFIG = 4,
  /** NOT_CONNECTED - Device is not connected/plugged. */
  NOT_CONNECTED = 2,
  /** NO_SSH - Device is not reachable over ssh. */
  NO_SSH = 6,
  /**
   * BROKEN - Device is broken or not working as expected. the state used if no specified
   * state for the issue.
   */
  BROKEN = 3,
  /** NEED_REPLACEMENT - Device cannot be repaired or required manual attention to fix/replace it. */
  NEED_REPLACEMENT = 7,
  /**
   * CR50_CONSOLE_MISSING - Servo specific states.
   * cr50 console missing or unresponsive.
   */
  CR50_CONSOLE_MISSING = 13,
  /** CCD_TESTLAB_ISSUE - Servod daemon cannot start on servo-host because cr50 testlab not enabled. */
  CCD_TESTLAB_ISSUE = 8,
  /** SERVOD_ISSUE - Servod daemon cannot start on servo-host. */
  SERVOD_ISSUE = 9,
  /** LID_OPEN_FAILED - device lid is not open. */
  LID_OPEN_FAILED = 10,
  /** BAD_RIBBON_CABLE - the ribbon cable between servo and DUT is broken or not connected. */
  BAD_RIBBON_CABLE = 11,
  /** EC_BROKEN - the EC on the DUT has issue. */
  EC_BROKEN = 12,
  /** DUT_NOT_CONNECTED - Servo is not connected to the DUT. */
  DUT_NOT_CONNECTED = 14,
  /** TOPOLOGY_ISSUE - Some component in servo-topology missed or not detected. */
  TOPOLOGY_ISSUE = 15,
  /** SBU_LOW_VOLTAGE - SBU voltage issues effect CR50 detection. */
  SBU_LOW_VOLTAGE = 16,
  /** CR50_NOT_ENUMERATED - CR50 SBU voltage detected but device was not enumerated. */
  CR50_NOT_ENUMERATED = 17,
  /**
   * SERVO_SERIAL_MISMATCH - Servo serial mismatch, when servo not detected and another serial detected
   * on previous used port.
   */
  SERVO_SERIAL_MISMATCH = 18,
  /** SERVOD_PROXY_ISSUE - Issue to connect to servod by XMLRPC proxy. */
  SERVOD_PROXY_ISSUE = 19,
  /**
   * SERVO_HOST_ISSUE - Issue related to servo-host. Timeout to start servod or issue with
   * detecting devices.
   */
  SERVO_HOST_ISSUE = 20,
  /** SERVO_UPDATER_ISSUE - Issue related to servo_updater. */
  SERVO_UPDATER_ISSUE = 21,
  /**
   * SERVOD_DUT_CONTROLLER_MISSING - Issue detected in servod and reported by dut_controller_missing_fault
   * control.
   */
  SERVOD_DUT_CONTROLLER_MISSING = 22,
  /** COLD_RESET_PIN_ISSUE - Issue related to cold reset pin on the DUT. */
  COLD_RESET_PIN_ISSUE = 23,
  /** WARM_RESET_PIN_ISSUE - Issue related to warm reset pin on the DUT. */
  WARM_RESET_PIN_ISSUE = 24,
  /** POWER_BUTTON_PIN_ISSUE - Issue related to power button pin on the DUT. */
  POWER_BUTTON_PIN_ISSUE = 25,
  /** NOT_APPLICABLE - Peripheral state is not applicable. */
  NOT_APPLICABLE = 26,
  /**
   * DEBUG_HEADER_SERVO_MISSING - The servo device connected to debug header is not detected.
   * Reported by dut_controller_missing_fault control.
   */
  DEBUG_HEADER_SERVO_MISSING = 27,
  /** DOLOS_NO_POWER_SUPPLIED - No power from charger detected. */
  DOLOS_NO_POWER_SUPPLIED = 28,
  /** DOLOS_OUTPUT_POWER_FAILED - No power is being delivered to the DUT. */
  DOLOS_OUTPUT_POWER_FAILED = 29,
  /** DOLOS_BMS_STATE_INVALID - Internal battery management state fail. */
  DOLOS_BMS_STATE_INVALID = 30,
  /** DOLOS_SMBUS_COMM_NOT_DETECTED - SMBus failure. */
  DOLOS_SMBUS_COMM_NOT_DETECTED = 31,
  /** DOLOS_EEPROM_FAILURE - Unable to communicate with the eeprom on the battery cable. */
  DOLOS_EEPROM_FAILURE = 32,
  /** DOLOS_NO_COMMUNICATION - UART device is present but unable to communicate with it. */
  DOLOS_NO_COMMUNICATION = 33,
  /** DOLOS_NOT_PRESENT - UART device is missing from the host. */
  DOLOS_NOT_PRESENT = 34,
}

export function peripheralStateFromJSON(object: any): PeripheralState {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return PeripheralState.UNKNOWN;
    case 1:
    case "WORKING":
      return PeripheralState.WORKING;
    case 5:
    case "MISSING_CONFIG":
      return PeripheralState.MISSING_CONFIG;
    case 4:
    case "WRONG_CONFIG":
      return PeripheralState.WRONG_CONFIG;
    case 2:
    case "NOT_CONNECTED":
      return PeripheralState.NOT_CONNECTED;
    case 6:
    case "NO_SSH":
      return PeripheralState.NO_SSH;
    case 3:
    case "BROKEN":
      return PeripheralState.BROKEN;
    case 7:
    case "NEED_REPLACEMENT":
      return PeripheralState.NEED_REPLACEMENT;
    case 13:
    case "CR50_CONSOLE_MISSING":
      return PeripheralState.CR50_CONSOLE_MISSING;
    case 8:
    case "CCD_TESTLAB_ISSUE":
      return PeripheralState.CCD_TESTLAB_ISSUE;
    case 9:
    case "SERVOD_ISSUE":
      return PeripheralState.SERVOD_ISSUE;
    case 10:
    case "LID_OPEN_FAILED":
      return PeripheralState.LID_OPEN_FAILED;
    case 11:
    case "BAD_RIBBON_CABLE":
      return PeripheralState.BAD_RIBBON_CABLE;
    case 12:
    case "EC_BROKEN":
      return PeripheralState.EC_BROKEN;
    case 14:
    case "DUT_NOT_CONNECTED":
      return PeripheralState.DUT_NOT_CONNECTED;
    case 15:
    case "TOPOLOGY_ISSUE":
      return PeripheralState.TOPOLOGY_ISSUE;
    case 16:
    case "SBU_LOW_VOLTAGE":
      return PeripheralState.SBU_LOW_VOLTAGE;
    case 17:
    case "CR50_NOT_ENUMERATED":
      return PeripheralState.CR50_NOT_ENUMERATED;
    case 18:
    case "SERVO_SERIAL_MISMATCH":
      return PeripheralState.SERVO_SERIAL_MISMATCH;
    case 19:
    case "SERVOD_PROXY_ISSUE":
      return PeripheralState.SERVOD_PROXY_ISSUE;
    case 20:
    case "SERVO_HOST_ISSUE":
      return PeripheralState.SERVO_HOST_ISSUE;
    case 21:
    case "SERVO_UPDATER_ISSUE":
      return PeripheralState.SERVO_UPDATER_ISSUE;
    case 22:
    case "SERVOD_DUT_CONTROLLER_MISSING":
      return PeripheralState.SERVOD_DUT_CONTROLLER_MISSING;
    case 23:
    case "COLD_RESET_PIN_ISSUE":
      return PeripheralState.COLD_RESET_PIN_ISSUE;
    case 24:
    case "WARM_RESET_PIN_ISSUE":
      return PeripheralState.WARM_RESET_PIN_ISSUE;
    case 25:
    case "POWER_BUTTON_PIN_ISSUE":
      return PeripheralState.POWER_BUTTON_PIN_ISSUE;
    case 26:
    case "NOT_APPLICABLE":
      return PeripheralState.NOT_APPLICABLE;
    case 27:
    case "DEBUG_HEADER_SERVO_MISSING":
      return PeripheralState.DEBUG_HEADER_SERVO_MISSING;
    case 28:
    case "DOLOS_NO_POWER_SUPPLIED":
      return PeripheralState.DOLOS_NO_POWER_SUPPLIED;
    case 29:
    case "DOLOS_OUTPUT_POWER_FAILED":
      return PeripheralState.DOLOS_OUTPUT_POWER_FAILED;
    case 30:
    case "DOLOS_BMS_STATE_INVALID":
      return PeripheralState.DOLOS_BMS_STATE_INVALID;
    case 31:
    case "DOLOS_SMBUS_COMM_NOT_DETECTED":
      return PeripheralState.DOLOS_SMBUS_COMM_NOT_DETECTED;
    case 32:
    case "DOLOS_EEPROM_FAILURE":
      return PeripheralState.DOLOS_EEPROM_FAILURE;
    case 33:
    case "DOLOS_NO_COMMUNICATION":
      return PeripheralState.DOLOS_NO_COMMUNICATION;
    case 34:
    case "DOLOS_NOT_PRESENT":
      return PeripheralState.DOLOS_NOT_PRESENT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PeripheralState");
  }
}

export function peripheralStateToJSON(object: PeripheralState): string {
  switch (object) {
    case PeripheralState.UNKNOWN:
      return "UNKNOWN";
    case PeripheralState.WORKING:
      return "WORKING";
    case PeripheralState.MISSING_CONFIG:
      return "MISSING_CONFIG";
    case PeripheralState.WRONG_CONFIG:
      return "WRONG_CONFIG";
    case PeripheralState.NOT_CONNECTED:
      return "NOT_CONNECTED";
    case PeripheralState.NO_SSH:
      return "NO_SSH";
    case PeripheralState.BROKEN:
      return "BROKEN";
    case PeripheralState.NEED_REPLACEMENT:
      return "NEED_REPLACEMENT";
    case PeripheralState.CR50_CONSOLE_MISSING:
      return "CR50_CONSOLE_MISSING";
    case PeripheralState.CCD_TESTLAB_ISSUE:
      return "CCD_TESTLAB_ISSUE";
    case PeripheralState.SERVOD_ISSUE:
      return "SERVOD_ISSUE";
    case PeripheralState.LID_OPEN_FAILED:
      return "LID_OPEN_FAILED";
    case PeripheralState.BAD_RIBBON_CABLE:
      return "BAD_RIBBON_CABLE";
    case PeripheralState.EC_BROKEN:
      return "EC_BROKEN";
    case PeripheralState.DUT_NOT_CONNECTED:
      return "DUT_NOT_CONNECTED";
    case PeripheralState.TOPOLOGY_ISSUE:
      return "TOPOLOGY_ISSUE";
    case PeripheralState.SBU_LOW_VOLTAGE:
      return "SBU_LOW_VOLTAGE";
    case PeripheralState.CR50_NOT_ENUMERATED:
      return "CR50_NOT_ENUMERATED";
    case PeripheralState.SERVO_SERIAL_MISMATCH:
      return "SERVO_SERIAL_MISMATCH";
    case PeripheralState.SERVOD_PROXY_ISSUE:
      return "SERVOD_PROXY_ISSUE";
    case PeripheralState.SERVO_HOST_ISSUE:
      return "SERVO_HOST_ISSUE";
    case PeripheralState.SERVO_UPDATER_ISSUE:
      return "SERVO_UPDATER_ISSUE";
    case PeripheralState.SERVOD_DUT_CONTROLLER_MISSING:
      return "SERVOD_DUT_CONTROLLER_MISSING";
    case PeripheralState.COLD_RESET_PIN_ISSUE:
      return "COLD_RESET_PIN_ISSUE";
    case PeripheralState.WARM_RESET_PIN_ISSUE:
      return "WARM_RESET_PIN_ISSUE";
    case PeripheralState.POWER_BUTTON_PIN_ISSUE:
      return "POWER_BUTTON_PIN_ISSUE";
    case PeripheralState.NOT_APPLICABLE:
      return "NOT_APPLICABLE";
    case PeripheralState.DEBUG_HEADER_SERVO_MISSING:
      return "DEBUG_HEADER_SERVO_MISSING";
    case PeripheralState.DOLOS_NO_POWER_SUPPLIED:
      return "DOLOS_NO_POWER_SUPPLIED";
    case PeripheralState.DOLOS_OUTPUT_POWER_FAILED:
      return "DOLOS_OUTPUT_POWER_FAILED";
    case PeripheralState.DOLOS_BMS_STATE_INVALID:
      return "DOLOS_BMS_STATE_INVALID";
    case PeripheralState.DOLOS_SMBUS_COMM_NOT_DETECTED:
      return "DOLOS_SMBUS_COMM_NOT_DETECTED";
    case PeripheralState.DOLOS_EEPROM_FAILURE:
      return "DOLOS_EEPROM_FAILURE";
    case PeripheralState.DOLOS_NO_COMMUNICATION:
      return "DOLOS_NO_COMMUNICATION";
    case PeripheralState.DOLOS_NOT_PRESENT:
      return "DOLOS_NOT_PRESENT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PeripheralState");
  }
}

/**
 * The states are using for DUT storage and USB-drive on servo.
 * Next Tag: 5
 */
export enum HardwareState {
  /** HARDWARE_UNKNOWN - keep for all unknown state by default. */
  HARDWARE_UNKNOWN = 0,
  /** HARDWARE_NORMAL - Hardware is in good shape and pass all verifiers. */
  HARDWARE_NORMAL = 1,
  /**
   * HARDWARE_ACCEPTABLE - Hardware is still good but some not critical verifiers did not pass or
   * provided border values. (used for DUT storage when usage reached 98%)
   */
  HARDWARE_ACCEPTABLE = 2,
  /** HARDWARE_NEED_REPLACEMENT - Hardware is broken or bad (did not pass verifiers). */
  HARDWARE_NEED_REPLACEMENT = 3,
  /**
   * HARDWARE_NOT_DETECTED - Hardware is not detected to run verifiers.
   * (used for USB-drive when it expected but not detected on the device)
   */
  HARDWARE_NOT_DETECTED = 4,
}

export function hardwareStateFromJSON(object: any): HardwareState {
  switch (object) {
    case 0:
    case "HARDWARE_UNKNOWN":
      return HardwareState.HARDWARE_UNKNOWN;
    case 1:
    case "HARDWARE_NORMAL":
      return HardwareState.HARDWARE_NORMAL;
    case 2:
    case "HARDWARE_ACCEPTABLE":
      return HardwareState.HARDWARE_ACCEPTABLE;
    case 3:
    case "HARDWARE_NEED_REPLACEMENT":
      return HardwareState.HARDWARE_NEED_REPLACEMENT;
    case 4:
    case "HARDWARE_NOT_DETECTED":
      return HardwareState.HARDWARE_NOT_DETECTED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareState");
  }
}

export function hardwareStateToJSON(object: HardwareState): string {
  switch (object) {
    case HardwareState.HARDWARE_UNKNOWN:
      return "HARDWARE_UNKNOWN";
    case HardwareState.HARDWARE_NORMAL:
      return "HARDWARE_NORMAL";
    case HardwareState.HARDWARE_ACCEPTABLE:
      return "HARDWARE_ACCEPTABLE";
    case HardwareState.HARDWARE_NEED_REPLACEMENT:
      return "HARDWARE_NEED_REPLACEMENT";
    case HardwareState.HARDWARE_NOT_DETECTED:
      return "HARDWARE_NOT_DETECTED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareState");
  }
}

/**
 * This proto defines status labels in lab config of a DUT.
 * Next Tag: 36
 */
export interface DutState {
  readonly id: ChromeOSDeviceID | undefined;
  readonly servo: PeripheralState;
  readonly chameleon: PeripheralState;
  readonly audioLoopbackDongle: PeripheralState;
  /**
   * wifi_peripheral_state is used for swarming scheduling. It represent the
   * state for wifi peripheral devices.
   */
  readonly wifiPeripheralState: PeripheralState;
  /** Indicate how many working bluetooth btpeers there are in the testbed. */
  readonly workingBluetoothBtpeer: number;
  /**
   * Combined state of all btpeers in the testbed. Only set as WORKING if all
   * btpeers are WORKING as well.
   */
  readonly peripheralBtpeerState: PeripheralState;
  /** State for the human motion robot system. */
  readonly hmrState: PeripheralState;
  /** State for audio latency toolkit */
  readonly audioLatencyToolkitState: PeripheralState;
  readonly cr50Phase: DutState_CR50Phase;
  /** Detected based on the cr50 RW version that the DUT is running on. */
  readonly cr50KeyEnv: DutState_CR50KeyEnv;
  /** Detected during running admin_audit task. */
  readonly storageState: HardwareState;
  readonly servoUsbState: HardwareState;
  readonly batteryState: HardwareState;
  /** wifi_state represent state for DUT's internal wifi component. */
  readonly wifiState: HardwareState;
  readonly bluetoothState: HardwareState;
  readonly cellularModemState: HardwareState;
  readonly starfishState: PeripheralState;
  readonly rpmState: PeripheralState;
  /** Record the last update timestamp of this MachineLSE (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** Record the hostname at that time for further analysis. */
  readonly hostname: string;
  /**
   * Explain why the DUT state was set.
   * The value may not be available, and is used to indicate reason of a bad
   * state.
   */
  readonly dutStateReason: string;
  /** List of repair-requestes specified by external services for AutoRepair. */
  readonly repairRequests: readonly DutState_RepairRequest[];
  /** Realm the underlying machine of the DutState belongs to. Shouldn't be set by the user. */
  readonly realm: string;
  /** Common version information for a device. */
  readonly versionInfo:
    | VersionInfo
    | undefined;
  /** State for Dolos device. */
  readonly dolosState: PeripheralState;
  /** FW targets name for EC/AP used for FW flash. */
  readonly fwEcTarget: string;
  readonly fwApTarget: string;
  readonly gpuId: string;
  /** State for AMT management. */
  readonly amtManagerState: PeripheralState;
  /** The type of Audio Beamforming on the DUT. */
  readonly audioBeamforming: string;
  /** State for camera on the DUT. */
  readonly cameraState: HardwareState;
  /** The type of Fingerprint board on the DUT. */
  readonly fingerprintBoard: string;
  /** The type of Fingerprint MCU on the DUT. */
  readonly fingerprintMcu: string;
  /** The type of Fingerprint sensor on the DUT. */
  readonly fingerprintSensor: string;
}

/**
 * CR50-related configs by definition shouldn't be a state config, but a build
 * config. However, we don't have a way to source it from any external
 * configuration system, and it's changed frequently enough to handle cr50
 * tests, which makes it basically impossible for manual updatings: See
 * crbug.com/1057145 for the troubles it causes.
 *
 * So we temporarily set it in state config so that repair job can update it.
 * For further changes of it, please see tracking bug crbug.com/1057719.
 *
 * phases for cr50 module. Next Tag: 3
 */
export enum DutState_CR50Phase {
  CR50_PHASE_INVALID = 0,
  CR50_PHASE_PREPVT = 1,
  CR50_PHASE_PVT = 2,
}

export function dutState_CR50PhaseFromJSON(object: any): DutState_CR50Phase {
  switch (object) {
    case 0:
    case "CR50_PHASE_INVALID":
      return DutState_CR50Phase.CR50_PHASE_INVALID;
    case 1:
    case "CR50_PHASE_PREPVT":
      return DutState_CR50Phase.CR50_PHASE_PREPVT;
    case 2:
    case "CR50_PHASE_PVT":
      return DutState_CR50Phase.CR50_PHASE_PVT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_CR50Phase");
  }
}

export function dutState_CR50PhaseToJSON(object: DutState_CR50Phase): string {
  switch (object) {
    case DutState_CR50Phase.CR50_PHASE_INVALID:
      return "CR50_PHASE_INVALID";
    case DutState_CR50Phase.CR50_PHASE_PREPVT:
      return "CR50_PHASE_PREPVT";
    case DutState_CR50Phase.CR50_PHASE_PVT:
      return "CR50_PHASE_PVT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_CR50Phase");
  }
}

/** key env for cr50 RW version. Next Tag: 3 */
export enum DutState_CR50KeyEnv {
  CR50_KEYENV_INVALID = 0,
  CR50_KEYENV_PROD = 1,
  CR50_KEYENV_DEV = 2,
}

export function dutState_CR50KeyEnvFromJSON(object: any): DutState_CR50KeyEnv {
  switch (object) {
    case 0:
    case "CR50_KEYENV_INVALID":
      return DutState_CR50KeyEnv.CR50_KEYENV_INVALID;
    case 1:
    case "CR50_KEYENV_PROD":
      return DutState_CR50KeyEnv.CR50_KEYENV_PROD;
    case 2:
    case "CR50_KEYENV_DEV":
      return DutState_CR50KeyEnv.CR50_KEYENV_DEV;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_CR50KeyEnv");
  }
}

export function dutState_CR50KeyEnvToJSON(object: DutState_CR50KeyEnv): string {
  switch (object) {
    case DutState_CR50KeyEnv.CR50_KEYENV_INVALID:
      return "CR50_KEYENV_INVALID";
    case DutState_CR50KeyEnv.CR50_KEYENV_PROD:
      return "CR50_KEYENV_PROD";
    case DutState_CR50KeyEnv.CR50_KEYENV_DEV:
      return "CR50_KEYENV_DEV";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_CR50KeyEnv");
  }
}

export enum DutState_RepairRequest {
  REPAIR_REQUEST_UNKNOWN = 0,
  /** REPAIR_REQUEST_PROVISION - Request to re-provision DUT. */
  REPAIR_REQUEST_PROVISION = 1,
  /** REPAIR_REQUEST_REIMAGE_BY_USBKEY - Request to reimage DUT by USB-key. */
  REPAIR_REQUEST_REIMAGE_BY_USBKEY = 2,
  /** REPAIR_REQUEST_UPDATE_USBKEY_IMAGE - Request to re-download image to USB-key. */
  REPAIR_REQUEST_UPDATE_USBKEY_IMAGE = 3,
  /** REPAIR_REQUEST_REFLASH_FW - Request to re-flash firmware of the DUT. */
  REPAIR_REQUEST_REFLASH_FW = 4,
}

export function dutState_RepairRequestFromJSON(object: any): DutState_RepairRequest {
  switch (object) {
    case 0:
    case "REPAIR_REQUEST_UNKNOWN":
      return DutState_RepairRequest.REPAIR_REQUEST_UNKNOWN;
    case 1:
    case "REPAIR_REQUEST_PROVISION":
      return DutState_RepairRequest.REPAIR_REQUEST_PROVISION;
    case 2:
    case "REPAIR_REQUEST_REIMAGE_BY_USBKEY":
      return DutState_RepairRequest.REPAIR_REQUEST_REIMAGE_BY_USBKEY;
    case 3:
    case "REPAIR_REQUEST_UPDATE_USBKEY_IMAGE":
      return DutState_RepairRequest.REPAIR_REQUEST_UPDATE_USBKEY_IMAGE;
    case 4:
    case "REPAIR_REQUEST_REFLASH_FW":
      return DutState_RepairRequest.REPAIR_REQUEST_REFLASH_FW;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_RepairRequest");
  }
}

export function dutState_RepairRequestToJSON(object: DutState_RepairRequest): string {
  switch (object) {
    case DutState_RepairRequest.REPAIR_REQUEST_UNKNOWN:
      return "REPAIR_REQUEST_UNKNOWN";
    case DutState_RepairRequest.REPAIR_REQUEST_PROVISION:
      return "REPAIR_REQUEST_PROVISION";
    case DutState_RepairRequest.REPAIR_REQUEST_REIMAGE_BY_USBKEY:
      return "REPAIR_REQUEST_REIMAGE_BY_USBKEY";
    case DutState_RepairRequest.REPAIR_REQUEST_UPDATE_USBKEY_IMAGE:
      return "REPAIR_REQUEST_UPDATE_USBKEY_IMAGE";
    case DutState_RepairRequest.REPAIR_REQUEST_REFLASH_FW:
      return "REPAIR_REQUEST_REFLASH_FW";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DutState_RepairRequest");
  }
}

/**
 * This proto define common version info we want to record from a device.
 * Next Tag: 5
 */
export interface VersionInfo {
  readonly os: string;
  readonly roFirmware: string;
  readonly rwFirmware: string;
  readonly osType: VersionInfo_OsType;
}

export enum VersionInfo_OsType {
  UNKNOWN = 0,
  CHROMEOS = 1,
  ANDROID = 2,
}

export function versionInfo_OsTypeFromJSON(object: any): VersionInfo_OsType {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return VersionInfo_OsType.UNKNOWN;
    case 1:
    case "CHROMEOS":
      return VersionInfo_OsType.CHROMEOS;
    case 2:
    case "ANDROID":
      return VersionInfo_OsType.ANDROID;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VersionInfo_OsType");
  }
}

export function versionInfo_OsTypeToJSON(object: VersionInfo_OsType): string {
  switch (object) {
    case VersionInfo_OsType.UNKNOWN:
      return "UNKNOWN";
    case VersionInfo_OsType.CHROMEOS:
      return "CHROMEOS";
    case VersionInfo_OsType.ANDROID:
      return "ANDROID";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum VersionInfo_OsType");
  }
}

function createBaseDutState(): DutState {
  return {
    id: undefined,
    servo: 0,
    chameleon: 0,
    audioLoopbackDongle: 0,
    wifiPeripheralState: 0,
    workingBluetoothBtpeer: 0,
    peripheralBtpeerState: 0,
    hmrState: 0,
    audioLatencyToolkitState: 0,
    cr50Phase: 0,
    cr50KeyEnv: 0,
    storageState: 0,
    servoUsbState: 0,
    batteryState: 0,
    wifiState: 0,
    bluetoothState: 0,
    cellularModemState: 0,
    starfishState: 0,
    rpmState: 0,
    updateTime: undefined,
    hostname: "",
    dutStateReason: "",
    repairRequests: [],
    realm: "",
    versionInfo: undefined,
    dolosState: 0,
    fwEcTarget: "",
    fwApTarget: "",
    gpuId: "",
    amtManagerState: 0,
    audioBeamforming: "",
    cameraState: 0,
    fingerprintBoard: "",
    fingerprintMcu: "",
    fingerprintSensor: "",
  };
}

export const DutState: MessageFns<DutState> = {
  encode(message: DutState, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      ChromeOSDeviceID.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.servo !== 0) {
      writer.uint32(16).int32(message.servo);
    }
    if (message.chameleon !== 0) {
      writer.uint32(24).int32(message.chameleon);
    }
    if (message.audioLoopbackDongle !== 0) {
      writer.uint32(32).int32(message.audioLoopbackDongle);
    }
    if (message.wifiPeripheralState !== 0) {
      writer.uint32(128).int32(message.wifiPeripheralState);
    }
    if (message.workingBluetoothBtpeer !== 0) {
      writer.uint32(40).int32(message.workingBluetoothBtpeer);
    }
    if (message.peripheralBtpeerState !== 0) {
      writer.uint32(184).int32(message.peripheralBtpeerState);
    }
    if (message.hmrState !== 0) {
      writer.uint32(160).int32(message.hmrState);
    }
    if (message.audioLatencyToolkitState !== 0) {
      writer.uint32(176).int32(message.audioLatencyToolkitState);
    }
    if (message.cr50Phase !== 0) {
      writer.uint32(48).int32(message.cr50Phase);
    }
    if (message.cr50KeyEnv !== 0) {
      writer.uint32(56).int32(message.cr50KeyEnv);
    }
    if (message.storageState !== 0) {
      writer.uint32(64).int32(message.storageState);
    }
    if (message.servoUsbState !== 0) {
      writer.uint32(72).int32(message.servoUsbState);
    }
    if (message.batteryState !== 0) {
      writer.uint32(104).int32(message.batteryState);
    }
    if (message.wifiState !== 0) {
      writer.uint32(112).int32(message.wifiState);
    }
    if (message.bluetoothState !== 0) {
      writer.uint32(120).int32(message.bluetoothState);
    }
    if (message.cellularModemState !== 0) {
      writer.uint32(136).int32(message.cellularModemState);
    }
    if (message.starfishState !== 0) {
      writer.uint32(232).int32(message.starfishState);
    }
    if (message.rpmState !== 0) {
      writer.uint32(96).int32(message.rpmState);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(82).fork()).join();
    }
    if (message.hostname !== "") {
      writer.uint32(90).string(message.hostname);
    }
    if (message.dutStateReason !== "") {
      writer.uint32(146).string(message.dutStateReason);
    }
    writer.uint32(154).fork();
    for (const v of message.repairRequests) {
      writer.int32(v);
    }
    writer.join();
    if (message.realm !== "") {
      writer.uint32(170).string(message.realm);
    }
    if (message.versionInfo !== undefined) {
      VersionInfo.encode(message.versionInfo, writer.uint32(194).fork()).join();
    }
    if (message.dolosState !== 0) {
      writer.uint32(200).int32(message.dolosState);
    }
    if (message.fwEcTarget !== "") {
      writer.uint32(210).string(message.fwEcTarget);
    }
    if (message.fwApTarget !== "") {
      writer.uint32(218).string(message.fwApTarget);
    }
    if (message.gpuId !== "") {
      writer.uint32(226).string(message.gpuId);
    }
    if (message.amtManagerState !== 0) {
      writer.uint32(240).int32(message.amtManagerState);
    }
    if (message.audioBeamforming !== "") {
      writer.uint32(250).string(message.audioBeamforming);
    }
    if (message.cameraState !== 0) {
      writer.uint32(256).int32(message.cameraState);
    }
    if (message.fingerprintBoard !== "") {
      writer.uint32(274).string(message.fingerprintBoard);
    }
    if (message.fingerprintMcu !== "") {
      writer.uint32(266).string(message.fingerprintMcu);
    }
    if (message.fingerprintSensor !== "") {
      writer.uint32(282).string(message.fingerprintSensor);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DutState {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDutState() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = ChromeOSDeviceID.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.servo = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.chameleon = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.audioLoopbackDongle = reader.int32() as any;
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.wifiPeripheralState = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.workingBluetoothBtpeer = reader.int32();
          continue;
        }
        case 23: {
          if (tag !== 184) {
            break;
          }

          message.peripheralBtpeerState = reader.int32() as any;
          continue;
        }
        case 20: {
          if (tag !== 160) {
            break;
          }

          message.hmrState = reader.int32() as any;
          continue;
        }
        case 22: {
          if (tag !== 176) {
            break;
          }

          message.audioLatencyToolkitState = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.cr50Phase = reader.int32() as any;
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.cr50KeyEnv = reader.int32() as any;
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.storageState = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.servoUsbState = reader.int32() as any;
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.batteryState = reader.int32() as any;
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.wifiState = reader.int32() as any;
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.bluetoothState = reader.int32() as any;
          continue;
        }
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.cellularModemState = reader.int32() as any;
          continue;
        }
        case 29: {
          if (tag !== 232) {
            break;
          }

          message.starfishState = reader.int32() as any;
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.rpmState = reader.int32() as any;
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.dutStateReason = reader.string();
          continue;
        }
        case 19: {
          if (tag === 152) {
            message.repairRequests.push(reader.int32() as any);

            continue;
          }

          if (tag === 154) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.repairRequests.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 21: {
          if (tag !== 170) {
            break;
          }

          message.realm = reader.string();
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.versionInfo = VersionInfo.decode(reader, reader.uint32());
          continue;
        }
        case 25: {
          if (tag !== 200) {
            break;
          }

          message.dolosState = reader.int32() as any;
          continue;
        }
        case 26: {
          if (tag !== 210) {
            break;
          }

          message.fwEcTarget = reader.string();
          continue;
        }
        case 27: {
          if (tag !== 218) {
            break;
          }

          message.fwApTarget = reader.string();
          continue;
        }
        case 28: {
          if (tag !== 226) {
            break;
          }

          message.gpuId = reader.string();
          continue;
        }
        case 30: {
          if (tag !== 240) {
            break;
          }

          message.amtManagerState = reader.int32() as any;
          continue;
        }
        case 31: {
          if (tag !== 250) {
            break;
          }

          message.audioBeamforming = reader.string();
          continue;
        }
        case 32: {
          if (tag !== 256) {
            break;
          }

          message.cameraState = reader.int32() as any;
          continue;
        }
        case 34: {
          if (tag !== 274) {
            break;
          }

          message.fingerprintBoard = reader.string();
          continue;
        }
        case 33: {
          if (tag !== 266) {
            break;
          }

          message.fingerprintMcu = reader.string();
          continue;
        }
        case 35: {
          if (tag !== 282) {
            break;
          }

          message.fingerprintSensor = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DutState {
    return {
      id: isSet(object.id) ? ChromeOSDeviceID.fromJSON(object.id) : undefined,
      servo: isSet(object.servo) ? peripheralStateFromJSON(object.servo) : 0,
      chameleon: isSet(object.chameleon) ? peripheralStateFromJSON(object.chameleon) : 0,
      audioLoopbackDongle: isSet(object.audioLoopbackDongle) ? peripheralStateFromJSON(object.audioLoopbackDongle) : 0,
      wifiPeripheralState: isSet(object.wifiPeripheralState) ? peripheralStateFromJSON(object.wifiPeripheralState) : 0,
      workingBluetoothBtpeer: isSet(object.workingBluetoothBtpeer)
        ? globalThis.Number(object.workingBluetoothBtpeer)
        : 0,
      peripheralBtpeerState: isSet(object.peripheralBtpeerState)
        ? peripheralStateFromJSON(object.peripheralBtpeerState)
        : 0,
      hmrState: isSet(object.hmrState) ? peripheralStateFromJSON(object.hmrState) : 0,
      audioLatencyToolkitState: isSet(object.audioLatencyToolkitState)
        ? peripheralStateFromJSON(object.audioLatencyToolkitState)
        : 0,
      cr50Phase: isSet(object.cr50Phase) ? dutState_CR50PhaseFromJSON(object.cr50Phase) : 0,
      cr50KeyEnv: isSet(object.cr50KeyEnv) ? dutState_CR50KeyEnvFromJSON(object.cr50KeyEnv) : 0,
      storageState: isSet(object.storageState) ? hardwareStateFromJSON(object.storageState) : 0,
      servoUsbState: isSet(object.servoUsbState) ? hardwareStateFromJSON(object.servoUsbState) : 0,
      batteryState: isSet(object.batteryState) ? hardwareStateFromJSON(object.batteryState) : 0,
      wifiState: isSet(object.wifiState) ? hardwareStateFromJSON(object.wifiState) : 0,
      bluetoothState: isSet(object.bluetoothState) ? hardwareStateFromJSON(object.bluetoothState) : 0,
      cellularModemState: isSet(object.cellularModemState) ? hardwareStateFromJSON(object.cellularModemState) : 0,
      starfishState: isSet(object.starfishState) ? peripheralStateFromJSON(object.starfishState) : 0,
      rpmState: isSet(object.rpmState) ? peripheralStateFromJSON(object.rpmState) : 0,
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      dutStateReason: isSet(object.dutStateReason) ? globalThis.String(object.dutStateReason) : "",
      repairRequests: globalThis.Array.isArray(object?.repairRequests)
        ? object.repairRequests.map((e: any) => dutState_RepairRequestFromJSON(e))
        : [],
      realm: isSet(object.realm) ? globalThis.String(object.realm) : "",
      versionInfo: isSet(object.versionInfo) ? VersionInfo.fromJSON(object.versionInfo) : undefined,
      dolosState: isSet(object.dolosState) ? peripheralStateFromJSON(object.dolosState) : 0,
      fwEcTarget: isSet(object.fwEcTarget) ? globalThis.String(object.fwEcTarget) : "",
      fwApTarget: isSet(object.fwApTarget) ? globalThis.String(object.fwApTarget) : "",
      gpuId: isSet(object.gpuId) ? globalThis.String(object.gpuId) : "",
      amtManagerState: isSet(object.amtManagerState) ? peripheralStateFromJSON(object.amtManagerState) : 0,
      audioBeamforming: isSet(object.audioBeamforming) ? globalThis.String(object.audioBeamforming) : "",
      cameraState: isSet(object.cameraState) ? hardwareStateFromJSON(object.cameraState) : 0,
      fingerprintBoard: isSet(object.fingerprintBoard) ? globalThis.String(object.fingerprintBoard) : "",
      fingerprintMcu: isSet(object.fingerprintMcu) ? globalThis.String(object.fingerprintMcu) : "",
      fingerprintSensor: isSet(object.fingerprintSensor) ? globalThis.String(object.fingerprintSensor) : "",
    };
  },

  toJSON(message: DutState): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = ChromeOSDeviceID.toJSON(message.id);
    }
    if (message.servo !== 0) {
      obj.servo = peripheralStateToJSON(message.servo);
    }
    if (message.chameleon !== 0) {
      obj.chameleon = peripheralStateToJSON(message.chameleon);
    }
    if (message.audioLoopbackDongle !== 0) {
      obj.audioLoopbackDongle = peripheralStateToJSON(message.audioLoopbackDongle);
    }
    if (message.wifiPeripheralState !== 0) {
      obj.wifiPeripheralState = peripheralStateToJSON(message.wifiPeripheralState);
    }
    if (message.workingBluetoothBtpeer !== 0) {
      obj.workingBluetoothBtpeer = Math.round(message.workingBluetoothBtpeer);
    }
    if (message.peripheralBtpeerState !== 0) {
      obj.peripheralBtpeerState = peripheralStateToJSON(message.peripheralBtpeerState);
    }
    if (message.hmrState !== 0) {
      obj.hmrState = peripheralStateToJSON(message.hmrState);
    }
    if (message.audioLatencyToolkitState !== 0) {
      obj.audioLatencyToolkitState = peripheralStateToJSON(message.audioLatencyToolkitState);
    }
    if (message.cr50Phase !== 0) {
      obj.cr50Phase = dutState_CR50PhaseToJSON(message.cr50Phase);
    }
    if (message.cr50KeyEnv !== 0) {
      obj.cr50KeyEnv = dutState_CR50KeyEnvToJSON(message.cr50KeyEnv);
    }
    if (message.storageState !== 0) {
      obj.storageState = hardwareStateToJSON(message.storageState);
    }
    if (message.servoUsbState !== 0) {
      obj.servoUsbState = hardwareStateToJSON(message.servoUsbState);
    }
    if (message.batteryState !== 0) {
      obj.batteryState = hardwareStateToJSON(message.batteryState);
    }
    if (message.wifiState !== 0) {
      obj.wifiState = hardwareStateToJSON(message.wifiState);
    }
    if (message.bluetoothState !== 0) {
      obj.bluetoothState = hardwareStateToJSON(message.bluetoothState);
    }
    if (message.cellularModemState !== 0) {
      obj.cellularModemState = hardwareStateToJSON(message.cellularModemState);
    }
    if (message.starfishState !== 0) {
      obj.starfishState = peripheralStateToJSON(message.starfishState);
    }
    if (message.rpmState !== 0) {
      obj.rpmState = peripheralStateToJSON(message.rpmState);
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.dutStateReason !== "") {
      obj.dutStateReason = message.dutStateReason;
    }
    if (message.repairRequests?.length) {
      obj.repairRequests = message.repairRequests.map((e) => dutState_RepairRequestToJSON(e));
    }
    if (message.realm !== "") {
      obj.realm = message.realm;
    }
    if (message.versionInfo !== undefined) {
      obj.versionInfo = VersionInfo.toJSON(message.versionInfo);
    }
    if (message.dolosState !== 0) {
      obj.dolosState = peripheralStateToJSON(message.dolosState);
    }
    if (message.fwEcTarget !== "") {
      obj.fwEcTarget = message.fwEcTarget;
    }
    if (message.fwApTarget !== "") {
      obj.fwApTarget = message.fwApTarget;
    }
    if (message.gpuId !== "") {
      obj.gpuId = message.gpuId;
    }
    if (message.amtManagerState !== 0) {
      obj.amtManagerState = peripheralStateToJSON(message.amtManagerState);
    }
    if (message.audioBeamforming !== "") {
      obj.audioBeamforming = message.audioBeamforming;
    }
    if (message.cameraState !== 0) {
      obj.cameraState = hardwareStateToJSON(message.cameraState);
    }
    if (message.fingerprintBoard !== "") {
      obj.fingerprintBoard = message.fingerprintBoard;
    }
    if (message.fingerprintMcu !== "") {
      obj.fingerprintMcu = message.fingerprintMcu;
    }
    if (message.fingerprintSensor !== "") {
      obj.fingerprintSensor = message.fingerprintSensor;
    }
    return obj;
  },

  create(base?: DeepPartial<DutState>): DutState {
    return DutState.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DutState>): DutState {
    const message = createBaseDutState() as any;
    message.id = (object.id !== undefined && object.id !== null) ? ChromeOSDeviceID.fromPartial(object.id) : undefined;
    message.servo = object.servo ?? 0;
    message.chameleon = object.chameleon ?? 0;
    message.audioLoopbackDongle = object.audioLoopbackDongle ?? 0;
    message.wifiPeripheralState = object.wifiPeripheralState ?? 0;
    message.workingBluetoothBtpeer = object.workingBluetoothBtpeer ?? 0;
    message.peripheralBtpeerState = object.peripheralBtpeerState ?? 0;
    message.hmrState = object.hmrState ?? 0;
    message.audioLatencyToolkitState = object.audioLatencyToolkitState ?? 0;
    message.cr50Phase = object.cr50Phase ?? 0;
    message.cr50KeyEnv = object.cr50KeyEnv ?? 0;
    message.storageState = object.storageState ?? 0;
    message.servoUsbState = object.servoUsbState ?? 0;
    message.batteryState = object.batteryState ?? 0;
    message.wifiState = object.wifiState ?? 0;
    message.bluetoothState = object.bluetoothState ?? 0;
    message.cellularModemState = object.cellularModemState ?? 0;
    message.starfishState = object.starfishState ?? 0;
    message.rpmState = object.rpmState ?? 0;
    message.updateTime = object.updateTime ?? undefined;
    message.hostname = object.hostname ?? "";
    message.dutStateReason = object.dutStateReason ?? "";
    message.repairRequests = object.repairRequests?.map((e) => e) || [];
    message.realm = object.realm ?? "";
    message.versionInfo = (object.versionInfo !== undefined && object.versionInfo !== null)
      ? VersionInfo.fromPartial(object.versionInfo)
      : undefined;
    message.dolosState = object.dolosState ?? 0;
    message.fwEcTarget = object.fwEcTarget ?? "";
    message.fwApTarget = object.fwApTarget ?? "";
    message.gpuId = object.gpuId ?? "";
    message.amtManagerState = object.amtManagerState ?? 0;
    message.audioBeamforming = object.audioBeamforming ?? "";
    message.cameraState = object.cameraState ?? 0;
    message.fingerprintBoard = object.fingerprintBoard ?? "";
    message.fingerprintMcu = object.fingerprintMcu ?? "";
    message.fingerprintSensor = object.fingerprintSensor ?? "";
    return message;
  },
};

function createBaseVersionInfo(): VersionInfo {
  return { os: "", roFirmware: "", rwFirmware: "", osType: 0 };
}

export const VersionInfo: MessageFns<VersionInfo> = {
  encode(message: VersionInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.os !== "") {
      writer.uint32(10).string(message.os);
    }
    if (message.roFirmware !== "") {
      writer.uint32(18).string(message.roFirmware);
    }
    if (message.rwFirmware !== "") {
      writer.uint32(26).string(message.rwFirmware);
    }
    if (message.osType !== 0) {
      writer.uint32(32).int32(message.osType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): VersionInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVersionInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.os = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.roFirmware = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.rwFirmware = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): VersionInfo {
    return {
      os: isSet(object.os) ? globalThis.String(object.os) : "",
      roFirmware: isSet(object.roFirmware) ? globalThis.String(object.roFirmware) : "",
      rwFirmware: isSet(object.rwFirmware) ? globalThis.String(object.rwFirmware) : "",
      osType: isSet(object.osType) ? versionInfo_OsTypeFromJSON(object.osType) : 0,
    };
  },

  toJSON(message: VersionInfo): unknown {
    const obj: any = {};
    if (message.os !== "") {
      obj.os = message.os;
    }
    if (message.roFirmware !== "") {
      obj.roFirmware = message.roFirmware;
    }
    if (message.rwFirmware !== "") {
      obj.rwFirmware = message.rwFirmware;
    }
    if (message.osType !== 0) {
      obj.osType = versionInfo_OsTypeToJSON(message.osType);
    }
    return obj;
  },

  create(base?: DeepPartial<VersionInfo>): VersionInfo {
    return VersionInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<VersionInfo>): VersionInfo {
    const message = createBaseVersionInfo() as any;
    message.os = object.os ?? "";
    message.roFirmware = object.roFirmware ?? "";
    message.rwFirmware = object.rwFirmware ?? "";
    message.osType = object.osType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
