// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/lab/peripherals.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { PasitHost } from "../../../../../../../chromiumos/config/proto/chromiumos/test/lab/api/pasit_host.pb";
import {
  WifiRouterDeviceType,
  wifiRouterDeviceTypeFromJSON,
  wifiRouterDeviceTypeToJSON,
  WifiRouterFeature,
  wifiRouterFeatureFromJSON,
  wifiRouterFeatureToJSON,
} from "../../../../../../../chromiumos/config/proto/chromiumos/test/lab/api/wifi_router.pb";
import { Chameleon } from "./chameleon.pb";
import { Dolos } from "./dolos.pb";
import { PeripheralState, peripheralStateFromJSON, peripheralStateToJSON } from "./dut_state.pb";
import { OSRPM } from "./rpm.pb";
import { Servo } from "./servo.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 3 */
export enum CameraType {
  CAMERA_INVALID = 0,
  /** CAMERA_HUDDLY - camera Huddly GO */
  CAMERA_HUDDLY = 1,
  /** CAMERA_PTZPRO2 - camera Logitech PTZ Pro 2 */
  CAMERA_PTZPRO2 = 2,
}

export function cameraTypeFromJSON(object: any): CameraType {
  switch (object) {
    case 0:
    case "CAMERA_INVALID":
      return CameraType.CAMERA_INVALID;
    case 1:
    case "CAMERA_HUDDLY":
      return CameraType.CAMERA_HUDDLY;
    case 2:
    case "CAMERA_PTZPRO2":
      return CameraType.CAMERA_PTZPRO2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CameraType");
  }
}

export function cameraTypeToJSON(object: CameraType): string {
  switch (object) {
    case CameraType.CAMERA_INVALID:
      return "CAMERA_INVALID";
    case CameraType.CAMERA_HUDDLY:
      return "CAMERA_HUDDLY";
    case CameraType.CAMERA_PTZPRO2:
      return "CAMERA_PTZPRO2";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CameraType");
  }
}

export enum CableType {
  CABLE_INVALID = 0,
  CABLE_AUDIOJACK = 1,
  CABLE_USBAUDIO = 2,
  CABLE_USBPRINTING = 3,
  CABLE_HDMIAUDIO = 4,
}

export function cableTypeFromJSON(object: any): CableType {
  switch (object) {
    case 0:
    case "CABLE_INVALID":
      return CableType.CABLE_INVALID;
    case 1:
    case "CABLE_AUDIOJACK":
      return CableType.CABLE_AUDIOJACK;
    case 2:
    case "CABLE_USBAUDIO":
      return CableType.CABLE_USBAUDIO;
    case 3:
    case "CABLE_USBPRINTING":
      return CableType.CABLE_USBPRINTING;
    case 4:
    case "CABLE_HDMIAUDIO":
      return CableType.CABLE_HDMIAUDIO;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CableType");
  }
}

export function cableTypeToJSON(object: CableType): string {
  switch (object) {
    case CableType.CABLE_INVALID:
      return "CABLE_INVALID";
    case CableType.CABLE_AUDIOJACK:
      return "CABLE_AUDIOJACK";
    case CableType.CABLE_USBAUDIO:
      return "CABLE_USBAUDIO";
    case CableType.CABLE_USBPRINTING:
      return "CABLE_USBPRINTING";
    case CableType.CABLE_HDMIAUDIO:
      return "CABLE_HDMIAUDIO";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum CableType");
  }
}

/** Peripherals of device. Next Tag: 26 */
export interface Peripherals {
  readonly servo: Servo | undefined;
  readonly chameleon: Chameleon | undefined;
  readonly rpm:
    | OSRPM
    | undefined;
  /** refer to cameras that connected to the device. */
  readonly connectedCamera: readonly Camera[];
  readonly audio: Audio | undefined;
  readonly wifi: Wifi | undefined;
  readonly touch:
    | Touch
    | undefined;
  /** e.g: "att", "verizon",.. It's a manual label set by lab, varies dut by dut. */
  readonly carrier: string;
  /** e.g: "0_att,1_verizon,3_tmobile", "1_kddi,6_rakuten".. slot mapping: only for Starfish enabled modules. */
  readonly starfishSlotMapping: string;
  /**
   * A list of all carriers that can be tested on the DUT. This extends the carrier field which could previously only
   * be used with a single carrier.
   */
  readonly supportedCarriers: readonly string[];
  /**
   * Indicate if the device is setup in a steady and controllable camera box environment for camera test automation.
   * http://go/cros-camera-box
   */
  readonly camerabox: boolean;
  /** Indicate if the device is setup in a chaos environment. It's a special settings for running wifi interop tests. */
  readonly chaos: boolean;
  /** Indicate the cables that connect audio, printer to the device in ACS lab. */
  readonly cable: readonly Cable[];
  /** Incompatible upgraded type from bool camerabox=9. */
  readonly cameraboxInfo:
    | Camerabox
    | undefined;
  /** Indicate if device is connected to a smart usb hub, detected in AdminRepair task. */
  readonly smartUsbhub: boolean;
  /**
   * Indicate if the DUT's back camera supports region of interest
   * Deprecated.
   *
   * @deprecated
   */
  readonly cameraRoiBack: boolean;
  /**
   * Indicate if the DUT's front camera supports region of interest
   * Deprecated.
   *
   * @deprecated
   */
  readonly cameraRoiFront: boolean;
  readonly bluetoothPeers: readonly BluetoothPeer[];
  readonly humanMotionRobot:
    | HumanMotionRobot
    | undefined;
  /** Indicate if the DUT is connected to an audio latency toolkit */
  readonly audioLatencyToolkit:
    | AudioLatencyToolkit
    | undefined;
  /** Indicate if the DUT uses Dolos as a battery replacement */
  readonly dolos: Dolos | undefined;
  readonly pasitHost2:
    | PasitHost
    | undefined;
  /** Indicate if the DUT is equipped with Intel AMT. */
  readonly amtManager: AMTManager | undefined;
}

/** Next Tag: 2 */
export interface Camera {
  readonly cameraType: CameraType;
}

export interface Cable {
  readonly type: CableType;
}

/** Next Tag: 4 */
export interface Audio {
  /**
   * Indicate if the DUT is housed in an audio box to record / replay audio
   * for audio testing.
   */
  readonly audioBox: boolean;
  /** Indicate if the DUT is connected to Atrus speakermic */
  readonly atrus: boolean;
  /** Indicate if the DUT is connected to chameleon through static audio cable */
  readonly audioCable: boolean;
}

/** Next Tag: 7 */
export interface Wifi {
  /** Indicate if the device is inside a hermetic wifi cell. */
  readonly wificell: boolean;
  readonly antennaConn: Wifi_AntennaConnection;
  /**
   * Indicate if the device is in a pre-setup environment with 802.11ax routers.
   * crbug.com/1044786
   */
  readonly router: Wifi_Router;
  /** collections of routers used for wifi testing. */
  readonly wifiRouters: readonly WifiRouter[];
  /**
   * WifiRouterFeatures that are supported by all wifi routers in the testbed.
   *
   * If there are no wifi routers this will be empty.
   * If any routers have unknown features, this will just have a single
   * WIFI_ROUTER_FEATURE_UNKNOWN entry.
   * If any routers' features has a WIFI_ROUTER_FEATURE_INVALID feature, a
   * WIFI_ROUTER_FEATURE_INVALID feature will be included along with the other
   * common, valid features.
   * If there are no common, valid features between all routers, this will just
   * have a single WIFI_ROUTER_FEATURE_INVALID entry.
   */
  readonly wifiRouterFeatures: readonly WifiRouterFeature[];
}

/**
 * DUT's WiFi antenna's connection.
 * Next Tag: 3
 */
export enum Wifi_AntennaConnection {
  CONN_UNKNOWN = 0,
  /** CONN_CONDUCTIVE - WIFI antenna is connected conductively. */
  CONN_CONDUCTIVE = 1,
  /** CONN_OTA - WIFI antenna is connected over-the-air. */
  CONN_OTA = 2,
}

export function wifi_AntennaConnectionFromJSON(object: any): Wifi_AntennaConnection {
  switch (object) {
    case 0:
    case "CONN_UNKNOWN":
      return Wifi_AntennaConnection.CONN_UNKNOWN;
    case 1:
    case "CONN_CONDUCTIVE":
      return Wifi_AntennaConnection.CONN_CONDUCTIVE;
    case 2:
    case "CONN_OTA":
      return Wifi_AntennaConnection.CONN_OTA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Wifi_AntennaConnection");
  }
}

export function wifi_AntennaConnectionToJSON(object: Wifi_AntennaConnection): string {
  switch (object) {
    case Wifi_AntennaConnection.CONN_UNKNOWN:
      return "CONN_UNKNOWN";
    case Wifi_AntennaConnection.CONN_CONDUCTIVE:
      return "CONN_CONDUCTIVE";
    case Wifi_AntennaConnection.CONN_OTA:
      return "CONN_OTA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Wifi_AntennaConnection");
  }
}

export enum Wifi_Router {
  ROUTER_UNSPECIFIED = 0,
  ROUTER_802_11AX = 1,
}

export function wifi_RouterFromJSON(object: any): Wifi_Router {
  switch (object) {
    case 0:
    case "ROUTER_UNSPECIFIED":
      return Wifi_Router.ROUTER_UNSPECIFIED;
    case 1:
    case "ROUTER_802_11AX":
      return Wifi_Router.ROUTER_802_11AX;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Wifi_Router");
  }
}

export function wifi_RouterToJSON(object: Wifi_Router): string {
  switch (object) {
    case Wifi_Router.ROUTER_UNSPECIFIED:
      return "ROUTER_UNSPECIFIED";
    case Wifi_Router.ROUTER_802_11AX:
      return "ROUTER_802_11AX";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Wifi_Router");
  }
}

/** Next Tag: 7 */
export interface WifiRouter {
  readonly hostname: string;
  readonly state: PeripheralState;
  readonly buildTarget: string;
  readonly model: string;
  /** RPM to perform remote power management. use OSRPM defined here. */
  readonly rpm:
    | OSRPM
    | undefined;
  /** Supported test router hardware and software features. */
  readonly supportedFeatures: readonly WifiRouterFeature[];
  /** The type of router device this is (e.g. OpenWrt-based, ChromeOS Gale). */
  readonly deviceType: WifiRouterDeviceType;
}

/** Next Tag: 2 */
export interface Touch {
  /** Has touch monitor mimo. */
  readonly mimo: boolean;
}

/** Next Tag: 3 */
export interface Camerabox {
  readonly facing: Camerabox_Facing;
  readonly light: Camerabox_Light;
}

/**
 * Facing of DUT's camera to be tested whose FOV should cover chart tablet's screen.
 * Next Tag: 4
 */
export enum Camerabox_Facing {
  FACING_UNKNOWN = 0,
  /** FACING_BACK - DUT's back camera is facing to chart tablet. */
  FACING_BACK = 1,
  /** FACING_FRONT - DUT's front camera is facing to chart tablet. */
  FACING_FRONT = 2,
  /** FACING_NOCAMERA - DUT has no camera */
  FACING_NOCAMERA = 3,
}

export function camerabox_FacingFromJSON(object: any): Camerabox_Facing {
  switch (object) {
    case 0:
    case "FACING_UNKNOWN":
      return Camerabox_Facing.FACING_UNKNOWN;
    case 1:
    case "FACING_BACK":
      return Camerabox_Facing.FACING_BACK;
    case 2:
    case "FACING_FRONT":
      return Camerabox_Facing.FACING_FRONT;
    case 3:
    case "FACING_NOCAMERA":
      return Camerabox_Facing.FACING_NOCAMERA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Camerabox_Facing");
  }
}

export function camerabox_FacingToJSON(object: Camerabox_Facing): string {
  switch (object) {
    case Camerabox_Facing.FACING_UNKNOWN:
      return "FACING_UNKNOWN";
    case Camerabox_Facing.FACING_BACK:
      return "FACING_BACK";
    case Camerabox_Facing.FACING_FRONT:
      return "FACING_FRONT";
    case Camerabox_Facing.FACING_NOCAMERA:
      return "FACING_NOCAMERA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Camerabox_Facing");
  }
}

/**
 * Light provision of camerabox, if LED is present or not.
 * Next Tag: 3
 */
export enum Camerabox_Light {
  LIGHT_UNKNOWN = 0,
  /** LIGHT_LED - Camerabox has LED light provisioned. */
  LIGHT_LED = 1,
  /** LIGHT_NOLED - Camerabox do not have LED light provision. */
  LIGHT_NOLED = 2,
}

export function camerabox_LightFromJSON(object: any): Camerabox_Light {
  switch (object) {
    case 0:
    case "LIGHT_UNKNOWN":
      return Camerabox_Light.LIGHT_UNKNOWN;
    case 1:
    case "LIGHT_LED":
      return Camerabox_Light.LIGHT_LED;
    case 2:
    case "LIGHT_NOLED":
      return Camerabox_Light.LIGHT_NOLED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Camerabox_Light");
  }
}

export function camerabox_LightToJSON(object: Camerabox_Light): string {
  switch (object) {
    case Camerabox_Light.LIGHT_UNKNOWN:
      return "LIGHT_UNKNOWN";
    case Camerabox_Light.LIGHT_LED:
      return "LIGHT_LED";
    case Camerabox_Light.LIGHT_NOLED:
      return "LIGHT_NOLED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Camerabox_Light");
  }
}

/**
 * RaspberryPi models hardware without an assumption of how
 * it will be used, e.g. Bluetooth peers, audio testing, etc.
 */
export interface RaspberryPi {
  readonly hostname: string;
  readonly state: PeripheralState;
}

/**
 * BluetoothPeer is hardware that is used as a Bluetooth peer.
 * It models function rather than hardware.
 */
export interface BluetoothPeer {
  readonly raspberryPi?:
    | RaspberryPi
    | undefined;
  /** Remote Power Management for the bluetooth peer device. */
  readonly rpm: OSRPM | undefined;
}

/**
 * HumanMotionRobot models the whole HMR system
 * including touchhost, Pi, HMR fixture, etc.
 * Next Tag: 8
 */
export interface HumanMotionRobot {
  /** hostname for HMR pi */
  readonly hostname: string;
  /** model of the HMR */
  readonly hmrModel: string;
  /** hostname for touch-host pi */
  readonly gatewayHostname: string;
  /** Remote Power Management for HMR device. */
  readonly rpm:
    | OSRPM
    | undefined;
  /**
   * Indicate the presence of a WALT device for latency testing.
   * The walt device will be connected to the bottom usb-A port of the servo.
   * Set to true if the HMR has a walt unit attached to it else False.
   */
  readonly hmrWalt: boolean;
  /** Indicate the tool type currently attached to the HMR. */
  readonly hmrToolType: HumanMotionRobot_HMRToolType;
  /**
   * Indicate the generation of the HMR.
   * The type of generation can be identified by the serial number behind the HMR.
   */
  readonly hmrGen: HumanMotionRobot_HMRGen;
}

/**
 * HMRToolType describe the enum of types of tool on HMR.
 * Next Tag: 3
 */
export enum HumanMotionRobot_HMRToolType {
  HMR_TOOL_TYPE_UNKNOWN = 0,
  HMR_TOOL_TYPE_STYLUS = 1,
  HMR_TOOL_TYPE_FAKE_FINGER = 2,
}

export function humanMotionRobot_HMRToolTypeFromJSON(object: any): HumanMotionRobot_HMRToolType {
  switch (object) {
    case 0:
    case "HMR_TOOL_TYPE_UNKNOWN":
      return HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_UNKNOWN;
    case 1:
    case "HMR_TOOL_TYPE_STYLUS":
      return HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_STYLUS;
    case 2:
    case "HMR_TOOL_TYPE_FAKE_FINGER":
      return HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_FAKE_FINGER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HumanMotionRobot_HMRToolType");
  }
}

export function humanMotionRobot_HMRToolTypeToJSON(object: HumanMotionRobot_HMRToolType): string {
  switch (object) {
    case HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_UNKNOWN:
      return "HMR_TOOL_TYPE_UNKNOWN";
    case HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_STYLUS:
      return "HMR_TOOL_TYPE_STYLUS";
    case HumanMotionRobot_HMRToolType.HMR_TOOL_TYPE_FAKE_FINGER:
      return "HMR_TOOL_TYPE_FAKE_FINGER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HumanMotionRobot_HMRToolType");
  }
}

/**
 * HMRGen describe the enum of generation of the HMR.
 * Next Tag: 3
 */
export enum HumanMotionRobot_HMRGen {
  HMR_GEN_UNKNOWN = 0,
  HMR_GEN_1 = 1,
  HMR_GEN_2 = 2,
}

export function humanMotionRobot_HMRGenFromJSON(object: any): HumanMotionRobot_HMRGen {
  switch (object) {
    case 0:
    case "HMR_GEN_UNKNOWN":
      return HumanMotionRobot_HMRGen.HMR_GEN_UNKNOWN;
    case 1:
    case "HMR_GEN_1":
      return HumanMotionRobot_HMRGen.HMR_GEN_1;
    case 2:
    case "HMR_GEN_2":
      return HumanMotionRobot_HMRGen.HMR_GEN_2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HumanMotionRobot_HMRGen");
  }
}

export function humanMotionRobot_HMRGenToJSON(object: HumanMotionRobot_HMRGen): string {
  switch (object) {
    case HumanMotionRobot_HMRGen.HMR_GEN_UNKNOWN:
      return "HMR_GEN_UNKNOWN";
    case HumanMotionRobot_HMRGen.HMR_GEN_1:
      return "HMR_GEN_1";
    case HumanMotionRobot_HMRGen.HMR_GEN_2:
      return "HMR_GEN_2";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HumanMotionRobot_HMRGen");
  }
}

/**
 * AudioLatencyToolkit models audio latency toolkits
 * including a Teensy and Teensy Audio Board
 * Next Tag: 1
 */
export interface AudioLatencyToolkit {
  /** default: "4.1" */
  readonly version: string;
}

/** AMTManager enables remote, out-of-band management. */
export interface AMTManager {
  /** Hostname for the AMT management port. */
  readonly hostname: string;
  /** Connect to AMT on port 16993 with TLS enabled. */
  readonly useTls: boolean;
}

function createBasePeripherals(): Peripherals {
  return {
    servo: undefined,
    chameleon: undefined,
    rpm: undefined,
    connectedCamera: [],
    audio: undefined,
    wifi: undefined,
    touch: undefined,
    carrier: "",
    starfishSlotMapping: "",
    supportedCarriers: [],
    camerabox: false,
    chaos: false,
    cable: [],
    cameraboxInfo: undefined,
    smartUsbhub: false,
    cameraRoiBack: false,
    cameraRoiFront: false,
    bluetoothPeers: [],
    humanMotionRobot: undefined,
    audioLatencyToolkit: undefined,
    dolos: undefined,
    pasitHost2: undefined,
    amtManager: undefined,
  };
}

export const Peripherals: MessageFns<Peripherals> = {
  encode(message: Peripherals, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.servo !== undefined) {
      Servo.encode(message.servo, writer.uint32(10).fork()).join();
    }
    if (message.chameleon !== undefined) {
      Chameleon.encode(message.chameleon, writer.uint32(18).fork()).join();
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(26).fork()).join();
    }
    for (const v of message.connectedCamera) {
      Camera.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.audio !== undefined) {
      Audio.encode(message.audio, writer.uint32(42).fork()).join();
    }
    if (message.wifi !== undefined) {
      Wifi.encode(message.wifi, writer.uint32(50).fork()).join();
    }
    if (message.touch !== undefined) {
      Touch.encode(message.touch, writer.uint32(58).fork()).join();
    }
    if (message.carrier !== "") {
      writer.uint32(66).string(message.carrier);
    }
    if (message.starfishSlotMapping !== "") {
      writer.uint32(138).string(message.starfishSlotMapping);
    }
    for (const v of message.supportedCarriers) {
      writer.uint32(170).string(v!);
    }
    if (message.camerabox !== false) {
      writer.uint32(72).bool(message.camerabox);
    }
    if (message.chaos !== false) {
      writer.uint32(80).bool(message.chaos);
    }
    for (const v of message.cable) {
      Cable.encode(v!, writer.uint32(90).fork()).join();
    }
    if (message.cameraboxInfo !== undefined) {
      Camerabox.encode(message.cameraboxInfo, writer.uint32(98).fork()).join();
    }
    if (message.smartUsbhub !== false) {
      writer.uint32(104).bool(message.smartUsbhub);
    }
    if (message.cameraRoiBack !== false) {
      writer.uint32(112).bool(message.cameraRoiBack);
    }
    if (message.cameraRoiFront !== false) {
      writer.uint32(120).bool(message.cameraRoiFront);
    }
    for (const v of message.bluetoothPeers) {
      BluetoothPeer.encode(v!, writer.uint32(130).fork()).join();
    }
    if (message.humanMotionRobot !== undefined) {
      HumanMotionRobot.encode(message.humanMotionRobot, writer.uint32(146).fork()).join();
    }
    if (message.audioLatencyToolkit !== undefined) {
      AudioLatencyToolkit.encode(message.audioLatencyToolkit, writer.uint32(154).fork()).join();
    }
    if (message.dolos !== undefined) {
      Dolos.encode(message.dolos, writer.uint32(162).fork()).join();
    }
    if (message.pasitHost2 !== undefined) {
      PasitHost.encode(message.pasitHost2, writer.uint32(194).fork()).join();
    }
    if (message.amtManager !== undefined) {
      AMTManager.encode(message.amtManager, writer.uint32(202).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Peripherals {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeripherals() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.servo = Servo.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.chameleon = Chameleon.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.connectedCamera.push(Camera.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.audio = Audio.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.wifi = Wifi.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.touch = Touch.decode(reader, reader.uint32());
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.carrier = reader.string();
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.starfishSlotMapping = reader.string();
          continue;
        }
        case 21: {
          if (tag !== 170) {
            break;
          }

          message.supportedCarriers.push(reader.string());
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.camerabox = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.chaos = reader.bool();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.cable.push(Cable.decode(reader, reader.uint32()));
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.cameraboxInfo = Camerabox.decode(reader, reader.uint32());
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.smartUsbhub = reader.bool();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.cameraRoiBack = reader.bool();
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.cameraRoiFront = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 130) {
            break;
          }

          message.bluetoothPeers.push(BluetoothPeer.decode(reader, reader.uint32()));
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.humanMotionRobot = HumanMotionRobot.decode(reader, reader.uint32());
          continue;
        }
        case 19: {
          if (tag !== 154) {
            break;
          }

          message.audioLatencyToolkit = AudioLatencyToolkit.decode(reader, reader.uint32());
          continue;
        }
        case 20: {
          if (tag !== 162) {
            break;
          }

          message.dolos = Dolos.decode(reader, reader.uint32());
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.pasitHost2 = PasitHost.decode(reader, reader.uint32());
          continue;
        }
        case 25: {
          if (tag !== 202) {
            break;
          }

          message.amtManager = AMTManager.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Peripherals {
    return {
      servo: isSet(object.servo) ? Servo.fromJSON(object.servo) : undefined,
      chameleon: isSet(object.chameleon) ? Chameleon.fromJSON(object.chameleon) : undefined,
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
      connectedCamera: globalThis.Array.isArray(object?.connectedCamera)
        ? object.connectedCamera.map((e: any) => Camera.fromJSON(e))
        : [],
      audio: isSet(object.audio) ? Audio.fromJSON(object.audio) : undefined,
      wifi: isSet(object.wifi) ? Wifi.fromJSON(object.wifi) : undefined,
      touch: isSet(object.touch) ? Touch.fromJSON(object.touch) : undefined,
      carrier: isSet(object.carrier) ? globalThis.String(object.carrier) : "",
      starfishSlotMapping: isSet(object.starfishSlotMapping) ? globalThis.String(object.starfishSlotMapping) : "",
      supportedCarriers: globalThis.Array.isArray(object?.supportedCarriers)
        ? object.supportedCarriers.map((e: any) => globalThis.String(e))
        : [],
      camerabox: isSet(object.camerabox) ? globalThis.Boolean(object.camerabox) : false,
      chaos: isSet(object.chaos) ? globalThis.Boolean(object.chaos) : false,
      cable: globalThis.Array.isArray(object?.cable) ? object.cable.map((e: any) => Cable.fromJSON(e)) : [],
      cameraboxInfo: isSet(object.cameraboxInfo) ? Camerabox.fromJSON(object.cameraboxInfo) : undefined,
      smartUsbhub: isSet(object.smartUsbhub) ? globalThis.Boolean(object.smartUsbhub) : false,
      cameraRoiBack: isSet(object.cameraRoiBack) ? globalThis.Boolean(object.cameraRoiBack) : false,
      cameraRoiFront: isSet(object.cameraRoiFront) ? globalThis.Boolean(object.cameraRoiFront) : false,
      bluetoothPeers: globalThis.Array.isArray(object?.bluetoothPeers)
        ? object.bluetoothPeers.map((e: any) => BluetoothPeer.fromJSON(e))
        : [],
      humanMotionRobot: isSet(object.humanMotionRobot) ? HumanMotionRobot.fromJSON(object.humanMotionRobot) : undefined,
      audioLatencyToolkit: isSet(object.audioLatencyToolkit)
        ? AudioLatencyToolkit.fromJSON(object.audioLatencyToolkit)
        : undefined,
      dolos: isSet(object.dolos) ? Dolos.fromJSON(object.dolos) : undefined,
      pasitHost2: isSet(object.pasitHost2) ? PasitHost.fromJSON(object.pasitHost2) : undefined,
      amtManager: isSet(object.amtManager) ? AMTManager.fromJSON(object.amtManager) : undefined,
    };
  },

  toJSON(message: Peripherals): unknown {
    const obj: any = {};
    if (message.servo !== undefined) {
      obj.servo = Servo.toJSON(message.servo);
    }
    if (message.chameleon !== undefined) {
      obj.chameleon = Chameleon.toJSON(message.chameleon);
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    if (message.connectedCamera?.length) {
      obj.connectedCamera = message.connectedCamera.map((e) => Camera.toJSON(e));
    }
    if (message.audio !== undefined) {
      obj.audio = Audio.toJSON(message.audio);
    }
    if (message.wifi !== undefined) {
      obj.wifi = Wifi.toJSON(message.wifi);
    }
    if (message.touch !== undefined) {
      obj.touch = Touch.toJSON(message.touch);
    }
    if (message.carrier !== "") {
      obj.carrier = message.carrier;
    }
    if (message.starfishSlotMapping !== "") {
      obj.starfishSlotMapping = message.starfishSlotMapping;
    }
    if (message.supportedCarriers?.length) {
      obj.supportedCarriers = message.supportedCarriers;
    }
    if (message.camerabox !== false) {
      obj.camerabox = message.camerabox;
    }
    if (message.chaos !== false) {
      obj.chaos = message.chaos;
    }
    if (message.cable?.length) {
      obj.cable = message.cable.map((e) => Cable.toJSON(e));
    }
    if (message.cameraboxInfo !== undefined) {
      obj.cameraboxInfo = Camerabox.toJSON(message.cameraboxInfo);
    }
    if (message.smartUsbhub !== false) {
      obj.smartUsbhub = message.smartUsbhub;
    }
    if (message.cameraRoiBack !== false) {
      obj.cameraRoiBack = message.cameraRoiBack;
    }
    if (message.cameraRoiFront !== false) {
      obj.cameraRoiFront = message.cameraRoiFront;
    }
    if (message.bluetoothPeers?.length) {
      obj.bluetoothPeers = message.bluetoothPeers.map((e) => BluetoothPeer.toJSON(e));
    }
    if (message.humanMotionRobot !== undefined) {
      obj.humanMotionRobot = HumanMotionRobot.toJSON(message.humanMotionRobot);
    }
    if (message.audioLatencyToolkit !== undefined) {
      obj.audioLatencyToolkit = AudioLatencyToolkit.toJSON(message.audioLatencyToolkit);
    }
    if (message.dolos !== undefined) {
      obj.dolos = Dolos.toJSON(message.dolos);
    }
    if (message.pasitHost2 !== undefined) {
      obj.pasitHost2 = PasitHost.toJSON(message.pasitHost2);
    }
    if (message.amtManager !== undefined) {
      obj.amtManager = AMTManager.toJSON(message.amtManager);
    }
    return obj;
  },

  create(base?: DeepPartial<Peripherals>): Peripherals {
    return Peripherals.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Peripherals>): Peripherals {
    const message = createBasePeripherals() as any;
    message.servo = (object.servo !== undefined && object.servo !== null) ? Servo.fromPartial(object.servo) : undefined;
    message.chameleon = (object.chameleon !== undefined && object.chameleon !== null)
      ? Chameleon.fromPartial(object.chameleon)
      : undefined;
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    message.connectedCamera = object.connectedCamera?.map((e) => Camera.fromPartial(e)) || [];
    message.audio = (object.audio !== undefined && object.audio !== null) ? Audio.fromPartial(object.audio) : undefined;
    message.wifi = (object.wifi !== undefined && object.wifi !== null) ? Wifi.fromPartial(object.wifi) : undefined;
    message.touch = (object.touch !== undefined && object.touch !== null) ? Touch.fromPartial(object.touch) : undefined;
    message.carrier = object.carrier ?? "";
    message.starfishSlotMapping = object.starfishSlotMapping ?? "";
    message.supportedCarriers = object.supportedCarriers?.map((e) => e) || [];
    message.camerabox = object.camerabox ?? false;
    message.chaos = object.chaos ?? false;
    message.cable = object.cable?.map((e) => Cable.fromPartial(e)) || [];
    message.cameraboxInfo = (object.cameraboxInfo !== undefined && object.cameraboxInfo !== null)
      ? Camerabox.fromPartial(object.cameraboxInfo)
      : undefined;
    message.smartUsbhub = object.smartUsbhub ?? false;
    message.cameraRoiBack = object.cameraRoiBack ?? false;
    message.cameraRoiFront = object.cameraRoiFront ?? false;
    message.bluetoothPeers = object.bluetoothPeers?.map((e) => BluetoothPeer.fromPartial(e)) || [];
    message.humanMotionRobot = (object.humanMotionRobot !== undefined && object.humanMotionRobot !== null)
      ? HumanMotionRobot.fromPartial(object.humanMotionRobot)
      : undefined;
    message.audioLatencyToolkit = (object.audioLatencyToolkit !== undefined && object.audioLatencyToolkit !== null)
      ? AudioLatencyToolkit.fromPartial(object.audioLatencyToolkit)
      : undefined;
    message.dolos = (object.dolos !== undefined && object.dolos !== null) ? Dolos.fromPartial(object.dolos) : undefined;
    message.pasitHost2 = (object.pasitHost2 !== undefined && object.pasitHost2 !== null)
      ? PasitHost.fromPartial(object.pasitHost2)
      : undefined;
    message.amtManager = (object.amtManager !== undefined && object.amtManager !== null)
      ? AMTManager.fromPartial(object.amtManager)
      : undefined;
    return message;
  },
};

function createBaseCamera(): Camera {
  return { cameraType: 0 };
}

export const Camera: MessageFns<Camera> = {
  encode(message: Camera, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.cameraType !== 0) {
      writer.uint32(8).int32(message.cameraType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Camera {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCamera() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.cameraType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Camera {
    return { cameraType: isSet(object.cameraType) ? cameraTypeFromJSON(object.cameraType) : 0 };
  },

  toJSON(message: Camera): unknown {
    const obj: any = {};
    if (message.cameraType !== 0) {
      obj.cameraType = cameraTypeToJSON(message.cameraType);
    }
    return obj;
  },

  create(base?: DeepPartial<Camera>): Camera {
    return Camera.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Camera>): Camera {
    const message = createBaseCamera() as any;
    message.cameraType = object.cameraType ?? 0;
    return message;
  },
};

function createBaseCable(): Cable {
  return { type: 0 };
}

export const Cable: MessageFns<Cable> = {
  encode(message: Cable, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Cable {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCable() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Cable {
    return { type: isSet(object.type) ? cableTypeFromJSON(object.type) : 0 };
  },

  toJSON(message: Cable): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = cableTypeToJSON(message.type);
    }
    return obj;
  },

  create(base?: DeepPartial<Cable>): Cable {
    return Cable.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Cable>): Cable {
    const message = createBaseCable() as any;
    message.type = object.type ?? 0;
    return message;
  },
};

function createBaseAudio(): Audio {
  return { audioBox: false, atrus: false, audioCable: false };
}

export const Audio: MessageFns<Audio> = {
  encode(message: Audio, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.audioBox !== false) {
      writer.uint32(8).bool(message.audioBox);
    }
    if (message.atrus !== false) {
      writer.uint32(16).bool(message.atrus);
    }
    if (message.audioCable !== false) {
      writer.uint32(24).bool(message.audioCable);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Audio {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAudio() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.audioBox = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.atrus = reader.bool();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.audioCable = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Audio {
    return {
      audioBox: isSet(object.audioBox) ? globalThis.Boolean(object.audioBox) : false,
      atrus: isSet(object.atrus) ? globalThis.Boolean(object.atrus) : false,
      audioCable: isSet(object.audioCable) ? globalThis.Boolean(object.audioCable) : false,
    };
  },

  toJSON(message: Audio): unknown {
    const obj: any = {};
    if (message.audioBox !== false) {
      obj.audioBox = message.audioBox;
    }
    if (message.atrus !== false) {
      obj.atrus = message.atrus;
    }
    if (message.audioCable !== false) {
      obj.audioCable = message.audioCable;
    }
    return obj;
  },

  create(base?: DeepPartial<Audio>): Audio {
    return Audio.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Audio>): Audio {
    const message = createBaseAudio() as any;
    message.audioBox = object.audioBox ?? false;
    message.atrus = object.atrus ?? false;
    message.audioCable = object.audioCable ?? false;
    return message;
  },
};

function createBaseWifi(): Wifi {
  return { wificell: false, antennaConn: 0, router: 0, wifiRouters: [], wifiRouterFeatures: [] };
}

export const Wifi: MessageFns<Wifi> = {
  encode(message: Wifi, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.wificell !== false) {
      writer.uint32(8).bool(message.wificell);
    }
    if (message.antennaConn !== 0) {
      writer.uint32(16).int32(message.antennaConn);
    }
    if (message.router !== 0) {
      writer.uint32(24).int32(message.router);
    }
    for (const v of message.wifiRouters) {
      WifiRouter.encode(v!, writer.uint32(34).fork()).join();
    }
    writer.uint32(50).fork();
    for (const v of message.wifiRouterFeatures) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Wifi {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWifi() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.wificell = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.antennaConn = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.router = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.wifiRouters.push(WifiRouter.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag === 48) {
            message.wifiRouterFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.wifiRouterFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Wifi {
    return {
      wificell: isSet(object.wificell) ? globalThis.Boolean(object.wificell) : false,
      antennaConn: isSet(object.antennaConn) ? wifi_AntennaConnectionFromJSON(object.antennaConn) : 0,
      router: isSet(object.router) ? wifi_RouterFromJSON(object.router) : 0,
      wifiRouters: globalThis.Array.isArray(object?.wifiRouters)
        ? object.wifiRouters.map((e: any) => WifiRouter.fromJSON(e))
        : [],
      wifiRouterFeatures: globalThis.Array.isArray(object?.wifiRouterFeatures)
        ? object.wifiRouterFeatures.map((e: any) => wifiRouterFeatureFromJSON(e))
        : [],
    };
  },

  toJSON(message: Wifi): unknown {
    const obj: any = {};
    if (message.wificell !== false) {
      obj.wificell = message.wificell;
    }
    if (message.antennaConn !== 0) {
      obj.antennaConn = wifi_AntennaConnectionToJSON(message.antennaConn);
    }
    if (message.router !== 0) {
      obj.router = wifi_RouterToJSON(message.router);
    }
    if (message.wifiRouters?.length) {
      obj.wifiRouters = message.wifiRouters.map((e) => WifiRouter.toJSON(e));
    }
    if (message.wifiRouterFeatures?.length) {
      obj.wifiRouterFeatures = message.wifiRouterFeatures.map((e) => wifiRouterFeatureToJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<Wifi>): Wifi {
    return Wifi.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Wifi>): Wifi {
    const message = createBaseWifi() as any;
    message.wificell = object.wificell ?? false;
    message.antennaConn = object.antennaConn ?? 0;
    message.router = object.router ?? 0;
    message.wifiRouters = object.wifiRouters?.map((e) => WifiRouter.fromPartial(e)) || [];
    message.wifiRouterFeatures = object.wifiRouterFeatures?.map((e) => e) || [];
    return message;
  },
};

function createBaseWifiRouter(): WifiRouter {
  return { hostname: "", state: 0, buildTarget: "", model: "", rpm: undefined, supportedFeatures: [], deviceType: 0 };
}

export const WifiRouter: MessageFns<WifiRouter> = {
  encode(message: WifiRouter, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    if (message.buildTarget !== "") {
      writer.uint32(26).string(message.buildTarget);
    }
    if (message.model !== "") {
      writer.uint32(34).string(message.model);
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(42).fork()).join();
    }
    writer.uint32(58).fork();
    for (const v of message.supportedFeatures) {
      writer.int32(v);
    }
    writer.join();
    if (message.deviceType !== 0) {
      writer.uint32(64).int32(message.deviceType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WifiRouter {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWifiRouter() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.buildTarget = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag === 56) {
            message.supportedFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 58) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportedFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.deviceType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WifiRouter {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      state: isSet(object.state) ? peripheralStateFromJSON(object.state) : 0,
      buildTarget: isSet(object.buildTarget) ? globalThis.String(object.buildTarget) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
      supportedFeatures: globalThis.Array.isArray(object?.supportedFeatures)
        ? object.supportedFeatures.map((e: any) => wifiRouterFeatureFromJSON(e))
        : [],
      deviceType: isSet(object.deviceType) ? wifiRouterDeviceTypeFromJSON(object.deviceType) : 0,
    };
  },

  toJSON(message: WifiRouter): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.state !== 0) {
      obj.state = peripheralStateToJSON(message.state);
    }
    if (message.buildTarget !== "") {
      obj.buildTarget = message.buildTarget;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    if (message.supportedFeatures?.length) {
      obj.supportedFeatures = message.supportedFeatures.map((e) => wifiRouterFeatureToJSON(e));
    }
    if (message.deviceType !== 0) {
      obj.deviceType = wifiRouterDeviceTypeToJSON(message.deviceType);
    }
    return obj;
  },

  create(base?: DeepPartial<WifiRouter>): WifiRouter {
    return WifiRouter.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WifiRouter>): WifiRouter {
    const message = createBaseWifiRouter() as any;
    message.hostname = object.hostname ?? "";
    message.state = object.state ?? 0;
    message.buildTarget = object.buildTarget ?? "";
    message.model = object.model ?? "";
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    message.supportedFeatures = object.supportedFeatures?.map((e) => e) || [];
    message.deviceType = object.deviceType ?? 0;
    return message;
  },
};

function createBaseTouch(): Touch {
  return { mimo: false };
}

export const Touch: MessageFns<Touch> = {
  encode(message: Touch, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.mimo !== false) {
      writer.uint32(8).bool(message.mimo);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Touch {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTouch() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.mimo = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Touch {
    return { mimo: isSet(object.mimo) ? globalThis.Boolean(object.mimo) : false };
  },

  toJSON(message: Touch): unknown {
    const obj: any = {};
    if (message.mimo !== false) {
      obj.mimo = message.mimo;
    }
    return obj;
  },

  create(base?: DeepPartial<Touch>): Touch {
    return Touch.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Touch>): Touch {
    const message = createBaseTouch() as any;
    message.mimo = object.mimo ?? false;
    return message;
  },
};

function createBaseCamerabox(): Camerabox {
  return { facing: 0, light: 0 };
}

export const Camerabox: MessageFns<Camerabox> = {
  encode(message: Camerabox, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.facing !== 0) {
      writer.uint32(8).int32(message.facing);
    }
    if (message.light !== 0) {
      writer.uint32(16).int32(message.light);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Camerabox {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCamerabox() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.facing = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.light = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Camerabox {
    return {
      facing: isSet(object.facing) ? camerabox_FacingFromJSON(object.facing) : 0,
      light: isSet(object.light) ? camerabox_LightFromJSON(object.light) : 0,
    };
  },

  toJSON(message: Camerabox): unknown {
    const obj: any = {};
    if (message.facing !== 0) {
      obj.facing = camerabox_FacingToJSON(message.facing);
    }
    if (message.light !== 0) {
      obj.light = camerabox_LightToJSON(message.light);
    }
    return obj;
  },

  create(base?: DeepPartial<Camerabox>): Camerabox {
    return Camerabox.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Camerabox>): Camerabox {
    const message = createBaseCamerabox() as any;
    message.facing = object.facing ?? 0;
    message.light = object.light ?? 0;
    return message;
  },
};

function createBaseRaspberryPi(): RaspberryPi {
  return { hostname: "", state: 0 };
}

export const RaspberryPi: MessageFns<RaspberryPi> = {
  encode(message: RaspberryPi, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.state !== 0) {
      writer.uint32(16).int32(message.state);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RaspberryPi {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRaspberryPi() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.state = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RaspberryPi {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      state: isSet(object.state) ? peripheralStateFromJSON(object.state) : 0,
    };
  },

  toJSON(message: RaspberryPi): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.state !== 0) {
      obj.state = peripheralStateToJSON(message.state);
    }
    return obj;
  },

  create(base?: DeepPartial<RaspberryPi>): RaspberryPi {
    return RaspberryPi.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RaspberryPi>): RaspberryPi {
    const message = createBaseRaspberryPi() as any;
    message.hostname = object.hostname ?? "";
    message.state = object.state ?? 0;
    return message;
  },
};

function createBaseBluetoothPeer(): BluetoothPeer {
  return { raspberryPi: undefined, rpm: undefined };
}

export const BluetoothPeer: MessageFns<BluetoothPeer> = {
  encode(message: BluetoothPeer, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.raspberryPi !== undefined) {
      RaspberryPi.encode(message.raspberryPi, writer.uint32(10).fork()).join();
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): BluetoothPeer {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBluetoothPeer() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.raspberryPi = RaspberryPi.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BluetoothPeer {
    return {
      raspberryPi: isSet(object.raspberryPi) ? RaspberryPi.fromJSON(object.raspberryPi) : undefined,
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
    };
  },

  toJSON(message: BluetoothPeer): unknown {
    const obj: any = {};
    if (message.raspberryPi !== undefined) {
      obj.raspberryPi = RaspberryPi.toJSON(message.raspberryPi);
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    return obj;
  },

  create(base?: DeepPartial<BluetoothPeer>): BluetoothPeer {
    return BluetoothPeer.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<BluetoothPeer>): BluetoothPeer {
    const message = createBaseBluetoothPeer() as any;
    message.raspberryPi = (object.raspberryPi !== undefined && object.raspberryPi !== null)
      ? RaspberryPi.fromPartial(object.raspberryPi)
      : undefined;
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    return message;
  },
};

function createBaseHumanMotionRobot(): HumanMotionRobot {
  return { hostname: "", hmrModel: "", gatewayHostname: "", rpm: undefined, hmrWalt: false, hmrToolType: 0, hmrGen: 0 };
}

export const HumanMotionRobot: MessageFns<HumanMotionRobot> = {
  encode(message: HumanMotionRobot, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.hmrModel !== "") {
      writer.uint32(18).string(message.hmrModel);
    }
    if (message.gatewayHostname !== "") {
      writer.uint32(26).string(message.gatewayHostname);
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(34).fork()).join();
    }
    if (message.hmrWalt !== false) {
      writer.uint32(40).bool(message.hmrWalt);
    }
    if (message.hmrToolType !== 0) {
      writer.uint32(48).int32(message.hmrToolType);
    }
    if (message.hmrGen !== 0) {
      writer.uint32(56).int32(message.hmrGen);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HumanMotionRobot {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHumanMotionRobot() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hmrModel = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.gatewayHostname = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.hmrWalt = reader.bool();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.hmrToolType = reader.int32() as any;
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.hmrGen = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HumanMotionRobot {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      hmrModel: isSet(object.hmrModel) ? globalThis.String(object.hmrModel) : "",
      gatewayHostname: isSet(object.gatewayHostname) ? globalThis.String(object.gatewayHostname) : "",
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
      hmrWalt: isSet(object.hmrWalt) ? globalThis.Boolean(object.hmrWalt) : false,
      hmrToolType: isSet(object.hmrToolType) ? humanMotionRobot_HMRToolTypeFromJSON(object.hmrToolType) : 0,
      hmrGen: isSet(object.hmrGen) ? humanMotionRobot_HMRGenFromJSON(object.hmrGen) : 0,
    };
  },

  toJSON(message: HumanMotionRobot): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.hmrModel !== "") {
      obj.hmrModel = message.hmrModel;
    }
    if (message.gatewayHostname !== "") {
      obj.gatewayHostname = message.gatewayHostname;
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    if (message.hmrWalt !== false) {
      obj.hmrWalt = message.hmrWalt;
    }
    if (message.hmrToolType !== 0) {
      obj.hmrToolType = humanMotionRobot_HMRToolTypeToJSON(message.hmrToolType);
    }
    if (message.hmrGen !== 0) {
      obj.hmrGen = humanMotionRobot_HMRGenToJSON(message.hmrGen);
    }
    return obj;
  },

  create(base?: DeepPartial<HumanMotionRobot>): HumanMotionRobot {
    return HumanMotionRobot.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<HumanMotionRobot>): HumanMotionRobot {
    const message = createBaseHumanMotionRobot() as any;
    message.hostname = object.hostname ?? "";
    message.hmrModel = object.hmrModel ?? "";
    message.gatewayHostname = object.gatewayHostname ?? "";
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    message.hmrWalt = object.hmrWalt ?? false;
    message.hmrToolType = object.hmrToolType ?? 0;
    message.hmrGen = object.hmrGen ?? 0;
    return message;
  },
};

function createBaseAudioLatencyToolkit(): AudioLatencyToolkit {
  return { version: "" };
}

export const AudioLatencyToolkit: MessageFns<AudioLatencyToolkit> = {
  encode(message: AudioLatencyToolkit, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.version !== "") {
      writer.uint32(10).string(message.version);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AudioLatencyToolkit {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAudioLatencyToolkit() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.version = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AudioLatencyToolkit {
    return { version: isSet(object.version) ? globalThis.String(object.version) : "" };
  },

  toJSON(message: AudioLatencyToolkit): unknown {
    const obj: any = {};
    if (message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create(base?: DeepPartial<AudioLatencyToolkit>): AudioLatencyToolkit {
    return AudioLatencyToolkit.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AudioLatencyToolkit>): AudioLatencyToolkit {
    const message = createBaseAudioLatencyToolkit() as any;
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseAMTManager(): AMTManager {
  return { hostname: "", useTls: false };
}

export const AMTManager: MessageFns<AMTManager> = {
  encode(message: AMTManager, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.useTls !== false) {
      writer.uint32(24).bool(message.useTls);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AMTManager {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAMTManager() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.useTls = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AMTManager {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      useTls: isSet(object.useTls) ? globalThis.Boolean(object.useTls) : false,
    };
  },

  toJSON(message: AMTManager): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.useTls !== false) {
      obj.useTls = message.useTls;
    }
    return obj;
  },

  create(base?: DeepPartial<AMTManager>): AMTManager {
    return AMTManager.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AMTManager>): AMTManager {
    const message = createBaseAMTManager() as any;
    message.hostname = object.hostname ?? "";
    message.useTls = object.useTls ?? false;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
