// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/lab/license.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 3 */
export enum LicenseType {
  LICENSE_TYPE_UNSPECIFIED = 0,
  /**
   * LICENSE_TYPE_WINDOWS_10_PRO - Microsoft Windows 10 Professional Desktop Operating System.
   * Contact the Chrome OS Parallels team for license specifics.
   */
  LICENSE_TYPE_WINDOWS_10_PRO = 1,
  /**
   * LICENSE_TYPE_MS_OFFICE_STANDARD - Microsoft Office Standard.
   * Contact the Chrome OS Parallels team for license specifics.
   */
  LICENSE_TYPE_MS_OFFICE_STANDARD = 2,
}

export function licenseTypeFromJSON(object: any): LicenseType {
  switch (object) {
    case 0:
    case "LICENSE_TYPE_UNSPECIFIED":
      return LicenseType.LICENSE_TYPE_UNSPECIFIED;
    case 1:
    case "LICENSE_TYPE_WINDOWS_10_PRO":
      return LicenseType.LICENSE_TYPE_WINDOWS_10_PRO;
    case 2:
    case "LICENSE_TYPE_MS_OFFICE_STANDARD":
      return LicenseType.LICENSE_TYPE_MS_OFFICE_STANDARD;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicenseType");
  }
}

export function licenseTypeToJSON(object: LicenseType): string {
  switch (object) {
    case LicenseType.LICENSE_TYPE_UNSPECIFIED:
      return "LICENSE_TYPE_UNSPECIFIED";
    case LicenseType.LICENSE_TYPE_WINDOWS_10_PRO:
      return "LICENSE_TYPE_WINDOWS_10_PRO";
    case LicenseType.LICENSE_TYPE_MS_OFFICE_STANDARD:
      return "LICENSE_TYPE_MS_OFFICE_STANDARD";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicenseType");
  }
}

/**
 * Represents a Software License assigned to a device.
 * Next Tag: 3
 */
export interface License {
  readonly type: LicenseType;
  /**
   * An optional string to uniquely identify the license that was assigned,
   * for tracking purposes.
   */
  readonly identifier: string;
}

function createBaseLicense(): License {
  return { type: 0, identifier: "" };
}

export const License: MessageFns<License> = {
  encode(message: License, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): License {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLicense() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): License {
    return {
      type: isSet(object.type) ? licenseTypeFromJSON(object.type) : 0,
      identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "",
    };
  },

  toJSON(message: License): unknown {
    const obj: any = {};
    if (message.type !== 0) {
      obj.type = licenseTypeToJSON(message.type);
    }
    if (message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    return obj;
  },

  create(base?: DeepPartial<License>): License {
    return License.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<License>): License {
    const message = createBaseLicense() as any;
    message.type = object.type ?? 0;
    message.identifier = object.identifier ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
