// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/libs/skylab/inventory/device.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Environment, environmentFromJSON, environmentToJSON, Timestamp } from "./common.pb";

export const protobufPackage = "chrome.chromeos_infra.skylab.proto.inventory";

/**
 * Copyright 2018 The Chromium Authors
 * Use of this source code is governed by a BSD-style license that can be
 * found in the LICENSE file.
 */

/**
 * Servo Firmware Channel describes the firmware expected to have on servos.
 * Next Tag : 4
 */
export enum ServoFwChannel {
  /** SERVO_FW_STABLE - Servo firmware from Stable channel. */
  SERVO_FW_STABLE = 0,
  /** SERVO_FW_PREV - The previous Servo firmware from Stable channel. */
  SERVO_FW_PREV = 1,
  /** SERVO_FW_DEV - Servo firmware from Dev channel. */
  SERVO_FW_DEV = 2,
  /** SERVO_FW_ALPHA - Servo firmware from Alpha channel. */
  SERVO_FW_ALPHA = 3,
}

export function servoFwChannelFromJSON(object: any): ServoFwChannel {
  switch (object) {
    case 0:
    case "SERVO_FW_STABLE":
      return ServoFwChannel.SERVO_FW_STABLE;
    case 1:
    case "SERVO_FW_PREV":
      return ServoFwChannel.SERVO_FW_PREV;
    case 2:
    case "SERVO_FW_DEV":
      return ServoFwChannel.SERVO_FW_DEV;
    case 3:
    case "SERVO_FW_ALPHA":
      return ServoFwChannel.SERVO_FW_ALPHA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ServoFwChannel");
  }
}

export function servoFwChannelToJSON(object: ServoFwChannel): string {
  switch (object) {
    case ServoFwChannel.SERVO_FW_STABLE:
      return "SERVO_FW_STABLE";
    case ServoFwChannel.SERVO_FW_PREV:
      return "SERVO_FW_PREV";
    case ServoFwChannel.SERVO_FW_DEV:
      return "SERVO_FW_DEV";
    case ServoFwChannel.SERVO_FW_ALPHA:
      return "SERVO_FW_ALPHA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ServoFwChannel");
  }
}

/**
 * copy of enum from dut_state.proto
 * Next Tag: 28
 */
export enum PeripheralState {
  /** UNKNOWN - Please keep for all unknown states. */
  UNKNOWN = 0,
  /** WORKING - Device and software on it is working as expected. */
  WORKING = 1,
  /** MISSING_CONFIG - Configuration for device is not provided. */
  MISSING_CONFIG = 5,
  /** WRONG_CONFIG - Configuration contains incorrect information. */
  WRONG_CONFIG = 4,
  /** NOT_CONNECTED - Device is not connected/plugged. */
  NOT_CONNECTED = 2,
  /** NO_SSH - Device is not reachable over ssh. */
  NO_SSH = 6,
  /** BROKEN - Device is broken or not working as expected. the state used if no specified state for the issue. */
  BROKEN = 3,
  /** NEED_REPLACEMENT - Device cannot be repaired or required manual attention to fix/replace it. */
  NEED_REPLACEMENT = 7,
  /**
   * CR50_CONSOLE_MISSING - Servo specific states.
   * cr50 console missing or unresponsive.
   */
  CR50_CONSOLE_MISSING = 13,
  /** CCD_TESTLAB_ISSUE - Servod daemon cannot start on servo-host because cr50 testlab not enabled. */
  CCD_TESTLAB_ISSUE = 8,
  /** SERVOD_ISSUE - Servod daemon cannot start on servo-host. */
  SERVOD_ISSUE = 9,
  /** LID_OPEN_FAILED - device lid is not open. */
  LID_OPEN_FAILED = 10,
  /** BAD_RIBBON_CABLE - the ribbon cable between servo and DUT is broken or not connected. */
  BAD_RIBBON_CABLE = 11,
  /** EC_BROKEN - the EC on the DUT has issue. */
  EC_BROKEN = 12,
  /** DUT_NOT_CONNECTED - Servo is not connected to the DUT. */
  DUT_NOT_CONNECTED = 14,
  /** TOPOLOGY_ISSUE - Some component in servo-topology missed or not detected. */
  TOPOLOGY_ISSUE = 15,
  /** SBU_LOW_VOLTAGE - SBU voltage issues effect CR50 detection. */
  SBU_LOW_VOLTAGE = 16,
  /** CR50_NOT_ENUMERATED - CR50 SBU voltage detected but device was not enumerated. */
  CR50_NOT_ENUMERATED = 17,
  /** SERVO_SERIAL_MISMATCH - Servo serial mismatch, when servo not detected and another serial detected on previous used port. */
  SERVO_SERIAL_MISMATCH = 18,
  /** SERVOD_PROXY_ISSUE - Issue to connect to servod by XMLRPC proxy. */
  SERVOD_PROXY_ISSUE = 19,
  /** SERVO_HOST_ISSUE - Issue related to servo-host. Timeout to start servod or issue with detecting devices. */
  SERVO_HOST_ISSUE = 20,
  /** SERVO_UPDATER_ISSUE - Issue related to servo_updater. */
  SERVO_UPDATER_ISSUE = 21,
  /** SERVOD_DUT_CONTROLLER_MISSING - Issue detected in servod and reported by dut_controller_missing_fault control. */
  SERVOD_DUT_CONTROLLER_MISSING = 22,
  /** COLD_RESET_PIN_ISSUE - Issue related to cold reset pin on the DUT. */
  COLD_RESET_PIN_ISSUE = 23,
  /** WARM_RESET_PIN_ISSUE - Issue related to warm reset pin on the DUT. */
  WARM_RESET_PIN_ISSUE = 24,
  /** POWER_BUTTON_PIN_ISSUE - Issue related to power button pin on the DUT. */
  POWER_BUTTON_PIN_ISSUE = 25,
  /** NOT_APPLICABLE - Peripheral state is not applicable. */
  NOT_APPLICABLE = 26,
  /**
   * DEBUG_HEADER_SERVO_MISSING - The servo device connected to debug header is not detected.
   * Reported by dut_controller_missing_fault control.
   */
  DEBUG_HEADER_SERVO_MISSING = 27,
}

export function peripheralStateFromJSON(object: any): PeripheralState {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return PeripheralState.UNKNOWN;
    case 1:
    case "WORKING":
      return PeripheralState.WORKING;
    case 5:
    case "MISSING_CONFIG":
      return PeripheralState.MISSING_CONFIG;
    case 4:
    case "WRONG_CONFIG":
      return PeripheralState.WRONG_CONFIG;
    case 2:
    case "NOT_CONNECTED":
      return PeripheralState.NOT_CONNECTED;
    case 6:
    case "NO_SSH":
      return PeripheralState.NO_SSH;
    case 3:
    case "BROKEN":
      return PeripheralState.BROKEN;
    case 7:
    case "NEED_REPLACEMENT":
      return PeripheralState.NEED_REPLACEMENT;
    case 13:
    case "CR50_CONSOLE_MISSING":
      return PeripheralState.CR50_CONSOLE_MISSING;
    case 8:
    case "CCD_TESTLAB_ISSUE":
      return PeripheralState.CCD_TESTLAB_ISSUE;
    case 9:
    case "SERVOD_ISSUE":
      return PeripheralState.SERVOD_ISSUE;
    case 10:
    case "LID_OPEN_FAILED":
      return PeripheralState.LID_OPEN_FAILED;
    case 11:
    case "BAD_RIBBON_CABLE":
      return PeripheralState.BAD_RIBBON_CABLE;
    case 12:
    case "EC_BROKEN":
      return PeripheralState.EC_BROKEN;
    case 14:
    case "DUT_NOT_CONNECTED":
      return PeripheralState.DUT_NOT_CONNECTED;
    case 15:
    case "TOPOLOGY_ISSUE":
      return PeripheralState.TOPOLOGY_ISSUE;
    case 16:
    case "SBU_LOW_VOLTAGE":
      return PeripheralState.SBU_LOW_VOLTAGE;
    case 17:
    case "CR50_NOT_ENUMERATED":
      return PeripheralState.CR50_NOT_ENUMERATED;
    case 18:
    case "SERVO_SERIAL_MISMATCH":
      return PeripheralState.SERVO_SERIAL_MISMATCH;
    case 19:
    case "SERVOD_PROXY_ISSUE":
      return PeripheralState.SERVOD_PROXY_ISSUE;
    case 20:
    case "SERVO_HOST_ISSUE":
      return PeripheralState.SERVO_HOST_ISSUE;
    case 21:
    case "SERVO_UPDATER_ISSUE":
      return PeripheralState.SERVO_UPDATER_ISSUE;
    case 22:
    case "SERVOD_DUT_CONTROLLER_MISSING":
      return PeripheralState.SERVOD_DUT_CONTROLLER_MISSING;
    case 23:
    case "COLD_RESET_PIN_ISSUE":
      return PeripheralState.COLD_RESET_PIN_ISSUE;
    case 24:
    case "WARM_RESET_PIN_ISSUE":
      return PeripheralState.WARM_RESET_PIN_ISSUE;
    case 25:
    case "POWER_BUTTON_PIN_ISSUE":
      return PeripheralState.POWER_BUTTON_PIN_ISSUE;
    case 26:
    case "NOT_APPLICABLE":
      return PeripheralState.NOT_APPLICABLE;
    case 27:
    case "DEBUG_HEADER_SERVO_MISSING":
      return PeripheralState.DEBUG_HEADER_SERVO_MISSING;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PeripheralState");
  }
}

export function peripheralStateToJSON(object: PeripheralState): string {
  switch (object) {
    case PeripheralState.UNKNOWN:
      return "UNKNOWN";
    case PeripheralState.WORKING:
      return "WORKING";
    case PeripheralState.MISSING_CONFIG:
      return "MISSING_CONFIG";
    case PeripheralState.WRONG_CONFIG:
      return "WRONG_CONFIG";
    case PeripheralState.NOT_CONNECTED:
      return "NOT_CONNECTED";
    case PeripheralState.NO_SSH:
      return "NO_SSH";
    case PeripheralState.BROKEN:
      return "BROKEN";
    case PeripheralState.NEED_REPLACEMENT:
      return "NEED_REPLACEMENT";
    case PeripheralState.CR50_CONSOLE_MISSING:
      return "CR50_CONSOLE_MISSING";
    case PeripheralState.CCD_TESTLAB_ISSUE:
      return "CCD_TESTLAB_ISSUE";
    case PeripheralState.SERVOD_ISSUE:
      return "SERVOD_ISSUE";
    case PeripheralState.LID_OPEN_FAILED:
      return "LID_OPEN_FAILED";
    case PeripheralState.BAD_RIBBON_CABLE:
      return "BAD_RIBBON_CABLE";
    case PeripheralState.EC_BROKEN:
      return "EC_BROKEN";
    case PeripheralState.DUT_NOT_CONNECTED:
      return "DUT_NOT_CONNECTED";
    case PeripheralState.TOPOLOGY_ISSUE:
      return "TOPOLOGY_ISSUE";
    case PeripheralState.SBU_LOW_VOLTAGE:
      return "SBU_LOW_VOLTAGE";
    case PeripheralState.CR50_NOT_ENUMERATED:
      return "CR50_NOT_ENUMERATED";
    case PeripheralState.SERVO_SERIAL_MISMATCH:
      return "SERVO_SERIAL_MISMATCH";
    case PeripheralState.SERVOD_PROXY_ISSUE:
      return "SERVOD_PROXY_ISSUE";
    case PeripheralState.SERVO_HOST_ISSUE:
      return "SERVO_HOST_ISSUE";
    case PeripheralState.SERVO_UPDATER_ISSUE:
      return "SERVO_UPDATER_ISSUE";
    case PeripheralState.SERVOD_DUT_CONTROLLER_MISSING:
      return "SERVOD_DUT_CONTROLLER_MISSING";
    case PeripheralState.COLD_RESET_PIN_ISSUE:
      return "COLD_RESET_PIN_ISSUE";
    case PeripheralState.WARM_RESET_PIN_ISSUE:
      return "WARM_RESET_PIN_ISSUE";
    case PeripheralState.POWER_BUTTON_PIN_ISSUE:
      return "POWER_BUTTON_PIN_ISSUE";
    case PeripheralState.NOT_APPLICABLE:
      return "NOT_APPLICABLE";
    case PeripheralState.DEBUG_HEADER_SERVO_MISSING:
      return "DEBUG_HEADER_SERVO_MISSING";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PeripheralState");
  }
}

/**
 * copy of enum from dut_state.proto
 * The states are using for DUT storage and USB-drive on servo.
 * Next Tag: 5
 */
export enum HardwareState {
  /** HARDWARE_UNKNOWN - keep for all unknown state by default. */
  HARDWARE_UNKNOWN = 0,
  /** HARDWARE_NORMAL - Hardware is in good shape and pass all verifiers. */
  HARDWARE_NORMAL = 1,
  /**
   * HARDWARE_ACCEPTABLE - Hardware is still good but some not critical verifiers did not pass or provided border values.
   * (used for DUT storage when usage reached 98%)
   */
  HARDWARE_ACCEPTABLE = 2,
  /** HARDWARE_NEED_REPLACEMENT - Hardware is broken or bad (did not pass verifiers). */
  HARDWARE_NEED_REPLACEMENT = 3,
  /**
   * HARDWARE_NOT_DETECTED - Hardware is not detected to run verifiers.
   * (used for USB-drive when it expected but not detected on the device)
   */
  HARDWARE_NOT_DETECTED = 4,
}

export function hardwareStateFromJSON(object: any): HardwareState {
  switch (object) {
    case 0:
    case "HARDWARE_UNKNOWN":
      return HardwareState.HARDWARE_UNKNOWN;
    case 1:
    case "HARDWARE_NORMAL":
      return HardwareState.HARDWARE_NORMAL;
    case 2:
    case "HARDWARE_ACCEPTABLE":
      return HardwareState.HARDWARE_ACCEPTABLE;
    case 3:
    case "HARDWARE_NEED_REPLACEMENT":
      return HardwareState.HARDWARE_NEED_REPLACEMENT;
    case 4:
    case "HARDWARE_NOT_DETECTED":
      return HardwareState.HARDWARE_NOT_DETECTED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareState");
  }
}

export function hardwareStateToJSON(object: HardwareState): string {
  switch (object) {
    case HardwareState.HARDWARE_UNKNOWN:
      return "HARDWARE_UNKNOWN";
    case HardwareState.HARDWARE_NORMAL:
      return "HARDWARE_NORMAL";
    case HardwareState.HARDWARE_ACCEPTABLE:
      return "HARDWARE_ACCEPTABLE";
    case HardwareState.HARDWARE_NEED_REPLACEMENT:
      return "HARDWARE_NEED_REPLACEMENT";
    case HardwareState.HARDWARE_NOT_DETECTED:
      return "HARDWARE_NOT_DETECTED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareState");
  }
}

/** Next Tag: 3 */
export enum LicenseType {
  LICENSE_TYPE_UNSPECIFIED = 0,
  /**
   * LICENSE_TYPE_WINDOWS_10_PRO - Microsoft Windows 10 Professional Desktop Operating System.
   * Contact the Chrome OS Parallels team for license specifics.
   */
  LICENSE_TYPE_WINDOWS_10_PRO = 1,
  /**
   * LICENSE_TYPE_MS_OFFICE_STANDARD - Microsoft Office Standard.
   * Contact the Chrome OS Parallels team for license specifics.
   */
  LICENSE_TYPE_MS_OFFICE_STANDARD = 2,
}

export function licenseTypeFromJSON(object: any): LicenseType {
  switch (object) {
    case 0:
    case "LICENSE_TYPE_UNSPECIFIED":
      return LicenseType.LICENSE_TYPE_UNSPECIFIED;
    case 1:
    case "LICENSE_TYPE_WINDOWS_10_PRO":
      return LicenseType.LICENSE_TYPE_WINDOWS_10_PRO;
    case 2:
    case "LICENSE_TYPE_MS_OFFICE_STANDARD":
      return LicenseType.LICENSE_TYPE_MS_OFFICE_STANDARD;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicenseType");
  }
}

export function licenseTypeToJSON(object: LicenseType): string {
  switch (object) {
    case LicenseType.LICENSE_TYPE_UNSPECIFIED:
      return "LICENSE_TYPE_UNSPECIFIED";
    case LicenseType.LICENSE_TYPE_WINDOWS_10_PRO:
      return "LICENSE_TYPE_WINDOWS_10_PRO";
    case LicenseType.LICENSE_TYPE_MS_OFFICE_STANDARD:
      return "LICENSE_TYPE_MS_OFFICE_STANDARD";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum LicenseType");
  }
}

/** Next Tag: 9 */
export enum ModemType {
  MODEM_TYPE_UNSPECIFIED = 0,
  /** MODEM_TYPE_UNSUPPORTED - Unsupported modem */
  MODEM_TYPE_UNSUPPORTED = 8,
  /** MODEM_TYPE_QUALCOMM_SC7180 - Qualcomm modem */
  MODEM_TYPE_QUALCOMM_SC7180 = 1,
  /** MODEM_TYPE_FIBOCOMM_L850GL - Fibocomm modem */
  MODEM_TYPE_FIBOCOMM_L850GL = 2,
  /** MODEM_TYPE_NL668 - NL668 */
  MODEM_TYPE_NL668 = 3,
  /** MODEM_TYPE_FM350 - FM350 */
  MODEM_TYPE_FM350 = 4,
  /** MODEM_TYPE_FM101 - FM101 */
  MODEM_TYPE_FM101 = 5,
  /** MODEM_TYPE_QUALCOMM_SC7280 - SC7280 */
  MODEM_TYPE_QUALCOMM_SC7280 = 6,
  /** MODEM_TYPE_EM060 - EM060 */
  MODEM_TYPE_EM060 = 7,
  /** MODEM_TYPE_RW101 - RW101 */
  MODEM_TYPE_RW101 = 9,
  /** MODEM_TYPE_RW135 - RW135 */
  MODEM_TYPE_RW135 = 10,
  /** MODEM_TYPE_LCUK54 - LCUK54 */
  MODEM_TYPE_LCUK54 = 11,
}

export function modemTypeFromJSON(object: any): ModemType {
  switch (object) {
    case 0:
    case "MODEM_TYPE_UNSPECIFIED":
      return ModemType.MODEM_TYPE_UNSPECIFIED;
    case 8:
    case "MODEM_TYPE_UNSUPPORTED":
      return ModemType.MODEM_TYPE_UNSUPPORTED;
    case 1:
    case "MODEM_TYPE_QUALCOMM_SC7180":
      return ModemType.MODEM_TYPE_QUALCOMM_SC7180;
    case 2:
    case "MODEM_TYPE_FIBOCOMM_L850GL":
      return ModemType.MODEM_TYPE_FIBOCOMM_L850GL;
    case 3:
    case "MODEM_TYPE_NL668":
      return ModemType.MODEM_TYPE_NL668;
    case 4:
    case "MODEM_TYPE_FM350":
      return ModemType.MODEM_TYPE_FM350;
    case 5:
    case "MODEM_TYPE_FM101":
      return ModemType.MODEM_TYPE_FM101;
    case 6:
    case "MODEM_TYPE_QUALCOMM_SC7280":
      return ModemType.MODEM_TYPE_QUALCOMM_SC7280;
    case 7:
    case "MODEM_TYPE_EM060":
      return ModemType.MODEM_TYPE_EM060;
    case 9:
    case "MODEM_TYPE_RW101":
      return ModemType.MODEM_TYPE_RW101;
    case 10:
    case "MODEM_TYPE_RW135":
      return ModemType.MODEM_TYPE_RW135;
    case 11:
    case "MODEM_TYPE_LCUK54":
      return ModemType.MODEM_TYPE_LCUK54;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ModemType");
  }
}

export function modemTypeToJSON(object: ModemType): string {
  switch (object) {
    case ModemType.MODEM_TYPE_UNSPECIFIED:
      return "MODEM_TYPE_UNSPECIFIED";
    case ModemType.MODEM_TYPE_UNSUPPORTED:
      return "MODEM_TYPE_UNSUPPORTED";
    case ModemType.MODEM_TYPE_QUALCOMM_SC7180:
      return "MODEM_TYPE_QUALCOMM_SC7180";
    case ModemType.MODEM_TYPE_FIBOCOMM_L850GL:
      return "MODEM_TYPE_FIBOCOMM_L850GL";
    case ModemType.MODEM_TYPE_NL668:
      return "MODEM_TYPE_NL668";
    case ModemType.MODEM_TYPE_FM350:
      return "MODEM_TYPE_FM350";
    case ModemType.MODEM_TYPE_FM101:
      return "MODEM_TYPE_FM101";
    case ModemType.MODEM_TYPE_QUALCOMM_SC7280:
      return "MODEM_TYPE_QUALCOMM_SC7280";
    case ModemType.MODEM_TYPE_EM060:
      return "MODEM_TYPE_EM060";
    case ModemType.MODEM_TYPE_RW101:
      return "MODEM_TYPE_RW101";
    case ModemType.MODEM_TYPE_RW135:
      return "MODEM_TYPE_RW135";
    case ModemType.MODEM_TYPE_LCUK54:
      return "MODEM_TYPE_LCUK54";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ModemType");
  }
}

/** Next Tag: 22 */
export enum NetworkProvider {
  NETWORK_OTHER = 0,
  NETWORK_UNSUPPORTED = 5,
  NETWORK_TEST = 1,
  NETWORK_ATT = 2,
  NETWORK_TMOBILE = 3,
  NETWORK_VERIZON = 4,
  NETWORK_SPRINT = 6,
  NETWORK_DOCOMO = 7,
  NETWORK_SOFTBANK = 8,
  NETWORK_KDDI = 9,
  NETWORK_RAKUTEN = 10,
  NETWORK_VODAFONE = 11,
  NETWORK_EE = 12,
  NETWORK_AMARISOFT = 13,
  NETWORK_ROGER = 14,
  NETWORK_BELL = 15,
  NETWORK_TELUS = 16,
  NETWORK_FI = 17,
  NETWORK_CBRS = 18,
  NETWORK_LINEMO = 19,
  NETWORK_POVO = 20,
  NETWORK_HANSHIN = 21,
}

export function networkProviderFromJSON(object: any): NetworkProvider {
  switch (object) {
    case 0:
    case "NETWORK_OTHER":
      return NetworkProvider.NETWORK_OTHER;
    case 5:
    case "NETWORK_UNSUPPORTED":
      return NetworkProvider.NETWORK_UNSUPPORTED;
    case 1:
    case "NETWORK_TEST":
      return NetworkProvider.NETWORK_TEST;
    case 2:
    case "NETWORK_ATT":
      return NetworkProvider.NETWORK_ATT;
    case 3:
    case "NETWORK_TMOBILE":
      return NetworkProvider.NETWORK_TMOBILE;
    case 4:
    case "NETWORK_VERIZON":
      return NetworkProvider.NETWORK_VERIZON;
    case 6:
    case "NETWORK_SPRINT":
      return NetworkProvider.NETWORK_SPRINT;
    case 7:
    case "NETWORK_DOCOMO":
      return NetworkProvider.NETWORK_DOCOMO;
    case 8:
    case "NETWORK_SOFTBANK":
      return NetworkProvider.NETWORK_SOFTBANK;
    case 9:
    case "NETWORK_KDDI":
      return NetworkProvider.NETWORK_KDDI;
    case 10:
    case "NETWORK_RAKUTEN":
      return NetworkProvider.NETWORK_RAKUTEN;
    case 11:
    case "NETWORK_VODAFONE":
      return NetworkProvider.NETWORK_VODAFONE;
    case 12:
    case "NETWORK_EE":
      return NetworkProvider.NETWORK_EE;
    case 13:
    case "NETWORK_AMARISOFT":
      return NetworkProvider.NETWORK_AMARISOFT;
    case 14:
    case "NETWORK_ROGER":
      return NetworkProvider.NETWORK_ROGER;
    case 15:
    case "NETWORK_BELL":
      return NetworkProvider.NETWORK_BELL;
    case 16:
    case "NETWORK_TELUS":
      return NetworkProvider.NETWORK_TELUS;
    case 17:
    case "NETWORK_FI":
      return NetworkProvider.NETWORK_FI;
    case 18:
    case "NETWORK_CBRS":
      return NetworkProvider.NETWORK_CBRS;
    case 19:
    case "NETWORK_LINEMO":
      return NetworkProvider.NETWORK_LINEMO;
    case 20:
    case "NETWORK_POVO":
      return NetworkProvider.NETWORK_POVO;
    case 21:
    case "NETWORK_HANSHIN":
      return NetworkProvider.NETWORK_HANSHIN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum NetworkProvider");
  }
}

export function networkProviderToJSON(object: NetworkProvider): string {
  switch (object) {
    case NetworkProvider.NETWORK_OTHER:
      return "NETWORK_OTHER";
    case NetworkProvider.NETWORK_UNSUPPORTED:
      return "NETWORK_UNSUPPORTED";
    case NetworkProvider.NETWORK_TEST:
      return "NETWORK_TEST";
    case NetworkProvider.NETWORK_ATT:
      return "NETWORK_ATT";
    case NetworkProvider.NETWORK_TMOBILE:
      return "NETWORK_TMOBILE";
    case NetworkProvider.NETWORK_VERIZON:
      return "NETWORK_VERIZON";
    case NetworkProvider.NETWORK_SPRINT:
      return "NETWORK_SPRINT";
    case NetworkProvider.NETWORK_DOCOMO:
      return "NETWORK_DOCOMO";
    case NetworkProvider.NETWORK_SOFTBANK:
      return "NETWORK_SOFTBANK";
    case NetworkProvider.NETWORK_KDDI:
      return "NETWORK_KDDI";
    case NetworkProvider.NETWORK_RAKUTEN:
      return "NETWORK_RAKUTEN";
    case NetworkProvider.NETWORK_VODAFONE:
      return "NETWORK_VODAFONE";
    case NetworkProvider.NETWORK_EE:
      return "NETWORK_EE";
    case NetworkProvider.NETWORK_AMARISOFT:
      return "NETWORK_AMARISOFT";
    case NetworkProvider.NETWORK_ROGER:
      return "NETWORK_ROGER";
    case NetworkProvider.NETWORK_BELL:
      return "NETWORK_BELL";
    case NetworkProvider.NETWORK_TELUS:
      return "NETWORK_TELUS";
    case NetworkProvider.NETWORK_FI:
      return "NETWORK_FI";
    case NetworkProvider.NETWORK_CBRS:
      return "NETWORK_CBRS";
    case NetworkProvider.NETWORK_LINEMO:
      return "NETWORK_LINEMO";
    case NetworkProvider.NETWORK_POVO:
      return "NETWORK_POVO";
    case NetworkProvider.NETWORK_HANSHIN:
      return "NETWORK_HANSHIN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum NetworkProvider");
  }
}

/** Next Tag: 3 */
export enum SIMType {
  SIM_UNKNOWN = 0,
  SIM_PHYSICAL = 1,
  SIM_DIGITAL = 2,
}

export function sIMTypeFromJSON(object: any): SIMType {
  switch (object) {
    case 0:
    case "SIM_UNKNOWN":
      return SIMType.SIM_UNKNOWN;
    case 1:
    case "SIM_PHYSICAL":
      return SIMType.SIM_PHYSICAL;
    case 2:
    case "SIM_DIGITAL":
      return SIMType.SIM_DIGITAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMType");
  }
}

export function sIMTypeToJSON(object: SIMType): string {
  switch (object) {
    case SIMType.SIM_UNKNOWN:
      return "SIM_UNKNOWN";
    case SIMType.SIM_PHYSICAL:
      return "SIM_PHYSICAL";
    case SIMType.SIM_DIGITAL:
      return "SIM_DIGITAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SIMType");
  }
}

/** NEXT TAG: 4 */
export interface Device {
  readonly dut?: DeviceUnderTest | undefined;
  readonly servoHost?: ServoHostDevice | undefined;
  readonly chameleon?: ChameleonDevice | undefined;
}

/** NEXT TAG: 2 */
export interface DeviceUnderTest {
  readonly common: CommonDeviceSpecs | undefined;
  readonly removalReason?: RemovalReason | undefined;
}

/** NEXT TAG: 2 */
export interface ServoHostDevice {
  readonly common: CommonDeviceSpecs | undefined;
  readonly version?: string | undefined;
}

/** NEXT TAG: 2 */
export interface ChameleonDevice {
  readonly common: CommonDeviceSpecs | undefined;
}

/**
 * Keep sorted by field name.
 *
 * NEXT TAG: 12
 */
export interface CommonDeviceSpecs {
  /**
   * These are opaque keyvals for skylab services. Devices may arbitrarily
   * define any attributes they want.
   */
  readonly attributes: readonly KeyValue[];
  /**
   * A device may be locked for multiple reasons by multiple people. All locks
   * need to be removed for the device to go back into circulation.
   */
  readonly deviceLocks: readonly DeviceLock[];
  readonly environment?: Environment | undefined;
  readonly hostname: string;
  /**
   * An invariant property corresponding to the hardware deployed for this
   * device.
   */
  readonly hwid?: string | undefined;
  readonly id: string;
  /**
   * The scheduling system uses these to target jobs at the right kinds of
   * devices.
   */
  readonly labels?: SchedulableLabels | undefined;
  readonly location?:
    | Location
    | undefined;
  /**
   * Deprecated: This is needed for the master AFE in the autotest infra to
   * forward RPCs to the relevant shard for a device.
   * Not to be used in skylab.
   *
   * @deprecated
   */
  readonly ownerShard?:
    | Shard
    | undefined;
  /**
   * An invariant about the distros inventory tag associated with the device.
   * For ChromeOS devices, this can be read off of vboot.
   */
  readonly serialNumber?:
    | string
    | undefined;
  /**
   * Similar to a useless machine, if set to true, automatic inventory
   * updates should set this back to false.  This can be used to test
   * that automated updates are firing correctly.
   */
  readonly uselessSwitch?: boolean | undefined;
}

/**
 * RemovalReason describes the reason for a DUT being removed from running
 * tasks. See also go/skylab-dut-removal-reason NEXT TAG: 4
 */
export interface RemovalReason {
  /** bug is a link to a bug in crbug.com or other bug tracker.  Required. */
  readonly bug?:
    | string
    | undefined;
  /**
   * comment should be a short comment to provide quick context for
   * the removal reason.  This should not be used as a replacement for
   * keeping the bug up to date with context.
   */
  readonly comment?:
    | string
    | undefined;
  /**
   * expire_time indicates when the reason for removal should be
   * revisited so that DUTs don't get forgotten.  Required.
   */
  readonly expireTime?: Timestamp | undefined;
}

/** NEXT TAG: 5 */
export interface Location {
  readonly lab: PhysicalLab | undefined;
  readonly row: number;
  readonly rack: number;
  readonly host: number;
}

/** NEXT TAG: 2 */
export interface PhysicalLab {
  readonly name: string;
}

/**
 * A DeviceLock is a generic way of pulling any lab device out of commission
 * temporarily. This allows us to quickly take out a device without deleting it
 * from inventory. The exact effect of locking a device varies by the type of
 * device and services involved.
 * NEXT TAG: 6
 */
export interface DeviceLock {
  /** @deprecated */
  readonly lock: boolean;
  /**
   * This field is informational only. Do not assume this as a real user
   * identity in any auth domain.
   */
  readonly lockedBy: string;
  readonly lockReason: string;
  readonly beginTime: Timestamp | undefined;
  readonly expireTime:
    | Timestamp
    | undefined;
  /** @deprecated */
  readonly lockId: number;
  readonly id: string;
}

/** NEXT TAG: 2 */
export interface Shard {
  readonly hostname: string;
}

/**
 * These labels are used for scheduling decisions in skylab. They should be
 * semantically known to the inventory service to allow filtering based on them.
 * (i.e., no "extra_labels" field down here)
 *
 * Keep sorted by field name.
 *
 * NEXT TAG: 44
 */
export interface SchedulableLabels {
  /** Whether this device supports ARC execution environment. */
  readonly arc?:
    | boolean
    | undefined;
  /**
   * TODO(pprabhu) This should be an enum shared with stable_versions.proto
   * But there are 171 of them...
   */
  readonly board?:
    | string
    | undefined;
  /** This matches the brand id in chromiumos/infra/proto/src/device/brand_id.proto */
  readonly brand?:
    | string
    | undefined;
  /** Whether this device is connected to a CallBox. */
  readonly callbox?: boolean | undefined;
  readonly capabilities?: HardwareCapabilities | undefined;
  readonly cr50Phase?:
    | SchedulableLabels_CR50Phase
    | undefined;
  /**
   * Example: cr50-ro-keyid:prod
   * Example: cr50-ro-keyid:0xaa66150f
   */
  readonly cr50RoKeyid?:
    | string
    | undefined;
  /** Example: cr50-ro-version:0.0.10 */
  readonly cr50RoVersion?:
    | string
    | undefined;
  /**
   * Example: cr50-rw-keyid:prod
   * Example: cr50-rw-keyid:0xde88588d
   */
  readonly cr50RwKeyid?:
    | string
    | undefined;
  /** Example:cr50-rw-version:0.3.20 */
  readonly cr50RwVersion?: string | undefined;
  readonly criticalPools: readonly SchedulableLabels_DUTPool[];
  readonly ctsAbi: readonly SchedulableLabels_CTSABI[];
  readonly ctsCpu: readonly SchedulableLabels_CTSCPU[];
  readonly ecType?: SchedulableLabels_ECType | undefined;
  readonly hwidComponent: readonly string[];
  /** crbug.com/994200: this matches to label "sku" in autotest. */
  readonly hwidSku?:
    | string
    | undefined;
  /** Example: 2931 */
  readonly dlmSkuId?:
    | string
    | undefined;
  /** The set of software licenses assigned to the device. */
  readonly licenses: readonly License[];
  readonly model?:
    | string
    | undefined;
  /** Modem Info */
  readonly modeminfo?: ModemInfo | undefined;
  readonly sku?:
    | string
    | undefined;
  /** SIM information */
  readonly siminfo: readonly SIMInfo[];
  readonly osType?: SchedulableLabels_OSType | undefined;
  readonly peripherals?: Peripherals | undefined;
  readonly phase?:
    | SchedulableLabels_Phase
    | undefined;
  /**
   * TODO(pprabhu) This should be an enum shared with stable_versions.proto
   * But there are 171 of them...
   */
  readonly platform?:
    | string
    | undefined;
  /** The RACC enabled status of the DUT. */
  readonly raccEnabledStatus?: string | undefined;
  readonly referenceDesign?: string | undefined;
  readonly selfServePools: readonly string[];
  /** Refer to whether this DUT is stable enough to be scheduled for a test. */
  readonly stability?: boolean | undefined;
  readonly testCoverageHints?:
    | TestCoverageHints
    | undefined;
  /**
   * useless_switch does nothing.  Like a useless machine, if set to
   * true, automatic label updates should set this back to false.
   * This can be used to test that automated updates are firing
   * correctly.
   */
  readonly uselessSwitch?: boolean | undefined;
  readonly variant: readonly string[];
  /** Come from manufacturing config: crbug.com/1046971 */
  readonly wifiChip?:
    | string
    | undefined;
  /** devboard_type specifies the type of devboard */
  readonly devboardType?:
    | SchedulableLabels_DevboardType
    | undefined;
  /** Indicates whether we have wifi network setup on the hosting location. */
  readonly wifiOnSite?:
    | boolean
    | undefined;
  /**
   * TODO(b/350065958): Remove when bot/dut is decoupled from device manager.
   * bot_size is used for test scheduler and device manager to assign tests to
   * bots with enough RAM size.
   */
  readonly botSize?: SchedulableLabels_BotSize | undefined;
}

/**
 * Similar to device phases, but for the cr50 module onboard.
 *
 * NEXT TAG: 3
 */
export enum SchedulableLabels_CR50Phase {
  CR50_PHASE_INVALID = 0,
  CR50_PHASE_PREPVT = 1,
  CR50_PHASE_PVT = 2,
}

export function schedulableLabels_CR50PhaseFromJSON(object: any): SchedulableLabels_CR50Phase {
  switch (object) {
    case 0:
    case "CR50_PHASE_INVALID":
      return SchedulableLabels_CR50Phase.CR50_PHASE_INVALID;
    case 1:
    case "CR50_PHASE_PREPVT":
      return SchedulableLabels_CR50Phase.CR50_PHASE_PREPVT;
    case 2:
    case "CR50_PHASE_PVT":
      return SchedulableLabels_CR50Phase.CR50_PHASE_PVT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CR50Phase");
  }
}

export function schedulableLabels_CR50PhaseToJSON(object: SchedulableLabels_CR50Phase): string {
  switch (object) {
    case SchedulableLabels_CR50Phase.CR50_PHASE_INVALID:
      return "CR50_PHASE_INVALID";
    case SchedulableLabels_CR50Phase.CR50_PHASE_PREPVT:
      return "CR50_PHASE_PREPVT";
    case SchedulableLabels_CR50Phase.CR50_PHASE_PVT:
      return "CR50_PHASE_PVT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CR50Phase");
  }
}

/**
 * critical_pools are pool labels that the builders are dependent on, and that
 * the cros-infra team is responsible for managing explicitly. All other pool
 * labels used for adhoc labeling of DUTs go into self_serve_pools.
 *
 * NEXT TAG: 9
 */
export enum SchedulableLabels_DUTPool {
  DUT_POOL_INVALID = 0,
  DUT_POOL_CQ = 1,
  DUT_POOL_BVT = 2,
  DUT_POOL_SUITES = 3,
  DUT_POOL_CTS = 4,
  DUT_POOL_CTS_PERBUILD = 5,
  DUT_POOL_CONTINUOUS = 6,
  DUT_POOL_ARC_PRESUBMIT = 7,
  DUT_POOL_QUOTA = 8,
}

export function schedulableLabels_DUTPoolFromJSON(object: any): SchedulableLabels_DUTPool {
  switch (object) {
    case 0:
    case "DUT_POOL_INVALID":
      return SchedulableLabels_DUTPool.DUT_POOL_INVALID;
    case 1:
    case "DUT_POOL_CQ":
      return SchedulableLabels_DUTPool.DUT_POOL_CQ;
    case 2:
    case "DUT_POOL_BVT":
      return SchedulableLabels_DUTPool.DUT_POOL_BVT;
    case 3:
    case "DUT_POOL_SUITES":
      return SchedulableLabels_DUTPool.DUT_POOL_SUITES;
    case 4:
    case "DUT_POOL_CTS":
      return SchedulableLabels_DUTPool.DUT_POOL_CTS;
    case 5:
    case "DUT_POOL_CTS_PERBUILD":
      return SchedulableLabels_DUTPool.DUT_POOL_CTS_PERBUILD;
    case 6:
    case "DUT_POOL_CONTINUOUS":
      return SchedulableLabels_DUTPool.DUT_POOL_CONTINUOUS;
    case 7:
    case "DUT_POOL_ARC_PRESUBMIT":
      return SchedulableLabels_DUTPool.DUT_POOL_ARC_PRESUBMIT;
    case 8:
    case "DUT_POOL_QUOTA":
      return SchedulableLabels_DUTPool.DUT_POOL_QUOTA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_DUTPool");
  }
}

export function schedulableLabels_DUTPoolToJSON(object: SchedulableLabels_DUTPool): string {
  switch (object) {
    case SchedulableLabels_DUTPool.DUT_POOL_INVALID:
      return "DUT_POOL_INVALID";
    case SchedulableLabels_DUTPool.DUT_POOL_CQ:
      return "DUT_POOL_CQ";
    case SchedulableLabels_DUTPool.DUT_POOL_BVT:
      return "DUT_POOL_BVT";
    case SchedulableLabels_DUTPool.DUT_POOL_SUITES:
      return "DUT_POOL_SUITES";
    case SchedulableLabels_DUTPool.DUT_POOL_CTS:
      return "DUT_POOL_CTS";
    case SchedulableLabels_DUTPool.DUT_POOL_CTS_PERBUILD:
      return "DUT_POOL_CTS_PERBUILD";
    case SchedulableLabels_DUTPool.DUT_POOL_CONTINUOUS:
      return "DUT_POOL_CONTINUOUS";
    case SchedulableLabels_DUTPool.DUT_POOL_ARC_PRESUBMIT:
      return "DUT_POOL_ARC_PRESUBMIT";
    case SchedulableLabels_DUTPool.DUT_POOL_QUOTA:
      return "DUT_POOL_QUOTA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_DUTPool");
  }
}

/** NEXT TAG: 3 */
export enum SchedulableLabels_CTSABI {
  CTS_ABI_INVALID = 0,
  CTS_ABI_ARM = 1,
  CTS_ABI_X86 = 2,
}

export function schedulableLabels_CTSABIFromJSON(object: any): SchedulableLabels_CTSABI {
  switch (object) {
    case 0:
    case "CTS_ABI_INVALID":
      return SchedulableLabels_CTSABI.CTS_ABI_INVALID;
    case 1:
    case "CTS_ABI_ARM":
      return SchedulableLabels_CTSABI.CTS_ABI_ARM;
    case 2:
    case "CTS_ABI_X86":
      return SchedulableLabels_CTSABI.CTS_ABI_X86;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CTSABI");
  }
}

export function schedulableLabels_CTSABIToJSON(object: SchedulableLabels_CTSABI): string {
  switch (object) {
    case SchedulableLabels_CTSABI.CTS_ABI_INVALID:
      return "CTS_ABI_INVALID";
    case SchedulableLabels_CTSABI.CTS_ABI_ARM:
      return "CTS_ABI_ARM";
    case SchedulableLabels_CTSABI.CTS_ABI_X86:
      return "CTS_ABI_X86";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CTSABI");
  }
}

/** NEXT TAG: 3 */
export enum SchedulableLabels_CTSCPU {
  CTS_CPU_INVALID = 0,
  CTS_CPU_ARM = 1,
  CTS_CPU_X86 = 2,
}

export function schedulableLabels_CTSCPUFromJSON(object: any): SchedulableLabels_CTSCPU {
  switch (object) {
    case 0:
    case "CTS_CPU_INVALID":
      return SchedulableLabels_CTSCPU.CTS_CPU_INVALID;
    case 1:
    case "CTS_CPU_ARM":
      return SchedulableLabels_CTSCPU.CTS_CPU_ARM;
    case 2:
    case "CTS_CPU_X86":
      return SchedulableLabels_CTSCPU.CTS_CPU_X86;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CTSCPU");
  }
}

export function schedulableLabels_CTSCPUToJSON(object: SchedulableLabels_CTSCPU): string {
  switch (object) {
    case SchedulableLabels_CTSCPU.CTS_CPU_INVALID:
      return "CTS_CPU_INVALID";
    case SchedulableLabels_CTSCPU.CTS_CPU_ARM:
      return "CTS_CPU_ARM";
    case SchedulableLabels_CTSCPU.CTS_CPU_X86:
      return "CTS_CPU_X86";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_CTSCPU");
  }
}

/** NEXT TAG: 2 */
export enum SchedulableLabels_ECType {
  EC_TYPE_INVALID = 0,
  EC_TYPE_CHROME_OS = 1,
}

export function schedulableLabels_ECTypeFromJSON(object: any): SchedulableLabels_ECType {
  switch (object) {
    case 0:
    case "EC_TYPE_INVALID":
      return SchedulableLabels_ECType.EC_TYPE_INVALID;
    case 1:
    case "EC_TYPE_CHROME_OS":
      return SchedulableLabels_ECType.EC_TYPE_CHROME_OS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_ECType");
  }
}

export function schedulableLabels_ECTypeToJSON(object: SchedulableLabels_ECType): string {
  switch (object) {
    case SchedulableLabels_ECType.EC_TYPE_INVALID:
      return "EC_TYPE_INVALID";
    case SchedulableLabels_ECType.EC_TYPE_CHROME_OS:
      return "EC_TYPE_CHROME_OS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_ECType");
  }
}

/** NEXT TAG: 6 */
export enum SchedulableLabels_OSType {
  OS_TYPE_INVALID = 0,
  OS_TYPE_ANDROID = 1,
  OS_TYPE_CROS = 2,
  OS_TYPE_JETSTREAM = 3,
  OS_TYPE_MOBLAB = 4,
  OS_TYPE_LABSTATION = 5,
}

export function schedulableLabels_OSTypeFromJSON(object: any): SchedulableLabels_OSType {
  switch (object) {
    case 0:
    case "OS_TYPE_INVALID":
      return SchedulableLabels_OSType.OS_TYPE_INVALID;
    case 1:
    case "OS_TYPE_ANDROID":
      return SchedulableLabels_OSType.OS_TYPE_ANDROID;
    case 2:
    case "OS_TYPE_CROS":
      return SchedulableLabels_OSType.OS_TYPE_CROS;
    case 3:
    case "OS_TYPE_JETSTREAM":
      return SchedulableLabels_OSType.OS_TYPE_JETSTREAM;
    case 4:
    case "OS_TYPE_MOBLAB":
      return SchedulableLabels_OSType.OS_TYPE_MOBLAB;
    case 5:
    case "OS_TYPE_LABSTATION":
      return SchedulableLabels_OSType.OS_TYPE_LABSTATION;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_OSType");
  }
}

export function schedulableLabels_OSTypeToJSON(object: SchedulableLabels_OSType): string {
  switch (object) {
    case SchedulableLabels_OSType.OS_TYPE_INVALID:
      return "OS_TYPE_INVALID";
    case SchedulableLabels_OSType.OS_TYPE_ANDROID:
      return "OS_TYPE_ANDROID";
    case SchedulableLabels_OSType.OS_TYPE_CROS:
      return "OS_TYPE_CROS";
    case SchedulableLabels_OSType.OS_TYPE_JETSTREAM:
      return "OS_TYPE_JETSTREAM";
    case SchedulableLabels_OSType.OS_TYPE_MOBLAB:
      return "OS_TYPE_MOBLAB";
    case SchedulableLabels_OSType.OS_TYPE_LABSTATION:
      return "OS_TYPE_LABSTATION";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_OSType");
  }
}

/**
 * Devices go through the phases EVT -> DVT -> PVT during early development.
 * Post launch devices are in the MP phase.
 * Certain phases end up having to be repeated as hardware evolves. Leave some
 * scope for iteration within phases.
 *
 * NEXT TAG: 9
 */
export enum SchedulableLabels_Phase {
  PHASE_INVALID = 0,
  PHASE_EVT = 1,
  PHASE_EVT2 = 2,
  PHASE_DVT = 3,
  PHASE_DVT2 = 4,
  PHASE_PVT = 5,
  PHASE_PVT2 = 6,
  PHASE_PVT3 = 7,
  PHASE_MP = 8,
}

export function schedulableLabels_PhaseFromJSON(object: any): SchedulableLabels_Phase {
  switch (object) {
    case 0:
    case "PHASE_INVALID":
      return SchedulableLabels_Phase.PHASE_INVALID;
    case 1:
    case "PHASE_EVT":
      return SchedulableLabels_Phase.PHASE_EVT;
    case 2:
    case "PHASE_EVT2":
      return SchedulableLabels_Phase.PHASE_EVT2;
    case 3:
    case "PHASE_DVT":
      return SchedulableLabels_Phase.PHASE_DVT;
    case 4:
    case "PHASE_DVT2":
      return SchedulableLabels_Phase.PHASE_DVT2;
    case 5:
    case "PHASE_PVT":
      return SchedulableLabels_Phase.PHASE_PVT;
    case 6:
    case "PHASE_PVT2":
      return SchedulableLabels_Phase.PHASE_PVT2;
    case 7:
    case "PHASE_PVT3":
      return SchedulableLabels_Phase.PHASE_PVT3;
    case 8:
    case "PHASE_MP":
      return SchedulableLabels_Phase.PHASE_MP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_Phase");
  }
}

export function schedulableLabels_PhaseToJSON(object: SchedulableLabels_Phase): string {
  switch (object) {
    case SchedulableLabels_Phase.PHASE_INVALID:
      return "PHASE_INVALID";
    case SchedulableLabels_Phase.PHASE_EVT:
      return "PHASE_EVT";
    case SchedulableLabels_Phase.PHASE_EVT2:
      return "PHASE_EVT2";
    case SchedulableLabels_Phase.PHASE_DVT:
      return "PHASE_DVT";
    case SchedulableLabels_Phase.PHASE_DVT2:
      return "PHASE_DVT2";
    case SchedulableLabels_Phase.PHASE_PVT:
      return "PHASE_PVT";
    case SchedulableLabels_Phase.PHASE_PVT2:
      return "PHASE_PVT2";
    case SchedulableLabels_Phase.PHASE_PVT3:
      return "PHASE_PVT3";
    case SchedulableLabels_Phase.PHASE_MP:
      return "PHASE_MP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_Phase");
  }
}

/** NEXT TAG: 2 */
export enum SchedulableLabels_BotSize {
  BOT_SIZE_INVALID = 0,
  BOT_SIZE_LARGE = 1,
}

export function schedulableLabels_BotSizeFromJSON(object: any): SchedulableLabels_BotSize {
  switch (object) {
    case 0:
    case "BOT_SIZE_INVALID":
      return SchedulableLabels_BotSize.BOT_SIZE_INVALID;
    case 1:
    case "BOT_SIZE_LARGE":
      return SchedulableLabels_BotSize.BOT_SIZE_LARGE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_BotSize");
  }
}

export function schedulableLabels_BotSizeToJSON(object: SchedulableLabels_BotSize): string {
  switch (object) {
    case SchedulableLabels_BotSize.BOT_SIZE_INVALID:
      return "BOT_SIZE_INVALID";
    case SchedulableLabels_BotSize.BOT_SIZE_LARGE:
      return "BOT_SIZE_LARGE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_BotSize");
  }
}

/** NEXT TAG: 4 */
export enum SchedulableLabels_DevboardType {
  DEVBOARD_TYPE_INVALID = 0,
  DEVBOARD_TYPE_ANDREIBOARD = 1,
  DEVBOARD_TYPE_ICETOWER = 2,
  DEVBOARD_TYPE_DRAGONCLAW = 3,
}

export function schedulableLabels_DevboardTypeFromJSON(object: any): SchedulableLabels_DevboardType {
  switch (object) {
    case 0:
    case "DEVBOARD_TYPE_INVALID":
      return SchedulableLabels_DevboardType.DEVBOARD_TYPE_INVALID;
    case 1:
    case "DEVBOARD_TYPE_ANDREIBOARD":
      return SchedulableLabels_DevboardType.DEVBOARD_TYPE_ANDREIBOARD;
    case 2:
    case "DEVBOARD_TYPE_ICETOWER":
      return SchedulableLabels_DevboardType.DEVBOARD_TYPE_ICETOWER;
    case 3:
    case "DEVBOARD_TYPE_DRAGONCLAW":
      return SchedulableLabels_DevboardType.DEVBOARD_TYPE_DRAGONCLAW;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_DevboardType");
  }
}

export function schedulableLabels_DevboardTypeToJSON(object: SchedulableLabels_DevboardType): string {
  switch (object) {
    case SchedulableLabels_DevboardType.DEVBOARD_TYPE_INVALID:
      return "DEVBOARD_TYPE_INVALID";
    case SchedulableLabels_DevboardType.DEVBOARD_TYPE_ANDREIBOARD:
      return "DEVBOARD_TYPE_ANDREIBOARD";
    case SchedulableLabels_DevboardType.DEVBOARD_TYPE_ICETOWER:
      return "DEVBOARD_TYPE_ICETOWER";
    case SchedulableLabels_DevboardType.DEVBOARD_TYPE_DRAGONCLAW:
      return "DEVBOARD_TYPE_DRAGONCLAW";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum SchedulableLabels_DevboardType");
  }
}

/**
 * Keep sorted by field name.
 *
 * NEXT TAG: 28
 */
export interface HardwareCapabilities {
  readonly atrus?: boolean | undefined;
  readonly bluetooth?: boolean | undefined;
  readonly carrier?:
    | HardwareCapabilities_Carrier
    | undefined;
  /**
   * A list of all carriers that can be tested on the DUT. This extends the carrier field which could previously only
   * be used with a single carrier.
   */
  readonly supportedCarriers: readonly HardwareCapabilities_Carrier[];
  /** Whether this device has Chromebook X capabilities */
  readonly cbx?:
    | HardwareCapabilities_CbxState
    | undefined;
  /** The type of CBX branding available */
  readonly cbxBranding?:
    | HardwareCapabilities_CbxBranding
    | undefined;
  /** Mapping between starfish slot_id and carrier name. */
  readonly starfishSlotMapping?: string | undefined;
  readonly detachablebase?:
    | boolean
    | undefined;
  /** Indicating if the device has fingerprint sensor. */
  readonly fingerprint?: boolean | undefined;
  readonly fingerprintBoard?: string | undefined;
  readonly fingerprintMcu?: string | undefined;
  readonly fingerprintSensor?: string | undefined;
  readonly flashrom?: boolean | undefined;
  readonly formFactor?: HardwareCapabilities_FormFactor | undefined;
  readonly gpuFamily?: string | undefined;
  readonly gpuId?: string | undefined;
  readonly graphics?: string | undefined;
  readonly hotwording?: boolean | undefined;
  readonly internalDisplay?: boolean | undefined;
  readonly lucidsleep?: boolean | undefined;
  readonly modem?: string | undefined;
  readonly power?: string | undefined;
  readonly storage?: string | undefined;
  readonly telephony?: string | undefined;
  readonly webcam?: boolean | undefined;
  readonly touchpad?: boolean | undefined;
  readonly touchscreen?: boolean | undefined;
  readonly videoAcceleration: readonly HardwareCapabilities_VideoAcceleration[];
}

/** NEXT TAG: 30 */
export enum HardwareCapabilities_Carrier {
  CARRIER_INVALID = 0,
  CARRIER_ATT = 1,
  CARRIER_TMOBILE = 2,
  CARRIER_VERIZON = 3,
  CARRIER_SPRINT = 4,
  CARRIER_TESTESIM = 5,
  CARRIER_ESIM = 6,
  CARRIER_MULTISIM = 7,
  CARRIER_ROAMSIM = 8,
  CARRIER_DOCOMO = 9,
  CARRIER_SOFTBANK = 10,
  CARRIER_KDDI = 11,
  CARRIER_RAKUTEN = 12,
  CARRIER_VODAFONE = 13,
  CARRIER_EE = 14,
  CARRIER_AMARISOFT = 15,
  CARRIER_ROGER = 16,
  CARRIER_BELL = 17,
  CARRIER_TELUS = 18,
  CARRIER_STARFISH = 19,
  CARRIER_STARFISHPLUS = 20,
  CARRIER_PINLOCK = 21,
  CARRIER_FI = 22,
  CARRIER_CMW500 = 23,
  CARRIER_CMX500 = 24,
  CARRIER_RAK = 25,
  CARRIER_CBRS = 26,
  CARRIER_LINEMO = 27,
  CARRIER_POVO = 28,
  CARRIER_HANSHIN = 29,
}

export function hardwareCapabilities_CarrierFromJSON(object: any): HardwareCapabilities_Carrier {
  switch (object) {
    case 0:
    case "CARRIER_INVALID":
      return HardwareCapabilities_Carrier.CARRIER_INVALID;
    case 1:
    case "CARRIER_ATT":
      return HardwareCapabilities_Carrier.CARRIER_ATT;
    case 2:
    case "CARRIER_TMOBILE":
      return HardwareCapabilities_Carrier.CARRIER_TMOBILE;
    case 3:
    case "CARRIER_VERIZON":
      return HardwareCapabilities_Carrier.CARRIER_VERIZON;
    case 4:
    case "CARRIER_SPRINT":
      return HardwareCapabilities_Carrier.CARRIER_SPRINT;
    case 5:
    case "CARRIER_TESTESIM":
      return HardwareCapabilities_Carrier.CARRIER_TESTESIM;
    case 6:
    case "CARRIER_ESIM":
      return HardwareCapabilities_Carrier.CARRIER_ESIM;
    case 7:
    case "CARRIER_MULTISIM":
      return HardwareCapabilities_Carrier.CARRIER_MULTISIM;
    case 8:
    case "CARRIER_ROAMSIM":
      return HardwareCapabilities_Carrier.CARRIER_ROAMSIM;
    case 9:
    case "CARRIER_DOCOMO":
      return HardwareCapabilities_Carrier.CARRIER_DOCOMO;
    case 10:
    case "CARRIER_SOFTBANK":
      return HardwareCapabilities_Carrier.CARRIER_SOFTBANK;
    case 11:
    case "CARRIER_KDDI":
      return HardwareCapabilities_Carrier.CARRIER_KDDI;
    case 12:
    case "CARRIER_RAKUTEN":
      return HardwareCapabilities_Carrier.CARRIER_RAKUTEN;
    case 13:
    case "CARRIER_VODAFONE":
      return HardwareCapabilities_Carrier.CARRIER_VODAFONE;
    case 14:
    case "CARRIER_EE":
      return HardwareCapabilities_Carrier.CARRIER_EE;
    case 15:
    case "CARRIER_AMARISOFT":
      return HardwareCapabilities_Carrier.CARRIER_AMARISOFT;
    case 16:
    case "CARRIER_ROGER":
      return HardwareCapabilities_Carrier.CARRIER_ROGER;
    case 17:
    case "CARRIER_BELL":
      return HardwareCapabilities_Carrier.CARRIER_BELL;
    case 18:
    case "CARRIER_TELUS":
      return HardwareCapabilities_Carrier.CARRIER_TELUS;
    case 19:
    case "CARRIER_STARFISH":
      return HardwareCapabilities_Carrier.CARRIER_STARFISH;
    case 20:
    case "CARRIER_STARFISHPLUS":
      return HardwareCapabilities_Carrier.CARRIER_STARFISHPLUS;
    case 21:
    case "CARRIER_PINLOCK":
      return HardwareCapabilities_Carrier.CARRIER_PINLOCK;
    case 22:
    case "CARRIER_FI":
      return HardwareCapabilities_Carrier.CARRIER_FI;
    case 23:
    case "CARRIER_CMW500":
      return HardwareCapabilities_Carrier.CARRIER_CMW500;
    case 24:
    case "CARRIER_CMX500":
      return HardwareCapabilities_Carrier.CARRIER_CMX500;
    case 25:
    case "CARRIER_RAK":
      return HardwareCapabilities_Carrier.CARRIER_RAK;
    case 26:
    case "CARRIER_CBRS":
      return HardwareCapabilities_Carrier.CARRIER_CBRS;
    case 27:
    case "CARRIER_LINEMO":
      return HardwareCapabilities_Carrier.CARRIER_LINEMO;
    case 28:
    case "CARRIER_POVO":
      return HardwareCapabilities_Carrier.CARRIER_POVO;
    case 29:
    case "CARRIER_HANSHIN":
      return HardwareCapabilities_Carrier.CARRIER_HANSHIN;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_Carrier");
  }
}

export function hardwareCapabilities_CarrierToJSON(object: HardwareCapabilities_Carrier): string {
  switch (object) {
    case HardwareCapabilities_Carrier.CARRIER_INVALID:
      return "CARRIER_INVALID";
    case HardwareCapabilities_Carrier.CARRIER_ATT:
      return "CARRIER_ATT";
    case HardwareCapabilities_Carrier.CARRIER_TMOBILE:
      return "CARRIER_TMOBILE";
    case HardwareCapabilities_Carrier.CARRIER_VERIZON:
      return "CARRIER_VERIZON";
    case HardwareCapabilities_Carrier.CARRIER_SPRINT:
      return "CARRIER_SPRINT";
    case HardwareCapabilities_Carrier.CARRIER_TESTESIM:
      return "CARRIER_TESTESIM";
    case HardwareCapabilities_Carrier.CARRIER_ESIM:
      return "CARRIER_ESIM";
    case HardwareCapabilities_Carrier.CARRIER_MULTISIM:
      return "CARRIER_MULTISIM";
    case HardwareCapabilities_Carrier.CARRIER_ROAMSIM:
      return "CARRIER_ROAMSIM";
    case HardwareCapabilities_Carrier.CARRIER_DOCOMO:
      return "CARRIER_DOCOMO";
    case HardwareCapabilities_Carrier.CARRIER_SOFTBANK:
      return "CARRIER_SOFTBANK";
    case HardwareCapabilities_Carrier.CARRIER_KDDI:
      return "CARRIER_KDDI";
    case HardwareCapabilities_Carrier.CARRIER_RAKUTEN:
      return "CARRIER_RAKUTEN";
    case HardwareCapabilities_Carrier.CARRIER_VODAFONE:
      return "CARRIER_VODAFONE";
    case HardwareCapabilities_Carrier.CARRIER_EE:
      return "CARRIER_EE";
    case HardwareCapabilities_Carrier.CARRIER_AMARISOFT:
      return "CARRIER_AMARISOFT";
    case HardwareCapabilities_Carrier.CARRIER_ROGER:
      return "CARRIER_ROGER";
    case HardwareCapabilities_Carrier.CARRIER_BELL:
      return "CARRIER_BELL";
    case HardwareCapabilities_Carrier.CARRIER_TELUS:
      return "CARRIER_TELUS";
    case HardwareCapabilities_Carrier.CARRIER_STARFISH:
      return "CARRIER_STARFISH";
    case HardwareCapabilities_Carrier.CARRIER_STARFISHPLUS:
      return "CARRIER_STARFISHPLUS";
    case HardwareCapabilities_Carrier.CARRIER_PINLOCK:
      return "CARRIER_PINLOCK";
    case HardwareCapabilities_Carrier.CARRIER_FI:
      return "CARRIER_FI";
    case HardwareCapabilities_Carrier.CARRIER_CMW500:
      return "CARRIER_CMW500";
    case HardwareCapabilities_Carrier.CARRIER_CMX500:
      return "CARRIER_CMX500";
    case HardwareCapabilities_Carrier.CARRIER_RAK:
      return "CARRIER_RAK";
    case HardwareCapabilities_Carrier.CARRIER_CBRS:
      return "CARRIER_CBRS";
    case HardwareCapabilities_Carrier.CARRIER_LINEMO:
      return "CARRIER_LINEMO";
    case HardwareCapabilities_Carrier.CARRIER_POVO:
      return "CARRIER_POVO";
    case HardwareCapabilities_Carrier.CARRIER_HANSHIN:
      return "CARRIER_HANSHIN";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_Carrier");
  }
}

/**
 * Next tag: 3
 * We care about the both true/false cases, so we need a default value to
 * avoid "false" getting added to an empty struct
 */
export enum HardwareCapabilities_CbxState {
  CBX_STATE_UNSPECIFIED = 0,
  CBX_STATE_TRUE = 1,
  CBX_STATE_FALSE = 2,
}

export function hardwareCapabilities_CbxStateFromJSON(object: any): HardwareCapabilities_CbxState {
  switch (object) {
    case 0:
    case "CBX_STATE_UNSPECIFIED":
      return HardwareCapabilities_CbxState.CBX_STATE_UNSPECIFIED;
    case 1:
    case "CBX_STATE_TRUE":
      return HardwareCapabilities_CbxState.CBX_STATE_TRUE;
    case 2:
    case "CBX_STATE_FALSE":
      return HardwareCapabilities_CbxState.CBX_STATE_FALSE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_CbxState");
  }
}

export function hardwareCapabilities_CbxStateToJSON(object: HardwareCapabilities_CbxState): string {
  switch (object) {
    case HardwareCapabilities_CbxState.CBX_STATE_UNSPECIFIED:
      return "CBX_STATE_UNSPECIFIED";
    case HardwareCapabilities_CbxState.CBX_STATE_TRUE:
      return "CBX_STATE_TRUE";
    case HardwareCapabilities_CbxState.CBX_STATE_FALSE:
      return "CBX_STATE_FALSE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_CbxState");
  }
}

/** Next tag: 3 */
export enum HardwareCapabilities_CbxBranding {
  CBX_BRANDING_UNSPECIFIED = 0,
  CBX_BRANDING_SOFT_BRANDING = 1,
  CBX_BRANDING_HARD_BRANDING = 2,
}

export function hardwareCapabilities_CbxBrandingFromJSON(object: any): HardwareCapabilities_CbxBranding {
  switch (object) {
    case 0:
    case "CBX_BRANDING_UNSPECIFIED":
      return HardwareCapabilities_CbxBranding.CBX_BRANDING_UNSPECIFIED;
    case 1:
    case "CBX_BRANDING_SOFT_BRANDING":
      return HardwareCapabilities_CbxBranding.CBX_BRANDING_SOFT_BRANDING;
    case 2:
    case "CBX_BRANDING_HARD_BRANDING":
      return HardwareCapabilities_CbxBranding.CBX_BRANDING_HARD_BRANDING;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_CbxBranding");
  }
}

export function hardwareCapabilities_CbxBrandingToJSON(object: HardwareCapabilities_CbxBranding): string {
  switch (object) {
    case HardwareCapabilities_CbxBranding.CBX_BRANDING_UNSPECIFIED:
      return "CBX_BRANDING_UNSPECIFIED";
    case HardwareCapabilities_CbxBranding.CBX_BRANDING_SOFT_BRANDING:
      return "CBX_BRANDING_SOFT_BRANDING";
    case HardwareCapabilities_CbxBranding.CBX_BRANDING_HARD_BRANDING:
      return "CBX_BRANDING_HARD_BRANDING";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_CbxBranding");
  }
}

/** Next tag: 8 */
export enum HardwareCapabilities_FormFactor {
  FORM_FACTOR_UNSPECIFIED = 0,
  FORM_FACTOR_CLAMSHELL = 1,
  FORM_FACTOR_CONVERTIBLE = 2,
  FORM_FACTOR_DETACHABLE = 3,
  FORM_FACTOR_CHROMEBASE = 4,
  FORM_FACTOR_CHROMEBOX = 5,
  FORM_FACTOR_CHROMEBIT = 6,
  FORM_FACTOR_CHROMESLATE = 7,
}

export function hardwareCapabilities_FormFactorFromJSON(object: any): HardwareCapabilities_FormFactor {
  switch (object) {
    case 0:
    case "FORM_FACTOR_UNSPECIFIED":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_UNSPECIFIED;
    case 1:
    case "FORM_FACTOR_CLAMSHELL":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CLAMSHELL;
    case 2:
    case "FORM_FACTOR_CONVERTIBLE":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CONVERTIBLE;
    case 3:
    case "FORM_FACTOR_DETACHABLE":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_DETACHABLE;
    case 4:
    case "FORM_FACTOR_CHROMEBASE":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBASE;
    case 5:
    case "FORM_FACTOR_CHROMEBOX":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBOX;
    case 6:
    case "FORM_FACTOR_CHROMEBIT":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBIT;
    case 7:
    case "FORM_FACTOR_CHROMESLATE":
      return HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMESLATE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_FormFactor");
  }
}

export function hardwareCapabilities_FormFactorToJSON(object: HardwareCapabilities_FormFactor): string {
  switch (object) {
    case HardwareCapabilities_FormFactor.FORM_FACTOR_UNSPECIFIED:
      return "FORM_FACTOR_UNSPECIFIED";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CLAMSHELL:
      return "FORM_FACTOR_CLAMSHELL";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CONVERTIBLE:
      return "FORM_FACTOR_CONVERTIBLE";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_DETACHABLE:
      return "FORM_FACTOR_DETACHABLE";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBASE:
      return "FORM_FACTOR_CHROMEBASE";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBOX:
      return "FORM_FACTOR_CHROMEBOX";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMEBIT:
      return "FORM_FACTOR_CHROMEBIT";
    case HardwareCapabilities_FormFactor.FORM_FACTOR_CHROMESLATE:
      return "FORM_FACTOR_CHROMESLATE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum HardwareCapabilities_FormFactor");
  }
}

/** NEXT TAG: 13 */
export enum HardwareCapabilities_VideoAcceleration {
  VIDEO_ACCELERATION_INVALID = 0,
  VIDEO_ACCELERATION_H264 = 1,
  VIDEO_ACCELERATION_ENC_H264 = 2,
  VIDEO_ACCELERATION_VP8 = 3,
  VIDEO_ACCELERATION_ENC_VP8 = 4,
  VIDEO_ACCELERATION_VP9 = 5,
  VIDEO_ACCELERATION_ENC_VP9 = 6,
  VIDEO_ACCELERATION_VP9_2 = 7,
  VIDEO_ACCELERATION_ENC_VP9_2 = 8,
  VIDEO_ACCELERATION_H265 = 9,
  VIDEO_ACCELERATION_ENC_H265 = 10,
  VIDEO_ACCELERATION_MJPG = 11,
  VIDEO_ACCELERATION_ENC_MJPG = 12,
}

export function hardwareCapabilities_VideoAccelerationFromJSON(object: any): HardwareCapabilities_VideoAcceleration {
  switch (object) {
    case 0:
    case "VIDEO_ACCELERATION_INVALID":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_INVALID;
    case 1:
    case "VIDEO_ACCELERATION_H264":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_H264;
    case 2:
    case "VIDEO_ACCELERATION_ENC_H264":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_H264;
    case 3:
    case "VIDEO_ACCELERATION_VP8":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP8;
    case 4:
    case "VIDEO_ACCELERATION_ENC_VP8":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP8;
    case 5:
    case "VIDEO_ACCELERATION_VP9":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP9;
    case 6:
    case "VIDEO_ACCELERATION_ENC_VP9":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9;
    case 7:
    case "VIDEO_ACCELERATION_VP9_2":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP9_2;
    case 8:
    case "VIDEO_ACCELERATION_ENC_VP9_2":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9_2;
    case 9:
    case "VIDEO_ACCELERATION_H265":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_H265;
    case 10:
    case "VIDEO_ACCELERATION_ENC_H265":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_H265;
    case 11:
    case "VIDEO_ACCELERATION_MJPG":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_MJPG;
    case 12:
    case "VIDEO_ACCELERATION_ENC_MJPG":
      return HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_MJPG;
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum HardwareCapabilities_VideoAcceleration",
      );
  }
}

export function hardwareCapabilities_VideoAccelerationToJSON(object: HardwareCapabilities_VideoAcceleration): string {
  switch (object) {
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_INVALID:
      return "VIDEO_ACCELERATION_INVALID";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_H264:
      return "VIDEO_ACCELERATION_H264";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_H264:
      return "VIDEO_ACCELERATION_ENC_H264";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP8:
      return "VIDEO_ACCELERATION_VP8";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP8:
      return "VIDEO_ACCELERATION_ENC_VP8";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP9:
      return "VIDEO_ACCELERATION_VP9";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9:
      return "VIDEO_ACCELERATION_ENC_VP9";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_VP9_2:
      return "VIDEO_ACCELERATION_VP9_2";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9_2:
      return "VIDEO_ACCELERATION_ENC_VP9_2";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_H265:
      return "VIDEO_ACCELERATION_H265";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_H265:
      return "VIDEO_ACCELERATION_ENC_H265";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_MJPG:
      return "VIDEO_ACCELERATION_MJPG";
    case HardwareCapabilities_VideoAcceleration.VIDEO_ACCELERATION_ENC_MJPG:
      return "VIDEO_ACCELERATION_ENC_MJPG";
    default:
      throw new globalThis.Error(
        "Unrecognized enum value " + object + " for enum HardwareCapabilities_VideoAcceleration",
      );
  }
}

/**
 * Peripherals list peripherals connected to the device in the lab.
 *
 * These peripherals are not addressable as devices themselves. For peripherals
 * that have complex connections with devices, create a separate device and
 * connections with other devices.
 *
 * Keep sorted by field names.
 *
 * NEXT TAG: 57
 */
export interface Peripherals {
  readonly audioBoard?:
    | boolean
    | undefined;
  /**
   * The DUT is housed in an audio box to record / replay audio for audio
   * testing.
   */
  readonly audioBox?:
    | boolean
    | undefined;
  /** Is the DUT connected with a static 3.5mm audio cable. */
  readonly audioCable?: boolean | undefined;
  readonly audioLoopbackDongle?:
    | boolean
    | undefined;
  /** Is this device connected to a chameleon (GVC head). */
  readonly chameleon?: boolean | undefined;
  readonly chameleonType: readonly Peripherals_ChameleonType[];
  readonly chameleonConnectionTypes: readonly Peripherals_ChameleonConnectionType[];
  readonly chameleonState?: PeripheralState | undefined;
  readonly audioboxJackpluggerState?:
    | Peripherals_AudioBoxJackPlugger
    | undefined;
  /** Indicate the type of audio cable */
  readonly trrsType?:
    | Peripherals_TRRSType
    | undefined;
  /** Whether DUT WiFi antenna is connected conductively or OTA. */
  readonly conductive?: boolean | undefined;
  readonly huddly?: boolean | undefined;
  readonly mimo?:
    | boolean
    | undefined;
  /** Whether a DUT has a special camera (Logitech PTZ Pro 2) connected to it. */
  readonly ptzpro2?: boolean | undefined;
  readonly servo?: boolean | undefined;
  readonly servoComponent: readonly string[];
  readonly servoState?: PeripheralState | undefined;
  readonly servoType?: string | undefined;
  readonly servoTopology?: ServoTopology | undefined;
  readonly servoFwChannel?: ServoFwChannel | undefined;
  readonly smartUsbhub?: boolean | undefined;
  readonly stylus?: boolean | undefined;
  readonly camerabox?:
    | boolean
    | undefined;
  /** Is this device inside a hermetic wifi cell. */
  readonly wificell?:
    | boolean
    | undefined;
  /**
   * Indicate if the device is in a pre-setup environment with 802.11ax routers.
   * crbug.com/1044786
   */
  readonly router80211ax?:
    | boolean
    | undefined;
  /** Indicate how many working btpeers for this device: crbug.com/1052511 */
  readonly workingBluetoothBtpeer?: number | undefined;
  readonly cameraboxFacing?: Peripherals_CameraboxFacing | undefined;
  readonly cameraboxLight?: Peripherals_CameraboxLight | undefined;
  readonly storageState?: HardwareState | undefined;
  readonly servoUsbState?: HardwareState | undefined;
  readonly batteryState?: HardwareState | undefined;
  readonly wifiState?: HardwareState | undefined;
  readonly bluetoothState?: HardwareState | undefined;
  readonly cellularModemState?: HardwareState | undefined;
  readonly starfishState?:
    | PeripheralState
    | undefined;
  /** The overall state of the celular SIMs in the device. */
  readonly simState?:
    | PeripheralState
    | undefined;
  /**
   * The number of SIMs with connectable networks detected. This is only used in the converters
   * and is not populated directly in UFS.
   */
  readonly workingSims?: number | undefined;
  readonly rpmState?:
    | PeripheralState
    | undefined;
  /**
   * The overall state of all btpeer peripherals in the testbed.
   * Sourced from unifiedfleet.api.v1.models.chromeos.lab.DutState.peripheral_btpeer_state.
   */
  readonly peripheralBtpeerState?:
    | PeripheralState
    | undefined;
  /**
   * The overall state of all wifi router peripherals in the testbed.
   * Sourced from unifiedfleet.api.v1.models.chromeos.lab.DutState.wifi_peripheral_state.
   */
  readonly peripheralWifiState?:
    | PeripheralState
    | undefined;
  /**
   * WifiRouterFeatures that are supported by all wifi routers in the testbed.
   *
   * This is a copy of unifiedfleet.api.v1.rpc.Wifi.wifi_router_features.
   *
   * If there are no wifi routers this will be empty.
   * If any routers have unknown features, this will just have a single
   * WIFI_ROUTER_FEATURE_UNKNOWN entry.
   * If any routers' features has a WIFI_ROUTER_FEATURE_INVALID feature, a
   * WIFI_ROUTER_FEATURE_INVALID feature will be included along with the other
   * common, valid features.
   * If there are no common, valid features between all routers, this will just
   * have a single WIFI_ROUTER_FEATURE_INVALID entry.
   */
  readonly wifiRouterFeatures: readonly Peripherals_WifiRouterFeature[];
  /**
   * All wifi router model names in the testbed.
   *
   * This is a collection of unifiedfleet.api.v1.rpc.WifiRouter.model values,
   * one for each in unifiedfleet.api.v1.rpc.Wifi.wifi_routers.
   */
  readonly wifiRouterModels: readonly string[];
  /**
   * The state of Human Motion Robot system.
   * See UFS peripherals.proto or go/fleet-infra-hmr-support for more context.
   */
  readonly hmrState?:
    | PeripheralState
    | undefined;
  /**
   * Indicate the presence of a WALT device for latency testing.
   * The walt device will be connected to the bottom usb-A port of the servo.
   * Set to true if the HMR has a walt unit attached to it else False.
   */
  readonly hmrWalt?:
    | boolean
    | undefined;
  /** Indicate the tool type currently attached to the HMR. */
  readonly hmrToolType?:
    | Peripherals_HMRToolType
    | undefined;
  /**
   * Indicate the generation of the HMR.
   * The type of generation can be identified by the serial number behind the HMR.
   */
  readonly hmrGen?:
    | Peripherals_HMRGen
    | undefined;
  /**
   * The state of Audio Latency Toolkit
   * See UFS peripherals.proto or go/teensy-in-audiobox-proposal for more context.
   */
  readonly audioLatencyToolkitState?:
    | PeripheralState
    | undefined;
  /**
   * The components supported by the PASIT testbed. This is a list of all of the
   * components in the testbed and their count e.g. "DOCK-1, MONITOR-1, MONITOR-2..."
   */
  readonly pasitComponents: readonly string[];
  /**
   * The state of Intel AMT management.
   * See UFS peripherals.proto or go/flex-amt-enabled-repair for more context.
   */
  readonly amtManagerState?:
    | PeripheralState
    | undefined;
  /** The type of Audio Beamforming on the DUT. */
  readonly audioBeamforming?:
    | string
    | undefined;
  /** The state of camera on the DUT. */
  readonly cameraState?:
    | HardwareState
    | undefined;
  /**
   * Features supported by the available SIMs on the DUT.
   * These are extracted from the underlying SIMProfileInfo and merged together.
   * See go/cros-cellular-features for more information.
   * File bugs against buganizer component: 979102.
   */
  readonly simFeatures: readonly Peripherals_SIMFeature[];
}

/** NEXT TAG: 12 */
export enum Peripherals_ChameleonType {
  CHAMELEON_TYPE_INVALID = 0,
  CHAMELEON_TYPE_DP = 2,
  /** @deprecated */
  CHAMELEON_TYPE_DP_HDMI = 3,
  /** @deprecated */
  CHAMELEON_TYPE_VGA = 4,
  CHAMELEON_TYPE_HDMI = 5,
  CHAMELEON_TYPE_V2 = 9,
  CHAMELEON_TYPE_V3 = 10,
  CHAMELEON_TYPE_RPI = 11,
}

export function peripherals_ChameleonTypeFromJSON(object: any): Peripherals_ChameleonType {
  switch (object) {
    case 0:
    case "CHAMELEON_TYPE_INVALID":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_INVALID;
    case 2:
    case "CHAMELEON_TYPE_DP":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_DP;
    case 3:
    case "CHAMELEON_TYPE_DP_HDMI":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_DP_HDMI;
    case 4:
    case "CHAMELEON_TYPE_VGA":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_VGA;
    case 5:
    case "CHAMELEON_TYPE_HDMI":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_HDMI;
    case 9:
    case "CHAMELEON_TYPE_V2":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_V2;
    case 10:
    case "CHAMELEON_TYPE_V3":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_V3;
    case 11:
    case "CHAMELEON_TYPE_RPI":
      return Peripherals_ChameleonType.CHAMELEON_TYPE_RPI;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_ChameleonType");
  }
}

export function peripherals_ChameleonTypeToJSON(object: Peripherals_ChameleonType): string {
  switch (object) {
    case Peripherals_ChameleonType.CHAMELEON_TYPE_INVALID:
      return "CHAMELEON_TYPE_INVALID";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_DP:
      return "CHAMELEON_TYPE_DP";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_DP_HDMI:
      return "CHAMELEON_TYPE_DP_HDMI";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_VGA:
      return "CHAMELEON_TYPE_VGA";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_HDMI:
      return "CHAMELEON_TYPE_HDMI";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_V2:
      return "CHAMELEON_TYPE_V2";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_V3:
      return "CHAMELEON_TYPE_V3";
    case Peripherals_ChameleonType.CHAMELEON_TYPE_RPI:
      return "CHAMELEON_TYPE_RPI";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_ChameleonType");
  }
}

export enum Peripherals_ChameleonConnectionType {
  CHAMELEON_CONNECTION_TYPE_INVALID = 0,
  CHAMELEON_CONNECTION_TYPE_AUDIOJACK = 1,
  CHAMELEON_CONNECTION_TYPE_USB = 2,
  CHAMELEON_CONNECTION_TYPE_HDMI = 3,
  CHAMELEON_CONNECTION_TYPE_DP = 4,
}

export function peripherals_ChameleonConnectionTypeFromJSON(object: any): Peripherals_ChameleonConnectionType {
  switch (object) {
    case 0:
    case "CHAMELEON_CONNECTION_TYPE_INVALID":
      return Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_INVALID;
    case 1:
    case "CHAMELEON_CONNECTION_TYPE_AUDIOJACK":
      return Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_AUDIOJACK;
    case 2:
    case "CHAMELEON_CONNECTION_TYPE_USB":
      return Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_USB;
    case 3:
    case "CHAMELEON_CONNECTION_TYPE_HDMI":
      return Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_HDMI;
    case 4:
    case "CHAMELEON_CONNECTION_TYPE_DP":
      return Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_DP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_ChameleonConnectionType");
  }
}

export function peripherals_ChameleonConnectionTypeToJSON(object: Peripherals_ChameleonConnectionType): string {
  switch (object) {
    case Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_INVALID:
      return "CHAMELEON_CONNECTION_TYPE_INVALID";
    case Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_AUDIOJACK:
      return "CHAMELEON_CONNECTION_TYPE_AUDIOJACK";
    case Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_USB:
      return "CHAMELEON_CONNECTION_TYPE_USB";
    case Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_HDMI:
      return "CHAMELEON_CONNECTION_TYPE_HDMI";
    case Peripherals_ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_DP:
      return "CHAMELEON_CONNECTION_TYPE_DP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_ChameleonConnectionType");
  }
}

/**
 * copy from chameleon.proto
 * NEXT TAG: 4
 */
export enum Peripherals_AudioBoxJackPlugger {
  AUDIOBOX_JACKPLUGGER_UNSPECIFIED = 0,
  AUDIOBOX_JACKPLUGGER_WORKING = 1,
  AUDIOBOX_JACKPLUGGER_BROKEN = 2,
  AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE = 3,
}

export function peripherals_AudioBoxJackPluggerFromJSON(object: any): Peripherals_AudioBoxJackPlugger {
  switch (object) {
    case 0:
    case "AUDIOBOX_JACKPLUGGER_UNSPECIFIED":
      return Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_UNSPECIFIED;
    case 1:
    case "AUDIOBOX_JACKPLUGGER_WORKING":
      return Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_WORKING;
    case 2:
    case "AUDIOBOX_JACKPLUGGER_BROKEN":
      return Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_BROKEN;
    case 3:
    case "AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE":
      return Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_AudioBoxJackPlugger");
  }
}

export function peripherals_AudioBoxJackPluggerToJSON(object: Peripherals_AudioBoxJackPlugger): string {
  switch (object) {
    case Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_UNSPECIFIED:
      return "AUDIOBOX_JACKPLUGGER_UNSPECIFIED";
    case Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_WORKING:
      return "AUDIOBOX_JACKPLUGGER_WORKING";
    case Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_BROKEN:
      return "AUDIOBOX_JACKPLUGGER_BROKEN";
    case Peripherals_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE:
      return "AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_AudioBoxJackPlugger");
  }
}

/**
 * copy from peripheral.proto
 * NEXT TAG: 3
 */
export enum Peripherals_TRRSType {
  TRRS_TYPE_UNSPECIFIED = 0,
  TRRS_TYPE_CTIA = 1,
  TRRS_TYPE_OMTP = 2,
}

export function peripherals_TRRSTypeFromJSON(object: any): Peripherals_TRRSType {
  switch (object) {
    case 0:
    case "TRRS_TYPE_UNSPECIFIED":
      return Peripherals_TRRSType.TRRS_TYPE_UNSPECIFIED;
    case 1:
    case "TRRS_TYPE_CTIA":
      return Peripherals_TRRSType.TRRS_TYPE_CTIA;
    case 2:
    case "TRRS_TYPE_OMTP":
      return Peripherals_TRRSType.TRRS_TYPE_OMTP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_TRRSType");
  }
}

export function peripherals_TRRSTypeToJSON(object: Peripherals_TRRSType): string {
  switch (object) {
    case Peripherals_TRRSType.TRRS_TYPE_UNSPECIFIED:
      return "TRRS_TYPE_UNSPECIFIED";
    case Peripherals_TRRSType.TRRS_TYPE_CTIA:
      return "TRRS_TYPE_CTIA";
    case Peripherals_TRRSType.TRRS_TYPE_OMTP:
      return "TRRS_TYPE_OMTP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_TRRSType");
  }
}

/**
 * Facing of DUT's camera to be tested whose FOV should cover chart tablet's
 * screen in camerabox setup.
 * NEXT TAG: 4
 */
export enum Peripherals_CameraboxFacing {
  CAMERABOX_FACING_UNKNOWN = 0,
  CAMERABOX_FACING_BACK = 1,
  CAMERABOX_FACING_FRONT = 2,
  CAMERABOX_FACING_NOCAMERA = 3,
}

export function peripherals_CameraboxFacingFromJSON(object: any): Peripherals_CameraboxFacing {
  switch (object) {
    case 0:
    case "CAMERABOX_FACING_UNKNOWN":
      return Peripherals_CameraboxFacing.CAMERABOX_FACING_UNKNOWN;
    case 1:
    case "CAMERABOX_FACING_BACK":
      return Peripherals_CameraboxFacing.CAMERABOX_FACING_BACK;
    case 2:
    case "CAMERABOX_FACING_FRONT":
      return Peripherals_CameraboxFacing.CAMERABOX_FACING_FRONT;
    case 3:
    case "CAMERABOX_FACING_NOCAMERA":
      return Peripherals_CameraboxFacing.CAMERABOX_FACING_NOCAMERA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_CameraboxFacing");
  }
}

export function peripherals_CameraboxFacingToJSON(object: Peripherals_CameraboxFacing): string {
  switch (object) {
    case Peripherals_CameraboxFacing.CAMERABOX_FACING_UNKNOWN:
      return "CAMERABOX_FACING_UNKNOWN";
    case Peripherals_CameraboxFacing.CAMERABOX_FACING_BACK:
      return "CAMERABOX_FACING_BACK";
    case Peripherals_CameraboxFacing.CAMERABOX_FACING_FRONT:
      return "CAMERABOX_FACING_FRONT";
    case Peripherals_CameraboxFacing.CAMERABOX_FACING_NOCAMERA:
      return "CAMERABOX_FACING_NOCAMERA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_CameraboxFacing");
  }
}

/**
 * External light source in the camerabox, LED or NOLED.
 * NEXT TAG: 3
 */
export enum Peripherals_CameraboxLight {
  CAMERABOX_LIGHT_UNKNOWN = 0,
  CAMERABOX_LIGHT_LED = 1,
  CAMERABOX_LIGHT_NOLED = 2,
}

export function peripherals_CameraboxLightFromJSON(object: any): Peripherals_CameraboxLight {
  switch (object) {
    case 0:
    case "CAMERABOX_LIGHT_UNKNOWN":
      return Peripherals_CameraboxLight.CAMERABOX_LIGHT_UNKNOWN;
    case 1:
    case "CAMERABOX_LIGHT_LED":
      return Peripherals_CameraboxLight.CAMERABOX_LIGHT_LED;
    case 2:
    case "CAMERABOX_LIGHT_NOLED":
      return Peripherals_CameraboxLight.CAMERABOX_LIGHT_NOLED;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_CameraboxLight");
  }
}

export function peripherals_CameraboxLightToJSON(object: Peripherals_CameraboxLight): string {
  switch (object) {
    case Peripherals_CameraboxLight.CAMERABOX_LIGHT_UNKNOWN:
      return "CAMERABOX_LIGHT_UNKNOWN";
    case Peripherals_CameraboxLight.CAMERABOX_LIGHT_LED:
      return "CAMERABOX_LIGHT_LED";
    case Peripherals_CameraboxLight.CAMERABOX_LIGHT_NOLED:
      return "CAMERABOX_LIGHT_NOLED";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_CameraboxLight");
  }
}

/**
 * Copy of chromiumos.test.lab.api.WifiRouterFeature.
 *
 * Recognized hardware and software features of WiFi router peripherals in
 * our testbeds.
 *
 * This is not an exhaustive list of features supported by these test WiFi
 * routers. The intent is to only track features that may differ between the
 * different types of WiFi router devices in our labs.
 */
export enum Peripherals_WifiRouterFeature {
  /** WIFI_ROUTER_FEATURE_UNKNOWN - Features are not known. */
  WIFI_ROUTER_FEATURE_UNKNOWN = 0,
  /** WIFI_ROUTER_FEATURE_INVALID - Feature was parsed from device, but it did not match any known features. */
  WIFI_ROUTER_FEATURE_INVALID = 1,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_A - WiFi 1 (IEEE 802.11a) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_A = 2,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_B - WiFi 2 (IEEE 802.11b) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_B = 3,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_G - WiFi 3 (IEEE 802.11g) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_G = 4,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_N - WiFi 4 (IEEE 802.11n) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_N = 5,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AC - WiFi 5 (IEEE 802.11ac) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AC = 6,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AX - WiFi 6 (IEEE 802.11ax, 2.4GHz, 5GHz) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AX = 7,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E - WiFi 6E (IEEE 802.11ax, 6GHz) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E = 8,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_BE - WiFi 7 (IEEE 802.11be) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_BE = 9,
  /** WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH - The CPU is fast enough to support a double bridge over veth. */
  WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH = 10,
  /** WIFI_ROUTER_FEATURE_GCMP - GCMP and GCMP-256 pairwise and group cipher support. */
  WIFI_ROUTER_FEATURE_GCMP = 11,
  /** WIFI_ROUTER_FEATURE_SAE_EXT_KEY - SAE-EXT-KEY support for AKM-24/25. */
  WIFI_ROUTER_FEATURE_SAE_EXT_KEY = 12,
}

export function peripherals_WifiRouterFeatureFromJSON(object: any): Peripherals_WifiRouterFeature {
  switch (object) {
    case 0:
    case "WIFI_ROUTER_FEATURE_UNKNOWN":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_UNKNOWN;
    case 1:
    case "WIFI_ROUTER_FEATURE_INVALID":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_INVALID;
    case 2:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_A":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_A;
    case 3:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_B":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_B;
    case 4:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_G":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_G;
    case 5:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_N":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_N;
    case 6:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AC":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AC;
    case 7:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AX":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX;
    case 8:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E;
    case 9:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_BE":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_BE;
    case 10:
    case "WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH;
    case 11:
    case "WIFI_ROUTER_FEATURE_GCMP":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_GCMP;
    case 12:
    case "WIFI_ROUTER_FEATURE_SAE_EXT_KEY":
      return Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_SAE_EXT_KEY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_WifiRouterFeature");
  }
}

export function peripherals_WifiRouterFeatureToJSON(object: Peripherals_WifiRouterFeature): string {
  switch (object) {
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_UNKNOWN:
      return "WIFI_ROUTER_FEATURE_UNKNOWN";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_INVALID:
      return "WIFI_ROUTER_FEATURE_INVALID";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_A:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_A";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_B:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_B";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_G:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_G";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_N:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_N";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AC:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AC";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AX";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_BE:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_BE";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH:
      return "WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_GCMP:
      return "WIFI_ROUTER_FEATURE_GCMP";
    case Peripherals_WifiRouterFeature.WIFI_ROUTER_FEATURE_SAE_EXT_KEY:
      return "WIFI_ROUTER_FEATURE_SAE_EXT_KEY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_WifiRouterFeature");
  }
}

/**
 * HMRToolType describe the enum of types of tool on HMR.
 * Next Tag: 3
 */
export enum Peripherals_HMRToolType {
  HMR_TOOL_TYPE_UNKNOWN = 0,
  HMR_TOOL_TYPE_STYLUS = 1,
  HMR_TOOL_TYPE_FAKE_FINGER = 2,
}

export function peripherals_HMRToolTypeFromJSON(object: any): Peripherals_HMRToolType {
  switch (object) {
    case 0:
    case "HMR_TOOL_TYPE_UNKNOWN":
      return Peripherals_HMRToolType.HMR_TOOL_TYPE_UNKNOWN;
    case 1:
    case "HMR_TOOL_TYPE_STYLUS":
      return Peripherals_HMRToolType.HMR_TOOL_TYPE_STYLUS;
    case 2:
    case "HMR_TOOL_TYPE_FAKE_FINGER":
      return Peripherals_HMRToolType.HMR_TOOL_TYPE_FAKE_FINGER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_HMRToolType");
  }
}

export function peripherals_HMRToolTypeToJSON(object: Peripherals_HMRToolType): string {
  switch (object) {
    case Peripherals_HMRToolType.HMR_TOOL_TYPE_UNKNOWN:
      return "HMR_TOOL_TYPE_UNKNOWN";
    case Peripherals_HMRToolType.HMR_TOOL_TYPE_STYLUS:
      return "HMR_TOOL_TYPE_STYLUS";
    case Peripherals_HMRToolType.HMR_TOOL_TYPE_FAKE_FINGER:
      return "HMR_TOOL_TYPE_FAKE_FINGER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_HMRToolType");
  }
}

/**
 * HMRGen describe the enum of generation of the HMR.
 * Next Tag: 3
 */
export enum Peripherals_HMRGen {
  HMR_GEN_UNKNOWN = 0,
  HMR_GEN_1 = 1,
  HMR_GEN_2 = 2,
}

export function peripherals_HMRGenFromJSON(object: any): Peripherals_HMRGen {
  switch (object) {
    case 0:
    case "HMR_GEN_UNKNOWN":
      return Peripherals_HMRGen.HMR_GEN_UNKNOWN;
    case 1:
    case "HMR_GEN_1":
      return Peripherals_HMRGen.HMR_GEN_1;
    case 2:
    case "HMR_GEN_2":
      return Peripherals_HMRGen.HMR_GEN_2;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_HMRGen");
  }
}

export function peripherals_HMRGenToJSON(object: Peripherals_HMRGen): string {
  switch (object) {
    case Peripherals_HMRGen.HMR_GEN_UNKNOWN:
      return "HMR_GEN_UNKNOWN";
    case Peripherals_HMRGen.HMR_GEN_1:
      return "HMR_GEN_1";
    case Peripherals_HMRGen.HMR_GEN_2:
      return "HMR_GEN_2";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_HMRGen");
  }
}

/** Possible features that the SIM supports. */
export enum Peripherals_SIMFeature {
  /** SIM_FEATURE_UNSPECIFIED - Unset feature. */
  SIM_FEATURE_UNSPECIFIED = 0,
  /** SIM_FEATURE_LIVE_NETWORK - The SIM supports a generic live network. */
  SIM_FEATURE_LIVE_NETWORK = 1,
  /** SIM_FEATURE_SMS - The SIM supports SMS messaging. */
  SIM_FEATURE_SMS = 2,
}

export function peripherals_SIMFeatureFromJSON(object: any): Peripherals_SIMFeature {
  switch (object) {
    case 0:
    case "SIM_FEATURE_UNSPECIFIED":
      return Peripherals_SIMFeature.SIM_FEATURE_UNSPECIFIED;
    case 1:
    case "SIM_FEATURE_LIVE_NETWORK":
      return Peripherals_SIMFeature.SIM_FEATURE_LIVE_NETWORK;
    case 2:
    case "SIM_FEATURE_SMS":
      return Peripherals_SIMFeature.SIM_FEATURE_SMS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_SIMFeature");
  }
}

export function peripherals_SIMFeatureToJSON(object: Peripherals_SIMFeature): string {
  switch (object) {
    case Peripherals_SIMFeature.SIM_FEATURE_UNSPECIFIED:
      return "SIM_FEATURE_UNSPECIFIED";
    case Peripherals_SIMFeature.SIM_FEATURE_LIVE_NETWORK:
      return "SIM_FEATURE_LIVE_NETWORK";
    case Peripherals_SIMFeature.SIM_FEATURE_SMS:
      return "SIM_FEATURE_SMS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Peripherals_SIMFeature");
  }
}

/**
 * Copy from servo.proto
 * Servo Topology describe connected servo devices on DUT set-up to provide Servo functionality.
 * Next Tag : 3
 */
export interface ServoTopology {
  readonly main?: ServoTopologyItem | undefined;
  readonly children: readonly ServoTopologyItem[];
}

/**
 * Servo Topology Item describe details of one servo device on DUT set-up.
 * Next Tag : 6
 */
export interface ServoTopologyItem {
  /**
   * type provides the type of servo device. Keeping as String to avoid issue
   * with introduce new type.
   */
  readonly type?:
    | string
    | undefined;
  /**
   * sysfs_product provides the product name of the device recorded in File
   * System.
   */
  readonly sysfsProduct?:
    | string
    | undefined;
  /** serial provides the serial number of the device. */
  readonly serial?:
    | string
    | undefined;
  /**
   * usb_hub_port provides the port connection to the device.
   * e.g. '1-6.2.2' where
   *   '1-6'  - port on the labstation
   *   '2'    - port on smart-hub connected to the labstation
   *   '2'    - port on servo hub (part of servo_v4 or servo_v4.1) connected to
   *   the smart-hub
   * The same path will look '1-6.2' if connected servo_v4 directly to the
   * labstation.
   */
  readonly usbHubPort?:
    | string
    | undefined;
  /** This is the firmware version of servo device. */
  readonly fwVersion?: string | undefined;
}

/**
 * These labels are used by the CI system to provide test coverage hints to
 * Skylab.
 *
 * TODO(pprabhu) Most of these should instead be self-servo pools.
 * Migrate the test requests to use pools and then deprecate these fields in
 * favor of pools.
 *
 * Keep sorted by field name.
 *
 * NEXT TAG: 14
 */
export interface TestCoverageHints {
  readonly chaosDut?: boolean | undefined;
  readonly chaosNightly?: boolean | undefined;
  readonly chromesign?: boolean | undefined;
  readonly ctsSparse: readonly TestCoverageHints_CTSSparse[];
  /**
   * Whether the DUT has the hangout app installed.
   * This is a software feature of the DUT that is not provisioned as part of
   * the test setup, hence the DUT must be labelled explicitly as supporting
   * this feature.  Should probably be a SoftwareFeature for the DUT.
   */
  readonly hangoutApp?:
    | boolean
    | undefined;
  /**
   * Whether the DUT has the meet app installed.
   * This is a software feature of the DUT that is not provisioned as part of
   * the test setup, hence the DUT must be labelled explicitly as supporting
   * this feature.  Should probably be a SoftwareFeature for the DUT.
   */
  readonly meetApp?: boolean | undefined;
  readonly recoveryTest?: boolean | undefined;
  readonly testAudiojack?: boolean | undefined;
  readonly testHdmiaudio?: boolean | undefined;
  readonly testUsbaudio?: boolean | undefined;
  readonly testUsbprinting?: boolean | undefined;
  readonly usbDetect?: boolean | undefined;
  readonly useLid?: boolean | undefined;
}

/**
 * Related to sparse CTS testing. See b/70309087
 * NEXT TAG: 4
 */
export enum TestCoverageHints_CTSSparse {
  CTS_SPARSE_INVALID = 0,
  CTS_SPARSE_2 = 1,
  CTS_SPARSE_3 = 2,
  CTS_SPARSE_5 = 3,
}

export function testCoverageHints_CTSSparseFromJSON(object: any): TestCoverageHints_CTSSparse {
  switch (object) {
    case 0:
    case "CTS_SPARSE_INVALID":
      return TestCoverageHints_CTSSparse.CTS_SPARSE_INVALID;
    case 1:
    case "CTS_SPARSE_2":
      return TestCoverageHints_CTSSparse.CTS_SPARSE_2;
    case 2:
    case "CTS_SPARSE_3":
      return TestCoverageHints_CTSSparse.CTS_SPARSE_3;
    case 3:
    case "CTS_SPARSE_5":
      return TestCoverageHints_CTSSparse.CTS_SPARSE_5;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TestCoverageHints_CTSSparse");
  }
}

export function testCoverageHints_CTSSparseToJSON(object: TestCoverageHints_CTSSparse): string {
  switch (object) {
    case TestCoverageHints_CTSSparse.CTS_SPARSE_INVALID:
      return "CTS_SPARSE_INVALID";
    case TestCoverageHints_CTSSparse.CTS_SPARSE_2:
      return "CTS_SPARSE_2";
    case TestCoverageHints_CTSSparse.CTS_SPARSE_3:
      return "CTS_SPARSE_3";
    case TestCoverageHints_CTSSparse.CTS_SPARSE_5:
      return "CTS_SPARSE_5";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum TestCoverageHints_CTSSparse");
  }
}

/** NEXT TAG: 3 */
export interface KeyValue {
  readonly key: string;
  readonly value?: string | undefined;
}

/**
 * Represents a Software License assigned to a device.
 * Next Tag: 2
 */
export interface License {
  /** The type of licenses (e.g. Windows) */
  readonly type?:
    | LicenseType
    | undefined;
  /**
   * An optional string to uniquely identify the license that was assigned,
   * for tracking purposes.
   */
  readonly identifier?: string | undefined;
}

/**
 * ModemInfo
 * Next Tag: 6
 */
export interface ModemInfo {
  readonly type?:
    | ModemType
    | undefined;
  /** Equipment Identifier */
  readonly imei?:
    | string
    | undefined;
  /** Comma separated band numbers */
  readonly supportedBands?:
    | string
    | undefined;
  /** Number of SIM's present */
  readonly simCount?:
    | number
    | undefined;
  /** The device model cellular sub-variant. */
  readonly modelVariant?: string | undefined;
}

/** Next Tag: 6 */
export interface SIMInfo {
  readonly slotId?: number | undefined;
  readonly type?: SIMType | undefined;
  readonly eid?: string | undefined;
  readonly testEsim?: boolean | undefined;
  readonly profileInfo: readonly SIMProfileInfo[];
}

/** Next Tag: 6 */
export interface SIMProfileInfo {
  readonly iccid?: string | undefined;
  readonly simPin?: string | undefined;
  readonly simPuk?: string | undefined;
  readonly carrierName?:
    | NetworkProvider
    | undefined;
  /** Carrier provided number */
  readonly ownNumber?: string | undefined;
}

function createBaseDevice(): Device {
  return { dut: undefined, servoHost: undefined, chameleon: undefined };
}

export const Device: MessageFns<Device> = {
  encode(message: Device, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.dut !== undefined) {
      DeviceUnderTest.encode(message.dut, writer.uint32(10).fork()).join();
    }
    if (message.servoHost !== undefined) {
      ServoHostDevice.encode(message.servoHost, writer.uint32(18).fork()).join();
    }
    if (message.chameleon !== undefined) {
      ChameleonDevice.encode(message.chameleon, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Device {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.dut = DeviceUnderTest.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.servoHost = ServoHostDevice.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.chameleon = ChameleonDevice.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Device {
    return {
      dut: isSet(object.dut) ? DeviceUnderTest.fromJSON(object.dut) : undefined,
      servoHost: isSet(object.servoHost) ? ServoHostDevice.fromJSON(object.servoHost) : undefined,
      chameleon: isSet(object.chameleon) ? ChameleonDevice.fromJSON(object.chameleon) : undefined,
    };
  },

  toJSON(message: Device): unknown {
    const obj: any = {};
    if (message.dut !== undefined) {
      obj.dut = DeviceUnderTest.toJSON(message.dut);
    }
    if (message.servoHost !== undefined) {
      obj.servoHost = ServoHostDevice.toJSON(message.servoHost);
    }
    if (message.chameleon !== undefined) {
      obj.chameleon = ChameleonDevice.toJSON(message.chameleon);
    }
    return obj;
  },

  create(base?: DeepPartial<Device>): Device {
    return Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Device>): Device {
    const message = createBaseDevice() as any;
    message.dut = (object.dut !== undefined && object.dut !== null)
      ? DeviceUnderTest.fromPartial(object.dut)
      : undefined;
    message.servoHost = (object.servoHost !== undefined && object.servoHost !== null)
      ? ServoHostDevice.fromPartial(object.servoHost)
      : undefined;
    message.chameleon = (object.chameleon !== undefined && object.chameleon !== null)
      ? ChameleonDevice.fromPartial(object.chameleon)
      : undefined;
    return message;
  },
};

function createBaseDeviceUnderTest(): DeviceUnderTest {
  return { common: undefined, removalReason: undefined };
}

export const DeviceUnderTest: MessageFns<DeviceUnderTest> = {
  encode(message: DeviceUnderTest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.common !== undefined) {
      CommonDeviceSpecs.encode(message.common, writer.uint32(10).fork()).join();
    }
    if (message.removalReason !== undefined) {
      RemovalReason.encode(message.removalReason, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceUnderTest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceUnderTest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.common = CommonDeviceSpecs.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.removalReason = RemovalReason.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceUnderTest {
    return {
      common: isSet(object.common) ? CommonDeviceSpecs.fromJSON(object.common) : undefined,
      removalReason: isSet(object.removalReason) ? RemovalReason.fromJSON(object.removalReason) : undefined,
    };
  },

  toJSON(message: DeviceUnderTest): unknown {
    const obj: any = {};
    if (message.common !== undefined) {
      obj.common = CommonDeviceSpecs.toJSON(message.common);
    }
    if (message.removalReason !== undefined) {
      obj.removalReason = RemovalReason.toJSON(message.removalReason);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceUnderTest>): DeviceUnderTest {
    return DeviceUnderTest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceUnderTest>): DeviceUnderTest {
    const message = createBaseDeviceUnderTest() as any;
    message.common = (object.common !== undefined && object.common !== null)
      ? CommonDeviceSpecs.fromPartial(object.common)
      : undefined;
    message.removalReason = (object.removalReason !== undefined && object.removalReason !== null)
      ? RemovalReason.fromPartial(object.removalReason)
      : undefined;
    return message;
  },
};

function createBaseServoHostDevice(): ServoHostDevice {
  return { common: undefined, version: "" };
}

export const ServoHostDevice: MessageFns<ServoHostDevice> = {
  encode(message: ServoHostDevice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.common !== undefined) {
      CommonDeviceSpecs.encode(message.common, writer.uint32(10).fork()).join();
    }
    if (message.version !== undefined && message.version !== "") {
      writer.uint32(18).string(message.version);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoHostDevice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoHostDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.common = CommonDeviceSpecs.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.version = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoHostDevice {
    return {
      common: isSet(object.common) ? CommonDeviceSpecs.fromJSON(object.common) : undefined,
      version: isSet(object.version) ? globalThis.String(object.version) : "",
    };
  },

  toJSON(message: ServoHostDevice): unknown {
    const obj: any = {};
    if (message.common !== undefined) {
      obj.common = CommonDeviceSpecs.toJSON(message.common);
    }
    if (message.version !== undefined && message.version !== "") {
      obj.version = message.version;
    }
    return obj;
  },

  create(base?: DeepPartial<ServoHostDevice>): ServoHostDevice {
    return ServoHostDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ServoHostDevice>): ServoHostDevice {
    const message = createBaseServoHostDevice() as any;
    message.common = (object.common !== undefined && object.common !== null)
      ? CommonDeviceSpecs.fromPartial(object.common)
      : undefined;
    message.version = object.version ?? "";
    return message;
  },
};

function createBaseChameleonDevice(): ChameleonDevice {
  return { common: undefined };
}

export const ChameleonDevice: MessageFns<ChameleonDevice> = {
  encode(message: ChameleonDevice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.common !== undefined) {
      CommonDeviceSpecs.encode(message.common, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChameleonDevice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChameleonDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.common = CommonDeviceSpecs.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChameleonDevice {
    return { common: isSet(object.common) ? CommonDeviceSpecs.fromJSON(object.common) : undefined };
  },

  toJSON(message: ChameleonDevice): unknown {
    const obj: any = {};
    if (message.common !== undefined) {
      obj.common = CommonDeviceSpecs.toJSON(message.common);
    }
    return obj;
  },

  create(base?: DeepPartial<ChameleonDevice>): ChameleonDevice {
    return ChameleonDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChameleonDevice>): ChameleonDevice {
    const message = createBaseChameleonDevice() as any;
    message.common = (object.common !== undefined && object.common !== null)
      ? CommonDeviceSpecs.fromPartial(object.common)
      : undefined;
    return message;
  },
};

function createBaseCommonDeviceSpecs(): CommonDeviceSpecs {
  return {
    attributes: [],
    deviceLocks: [],
    environment: 0,
    hostname: "",
    hwid: "",
    id: "",
    labels: undefined,
    location: undefined,
    ownerShard: undefined,
    serialNumber: "",
    uselessSwitch: false,
  };
}

export const CommonDeviceSpecs: MessageFns<CommonDeviceSpecs> = {
  encode(message: CommonDeviceSpecs, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.attributes) {
      KeyValue.encode(v!, writer.uint32(74).fork()).join();
    }
    for (const v of message.deviceLocks) {
      DeviceLock.encode(v!, writer.uint32(50).fork()).join();
    }
    if (message.environment !== undefined && message.environment !== 0) {
      writer.uint32(80).int32(message.environment);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.hwid !== undefined && message.hwid !== "") {
      writer.uint32(26).string(message.hwid);
    }
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.labels !== undefined) {
      SchedulableLabels.encode(message.labels, writer.uint32(66).fork()).join();
    }
    if (message.location !== undefined) {
      Location.encode(message.location, writer.uint32(42).fork()).join();
    }
    if (message.ownerShard !== undefined) {
      Shard.encode(message.ownerShard, writer.uint32(58).fork()).join();
    }
    if (message.serialNumber !== undefined && message.serialNumber !== "") {
      writer.uint32(34).string(message.serialNumber);
    }
    if (message.uselessSwitch !== undefined && message.uselessSwitch !== false) {
      writer.uint32(88).bool(message.uselessSwitch);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CommonDeviceSpecs {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCommonDeviceSpecs() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.attributes.push(KeyValue.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.deviceLocks.push(DeviceLock.decode(reader, reader.uint32()));
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.environment = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.hwid = reader.string();
          continue;
        }
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.labels = SchedulableLabels.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.location = Location.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.ownerShard = Shard.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.uselessSwitch = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CommonDeviceSpecs {
    return {
      attributes: globalThis.Array.isArray(object?.attributes)
        ? object.attributes.map((e: any) => KeyValue.fromJSON(e))
        : [],
      deviceLocks: globalThis.Array.isArray(object?.deviceLocks)
        ? object.deviceLocks.map((e: any) => DeviceLock.fromJSON(e))
        : [],
      environment: isSet(object.environment) ? environmentFromJSON(object.environment) : 0,
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      hwid: isSet(object.hwid) ? globalThis.String(object.hwid) : "",
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      labels: isSet(object.labels) ? SchedulableLabels.fromJSON(object.labels) : undefined,
      location: isSet(object.location) ? Location.fromJSON(object.location) : undefined,
      ownerShard: isSet(object.ownerShard) ? Shard.fromJSON(object.ownerShard) : undefined,
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      uselessSwitch: isSet(object.uselessSwitch) ? globalThis.Boolean(object.uselessSwitch) : false,
    };
  },

  toJSON(message: CommonDeviceSpecs): unknown {
    const obj: any = {};
    if (message.attributes?.length) {
      obj.attributes = message.attributes.map((e) => KeyValue.toJSON(e));
    }
    if (message.deviceLocks?.length) {
      obj.deviceLocks = message.deviceLocks.map((e) => DeviceLock.toJSON(e));
    }
    if (message.environment !== undefined && message.environment !== 0) {
      obj.environment = environmentToJSON(message.environment);
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.hwid !== undefined && message.hwid !== "") {
      obj.hwid = message.hwid;
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.labels !== undefined) {
      obj.labels = SchedulableLabels.toJSON(message.labels);
    }
    if (message.location !== undefined) {
      obj.location = Location.toJSON(message.location);
    }
    if (message.ownerShard !== undefined) {
      obj.ownerShard = Shard.toJSON(message.ownerShard);
    }
    if (message.serialNumber !== undefined && message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.uselessSwitch !== undefined && message.uselessSwitch !== false) {
      obj.uselessSwitch = message.uselessSwitch;
    }
    return obj;
  },

  create(base?: DeepPartial<CommonDeviceSpecs>): CommonDeviceSpecs {
    return CommonDeviceSpecs.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CommonDeviceSpecs>): CommonDeviceSpecs {
    const message = createBaseCommonDeviceSpecs() as any;
    message.attributes = object.attributes?.map((e) => KeyValue.fromPartial(e)) || [];
    message.deviceLocks = object.deviceLocks?.map((e) => DeviceLock.fromPartial(e)) || [];
    message.environment = object.environment ?? 0;
    message.hostname = object.hostname ?? "";
    message.hwid = object.hwid ?? "";
    message.id = object.id ?? "";
    message.labels = (object.labels !== undefined && object.labels !== null)
      ? SchedulableLabels.fromPartial(object.labels)
      : undefined;
    message.location = (object.location !== undefined && object.location !== null)
      ? Location.fromPartial(object.location)
      : undefined;
    message.ownerShard = (object.ownerShard !== undefined && object.ownerShard !== null)
      ? Shard.fromPartial(object.ownerShard)
      : undefined;
    message.serialNumber = object.serialNumber ?? "";
    message.uselessSwitch = object.uselessSwitch ?? false;
    return message;
  },
};

function createBaseRemovalReason(): RemovalReason {
  return { bug: "", comment: "", expireTime: undefined };
}

export const RemovalReason: MessageFns<RemovalReason> = {
  encode(message: RemovalReason, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.bug !== undefined && message.bug !== "") {
      writer.uint32(10).string(message.bug);
    }
    if (message.comment !== undefined && message.comment !== "") {
      writer.uint32(18).string(message.comment);
    }
    if (message.expireTime !== undefined) {
      Timestamp.encode(message.expireTime, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): RemovalReason {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemovalReason() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.bug = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.comment = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.expireTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): RemovalReason {
    return {
      bug: isSet(object.bug) ? globalThis.String(object.bug) : "",
      comment: isSet(object.comment) ? globalThis.String(object.comment) : "",
      expireTime: isSet(object.expireTime) ? Timestamp.fromJSON(object.expireTime) : undefined,
    };
  },

  toJSON(message: RemovalReason): unknown {
    const obj: any = {};
    if (message.bug !== undefined && message.bug !== "") {
      obj.bug = message.bug;
    }
    if (message.comment !== undefined && message.comment !== "") {
      obj.comment = message.comment;
    }
    if (message.expireTime !== undefined) {
      obj.expireTime = Timestamp.toJSON(message.expireTime);
    }
    return obj;
  },

  create(base?: DeepPartial<RemovalReason>): RemovalReason {
    return RemovalReason.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<RemovalReason>): RemovalReason {
    const message = createBaseRemovalReason() as any;
    message.bug = object.bug ?? "";
    message.comment = object.comment ?? "";
    message.expireTime = (object.expireTime !== undefined && object.expireTime !== null)
      ? Timestamp.fromPartial(object.expireTime)
      : undefined;
    return message;
  },
};

function createBaseLocation(): Location {
  return { lab: undefined, row: 0, rack: 0, host: 0 };
}

export const Location: MessageFns<Location> = {
  encode(message: Location, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lab !== undefined) {
      PhysicalLab.encode(message.lab, writer.uint32(10).fork()).join();
    }
    if (message.row !== 0) {
      writer.uint32(16).int32(message.row);
    }
    if (message.rack !== 0) {
      writer.uint32(24).int32(message.rack);
    }
    if (message.host !== 0) {
      writer.uint32(32).int32(message.host);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Location {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLocation() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.lab = PhysicalLab.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.row = reader.int32();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.rack = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.host = reader.int32();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Location {
    return {
      lab: isSet(object.lab) ? PhysicalLab.fromJSON(object.lab) : undefined,
      row: isSet(object.row) ? globalThis.Number(object.row) : 0,
      rack: isSet(object.rack) ? globalThis.Number(object.rack) : 0,
      host: isSet(object.host) ? globalThis.Number(object.host) : 0,
    };
  },

  toJSON(message: Location): unknown {
    const obj: any = {};
    if (message.lab !== undefined) {
      obj.lab = PhysicalLab.toJSON(message.lab);
    }
    if (message.row !== 0) {
      obj.row = Math.round(message.row);
    }
    if (message.rack !== 0) {
      obj.rack = Math.round(message.rack);
    }
    if (message.host !== 0) {
      obj.host = Math.round(message.host);
    }
    return obj;
  },

  create(base?: DeepPartial<Location>): Location {
    return Location.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Location>): Location {
    const message = createBaseLocation() as any;
    message.lab = (object.lab !== undefined && object.lab !== null) ? PhysicalLab.fromPartial(object.lab) : undefined;
    message.row = object.row ?? 0;
    message.rack = object.rack ?? 0;
    message.host = object.host ?? 0;
    return message;
  },
};

function createBasePhysicalLab(): PhysicalLab {
  return { name: "" };
}

export const PhysicalLab: MessageFns<PhysicalLab> = {
  encode(message: PhysicalLab, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PhysicalLab {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePhysicalLab() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PhysicalLab {
    return { name: isSet(object.name) ? globalThis.String(object.name) : "" };
  },

  toJSON(message: PhysicalLab): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    return obj;
  },

  create(base?: DeepPartial<PhysicalLab>): PhysicalLab {
    return PhysicalLab.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PhysicalLab>): PhysicalLab {
    const message = createBasePhysicalLab() as any;
    message.name = object.name ?? "";
    return message;
  },
};

function createBaseDeviceLock(): DeviceLock {
  return { lock: false, lockedBy: "", lockReason: "", beginTime: undefined, expireTime: undefined, lockId: 0, id: "" };
}

export const DeviceLock: MessageFns<DeviceLock> = {
  encode(message: DeviceLock, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.lock !== false) {
      writer.uint32(8).bool(message.lock);
    }
    if (message.lockedBy !== "") {
      writer.uint32(18).string(message.lockedBy);
    }
    if (message.lockReason !== "") {
      writer.uint32(26).string(message.lockReason);
    }
    if (message.beginTime !== undefined) {
      Timestamp.encode(message.beginTime, writer.uint32(34).fork()).join();
    }
    if (message.expireTime !== undefined) {
      Timestamp.encode(message.expireTime, writer.uint32(42).fork()).join();
    }
    if (message.lockId !== 0) {
      writer.uint32(48).int32(message.lockId);
    }
    if (message.id !== "") {
      writer.uint32(58).string(message.id);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceLock {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceLock() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.lock = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.lockedBy = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.lockReason = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.beginTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.expireTime = Timestamp.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.lockId = reader.int32();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.id = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceLock {
    return {
      lock: isSet(object.lock) ? globalThis.Boolean(object.lock) : false,
      lockedBy: isSet(object.lockedBy) ? globalThis.String(object.lockedBy) : "",
      lockReason: isSet(object.lockReason) ? globalThis.String(object.lockReason) : "",
      beginTime: isSet(object.beginTime) ? Timestamp.fromJSON(object.beginTime) : undefined,
      expireTime: isSet(object.expireTime) ? Timestamp.fromJSON(object.expireTime) : undefined,
      lockId: isSet(object.lockId) ? globalThis.Number(object.lockId) : 0,
      id: isSet(object.id) ? globalThis.String(object.id) : "",
    };
  },

  toJSON(message: DeviceLock): unknown {
    const obj: any = {};
    if (message.lock !== false) {
      obj.lock = message.lock;
    }
    if (message.lockedBy !== "") {
      obj.lockedBy = message.lockedBy;
    }
    if (message.lockReason !== "") {
      obj.lockReason = message.lockReason;
    }
    if (message.beginTime !== undefined) {
      obj.beginTime = Timestamp.toJSON(message.beginTime);
    }
    if (message.expireTime !== undefined) {
      obj.expireTime = Timestamp.toJSON(message.expireTime);
    }
    if (message.lockId !== 0) {
      obj.lockId = Math.round(message.lockId);
    }
    if (message.id !== "") {
      obj.id = message.id;
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceLock>): DeviceLock {
    return DeviceLock.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceLock>): DeviceLock {
    const message = createBaseDeviceLock() as any;
    message.lock = object.lock ?? false;
    message.lockedBy = object.lockedBy ?? "";
    message.lockReason = object.lockReason ?? "";
    message.beginTime = (object.beginTime !== undefined && object.beginTime !== null)
      ? Timestamp.fromPartial(object.beginTime)
      : undefined;
    message.expireTime = (object.expireTime !== undefined && object.expireTime !== null)
      ? Timestamp.fromPartial(object.expireTime)
      : undefined;
    message.lockId = object.lockId ?? 0;
    message.id = object.id ?? "";
    return message;
  },
};

function createBaseShard(): Shard {
  return { hostname: "" };
}

export const Shard: MessageFns<Shard> = {
  encode(message: Shard, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Shard {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseShard() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Shard {
    return { hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "" };
  },

  toJSON(message: Shard): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    return obj;
  },

  create(base?: DeepPartial<Shard>): Shard {
    return Shard.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Shard>): Shard {
    const message = createBaseShard() as any;
    message.hostname = object.hostname ?? "";
    return message;
  },
};

function createBaseSchedulableLabels(): SchedulableLabels {
  return {
    arc: false,
    board: "",
    brand: "",
    callbox: false,
    capabilities: undefined,
    cr50Phase: 0,
    cr50RoKeyid: "",
    cr50RoVersion: "",
    cr50RwKeyid: "",
    cr50RwVersion: "",
    criticalPools: [],
    ctsAbi: [],
    ctsCpu: [],
    ecType: 0,
    hwidComponent: [],
    hwidSku: "",
    dlmSkuId: "",
    licenses: [],
    model: "",
    modeminfo: undefined,
    sku: "",
    siminfo: [],
    osType: 0,
    peripherals: undefined,
    phase: 0,
    platform: "",
    raccEnabledStatus: "",
    referenceDesign: "",
    selfServePools: [],
    stability: false,
    testCoverageHints: undefined,
    uselessSwitch: false,
    variant: [],
    wifiChip: "",
    devboardType: 0,
    wifiOnSite: false,
    botSize: 0,
  };
}

export const SchedulableLabels: MessageFns<SchedulableLabels> = {
  encode(message: SchedulableLabels, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.arc !== undefined && message.arc !== false) {
      writer.uint32(160).bool(message.arc);
    }
    if (message.board !== undefined && message.board !== "") {
      writer.uint32(18).string(message.board);
    }
    if (message.brand !== undefined && message.brand !== "") {
      writer.uint32(210).string(message.brand);
    }
    if (message.callbox !== undefined && message.callbox !== false) {
      writer.uint32(304).bool(message.callbox);
    }
    if (message.capabilities !== undefined) {
      HardwareCapabilities.encode(message.capabilities, writer.uint32(42).fork()).join();
    }
    if (message.cr50Phase !== undefined && message.cr50Phase !== 0) {
      writer.uint32(176).int32(message.cr50Phase);
    }
    if (message.cr50RoKeyid !== undefined && message.cr50RoKeyid !== "") {
      writer.uint32(226).string(message.cr50RoKeyid);
    }
    if (message.cr50RoVersion !== undefined && message.cr50RoVersion !== "") {
      writer.uint32(234).string(message.cr50RoVersion);
    }
    if (message.cr50RwKeyid !== undefined && message.cr50RwKeyid !== "") {
      writer.uint32(242).string(message.cr50RwKeyid);
    }
    if (message.cr50RwVersion !== undefined && message.cr50RwVersion !== "") {
      writer.uint32(250).string(message.cr50RwVersion);
    }
    writer.uint32(26).fork();
    for (const v of message.criticalPools) {
      writer.int32(v);
    }
    writer.join();
    writer.uint32(114).fork();
    for (const v of message.ctsAbi) {
      writer.int32(v);
    }
    writer.join();
    writer.uint32(170).fork();
    for (const v of message.ctsCpu) {
      writer.int32(v);
    }
    writer.join();
    if (message.ecType !== undefined && message.ecType !== 0) {
      writer.uint32(80).int32(message.ecType);
    }
    for (const v of message.hwidComponent) {
      writer.uint32(266).string(v!);
    }
    if (message.hwidSku !== undefined && message.hwidSku !== "") {
      writer.uint32(218).string(message.hwidSku);
    }
    if (message.dlmSkuId !== undefined && message.dlmSkuId !== "") {
      writer.uint32(322).string(message.dlmSkuId);
    }
    for (const v of message.licenses) {
      License.encode(v!, writer.uint32(274).fork()).join();
    }
    if (message.model !== undefined && message.model !== "") {
      writer.uint32(106).string(message.model);
    }
    if (message.modeminfo !== undefined) {
      ModemInfo.encode(message.modeminfo, writer.uint32(282).fork()).join();
    }
    if (message.sku !== undefined && message.sku !== "") {
      writer.uint32(202).string(message.sku);
    }
    for (const v of message.siminfo) {
      SIMInfo.encode(v!, writer.uint32(290).fork()).join();
    }
    if (message.osType !== undefined && message.osType !== 0) {
      writer.uint32(120).int32(message.osType);
    }
    if (message.peripherals !== undefined) {
      Peripherals.encode(message.peripherals, writer.uint32(154).fork()).join();
    }
    if (message.phase !== undefined && message.phase !== 0) {
      writer.uint32(128).int32(message.phase);
    }
    if (message.platform !== undefined && message.platform !== "") {
      writer.uint32(10).string(message.platform);
    }
    if (message.raccEnabledStatus !== undefined && message.raccEnabledStatus !== "") {
      writer.uint32(346).string(message.raccEnabledStatus);
    }
    if (message.referenceDesign !== undefined && message.referenceDesign !== "") {
      writer.uint32(194).string(message.referenceDesign);
    }
    for (const v of message.selfServePools) {
      writer.uint32(34).string(v!);
    }
    if (message.stability !== undefined && message.stability !== false) {
      writer.uint32(296).bool(message.stability);
    }
    if (message.testCoverageHints !== undefined) {
      TestCoverageHints.encode(message.testCoverageHints, writer.uint32(146).fork()).join();
    }
    if (message.uselessSwitch !== undefined && message.uselessSwitch !== false) {
      writer.uint32(184).bool(message.uselessSwitch);
    }
    for (const v of message.variant) {
      writer.uint32(138).string(v!);
    }
    if (message.wifiChip !== undefined && message.wifiChip !== "") {
      writer.uint32(258).string(message.wifiChip);
    }
    if (message.devboardType !== undefined && message.devboardType !== 0) {
      writer.uint32(312).int32(message.devboardType);
    }
    if (message.wifiOnSite !== undefined && message.wifiOnSite !== false) {
      writer.uint32(328).bool(message.wifiOnSite);
    }
    if (message.botSize !== undefined && message.botSize !== 0) {
      writer.uint32(336).int32(message.botSize);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SchedulableLabels {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSchedulableLabels() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 20: {
          if (tag !== 160) {
            break;
          }

          message.arc = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.board = reader.string();
          continue;
        }
        case 26: {
          if (tag !== 210) {
            break;
          }

          message.brand = reader.string();
          continue;
        }
        case 38: {
          if (tag !== 304) {
            break;
          }

          message.callbox = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.capabilities = HardwareCapabilities.decode(reader, reader.uint32());
          continue;
        }
        case 22: {
          if (tag !== 176) {
            break;
          }

          message.cr50Phase = reader.int32() as any;
          continue;
        }
        case 28: {
          if (tag !== 226) {
            break;
          }

          message.cr50RoKeyid = reader.string();
          continue;
        }
        case 29: {
          if (tag !== 234) {
            break;
          }

          message.cr50RoVersion = reader.string();
          continue;
        }
        case 30: {
          if (tag !== 242) {
            break;
          }

          message.cr50RwKeyid = reader.string();
          continue;
        }
        case 31: {
          if (tag !== 250) {
            break;
          }

          message.cr50RwVersion = reader.string();
          continue;
        }
        case 3: {
          if (tag === 24) {
            message.criticalPools.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.criticalPools.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 14: {
          if (tag === 112) {
            message.ctsAbi.push(reader.int32() as any);

            continue;
          }

          if (tag === 114) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ctsAbi.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 21: {
          if (tag === 168) {
            message.ctsCpu.push(reader.int32() as any);

            continue;
          }

          if (tag === 170) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ctsCpu.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.ecType = reader.int32() as any;
          continue;
        }
        case 33: {
          if (tag !== 266) {
            break;
          }

          message.hwidComponent.push(reader.string());
          continue;
        }
        case 27: {
          if (tag !== 218) {
            break;
          }

          message.hwidSku = reader.string();
          continue;
        }
        case 40: {
          if (tag !== 322) {
            break;
          }

          message.dlmSkuId = reader.string();
          continue;
        }
        case 34: {
          if (tag !== 274) {
            break;
          }

          message.licenses.push(License.decode(reader, reader.uint32()));
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 35: {
          if (tag !== 282) {
            break;
          }

          message.modeminfo = ModemInfo.decode(reader, reader.uint32());
          continue;
        }
        case 25: {
          if (tag !== 202) {
            break;
          }

          message.sku = reader.string();
          continue;
        }
        case 36: {
          if (tag !== 290) {
            break;
          }

          message.siminfo.push(SIMInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.osType = reader.int32() as any;
          continue;
        }
        case 19: {
          if (tag !== 154) {
            break;
          }

          message.peripherals = Peripherals.decode(reader, reader.uint32());
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.phase = reader.int32() as any;
          continue;
        }
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.platform = reader.string();
          continue;
        }
        case 43: {
          if (tag !== 346) {
            break;
          }

          message.raccEnabledStatus = reader.string();
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.referenceDesign = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.selfServePools.push(reader.string());
          continue;
        }
        case 37: {
          if (tag !== 296) {
            break;
          }

          message.stability = reader.bool();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.testCoverageHints = TestCoverageHints.decode(reader, reader.uint32());
          continue;
        }
        case 23: {
          if (tag !== 184) {
            break;
          }

          message.uselessSwitch = reader.bool();
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.variant.push(reader.string());
          continue;
        }
        case 32: {
          if (tag !== 258) {
            break;
          }

          message.wifiChip = reader.string();
          continue;
        }
        case 39: {
          if (tag !== 312) {
            break;
          }

          message.devboardType = reader.int32() as any;
          continue;
        }
        case 41: {
          if (tag !== 328) {
            break;
          }

          message.wifiOnSite = reader.bool();
          continue;
        }
        case 42: {
          if (tag !== 336) {
            break;
          }

          message.botSize = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SchedulableLabels {
    return {
      arc: isSet(object.arc) ? globalThis.Boolean(object.arc) : false,
      board: isSet(object.board) ? globalThis.String(object.board) : "",
      brand: isSet(object.brand) ? globalThis.String(object.brand) : "",
      callbox: isSet(object.callbox) ? globalThis.Boolean(object.callbox) : false,
      capabilities: isSet(object.capabilities) ? HardwareCapabilities.fromJSON(object.capabilities) : undefined,
      cr50Phase: isSet(object.cr50Phase) ? schedulableLabels_CR50PhaseFromJSON(object.cr50Phase) : 0,
      cr50RoKeyid: isSet(object.cr50RoKeyid) ? globalThis.String(object.cr50RoKeyid) : "",
      cr50RoVersion: isSet(object.cr50RoVersion) ? globalThis.String(object.cr50RoVersion) : "",
      cr50RwKeyid: isSet(object.cr50RwKeyid) ? globalThis.String(object.cr50RwKeyid) : "",
      cr50RwVersion: isSet(object.cr50RwVersion) ? globalThis.String(object.cr50RwVersion) : "",
      criticalPools: globalThis.Array.isArray(object?.criticalPools)
        ? object.criticalPools.map((e: any) => schedulableLabels_DUTPoolFromJSON(e))
        : [],
      ctsAbi: globalThis.Array.isArray(object?.ctsAbi)
        ? object.ctsAbi.map((e: any) => schedulableLabels_CTSABIFromJSON(e))
        : [],
      ctsCpu: globalThis.Array.isArray(object?.ctsCpu)
        ? object.ctsCpu.map((e: any) => schedulableLabels_CTSCPUFromJSON(e))
        : [],
      ecType: isSet(object.ecType) ? schedulableLabels_ECTypeFromJSON(object.ecType) : 0,
      hwidComponent: globalThis.Array.isArray(object?.hwidComponent)
        ? object.hwidComponent.map((e: any) => globalThis.String(e))
        : [],
      hwidSku: isSet(object.hwidSku) ? globalThis.String(object.hwidSku) : "",
      dlmSkuId: isSet(object.dlmSkuId) ? globalThis.String(object.dlmSkuId) : "",
      licenses: globalThis.Array.isArray(object?.licenses)
        ? object.licenses.map((e: any) => License.fromJSON(e))
        : [],
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      modeminfo: isSet(object.modeminfo) ? ModemInfo.fromJSON(object.modeminfo) : undefined,
      sku: isSet(object.sku) ? globalThis.String(object.sku) : "",
      siminfo: globalThis.Array.isArray(object?.siminfo) ? object.siminfo.map((e: any) => SIMInfo.fromJSON(e)) : [],
      osType: isSet(object.osType) ? schedulableLabels_OSTypeFromJSON(object.osType) : 0,
      peripherals: isSet(object.peripherals) ? Peripherals.fromJSON(object.peripherals) : undefined,
      phase: isSet(object.phase) ? schedulableLabels_PhaseFromJSON(object.phase) : 0,
      platform: isSet(object.platform) ? globalThis.String(object.platform) : "",
      raccEnabledStatus: isSet(object.raccEnabledStatus) ? globalThis.String(object.raccEnabledStatus) : "",
      referenceDesign: isSet(object.referenceDesign) ? globalThis.String(object.referenceDesign) : "",
      selfServePools: globalThis.Array.isArray(object?.selfServePools)
        ? object.selfServePools.map((e: any) => globalThis.String(e))
        : [],
      stability: isSet(object.stability) ? globalThis.Boolean(object.stability) : false,
      testCoverageHints: isSet(object.testCoverageHints)
        ? TestCoverageHints.fromJSON(object.testCoverageHints)
        : undefined,
      uselessSwitch: isSet(object.uselessSwitch) ? globalThis.Boolean(object.uselessSwitch) : false,
      variant: globalThis.Array.isArray(object?.variant)
        ? object.variant.map((e: any) => globalThis.String(e))
        : [],
      wifiChip: isSet(object.wifiChip) ? globalThis.String(object.wifiChip) : "",
      devboardType: isSet(object.devboardType) ? schedulableLabels_DevboardTypeFromJSON(object.devboardType) : 0,
      wifiOnSite: isSet(object.wifiOnSite) ? globalThis.Boolean(object.wifiOnSite) : false,
      botSize: isSet(object.botSize) ? schedulableLabels_BotSizeFromJSON(object.botSize) : 0,
    };
  },

  toJSON(message: SchedulableLabels): unknown {
    const obj: any = {};
    if (message.arc !== undefined && message.arc !== false) {
      obj.arc = message.arc;
    }
    if (message.board !== undefined && message.board !== "") {
      obj.board = message.board;
    }
    if (message.brand !== undefined && message.brand !== "") {
      obj.brand = message.brand;
    }
    if (message.callbox !== undefined && message.callbox !== false) {
      obj.callbox = message.callbox;
    }
    if (message.capabilities !== undefined) {
      obj.capabilities = HardwareCapabilities.toJSON(message.capabilities);
    }
    if (message.cr50Phase !== undefined && message.cr50Phase !== 0) {
      obj.cr50Phase = schedulableLabels_CR50PhaseToJSON(message.cr50Phase);
    }
    if (message.cr50RoKeyid !== undefined && message.cr50RoKeyid !== "") {
      obj.cr50RoKeyid = message.cr50RoKeyid;
    }
    if (message.cr50RoVersion !== undefined && message.cr50RoVersion !== "") {
      obj.cr50RoVersion = message.cr50RoVersion;
    }
    if (message.cr50RwKeyid !== undefined && message.cr50RwKeyid !== "") {
      obj.cr50RwKeyid = message.cr50RwKeyid;
    }
    if (message.cr50RwVersion !== undefined && message.cr50RwVersion !== "") {
      obj.cr50RwVersion = message.cr50RwVersion;
    }
    if (message.criticalPools?.length) {
      obj.criticalPools = message.criticalPools.map((e) => schedulableLabels_DUTPoolToJSON(e));
    }
    if (message.ctsAbi?.length) {
      obj.ctsAbi = message.ctsAbi.map((e) => schedulableLabels_CTSABIToJSON(e));
    }
    if (message.ctsCpu?.length) {
      obj.ctsCpu = message.ctsCpu.map((e) => schedulableLabels_CTSCPUToJSON(e));
    }
    if (message.ecType !== undefined && message.ecType !== 0) {
      obj.ecType = schedulableLabels_ECTypeToJSON(message.ecType);
    }
    if (message.hwidComponent?.length) {
      obj.hwidComponent = message.hwidComponent;
    }
    if (message.hwidSku !== undefined && message.hwidSku !== "") {
      obj.hwidSku = message.hwidSku;
    }
    if (message.dlmSkuId !== undefined && message.dlmSkuId !== "") {
      obj.dlmSkuId = message.dlmSkuId;
    }
    if (message.licenses?.length) {
      obj.licenses = message.licenses.map((e) => License.toJSON(e));
    }
    if (message.model !== undefined && message.model !== "") {
      obj.model = message.model;
    }
    if (message.modeminfo !== undefined) {
      obj.modeminfo = ModemInfo.toJSON(message.modeminfo);
    }
    if (message.sku !== undefined && message.sku !== "") {
      obj.sku = message.sku;
    }
    if (message.siminfo?.length) {
      obj.siminfo = message.siminfo.map((e) => SIMInfo.toJSON(e));
    }
    if (message.osType !== undefined && message.osType !== 0) {
      obj.osType = schedulableLabels_OSTypeToJSON(message.osType);
    }
    if (message.peripherals !== undefined) {
      obj.peripherals = Peripherals.toJSON(message.peripherals);
    }
    if (message.phase !== undefined && message.phase !== 0) {
      obj.phase = schedulableLabels_PhaseToJSON(message.phase);
    }
    if (message.platform !== undefined && message.platform !== "") {
      obj.platform = message.platform;
    }
    if (message.raccEnabledStatus !== undefined && message.raccEnabledStatus !== "") {
      obj.raccEnabledStatus = message.raccEnabledStatus;
    }
    if (message.referenceDesign !== undefined && message.referenceDesign !== "") {
      obj.referenceDesign = message.referenceDesign;
    }
    if (message.selfServePools?.length) {
      obj.selfServePools = message.selfServePools;
    }
    if (message.stability !== undefined && message.stability !== false) {
      obj.stability = message.stability;
    }
    if (message.testCoverageHints !== undefined) {
      obj.testCoverageHints = TestCoverageHints.toJSON(message.testCoverageHints);
    }
    if (message.uselessSwitch !== undefined && message.uselessSwitch !== false) {
      obj.uselessSwitch = message.uselessSwitch;
    }
    if (message.variant?.length) {
      obj.variant = message.variant;
    }
    if (message.wifiChip !== undefined && message.wifiChip !== "") {
      obj.wifiChip = message.wifiChip;
    }
    if (message.devboardType !== undefined && message.devboardType !== 0) {
      obj.devboardType = schedulableLabels_DevboardTypeToJSON(message.devboardType);
    }
    if (message.wifiOnSite !== undefined && message.wifiOnSite !== false) {
      obj.wifiOnSite = message.wifiOnSite;
    }
    if (message.botSize !== undefined && message.botSize !== 0) {
      obj.botSize = schedulableLabels_BotSizeToJSON(message.botSize);
    }
    return obj;
  },

  create(base?: DeepPartial<SchedulableLabels>): SchedulableLabels {
    return SchedulableLabels.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SchedulableLabels>): SchedulableLabels {
    const message = createBaseSchedulableLabels() as any;
    message.arc = object.arc ?? false;
    message.board = object.board ?? "";
    message.brand = object.brand ?? "";
    message.callbox = object.callbox ?? false;
    message.capabilities = (object.capabilities !== undefined && object.capabilities !== null)
      ? HardwareCapabilities.fromPartial(object.capabilities)
      : undefined;
    message.cr50Phase = object.cr50Phase ?? 0;
    message.cr50RoKeyid = object.cr50RoKeyid ?? "";
    message.cr50RoVersion = object.cr50RoVersion ?? "";
    message.cr50RwKeyid = object.cr50RwKeyid ?? "";
    message.cr50RwVersion = object.cr50RwVersion ?? "";
    message.criticalPools = object.criticalPools?.map((e) => e) || [];
    message.ctsAbi = object.ctsAbi?.map((e) => e) || [];
    message.ctsCpu = object.ctsCpu?.map((e) => e) || [];
    message.ecType = object.ecType ?? 0;
    message.hwidComponent = object.hwidComponent?.map((e) => e) || [];
    message.hwidSku = object.hwidSku ?? "";
    message.dlmSkuId = object.dlmSkuId ?? "";
    message.licenses = object.licenses?.map((e) => License.fromPartial(e)) || [];
    message.model = object.model ?? "";
    message.modeminfo = (object.modeminfo !== undefined && object.modeminfo !== null)
      ? ModemInfo.fromPartial(object.modeminfo)
      : undefined;
    message.sku = object.sku ?? "";
    message.siminfo = object.siminfo?.map((e) => SIMInfo.fromPartial(e)) || [];
    message.osType = object.osType ?? 0;
    message.peripherals = (object.peripherals !== undefined && object.peripherals !== null)
      ? Peripherals.fromPartial(object.peripherals)
      : undefined;
    message.phase = object.phase ?? 0;
    message.platform = object.platform ?? "";
    message.raccEnabledStatus = object.raccEnabledStatus ?? "";
    message.referenceDesign = object.referenceDesign ?? "";
    message.selfServePools = object.selfServePools?.map((e) => e) || [];
    message.stability = object.stability ?? false;
    message.testCoverageHints = (object.testCoverageHints !== undefined && object.testCoverageHints !== null)
      ? TestCoverageHints.fromPartial(object.testCoverageHints)
      : undefined;
    message.uselessSwitch = object.uselessSwitch ?? false;
    message.variant = object.variant?.map((e) => e) || [];
    message.wifiChip = object.wifiChip ?? "";
    message.devboardType = object.devboardType ?? 0;
    message.wifiOnSite = object.wifiOnSite ?? false;
    message.botSize = object.botSize ?? 0;
    return message;
  },
};

function createBaseHardwareCapabilities(): HardwareCapabilities {
  return {
    atrus: false,
    bluetooth: false,
    carrier: 0,
    supportedCarriers: [],
    cbx: 0,
    cbxBranding: 0,
    starfishSlotMapping: "",
    detachablebase: false,
    fingerprint: false,
    fingerprintBoard: "",
    fingerprintMcu: "",
    fingerprintSensor: "",
    flashrom: false,
    formFactor: 0,
    gpuFamily: "",
    gpuId: "",
    graphics: "",
    hotwording: false,
    internalDisplay: false,
    lucidsleep: false,
    modem: "",
    power: "",
    storage: "",
    telephony: "",
    webcam: false,
    touchpad: false,
    touchscreen: false,
    videoAcceleration: [],
  };
}

export const HardwareCapabilities: MessageFns<HardwareCapabilities> = {
  encode(message: HardwareCapabilities, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.atrus !== undefined && message.atrus !== false) {
      writer.uint32(136).bool(message.atrus);
    }
    if (message.bluetooth !== undefined && message.bluetooth !== false) {
      writer.uint32(56).bool(message.bluetooth);
    }
    if (message.carrier !== undefined && message.carrier !== 0) {
      writer.uint32(128).int32(message.carrier);
    }
    writer.uint32(330).fork();
    for (const v of message.supportedCarriers) {
      writer.int32(v);
    }
    writer.join();
    if (message.cbx !== undefined && message.cbx !== 0) {
      writer.uint32(168).int32(message.cbx);
    }
    if (message.cbxBranding !== undefined && message.cbxBranding !== 0) {
      writer.uint32(176).int32(message.cbxBranding);
    }
    if (message.starfishSlotMapping !== undefined && message.starfishSlotMapping !== "") {
      writer.uint32(162).string(message.starfishSlotMapping);
    }
    if (message.detachablebase !== undefined && message.detachablebase !== false) {
      writer.uint32(96).bool(message.detachablebase);
    }
    if (message.fingerprint !== undefined && message.fingerprint !== false) {
      writer.uint32(152).bool(message.fingerprint);
    }
    if (message.fingerprintBoard !== undefined && message.fingerprintBoard !== "") {
      writer.uint32(210).string(message.fingerprintBoard);
    }
    if (message.fingerprintMcu !== undefined && message.fingerprintMcu !== "") {
      writer.uint32(202).string(message.fingerprintMcu);
    }
    if (message.fingerprintSensor !== undefined && message.fingerprintSensor !== "") {
      writer.uint32(218).string(message.fingerprintSensor);
    }
    if (message.flashrom !== undefined && message.flashrom !== false) {
      writer.uint32(112).bool(message.flashrom);
    }
    if (message.formFactor !== undefined && message.formFactor !== 0) {
      writer.uint32(184).int32(message.formFactor);
    }
    if (message.gpuFamily !== undefined && message.gpuFamily !== "") {
      writer.uint32(26).string(message.gpuFamily);
    }
    if (message.gpuId !== undefined && message.gpuId !== "") {
      writer.uint32(194).string(message.gpuId);
    }
    if (message.graphics !== undefined && message.graphics !== "") {
      writer.uint32(34).string(message.graphics);
    }
    if (message.hotwording !== undefined && message.hotwording !== false) {
      writer.uint32(120).bool(message.hotwording);
    }
    if (message.internalDisplay !== undefined && message.internalDisplay !== false) {
      writer.uint32(64).bool(message.internalDisplay);
    }
    if (message.lucidsleep !== undefined && message.lucidsleep !== false) {
      writer.uint32(104).bool(message.lucidsleep);
    }
    if (message.modem !== undefined && message.modem !== "") {
      writer.uint32(10).string(message.modem);
    }
    if (message.power !== undefined && message.power !== "") {
      writer.uint32(50).string(message.power);
    }
    if (message.storage !== undefined && message.storage !== "") {
      writer.uint32(42).string(message.storage);
    }
    if (message.telephony !== undefined && message.telephony !== "") {
      writer.uint32(18).string(message.telephony);
    }
    if (message.webcam !== undefined && message.webcam !== false) {
      writer.uint32(72).bool(message.webcam);
    }
    if (message.touchpad !== undefined && message.touchpad !== false) {
      writer.uint32(80).bool(message.touchpad);
    }
    if (message.touchscreen !== undefined && message.touchscreen !== false) {
      writer.uint32(144).bool(message.touchscreen);
    }
    writer.uint32(90).fork();
    for (const v of message.videoAcceleration) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): HardwareCapabilities {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHardwareCapabilities() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 17: {
          if (tag !== 136) {
            break;
          }

          message.atrus = reader.bool();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.bluetooth = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.carrier = reader.int32() as any;
          continue;
        }
        case 41: {
          if (tag === 328) {
            message.supportedCarriers.push(reader.int32() as any);

            continue;
          }

          if (tag === 330) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.supportedCarriers.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 21: {
          if (tag !== 168) {
            break;
          }

          message.cbx = reader.int32() as any;
          continue;
        }
        case 22: {
          if (tag !== 176) {
            break;
          }

          message.cbxBranding = reader.int32() as any;
          continue;
        }
        case 20: {
          if (tag !== 162) {
            break;
          }

          message.starfishSlotMapping = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.detachablebase = reader.bool();
          continue;
        }
        case 19: {
          if (tag !== 152) {
            break;
          }

          message.fingerprint = reader.bool();
          continue;
        }
        case 26: {
          if (tag !== 210) {
            break;
          }

          message.fingerprintBoard = reader.string();
          continue;
        }
        case 25: {
          if (tag !== 202) {
            break;
          }

          message.fingerprintMcu = reader.string();
          continue;
        }
        case 27: {
          if (tag !== 218) {
            break;
          }

          message.fingerprintSensor = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.flashrom = reader.bool();
          continue;
        }
        case 23: {
          if (tag !== 184) {
            break;
          }

          message.formFactor = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.gpuFamily = reader.string();
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.gpuId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.graphics = reader.string();
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.hotwording = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.internalDisplay = reader.bool();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.lucidsleep = reader.bool();
          continue;
        }
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.modem = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.power = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.storage = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.telephony = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.webcam = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.touchpad = reader.bool();
          continue;
        }
        case 18: {
          if (tag !== 144) {
            break;
          }

          message.touchscreen = reader.bool();
          continue;
        }
        case 11: {
          if (tag === 88) {
            message.videoAcceleration.push(reader.int32() as any);

            continue;
          }

          if (tag === 90) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.videoAcceleration.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HardwareCapabilities {
    return {
      atrus: isSet(object.atrus) ? globalThis.Boolean(object.atrus) : false,
      bluetooth: isSet(object.bluetooth) ? globalThis.Boolean(object.bluetooth) : false,
      carrier: isSet(object.carrier) ? hardwareCapabilities_CarrierFromJSON(object.carrier) : 0,
      supportedCarriers: globalThis.Array.isArray(object?.supportedCarriers)
        ? object.supportedCarriers.map((e: any) => hardwareCapabilities_CarrierFromJSON(e))
        : [],
      cbx: isSet(object.cbx) ? hardwareCapabilities_CbxStateFromJSON(object.cbx) : 0,
      cbxBranding: isSet(object.cbxBranding) ? hardwareCapabilities_CbxBrandingFromJSON(object.cbxBranding) : 0,
      starfishSlotMapping: isSet(object.starfishSlotMapping) ? globalThis.String(object.starfishSlotMapping) : "",
      detachablebase: isSet(object.detachablebase) ? globalThis.Boolean(object.detachablebase) : false,
      fingerprint: isSet(object.fingerprint) ? globalThis.Boolean(object.fingerprint) : false,
      fingerprintBoard: isSet(object.fingerprintBoard) ? globalThis.String(object.fingerprintBoard) : "",
      fingerprintMcu: isSet(object.fingerprintMcu) ? globalThis.String(object.fingerprintMcu) : "",
      fingerprintSensor: isSet(object.fingerprintSensor) ? globalThis.String(object.fingerprintSensor) : "",
      flashrom: isSet(object.flashrom) ? globalThis.Boolean(object.flashrom) : false,
      formFactor: isSet(object.formFactor) ? hardwareCapabilities_FormFactorFromJSON(object.formFactor) : 0,
      gpuFamily: isSet(object.gpuFamily) ? globalThis.String(object.gpuFamily) : "",
      gpuId: isSet(object.gpuId) ? globalThis.String(object.gpuId) : "",
      graphics: isSet(object.graphics) ? globalThis.String(object.graphics) : "",
      hotwording: isSet(object.hotwording) ? globalThis.Boolean(object.hotwording) : false,
      internalDisplay: isSet(object.internalDisplay) ? globalThis.Boolean(object.internalDisplay) : false,
      lucidsleep: isSet(object.lucidsleep) ? globalThis.Boolean(object.lucidsleep) : false,
      modem: isSet(object.modem) ? globalThis.String(object.modem) : "",
      power: isSet(object.power) ? globalThis.String(object.power) : "",
      storage: isSet(object.storage) ? globalThis.String(object.storage) : "",
      telephony: isSet(object.telephony) ? globalThis.String(object.telephony) : "",
      webcam: isSet(object.webcam) ? globalThis.Boolean(object.webcam) : false,
      touchpad: isSet(object.touchpad) ? globalThis.Boolean(object.touchpad) : false,
      touchscreen: isSet(object.touchscreen) ? globalThis.Boolean(object.touchscreen) : false,
      videoAcceleration: globalThis.Array.isArray(object?.videoAcceleration)
        ? object.videoAcceleration.map((e: any) => hardwareCapabilities_VideoAccelerationFromJSON(e))
        : [],
    };
  },

  toJSON(message: HardwareCapabilities): unknown {
    const obj: any = {};
    if (message.atrus !== undefined && message.atrus !== false) {
      obj.atrus = message.atrus;
    }
    if (message.bluetooth !== undefined && message.bluetooth !== false) {
      obj.bluetooth = message.bluetooth;
    }
    if (message.carrier !== undefined && message.carrier !== 0) {
      obj.carrier = hardwareCapabilities_CarrierToJSON(message.carrier);
    }
    if (message.supportedCarriers?.length) {
      obj.supportedCarriers = message.supportedCarriers.map((e) => hardwareCapabilities_CarrierToJSON(e));
    }
    if (message.cbx !== undefined && message.cbx !== 0) {
      obj.cbx = hardwareCapabilities_CbxStateToJSON(message.cbx);
    }
    if (message.cbxBranding !== undefined && message.cbxBranding !== 0) {
      obj.cbxBranding = hardwareCapabilities_CbxBrandingToJSON(message.cbxBranding);
    }
    if (message.starfishSlotMapping !== undefined && message.starfishSlotMapping !== "") {
      obj.starfishSlotMapping = message.starfishSlotMapping;
    }
    if (message.detachablebase !== undefined && message.detachablebase !== false) {
      obj.detachablebase = message.detachablebase;
    }
    if (message.fingerprint !== undefined && message.fingerprint !== false) {
      obj.fingerprint = message.fingerprint;
    }
    if (message.fingerprintBoard !== undefined && message.fingerprintBoard !== "") {
      obj.fingerprintBoard = message.fingerprintBoard;
    }
    if (message.fingerprintMcu !== undefined && message.fingerprintMcu !== "") {
      obj.fingerprintMcu = message.fingerprintMcu;
    }
    if (message.fingerprintSensor !== undefined && message.fingerprintSensor !== "") {
      obj.fingerprintSensor = message.fingerprintSensor;
    }
    if (message.flashrom !== undefined && message.flashrom !== false) {
      obj.flashrom = message.flashrom;
    }
    if (message.formFactor !== undefined && message.formFactor !== 0) {
      obj.formFactor = hardwareCapabilities_FormFactorToJSON(message.formFactor);
    }
    if (message.gpuFamily !== undefined && message.gpuFamily !== "") {
      obj.gpuFamily = message.gpuFamily;
    }
    if (message.gpuId !== undefined && message.gpuId !== "") {
      obj.gpuId = message.gpuId;
    }
    if (message.graphics !== undefined && message.graphics !== "") {
      obj.graphics = message.graphics;
    }
    if (message.hotwording !== undefined && message.hotwording !== false) {
      obj.hotwording = message.hotwording;
    }
    if (message.internalDisplay !== undefined && message.internalDisplay !== false) {
      obj.internalDisplay = message.internalDisplay;
    }
    if (message.lucidsleep !== undefined && message.lucidsleep !== false) {
      obj.lucidsleep = message.lucidsleep;
    }
    if (message.modem !== undefined && message.modem !== "") {
      obj.modem = message.modem;
    }
    if (message.power !== undefined && message.power !== "") {
      obj.power = message.power;
    }
    if (message.storage !== undefined && message.storage !== "") {
      obj.storage = message.storage;
    }
    if (message.telephony !== undefined && message.telephony !== "") {
      obj.telephony = message.telephony;
    }
    if (message.webcam !== undefined && message.webcam !== false) {
      obj.webcam = message.webcam;
    }
    if (message.touchpad !== undefined && message.touchpad !== false) {
      obj.touchpad = message.touchpad;
    }
    if (message.touchscreen !== undefined && message.touchscreen !== false) {
      obj.touchscreen = message.touchscreen;
    }
    if (message.videoAcceleration?.length) {
      obj.videoAcceleration = message.videoAcceleration.map((e) => hardwareCapabilities_VideoAccelerationToJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<HardwareCapabilities>): HardwareCapabilities {
    return HardwareCapabilities.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<HardwareCapabilities>): HardwareCapabilities {
    const message = createBaseHardwareCapabilities() as any;
    message.atrus = object.atrus ?? false;
    message.bluetooth = object.bluetooth ?? false;
    message.carrier = object.carrier ?? 0;
    message.supportedCarriers = object.supportedCarriers?.map((e) => e) || [];
    message.cbx = object.cbx ?? 0;
    message.cbxBranding = object.cbxBranding ?? 0;
    message.starfishSlotMapping = object.starfishSlotMapping ?? "";
    message.detachablebase = object.detachablebase ?? false;
    message.fingerprint = object.fingerprint ?? false;
    message.fingerprintBoard = object.fingerprintBoard ?? "";
    message.fingerprintMcu = object.fingerprintMcu ?? "";
    message.fingerprintSensor = object.fingerprintSensor ?? "";
    message.flashrom = object.flashrom ?? false;
    message.formFactor = object.formFactor ?? 0;
    message.gpuFamily = object.gpuFamily ?? "";
    message.gpuId = object.gpuId ?? "";
    message.graphics = object.graphics ?? "";
    message.hotwording = object.hotwording ?? false;
    message.internalDisplay = object.internalDisplay ?? false;
    message.lucidsleep = object.lucidsleep ?? false;
    message.modem = object.modem ?? "";
    message.power = object.power ?? "";
    message.storage = object.storage ?? "";
    message.telephony = object.telephony ?? "";
    message.webcam = object.webcam ?? false;
    message.touchpad = object.touchpad ?? false;
    message.touchscreen = object.touchscreen ?? false;
    message.videoAcceleration = object.videoAcceleration?.map((e) => e) || [];
    return message;
  },
};

function createBasePeripherals(): Peripherals {
  return {
    audioBoard: false,
    audioBox: false,
    audioCable: false,
    audioLoopbackDongle: false,
    chameleon: false,
    chameleonType: [],
    chameleonConnectionTypes: [],
    chameleonState: 0,
    audioboxJackpluggerState: 0,
    trrsType: 0,
    conductive: false,
    huddly: false,
    mimo: false,
    ptzpro2: false,
    servo: false,
    servoComponent: [],
    servoState: 0,
    servoType: "",
    servoTopology: undefined,
    servoFwChannel: 0,
    smartUsbhub: false,
    stylus: false,
    camerabox: false,
    wificell: false,
    router80211ax: false,
    workingBluetoothBtpeer: 0,
    cameraboxFacing: 0,
    cameraboxLight: 0,
    storageState: 0,
    servoUsbState: 0,
    batteryState: 0,
    wifiState: 0,
    bluetoothState: 0,
    cellularModemState: 0,
    starfishState: 0,
    simState: 0,
    workingSims: 0,
    rpmState: 0,
    peripheralBtpeerState: 0,
    peripheralWifiState: 0,
    wifiRouterFeatures: [],
    wifiRouterModels: [],
    hmrState: 0,
    hmrWalt: false,
    hmrToolType: 0,
    hmrGen: 0,
    audioLatencyToolkitState: 0,
    pasitComponents: [],
    amtManagerState: 0,
    audioBeamforming: "",
    cameraState: 0,
    simFeatures: [],
  };
}

export const Peripherals: MessageFns<Peripherals> = {
  encode(message: Peripherals, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.audioBoard !== undefined && message.audioBoard !== false) {
      writer.uint32(48).bool(message.audioBoard);
    }
    if (message.audioBox !== undefined && message.audioBox !== false) {
      writer.uint32(56).bool(message.audioBox);
    }
    if (message.audioCable !== undefined && message.audioCable !== false) {
      writer.uint32(184).bool(message.audioCable);
    }
    if (message.audioLoopbackDongle !== undefined && message.audioLoopbackDongle !== false) {
      writer.uint32(8).bool(message.audioLoopbackDongle);
    }
    if (message.chameleon !== undefined && message.chameleon !== false) {
      writer.uint32(40).bool(message.chameleon);
    }
    writer.uint32(82).fork();
    for (const v of message.chameleonType) {
      writer.int32(v);
    }
    writer.join();
    writer.uint32(362).fork();
    for (const v of message.chameleonConnectionTypes) {
      writer.int32(v);
    }
    writer.join();
    if (message.chameleonState !== undefined && message.chameleonState !== 0) {
      writer.uint32(264).int32(message.chameleonState);
    }
    if (message.audioboxJackpluggerState !== undefined && message.audioboxJackpluggerState !== 0) {
      writer.uint32(280).int32(message.audioboxJackpluggerState);
    }
    if (message.trrsType !== undefined && message.trrsType !== 0) {
      writer.uint32(336).int32(message.trrsType);
    }
    if (message.conductive !== undefined && message.conductive !== false) {
      writer.uint32(88).bool(message.conductive);
    }
    if (message.huddly !== undefined && message.huddly !== false) {
      writer.uint32(72).bool(message.huddly);
    }
    if (message.mimo !== undefined && message.mimo !== false) {
      writer.uint32(64).bool(message.mimo);
    }
    if (message.ptzpro2 !== undefined && message.ptzpro2 !== false) {
      writer.uint32(96).bool(message.ptzpro2);
    }
    if (message.servo !== undefined && message.servo !== false) {
      writer.uint32(16).bool(message.servo);
    }
    for (const v of message.servoComponent) {
      writer.uint32(242).string(v!);
    }
    if (message.servoState !== undefined && message.servoState !== 0) {
      writer.uint32(120).int32(message.servoState);
    }
    if (message.servoType !== undefined && message.servoType !== "") {
      writer.uint32(138).string(message.servoType);
    }
    if (message.servoTopology !== undefined) {
      ServoTopology.encode(message.servoTopology, writer.uint32(194).fork()).join();
    }
    if (message.servoFwChannel !== undefined && message.servoFwChannel !== 0) {
      writer.uint32(216).int32(message.servoFwChannel);
    }
    if (message.smartUsbhub !== undefined && message.smartUsbhub !== false) {
      writer.uint32(168).bool(message.smartUsbhub);
    }
    if (message.stylus !== undefined && message.stylus !== false) {
      writer.uint32(24).bool(message.stylus);
    }
    if (message.camerabox !== undefined && message.camerabox !== false) {
      writer.uint32(104).bool(message.camerabox);
    }
    if (message.wificell !== undefined && message.wificell !== false) {
      writer.uint32(32).bool(message.wificell);
    }
    if (message.router80211ax !== undefined && message.router80211ax !== false) {
      writer.uint32(112).bool(message.router80211ax);
    }
    if (message.workingBluetoothBtpeer !== undefined && message.workingBluetoothBtpeer !== 0) {
      writer.uint32(128).int32(message.workingBluetoothBtpeer);
    }
    if (message.cameraboxFacing !== undefined && message.cameraboxFacing !== 0) {
      writer.uint32(144).int32(message.cameraboxFacing);
    }
    if (message.cameraboxLight !== undefined && message.cameraboxLight !== 0) {
      writer.uint32(176).int32(message.cameraboxLight);
    }
    if (message.storageState !== undefined && message.storageState !== 0) {
      writer.uint32(152).int32(message.storageState);
    }
    if (message.servoUsbState !== undefined && message.servoUsbState !== 0) {
      writer.uint32(160).int32(message.servoUsbState);
    }
    if (message.batteryState !== undefined && message.batteryState !== 0) {
      writer.uint32(208).int32(message.batteryState);
    }
    if (message.wifiState !== undefined && message.wifiState !== 0) {
      writer.uint32(224).int32(message.wifiState);
    }
    if (message.bluetoothState !== undefined && message.bluetoothState !== 0) {
      writer.uint32(232).int32(message.bluetoothState);
    }
    if (message.cellularModemState !== undefined && message.cellularModemState !== 0) {
      writer.uint32(272).int32(message.cellularModemState);
    }
    if (message.starfishState !== undefined && message.starfishState !== 0) {
      writer.uint32(368).int32(message.starfishState);
    }
    if (message.simState !== undefined && message.simState !== 0) {
      writer.uint32(384).int32(message.simState);
    }
    if (message.workingSims !== undefined && message.workingSims !== 0) {
      writer.uint32(392).int32(message.workingSims);
    }
    if (message.rpmState !== undefined && message.rpmState !== 0) {
      writer.uint32(200).int32(message.rpmState);
    }
    if (message.peripheralBtpeerState !== undefined && message.peripheralBtpeerState !== 0) {
      writer.uint32(352).int32(message.peripheralBtpeerState);
    }
    if (message.peripheralWifiState !== undefined && message.peripheralWifiState !== 0) {
      writer.uint32(248).int32(message.peripheralWifiState);
    }
    writer.uint32(322).fork();
    for (const v of message.wifiRouterFeatures) {
      writer.int32(v);
    }
    writer.join();
    for (const v of message.wifiRouterModels) {
      writer.uint32(330).string(v!);
    }
    if (message.hmrState !== undefined && message.hmrState !== 0) {
      writer.uint32(288).int32(message.hmrState);
    }
    if (message.hmrWalt !== undefined && message.hmrWalt !== false) {
      writer.uint32(432).bool(message.hmrWalt);
    }
    if (message.hmrToolType !== undefined && message.hmrToolType !== 0) {
      writer.uint32(440).int32(message.hmrToolType);
    }
    if (message.hmrGen !== undefined && message.hmrGen !== 0) {
      writer.uint32(448).int32(message.hmrGen);
    }
    if (message.audioLatencyToolkitState !== undefined && message.audioLatencyToolkitState !== 0) {
      writer.uint32(344).int32(message.audioLatencyToolkitState);
    }
    for (const v of message.pasitComponents) {
      writer.uint32(378).string(v!);
    }
    if (message.amtManagerState !== undefined && message.amtManagerState !== 0) {
      writer.uint32(400).int32(message.amtManagerState);
    }
    if (message.audioBeamforming !== undefined && message.audioBeamforming !== "") {
      writer.uint32(410).string(message.audioBeamforming);
    }
    if (message.cameraState !== undefined && message.cameraState !== 0) {
      writer.uint32(416).int32(message.cameraState);
    }
    writer.uint32(426).fork();
    for (const v of message.simFeatures) {
      writer.int32(v);
    }
    writer.join();
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Peripherals {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePeripherals() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.audioBoard = reader.bool();
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.audioBox = reader.bool();
          continue;
        }
        case 23: {
          if (tag !== 184) {
            break;
          }

          message.audioCable = reader.bool();
          continue;
        }
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.audioLoopbackDongle = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.chameleon = reader.bool();
          continue;
        }
        case 10: {
          if (tag === 80) {
            message.chameleonType.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.chameleonType.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 45: {
          if (tag === 360) {
            message.chameleonConnectionTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 362) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.chameleonConnectionTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 33: {
          if (tag !== 264) {
            break;
          }

          message.chameleonState = reader.int32() as any;
          continue;
        }
        case 35: {
          if (tag !== 280) {
            break;
          }

          message.audioboxJackpluggerState = reader.int32() as any;
          continue;
        }
        case 42: {
          if (tag !== 336) {
            break;
          }

          message.trrsType = reader.int32() as any;
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.conductive = reader.bool();
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.huddly = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.mimo = reader.bool();
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.ptzpro2 = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.servo = reader.bool();
          continue;
        }
        case 30: {
          if (tag !== 242) {
            break;
          }

          message.servoComponent.push(reader.string());
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.servoState = reader.int32() as any;
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.servoType = reader.string();
          continue;
        }
        case 24: {
          if (tag !== 194) {
            break;
          }

          message.servoTopology = ServoTopology.decode(reader, reader.uint32());
          continue;
        }
        case 27: {
          if (tag !== 216) {
            break;
          }

          message.servoFwChannel = reader.int32() as any;
          continue;
        }
        case 21: {
          if (tag !== 168) {
            break;
          }

          message.smartUsbhub = reader.bool();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.stylus = reader.bool();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.camerabox = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.wificell = reader.bool();
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.router80211ax = reader.bool();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.workingBluetoothBtpeer = reader.int32();
          continue;
        }
        case 18: {
          if (tag !== 144) {
            break;
          }

          message.cameraboxFacing = reader.int32() as any;
          continue;
        }
        case 22: {
          if (tag !== 176) {
            break;
          }

          message.cameraboxLight = reader.int32() as any;
          continue;
        }
        case 19: {
          if (tag !== 152) {
            break;
          }

          message.storageState = reader.int32() as any;
          continue;
        }
        case 20: {
          if (tag !== 160) {
            break;
          }

          message.servoUsbState = reader.int32() as any;
          continue;
        }
        case 26: {
          if (tag !== 208) {
            break;
          }

          message.batteryState = reader.int32() as any;
          continue;
        }
        case 28: {
          if (tag !== 224) {
            break;
          }

          message.wifiState = reader.int32() as any;
          continue;
        }
        case 29: {
          if (tag !== 232) {
            break;
          }

          message.bluetoothState = reader.int32() as any;
          continue;
        }
        case 34: {
          if (tag !== 272) {
            break;
          }

          message.cellularModemState = reader.int32() as any;
          continue;
        }
        case 46: {
          if (tag !== 368) {
            break;
          }

          message.starfishState = reader.int32() as any;
          continue;
        }
        case 48: {
          if (tag !== 384) {
            break;
          }

          message.simState = reader.int32() as any;
          continue;
        }
        case 49: {
          if (tag !== 392) {
            break;
          }

          message.workingSims = reader.int32();
          continue;
        }
        case 25: {
          if (tag !== 200) {
            break;
          }

          message.rpmState = reader.int32() as any;
          continue;
        }
        case 44: {
          if (tag !== 352) {
            break;
          }

          message.peripheralBtpeerState = reader.int32() as any;
          continue;
        }
        case 31: {
          if (tag !== 248) {
            break;
          }

          message.peripheralWifiState = reader.int32() as any;
          continue;
        }
        case 40: {
          if (tag === 320) {
            message.wifiRouterFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 322) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.wifiRouterFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 41: {
          if (tag !== 330) {
            break;
          }

          message.wifiRouterModels.push(reader.string());
          continue;
        }
        case 36: {
          if (tag !== 288) {
            break;
          }

          message.hmrState = reader.int32() as any;
          continue;
        }
        case 54: {
          if (tag !== 432) {
            break;
          }

          message.hmrWalt = reader.bool();
          continue;
        }
        case 55: {
          if (tag !== 440) {
            break;
          }

          message.hmrToolType = reader.int32() as any;
          continue;
        }
        case 56: {
          if (tag !== 448) {
            break;
          }

          message.hmrGen = reader.int32() as any;
          continue;
        }
        case 43: {
          if (tag !== 344) {
            break;
          }

          message.audioLatencyToolkitState = reader.int32() as any;
          continue;
        }
        case 47: {
          if (tag !== 378) {
            break;
          }

          message.pasitComponents.push(reader.string());
          continue;
        }
        case 50: {
          if (tag !== 400) {
            break;
          }

          message.amtManagerState = reader.int32() as any;
          continue;
        }
        case 51: {
          if (tag !== 410) {
            break;
          }

          message.audioBeamforming = reader.string();
          continue;
        }
        case 52: {
          if (tag !== 416) {
            break;
          }

          message.cameraState = reader.int32() as any;
          continue;
        }
        case 53: {
          if (tag === 424) {
            message.simFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 426) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.simFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Peripherals {
    return {
      audioBoard: isSet(object.audioBoard) ? globalThis.Boolean(object.audioBoard) : false,
      audioBox: isSet(object.audioBox) ? globalThis.Boolean(object.audioBox) : false,
      audioCable: isSet(object.audioCable) ? globalThis.Boolean(object.audioCable) : false,
      audioLoopbackDongle: isSet(object.audioLoopbackDongle) ? globalThis.Boolean(object.audioLoopbackDongle) : false,
      chameleon: isSet(object.chameleon) ? globalThis.Boolean(object.chameleon) : false,
      chameleonType: globalThis.Array.isArray(object?.chameleonType)
        ? object.chameleonType.map((e: any) => peripherals_ChameleonTypeFromJSON(e))
        : [],
      chameleonConnectionTypes: globalThis.Array.isArray(object?.chameleonConnectionTypes)
        ? object.chameleonConnectionTypes.map((e: any) => peripherals_ChameleonConnectionTypeFromJSON(e))
        : [],
      chameleonState: isSet(object.chameleonState) ? peripheralStateFromJSON(object.chameleonState) : 0,
      audioboxJackpluggerState: isSet(object.audioboxJackpluggerState)
        ? peripherals_AudioBoxJackPluggerFromJSON(object.audioboxJackpluggerState)
        : 0,
      trrsType: isSet(object.trrsType) ? peripherals_TRRSTypeFromJSON(object.trrsType) : 0,
      conductive: isSet(object.conductive) ? globalThis.Boolean(object.conductive) : false,
      huddly: isSet(object.huddly) ? globalThis.Boolean(object.huddly) : false,
      mimo: isSet(object.mimo) ? globalThis.Boolean(object.mimo) : false,
      ptzpro2: isSet(object.ptzpro2) ? globalThis.Boolean(object.ptzpro2) : false,
      servo: isSet(object.servo) ? globalThis.Boolean(object.servo) : false,
      servoComponent: globalThis.Array.isArray(object?.servoComponent)
        ? object.servoComponent.map((e: any) => globalThis.String(e))
        : [],
      servoState: isSet(object.servoState) ? peripheralStateFromJSON(object.servoState) : 0,
      servoType: isSet(object.servoType) ? globalThis.String(object.servoType) : "",
      servoTopology: isSet(object.servoTopology) ? ServoTopology.fromJSON(object.servoTopology) : undefined,
      servoFwChannel: isSet(object.servoFwChannel) ? servoFwChannelFromJSON(object.servoFwChannel) : 0,
      smartUsbhub: isSet(object.smartUsbhub) ? globalThis.Boolean(object.smartUsbhub) : false,
      stylus: isSet(object.stylus) ? globalThis.Boolean(object.stylus) : false,
      camerabox: isSet(object.camerabox) ? globalThis.Boolean(object.camerabox) : false,
      wificell: isSet(object.wificell) ? globalThis.Boolean(object.wificell) : false,
      router80211ax: isSet(object.router80211ax) ? globalThis.Boolean(object.router80211ax) : false,
      workingBluetoothBtpeer: isSet(object.workingBluetoothBtpeer)
        ? globalThis.Number(object.workingBluetoothBtpeer)
        : 0,
      cameraboxFacing: isSet(object.cameraboxFacing) ? peripherals_CameraboxFacingFromJSON(object.cameraboxFacing) : 0,
      cameraboxLight: isSet(object.cameraboxLight) ? peripherals_CameraboxLightFromJSON(object.cameraboxLight) : 0,
      storageState: isSet(object.storageState) ? hardwareStateFromJSON(object.storageState) : 0,
      servoUsbState: isSet(object.servoUsbState) ? hardwareStateFromJSON(object.servoUsbState) : 0,
      batteryState: isSet(object.batteryState) ? hardwareStateFromJSON(object.batteryState) : 0,
      wifiState: isSet(object.wifiState) ? hardwareStateFromJSON(object.wifiState) : 0,
      bluetoothState: isSet(object.bluetoothState) ? hardwareStateFromJSON(object.bluetoothState) : 0,
      cellularModemState: isSet(object.cellularModemState) ? hardwareStateFromJSON(object.cellularModemState) : 0,
      starfishState: isSet(object.starfishState) ? peripheralStateFromJSON(object.starfishState) : 0,
      simState: isSet(object.simState) ? peripheralStateFromJSON(object.simState) : 0,
      workingSims: isSet(object.workingSims) ? globalThis.Number(object.workingSims) : 0,
      rpmState: isSet(object.rpmState) ? peripheralStateFromJSON(object.rpmState) : 0,
      peripheralBtpeerState: isSet(object.peripheralBtpeerState)
        ? peripheralStateFromJSON(object.peripheralBtpeerState)
        : 0,
      peripheralWifiState: isSet(object.peripheralWifiState) ? peripheralStateFromJSON(object.peripheralWifiState) : 0,
      wifiRouterFeatures: globalThis.Array.isArray(object?.wifiRouterFeatures)
        ? object.wifiRouterFeatures.map((e: any) => peripherals_WifiRouterFeatureFromJSON(e))
        : [],
      wifiRouterModels: globalThis.Array.isArray(object?.wifiRouterModels)
        ? object.wifiRouterModels.map((e: any) => globalThis.String(e))
        : [],
      hmrState: isSet(object.hmrState) ? peripheralStateFromJSON(object.hmrState) : 0,
      hmrWalt: isSet(object.hmrWalt) ? globalThis.Boolean(object.hmrWalt) : false,
      hmrToolType: isSet(object.hmrToolType) ? peripherals_HMRToolTypeFromJSON(object.hmrToolType) : 0,
      hmrGen: isSet(object.hmrGen) ? peripherals_HMRGenFromJSON(object.hmrGen) : 0,
      audioLatencyToolkitState: isSet(object.audioLatencyToolkitState)
        ? peripheralStateFromJSON(object.audioLatencyToolkitState)
        : 0,
      pasitComponents: globalThis.Array.isArray(object?.pasitComponents)
        ? object.pasitComponents.map((e: any) => globalThis.String(e))
        : [],
      amtManagerState: isSet(object.amtManagerState) ? peripheralStateFromJSON(object.amtManagerState) : 0,
      audioBeamforming: isSet(object.audioBeamforming) ? globalThis.String(object.audioBeamforming) : "",
      cameraState: isSet(object.cameraState) ? hardwareStateFromJSON(object.cameraState) : 0,
      simFeatures: globalThis.Array.isArray(object?.simFeatures)
        ? object.simFeatures.map((e: any) => peripherals_SIMFeatureFromJSON(e))
        : [],
    };
  },

  toJSON(message: Peripherals): unknown {
    const obj: any = {};
    if (message.audioBoard !== undefined && message.audioBoard !== false) {
      obj.audioBoard = message.audioBoard;
    }
    if (message.audioBox !== undefined && message.audioBox !== false) {
      obj.audioBox = message.audioBox;
    }
    if (message.audioCable !== undefined && message.audioCable !== false) {
      obj.audioCable = message.audioCable;
    }
    if (message.audioLoopbackDongle !== undefined && message.audioLoopbackDongle !== false) {
      obj.audioLoopbackDongle = message.audioLoopbackDongle;
    }
    if (message.chameleon !== undefined && message.chameleon !== false) {
      obj.chameleon = message.chameleon;
    }
    if (message.chameleonType?.length) {
      obj.chameleonType = message.chameleonType.map((e) => peripherals_ChameleonTypeToJSON(e));
    }
    if (message.chameleonConnectionTypes?.length) {
      obj.chameleonConnectionTypes = message.chameleonConnectionTypes.map((e) =>
        peripherals_ChameleonConnectionTypeToJSON(e)
      );
    }
    if (message.chameleonState !== undefined && message.chameleonState !== 0) {
      obj.chameleonState = peripheralStateToJSON(message.chameleonState);
    }
    if (message.audioboxJackpluggerState !== undefined && message.audioboxJackpluggerState !== 0) {
      obj.audioboxJackpluggerState = peripherals_AudioBoxJackPluggerToJSON(message.audioboxJackpluggerState);
    }
    if (message.trrsType !== undefined && message.trrsType !== 0) {
      obj.trrsType = peripherals_TRRSTypeToJSON(message.trrsType);
    }
    if (message.conductive !== undefined && message.conductive !== false) {
      obj.conductive = message.conductive;
    }
    if (message.huddly !== undefined && message.huddly !== false) {
      obj.huddly = message.huddly;
    }
    if (message.mimo !== undefined && message.mimo !== false) {
      obj.mimo = message.mimo;
    }
    if (message.ptzpro2 !== undefined && message.ptzpro2 !== false) {
      obj.ptzpro2 = message.ptzpro2;
    }
    if (message.servo !== undefined && message.servo !== false) {
      obj.servo = message.servo;
    }
    if (message.servoComponent?.length) {
      obj.servoComponent = message.servoComponent;
    }
    if (message.servoState !== undefined && message.servoState !== 0) {
      obj.servoState = peripheralStateToJSON(message.servoState);
    }
    if (message.servoType !== undefined && message.servoType !== "") {
      obj.servoType = message.servoType;
    }
    if (message.servoTopology !== undefined) {
      obj.servoTopology = ServoTopology.toJSON(message.servoTopology);
    }
    if (message.servoFwChannel !== undefined && message.servoFwChannel !== 0) {
      obj.servoFwChannel = servoFwChannelToJSON(message.servoFwChannel);
    }
    if (message.smartUsbhub !== undefined && message.smartUsbhub !== false) {
      obj.smartUsbhub = message.smartUsbhub;
    }
    if (message.stylus !== undefined && message.stylus !== false) {
      obj.stylus = message.stylus;
    }
    if (message.camerabox !== undefined && message.camerabox !== false) {
      obj.camerabox = message.camerabox;
    }
    if (message.wificell !== undefined && message.wificell !== false) {
      obj.wificell = message.wificell;
    }
    if (message.router80211ax !== undefined && message.router80211ax !== false) {
      obj.router80211ax = message.router80211ax;
    }
    if (message.workingBluetoothBtpeer !== undefined && message.workingBluetoothBtpeer !== 0) {
      obj.workingBluetoothBtpeer = Math.round(message.workingBluetoothBtpeer);
    }
    if (message.cameraboxFacing !== undefined && message.cameraboxFacing !== 0) {
      obj.cameraboxFacing = peripherals_CameraboxFacingToJSON(message.cameraboxFacing);
    }
    if (message.cameraboxLight !== undefined && message.cameraboxLight !== 0) {
      obj.cameraboxLight = peripherals_CameraboxLightToJSON(message.cameraboxLight);
    }
    if (message.storageState !== undefined && message.storageState !== 0) {
      obj.storageState = hardwareStateToJSON(message.storageState);
    }
    if (message.servoUsbState !== undefined && message.servoUsbState !== 0) {
      obj.servoUsbState = hardwareStateToJSON(message.servoUsbState);
    }
    if (message.batteryState !== undefined && message.batteryState !== 0) {
      obj.batteryState = hardwareStateToJSON(message.batteryState);
    }
    if (message.wifiState !== undefined && message.wifiState !== 0) {
      obj.wifiState = hardwareStateToJSON(message.wifiState);
    }
    if (message.bluetoothState !== undefined && message.bluetoothState !== 0) {
      obj.bluetoothState = hardwareStateToJSON(message.bluetoothState);
    }
    if (message.cellularModemState !== undefined && message.cellularModemState !== 0) {
      obj.cellularModemState = hardwareStateToJSON(message.cellularModemState);
    }
    if (message.starfishState !== undefined && message.starfishState !== 0) {
      obj.starfishState = peripheralStateToJSON(message.starfishState);
    }
    if (message.simState !== undefined && message.simState !== 0) {
      obj.simState = peripheralStateToJSON(message.simState);
    }
    if (message.workingSims !== undefined && message.workingSims !== 0) {
      obj.workingSims = Math.round(message.workingSims);
    }
    if (message.rpmState !== undefined && message.rpmState !== 0) {
      obj.rpmState = peripheralStateToJSON(message.rpmState);
    }
    if (message.peripheralBtpeerState !== undefined && message.peripheralBtpeerState !== 0) {
      obj.peripheralBtpeerState = peripheralStateToJSON(message.peripheralBtpeerState);
    }
    if (message.peripheralWifiState !== undefined && message.peripheralWifiState !== 0) {
      obj.peripheralWifiState = peripheralStateToJSON(message.peripheralWifiState);
    }
    if (message.wifiRouterFeatures?.length) {
      obj.wifiRouterFeatures = message.wifiRouterFeatures.map((e) => peripherals_WifiRouterFeatureToJSON(e));
    }
    if (message.wifiRouterModels?.length) {
      obj.wifiRouterModels = message.wifiRouterModels;
    }
    if (message.hmrState !== undefined && message.hmrState !== 0) {
      obj.hmrState = peripheralStateToJSON(message.hmrState);
    }
    if (message.hmrWalt !== undefined && message.hmrWalt !== false) {
      obj.hmrWalt = message.hmrWalt;
    }
    if (message.hmrToolType !== undefined && message.hmrToolType !== 0) {
      obj.hmrToolType = peripherals_HMRToolTypeToJSON(message.hmrToolType);
    }
    if (message.hmrGen !== undefined && message.hmrGen !== 0) {
      obj.hmrGen = peripherals_HMRGenToJSON(message.hmrGen);
    }
    if (message.audioLatencyToolkitState !== undefined && message.audioLatencyToolkitState !== 0) {
      obj.audioLatencyToolkitState = peripheralStateToJSON(message.audioLatencyToolkitState);
    }
    if (message.pasitComponents?.length) {
      obj.pasitComponents = message.pasitComponents;
    }
    if (message.amtManagerState !== undefined && message.amtManagerState !== 0) {
      obj.amtManagerState = peripheralStateToJSON(message.amtManagerState);
    }
    if (message.audioBeamforming !== undefined && message.audioBeamforming !== "") {
      obj.audioBeamforming = message.audioBeamforming;
    }
    if (message.cameraState !== undefined && message.cameraState !== 0) {
      obj.cameraState = hardwareStateToJSON(message.cameraState);
    }
    if (message.simFeatures?.length) {
      obj.simFeatures = message.simFeatures.map((e) => peripherals_SIMFeatureToJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<Peripherals>): Peripherals {
    return Peripherals.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Peripherals>): Peripherals {
    const message = createBasePeripherals() as any;
    message.audioBoard = object.audioBoard ?? false;
    message.audioBox = object.audioBox ?? false;
    message.audioCable = object.audioCable ?? false;
    message.audioLoopbackDongle = object.audioLoopbackDongle ?? false;
    message.chameleon = object.chameleon ?? false;
    message.chameleonType = object.chameleonType?.map((e) => e) || [];
    message.chameleonConnectionTypes = object.chameleonConnectionTypes?.map((e) => e) || [];
    message.chameleonState = object.chameleonState ?? 0;
    message.audioboxJackpluggerState = object.audioboxJackpluggerState ?? 0;
    message.trrsType = object.trrsType ?? 0;
    message.conductive = object.conductive ?? false;
    message.huddly = object.huddly ?? false;
    message.mimo = object.mimo ?? false;
    message.ptzpro2 = object.ptzpro2 ?? false;
    message.servo = object.servo ?? false;
    message.servoComponent = object.servoComponent?.map((e) => e) || [];
    message.servoState = object.servoState ?? 0;
    message.servoType = object.servoType ?? "";
    message.servoTopology = (object.servoTopology !== undefined && object.servoTopology !== null)
      ? ServoTopology.fromPartial(object.servoTopology)
      : undefined;
    message.servoFwChannel = object.servoFwChannel ?? 0;
    message.smartUsbhub = object.smartUsbhub ?? false;
    message.stylus = object.stylus ?? false;
    message.camerabox = object.camerabox ?? false;
    message.wificell = object.wificell ?? false;
    message.router80211ax = object.router80211ax ?? false;
    message.workingBluetoothBtpeer = object.workingBluetoothBtpeer ?? 0;
    message.cameraboxFacing = object.cameraboxFacing ?? 0;
    message.cameraboxLight = object.cameraboxLight ?? 0;
    message.storageState = object.storageState ?? 0;
    message.servoUsbState = object.servoUsbState ?? 0;
    message.batteryState = object.batteryState ?? 0;
    message.wifiState = object.wifiState ?? 0;
    message.bluetoothState = object.bluetoothState ?? 0;
    message.cellularModemState = object.cellularModemState ?? 0;
    message.starfishState = object.starfishState ?? 0;
    message.simState = object.simState ?? 0;
    message.workingSims = object.workingSims ?? 0;
    message.rpmState = object.rpmState ?? 0;
    message.peripheralBtpeerState = object.peripheralBtpeerState ?? 0;
    message.peripheralWifiState = object.peripheralWifiState ?? 0;
    message.wifiRouterFeatures = object.wifiRouterFeatures?.map((e) => e) || [];
    message.wifiRouterModels = object.wifiRouterModels?.map((e) => e) || [];
    message.hmrState = object.hmrState ?? 0;
    message.hmrWalt = object.hmrWalt ?? false;
    message.hmrToolType = object.hmrToolType ?? 0;
    message.hmrGen = object.hmrGen ?? 0;
    message.audioLatencyToolkitState = object.audioLatencyToolkitState ?? 0;
    message.pasitComponents = object.pasitComponents?.map((e) => e) || [];
    message.amtManagerState = object.amtManagerState ?? 0;
    message.audioBeamforming = object.audioBeamforming ?? "";
    message.cameraState = object.cameraState ?? 0;
    message.simFeatures = object.simFeatures?.map((e) => e) || [];
    return message;
  },
};

function createBaseServoTopology(): ServoTopology {
  return { main: undefined, children: [] };
}

export const ServoTopology: MessageFns<ServoTopology> = {
  encode(message: ServoTopology, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.main !== undefined) {
      ServoTopologyItem.encode(message.main, writer.uint32(10).fork()).join();
    }
    for (const v of message.children) {
      ServoTopologyItem.encode(v!, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoTopology {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoTopology() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.main = ServoTopologyItem.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.children.push(ServoTopologyItem.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoTopology {
    return {
      main: isSet(object.main) ? ServoTopologyItem.fromJSON(object.main) : undefined,
      children: globalThis.Array.isArray(object?.children)
        ? object.children.map((e: any) => ServoTopologyItem.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ServoTopology): unknown {
    const obj: any = {};
    if (message.main !== undefined) {
      obj.main = ServoTopologyItem.toJSON(message.main);
    }
    if (message.children?.length) {
      obj.children = message.children.map((e) => ServoTopologyItem.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<ServoTopology>): ServoTopology {
    return ServoTopology.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ServoTopology>): ServoTopology {
    const message = createBaseServoTopology() as any;
    message.main = (object.main !== undefined && object.main !== null)
      ? ServoTopologyItem.fromPartial(object.main)
      : undefined;
    message.children = object.children?.map((e) => ServoTopologyItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseServoTopologyItem(): ServoTopologyItem {
  return { type: "", sysfsProduct: "", serial: "", usbHubPort: "", fwVersion: "" };
}

export const ServoTopologyItem: MessageFns<ServoTopologyItem> = {
  encode(message: ServoTopologyItem, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined && message.type !== "") {
      writer.uint32(10).string(message.type);
    }
    if (message.sysfsProduct !== undefined && message.sysfsProduct !== "") {
      writer.uint32(18).string(message.sysfsProduct);
    }
    if (message.serial !== undefined && message.serial !== "") {
      writer.uint32(26).string(message.serial);
    }
    if (message.usbHubPort !== undefined && message.usbHubPort !== "") {
      writer.uint32(34).string(message.usbHubPort);
    }
    if (message.fwVersion !== undefined && message.fwVersion !== "") {
      writer.uint32(42).string(message.fwVersion);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ServoTopologyItem {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseServoTopologyItem() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.type = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.sysfsProduct = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.serial = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.usbHubPort = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.fwVersion = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ServoTopologyItem {
    return {
      type: isSet(object.type) ? globalThis.String(object.type) : "",
      sysfsProduct: isSet(object.sysfsProduct) ? globalThis.String(object.sysfsProduct) : "",
      serial: isSet(object.serial) ? globalThis.String(object.serial) : "",
      usbHubPort: isSet(object.usbHubPort) ? globalThis.String(object.usbHubPort) : "",
      fwVersion: isSet(object.fwVersion) ? globalThis.String(object.fwVersion) : "",
    };
  },

  toJSON(message: ServoTopologyItem): unknown {
    const obj: any = {};
    if (message.type !== undefined && message.type !== "") {
      obj.type = message.type;
    }
    if (message.sysfsProduct !== undefined && message.sysfsProduct !== "") {
      obj.sysfsProduct = message.sysfsProduct;
    }
    if (message.serial !== undefined && message.serial !== "") {
      obj.serial = message.serial;
    }
    if (message.usbHubPort !== undefined && message.usbHubPort !== "") {
      obj.usbHubPort = message.usbHubPort;
    }
    if (message.fwVersion !== undefined && message.fwVersion !== "") {
      obj.fwVersion = message.fwVersion;
    }
    return obj;
  },

  create(base?: DeepPartial<ServoTopologyItem>): ServoTopologyItem {
    return ServoTopologyItem.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ServoTopologyItem>): ServoTopologyItem {
    const message = createBaseServoTopologyItem() as any;
    message.type = object.type ?? "";
    message.sysfsProduct = object.sysfsProduct ?? "";
    message.serial = object.serial ?? "";
    message.usbHubPort = object.usbHubPort ?? "";
    message.fwVersion = object.fwVersion ?? "";
    return message;
  },
};

function createBaseTestCoverageHints(): TestCoverageHints {
  return {
    chaosDut: false,
    chaosNightly: false,
    chromesign: false,
    ctsSparse: [],
    hangoutApp: false,
    meetApp: false,
    recoveryTest: false,
    testAudiojack: false,
    testHdmiaudio: false,
    testUsbaudio: false,
    testUsbprinting: false,
    usbDetect: false,
    useLid: false,
  };
}

export const TestCoverageHints: MessageFns<TestCoverageHints> = {
  encode(message: TestCoverageHints, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.chaosDut !== undefined && message.chaosDut !== false) {
      writer.uint32(56).bool(message.chaosDut);
    }
    if (message.chaosNightly !== undefined && message.chaosNightly !== false) {
      writer.uint32(104).bool(message.chaosNightly);
    }
    if (message.chromesign !== undefined && message.chromesign !== false) {
      writer.uint32(88).bool(message.chromesign);
    }
    writer.uint32(10).fork();
    for (const v of message.ctsSparse) {
      writer.int32(v);
    }
    writer.join();
    if (message.hangoutApp !== undefined && message.hangoutApp !== false) {
      writer.uint32(72).bool(message.hangoutApp);
    }
    if (message.meetApp !== undefined && message.meetApp !== false) {
      writer.uint32(80).bool(message.meetApp);
    }
    if (message.recoveryTest !== undefined && message.recoveryTest !== false) {
      writer.uint32(64).bool(message.recoveryTest);
    }
    if (message.testAudiojack !== undefined && message.testAudiojack !== false) {
      writer.uint32(24).bool(message.testAudiojack);
    }
    if (message.testHdmiaudio !== undefined && message.testHdmiaudio !== false) {
      writer.uint32(16).bool(message.testHdmiaudio);
    }
    if (message.testUsbaudio !== undefined && message.testUsbaudio !== false) {
      writer.uint32(32).bool(message.testUsbaudio);
    }
    if (message.testUsbprinting !== undefined && message.testUsbprinting !== false) {
      writer.uint32(40).bool(message.testUsbprinting);
    }
    if (message.usbDetect !== undefined && message.usbDetect !== false) {
      writer.uint32(48).bool(message.usbDetect);
    }
    if (message.useLid !== undefined && message.useLid !== false) {
      writer.uint32(96).bool(message.useLid);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): TestCoverageHints {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTestCoverageHints() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.chaosDut = reader.bool();
          continue;
        }
        case 13: {
          if (tag !== 104) {
            break;
          }

          message.chaosNightly = reader.bool();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.chromesign = reader.bool();
          continue;
        }
        case 1: {
          if (tag === 8) {
            message.ctsSparse.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.ctsSparse.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.hangoutApp = reader.bool();
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.meetApp = reader.bool();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.recoveryTest = reader.bool();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.testAudiojack = reader.bool();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.testHdmiaudio = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.testUsbaudio = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.testUsbprinting = reader.bool();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.usbDetect = reader.bool();
          continue;
        }
        case 12: {
          if (tag !== 96) {
            break;
          }

          message.useLid = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): TestCoverageHints {
    return {
      chaosDut: isSet(object.chaosDut) ? globalThis.Boolean(object.chaosDut) : false,
      chaosNightly: isSet(object.chaosNightly) ? globalThis.Boolean(object.chaosNightly) : false,
      chromesign: isSet(object.chromesign) ? globalThis.Boolean(object.chromesign) : false,
      ctsSparse: globalThis.Array.isArray(object?.ctsSparse)
        ? object.ctsSparse.map((e: any) => testCoverageHints_CTSSparseFromJSON(e))
        : [],
      hangoutApp: isSet(object.hangoutApp) ? globalThis.Boolean(object.hangoutApp) : false,
      meetApp: isSet(object.meetApp) ? globalThis.Boolean(object.meetApp) : false,
      recoveryTest: isSet(object.recoveryTest) ? globalThis.Boolean(object.recoveryTest) : false,
      testAudiojack: isSet(object.testAudiojack) ? globalThis.Boolean(object.testAudiojack) : false,
      testHdmiaudio: isSet(object.testHdmiaudio) ? globalThis.Boolean(object.testHdmiaudio) : false,
      testUsbaudio: isSet(object.testUsbaudio) ? globalThis.Boolean(object.testUsbaudio) : false,
      testUsbprinting: isSet(object.testUsbprinting) ? globalThis.Boolean(object.testUsbprinting) : false,
      usbDetect: isSet(object.usbDetect) ? globalThis.Boolean(object.usbDetect) : false,
      useLid: isSet(object.useLid) ? globalThis.Boolean(object.useLid) : false,
    };
  },

  toJSON(message: TestCoverageHints): unknown {
    const obj: any = {};
    if (message.chaosDut !== undefined && message.chaosDut !== false) {
      obj.chaosDut = message.chaosDut;
    }
    if (message.chaosNightly !== undefined && message.chaosNightly !== false) {
      obj.chaosNightly = message.chaosNightly;
    }
    if (message.chromesign !== undefined && message.chromesign !== false) {
      obj.chromesign = message.chromesign;
    }
    if (message.ctsSparse?.length) {
      obj.ctsSparse = message.ctsSparse.map((e) => testCoverageHints_CTSSparseToJSON(e));
    }
    if (message.hangoutApp !== undefined && message.hangoutApp !== false) {
      obj.hangoutApp = message.hangoutApp;
    }
    if (message.meetApp !== undefined && message.meetApp !== false) {
      obj.meetApp = message.meetApp;
    }
    if (message.recoveryTest !== undefined && message.recoveryTest !== false) {
      obj.recoveryTest = message.recoveryTest;
    }
    if (message.testAudiojack !== undefined && message.testAudiojack !== false) {
      obj.testAudiojack = message.testAudiojack;
    }
    if (message.testHdmiaudio !== undefined && message.testHdmiaudio !== false) {
      obj.testHdmiaudio = message.testHdmiaudio;
    }
    if (message.testUsbaudio !== undefined && message.testUsbaudio !== false) {
      obj.testUsbaudio = message.testUsbaudio;
    }
    if (message.testUsbprinting !== undefined && message.testUsbprinting !== false) {
      obj.testUsbprinting = message.testUsbprinting;
    }
    if (message.usbDetect !== undefined && message.usbDetect !== false) {
      obj.usbDetect = message.usbDetect;
    }
    if (message.useLid !== undefined && message.useLid !== false) {
      obj.useLid = message.useLid;
    }
    return obj;
  },

  create(base?: DeepPartial<TestCoverageHints>): TestCoverageHints {
    return TestCoverageHints.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<TestCoverageHints>): TestCoverageHints {
    const message = createBaseTestCoverageHints() as any;
    message.chaosDut = object.chaosDut ?? false;
    message.chaosNightly = object.chaosNightly ?? false;
    message.chromesign = object.chromesign ?? false;
    message.ctsSparse = object.ctsSparse?.map((e) => e) || [];
    message.hangoutApp = object.hangoutApp ?? false;
    message.meetApp = object.meetApp ?? false;
    message.recoveryTest = object.recoveryTest ?? false;
    message.testAudiojack = object.testAudiojack ?? false;
    message.testHdmiaudio = object.testHdmiaudio ?? false;
    message.testUsbaudio = object.testUsbaudio ?? false;
    message.testUsbprinting = object.testUsbprinting ?? false;
    message.usbDetect = object.usbDetect ?? false;
    message.useLid = object.useLid ?? false;
    return message;
  },
};

function createBaseKeyValue(): KeyValue {
  return { key: "", value: "" };
}

export const KeyValue: MessageFns<KeyValue> = {
  encode(message: KeyValue, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined && message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): KeyValue {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseKeyValue() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): KeyValue {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: KeyValue): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined && message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(base?: DeepPartial<KeyValue>): KeyValue {
    return KeyValue.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<KeyValue>): KeyValue {
    const message = createBaseKeyValue() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseLicense(): License {
  return { type: 0, identifier: "" };
}

export const License: MessageFns<License> = {
  encode(message: License, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.identifier !== undefined && message.identifier !== "") {
      writer.uint32(18).string(message.identifier);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): License {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLicense() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.identifier = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): License {
    return {
      type: isSet(object.type) ? licenseTypeFromJSON(object.type) : 0,
      identifier: isSet(object.identifier) ? globalThis.String(object.identifier) : "",
    };
  },

  toJSON(message: License): unknown {
    const obj: any = {};
    if (message.type !== undefined && message.type !== 0) {
      obj.type = licenseTypeToJSON(message.type);
    }
    if (message.identifier !== undefined && message.identifier !== "") {
      obj.identifier = message.identifier;
    }
    return obj;
  },

  create(base?: DeepPartial<License>): License {
    return License.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<License>): License {
    const message = createBaseLicense() as any;
    message.type = object.type ?? 0;
    message.identifier = object.identifier ?? "";
    return message;
  },
};

function createBaseModemInfo(): ModemInfo {
  return { type: 0, imei: "", supportedBands: "", simCount: 0, modelVariant: "" };
}

export const ModemInfo: MessageFns<ModemInfo> = {
  encode(message: ModemInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(8).int32(message.type);
    }
    if (message.imei !== undefined && message.imei !== "") {
      writer.uint32(18).string(message.imei);
    }
    if (message.supportedBands !== undefined && message.supportedBands !== "") {
      writer.uint32(26).string(message.supportedBands);
    }
    if (message.simCount !== undefined && message.simCount !== 0) {
      writer.uint32(32).int32(message.simCount);
    }
    if (message.modelVariant !== undefined && message.modelVariant !== "") {
      writer.uint32(42).string(message.modelVariant);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ModemInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseModemInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.imei = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.supportedBands = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.simCount = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.modelVariant = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ModemInfo {
    return {
      type: isSet(object.type) ? modemTypeFromJSON(object.type) : 0,
      imei: isSet(object.imei) ? globalThis.String(object.imei) : "",
      supportedBands: isSet(object.supportedBands) ? globalThis.String(object.supportedBands) : "",
      simCount: isSet(object.simCount) ? globalThis.Number(object.simCount) : 0,
      modelVariant: isSet(object.modelVariant) ? globalThis.String(object.modelVariant) : "",
    };
  },

  toJSON(message: ModemInfo): unknown {
    const obj: any = {};
    if (message.type !== undefined && message.type !== 0) {
      obj.type = modemTypeToJSON(message.type);
    }
    if (message.imei !== undefined && message.imei !== "") {
      obj.imei = message.imei;
    }
    if (message.supportedBands !== undefined && message.supportedBands !== "") {
      obj.supportedBands = message.supportedBands;
    }
    if (message.simCount !== undefined && message.simCount !== 0) {
      obj.simCount = Math.round(message.simCount);
    }
    if (message.modelVariant !== undefined && message.modelVariant !== "") {
      obj.modelVariant = message.modelVariant;
    }
    return obj;
  },

  create(base?: DeepPartial<ModemInfo>): ModemInfo {
    return ModemInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ModemInfo>): ModemInfo {
    const message = createBaseModemInfo() as any;
    message.type = object.type ?? 0;
    message.imei = object.imei ?? "";
    message.supportedBands = object.supportedBands ?? "";
    message.simCount = object.simCount ?? 0;
    message.modelVariant = object.modelVariant ?? "";
    return message;
  },
};

function createBaseSIMInfo(): SIMInfo {
  return { slotId: 0, type: 0, eid: "", testEsim: false, profileInfo: [] };
}

export const SIMInfo: MessageFns<SIMInfo> = {
  encode(message: SIMInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.slotId !== undefined && message.slotId !== 0) {
      writer.uint32(8).int32(message.slotId);
    }
    if (message.type !== undefined && message.type !== 0) {
      writer.uint32(16).int32(message.type);
    }
    if (message.eid !== undefined && message.eid !== "") {
      writer.uint32(26).string(message.eid);
    }
    if (message.testEsim !== undefined && message.testEsim !== false) {
      writer.uint32(32).bool(message.testEsim);
    }
    for (const v of message.profileInfo) {
      SIMProfileInfo.encode(v!, writer.uint32(42).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SIMInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSIMInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.slotId = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.eid = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.testEsim = reader.bool();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.profileInfo.push(SIMProfileInfo.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SIMInfo {
    return {
      slotId: isSet(object.slotId) ? globalThis.Number(object.slotId) : 0,
      type: isSet(object.type) ? sIMTypeFromJSON(object.type) : 0,
      eid: isSet(object.eid) ? globalThis.String(object.eid) : "",
      testEsim: isSet(object.testEsim) ? globalThis.Boolean(object.testEsim) : false,
      profileInfo: globalThis.Array.isArray(object?.profileInfo)
        ? object.profileInfo.map((e: any) => SIMProfileInfo.fromJSON(e))
        : [],
    };
  },

  toJSON(message: SIMInfo): unknown {
    const obj: any = {};
    if (message.slotId !== undefined && message.slotId !== 0) {
      obj.slotId = Math.round(message.slotId);
    }
    if (message.type !== undefined && message.type !== 0) {
      obj.type = sIMTypeToJSON(message.type);
    }
    if (message.eid !== undefined && message.eid !== "") {
      obj.eid = message.eid;
    }
    if (message.testEsim !== undefined && message.testEsim !== false) {
      obj.testEsim = message.testEsim;
    }
    if (message.profileInfo?.length) {
      obj.profileInfo = message.profileInfo.map((e) => SIMProfileInfo.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<SIMInfo>): SIMInfo {
    return SIMInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SIMInfo>): SIMInfo {
    const message = createBaseSIMInfo() as any;
    message.slotId = object.slotId ?? 0;
    message.type = object.type ?? 0;
    message.eid = object.eid ?? "";
    message.testEsim = object.testEsim ?? false;
    message.profileInfo = object.profileInfo?.map((e) => SIMProfileInfo.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSIMProfileInfo(): SIMProfileInfo {
  return { iccid: "", simPin: "", simPuk: "", carrierName: 0, ownNumber: "" };
}

export const SIMProfileInfo: MessageFns<SIMProfileInfo> = {
  encode(message: SIMProfileInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.iccid !== undefined && message.iccid !== "") {
      writer.uint32(10).string(message.iccid);
    }
    if (message.simPin !== undefined && message.simPin !== "") {
      writer.uint32(18).string(message.simPin);
    }
    if (message.simPuk !== undefined && message.simPuk !== "") {
      writer.uint32(26).string(message.simPuk);
    }
    if (message.carrierName !== undefined && message.carrierName !== 0) {
      writer.uint32(32).int32(message.carrierName);
    }
    if (message.ownNumber !== undefined && message.ownNumber !== "") {
      writer.uint32(42).string(message.ownNumber);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): SIMProfileInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSIMProfileInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.iccid = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.simPin = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.simPuk = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.carrierName = reader.int32() as any;
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.ownNumber = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SIMProfileInfo {
    return {
      iccid: isSet(object.iccid) ? globalThis.String(object.iccid) : "",
      simPin: isSet(object.simPin) ? globalThis.String(object.simPin) : "",
      simPuk: isSet(object.simPuk) ? globalThis.String(object.simPuk) : "",
      carrierName: isSet(object.carrierName) ? networkProviderFromJSON(object.carrierName) : 0,
      ownNumber: isSet(object.ownNumber) ? globalThis.String(object.ownNumber) : "",
    };
  },

  toJSON(message: SIMProfileInfo): unknown {
    const obj: any = {};
    if (message.iccid !== undefined && message.iccid !== "") {
      obj.iccid = message.iccid;
    }
    if (message.simPin !== undefined && message.simPin !== "") {
      obj.simPin = message.simPin;
    }
    if (message.simPuk !== undefined && message.simPuk !== "") {
      obj.simPuk = message.simPuk;
    }
    if (message.carrierName !== undefined && message.carrierName !== 0) {
      obj.carrierName = networkProviderToJSON(message.carrierName);
    }
    if (message.ownNumber !== undefined && message.ownNumber !== "") {
      obj.ownNumber = message.ownNumber;
    }
    return obj;
  },

  create(base?: DeepPartial<SIMProfileInfo>): SIMProfileInfo {
    return SIMProfileInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<SIMProfileInfo>): SIMProfileInfo {
    const message = createBaseSIMProfileInfo() as any;
    message.iccid = object.iccid ?? "";
    message.simPin = object.simPin ?? "";
    message.simPuk = object.simPuk ?? "";
    message.carrierName = object.carrierName ?? 0;
    message.ownNumber = object.ownNumber ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
