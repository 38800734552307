// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/wifi_router.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../../../../google/protobuf/timestamp.pb";

export const protobufPackage = "chromiumos.test.lab.api";

/**
 * Recognized hardware and software features of WiFi router peripherals in
 * our testbeds.
 *
 * This is not an exhaustive list of features supported by these test WiFi
 * routers. The intent is to only track features that may differ between the
 * different types of WiFi router devices in our labs.
 */
export enum WifiRouterFeature {
  /** WIFI_ROUTER_FEATURE_UNKNOWN - Features are not known. */
  WIFI_ROUTER_FEATURE_UNKNOWN = 0,
  /** WIFI_ROUTER_FEATURE_INVALID - Feature was parsed from device, but it did not match any known features. */
  WIFI_ROUTER_FEATURE_INVALID = 1,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_A - WiFi 1 (IEEE 802.11a) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_A = 2,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_B - WiFi 2 (IEEE 802.11b) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_B = 3,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_G - WiFi 3 (IEEE 802.11g) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_G = 4,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_N - WiFi 4 (IEEE 802.11n) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_N = 5,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AC - WiFi 5 (IEEE 802.11ac) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AC = 6,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AX - WiFi 6 (IEEE 802.11ax, 2.4GHz, 5GHz) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AX = 7,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E - WiFi 6E (IEEE 802.11ax, 6GHz) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E = 8,
  /** WIFI_ROUTER_FEATURE_IEEE_802_11_BE - WiFi 7 (IEEE 802.11be) support. */
  WIFI_ROUTER_FEATURE_IEEE_802_11_BE = 9,
  /** WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH - The CPU is fast enough to support a double bridge over veth. */
  WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH = 10,
  /** WIFI_ROUTER_FEATURE_GCMP - GCMP and GCMP-256 pairwise and group cipher support. */
  WIFI_ROUTER_FEATURE_GCMP = 11,
  /** WIFI_ROUTER_FEATURE_SAE_EXT_KEY - SAE-EXT-KEY support for AKM-24/25. */
  WIFI_ROUTER_FEATURE_SAE_EXT_KEY = 12,
}

export function wifiRouterFeatureFromJSON(object: any): WifiRouterFeature {
  switch (object) {
    case 0:
    case "WIFI_ROUTER_FEATURE_UNKNOWN":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_UNKNOWN;
    case 1:
    case "WIFI_ROUTER_FEATURE_INVALID":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_INVALID;
    case 2:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_A":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_A;
    case 3:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_B":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_B;
    case 4:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_G":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_G;
    case 5:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_N":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_N;
    case 6:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AC":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AC;
    case 7:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AX":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX;
    case 8:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E;
    case 9:
    case "WIFI_ROUTER_FEATURE_IEEE_802_11_BE":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_BE;
    case 10:
    case "WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH;
    case 11:
    case "WIFI_ROUTER_FEATURE_GCMP":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_GCMP;
    case 12:
    case "WIFI_ROUTER_FEATURE_SAE_EXT_KEY":
      return WifiRouterFeature.WIFI_ROUTER_FEATURE_SAE_EXT_KEY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum WifiRouterFeature");
  }
}

export function wifiRouterFeatureToJSON(object: WifiRouterFeature): string {
  switch (object) {
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_UNKNOWN:
      return "WIFI_ROUTER_FEATURE_UNKNOWN";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_INVALID:
      return "WIFI_ROUTER_FEATURE_INVALID";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_A:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_A";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_B:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_B";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_G:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_G";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_N:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_N";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AC:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AC";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AX";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_AX_E";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_IEEE_802_11_BE:
      return "WIFI_ROUTER_FEATURE_IEEE_802_11_BE";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH:
      return "WIFI_ROUTER_FEATURE_DOUBLE_BRIDGE_OVER_VETH";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_GCMP:
      return "WIFI_ROUTER_FEATURE_GCMP";
    case WifiRouterFeature.WIFI_ROUTER_FEATURE_SAE_EXT_KEY:
      return "WIFI_ROUTER_FEATURE_SAE_EXT_KEY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum WifiRouterFeature");
  }
}

/** The type of Wifi AP device a WiFi AP peripheral is, functionally speaking. */
export enum WifiRouterDeviceType {
  /** WIFI_ROUTER_DEVICE_TYPE_UNKNOWN - Default. Type not yet determined. */
  WIFI_ROUTER_DEVICE_TYPE_UNKNOWN = 0,
  /** WIFI_ROUTER_DEVICE_TYPE_INVALID - Attempted to identify device, but automatic identification failed. */
  WIFI_ROUTER_DEVICE_TYPE_INVALID = 1,
  /**
   * WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE - Google Gale router with a customized ChromeOS image specifically for WiFi
   * testing with Gales.
   */
  WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE = 2,
  /**
   * WIFI_ROUTER_DEVICE_TYPE_OPENWRT - WiFi router using an OpenWrt OS image that has been customized for
   * ChromeOS testing.
   */
  WIFI_ROUTER_DEVICE_TYPE_OPENWRT = 3,
  /** WIFI_ROUTER_DEVICE_TYPE_ASUSWRT - Retail ASUS WiFi router with the ASUSWRT interface. */
  WIFI_ROUTER_DEVICE_TYPE_ASUSWRT = 4,
  /** WIFI_ROUTER_DEVICE_TYPE_UBUNTU - Ubuntu-based device configured to be used as WiFi router (e.g. Intel NUC). */
  WIFI_ROUTER_DEVICE_TYPE_UBUNTU = 5,
}

export function wifiRouterDeviceTypeFromJSON(object: any): WifiRouterDeviceType {
  switch (object) {
    case 0:
    case "WIFI_ROUTER_DEVICE_TYPE_UNKNOWN":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_UNKNOWN;
    case 1:
    case "WIFI_ROUTER_DEVICE_TYPE_INVALID":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_INVALID;
    case 2:
    case "WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE;
    case 3:
    case "WIFI_ROUTER_DEVICE_TYPE_OPENWRT":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_OPENWRT;
    case 4:
    case "WIFI_ROUTER_DEVICE_TYPE_ASUSWRT":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_ASUSWRT;
    case 5:
    case "WIFI_ROUTER_DEVICE_TYPE_UBUNTU":
      return WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_UBUNTU;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum WifiRouterDeviceType");
  }
}

export function wifiRouterDeviceTypeToJSON(object: WifiRouterDeviceType): string {
  switch (object) {
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_UNKNOWN:
      return "WIFI_ROUTER_DEVICE_TYPE_UNKNOWN";
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_INVALID:
      return "WIFI_ROUTER_DEVICE_TYPE_INVALID";
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE:
      return "WIFI_ROUTER_DEVICE_TYPE_CHROMEOS_GALE";
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_OPENWRT:
      return "WIFI_ROUTER_DEVICE_TYPE_OPENWRT";
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_ASUSWRT:
      return "WIFI_ROUTER_DEVICE_TYPE_ASUSWRT";
    case WifiRouterDeviceType.WIFI_ROUTER_DEVICE_TYPE_UBUNTU:
      return "WIFI_ROUTER_DEVICE_TYPE_UBUNTU";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum WifiRouterDeviceType");
  }
}

/**
 * WifiRouterConfig is the format of the Wifi router config JSON file stored in
 * GCS that contains configuration information for all router device types.
 */
export interface WifiRouterConfig {
  /** OpenWrt device configs by their device names. */
  readonly openwrt: { [key: string]: OpenWrtWifiRouterDeviceConfig };
}

export interface WifiRouterConfig_OpenwrtEntry {
  readonly key: string;
  readonly value: OpenWrtWifiRouterDeviceConfig | undefined;
}

/**
 * OpenWrtWifiRouterConfig defines which OpenWrt OS image should be used for a
 * given OpenWrt device and DUT ChromeOS release version.
 *
 * Each device has its own set of versions and a pool of DUTs that are always on
 * the next version to assist in its verification.
 */
export interface OpenWrtWifiRouterDeviceConfig {
  /**
   * The image that should be used by default.
   *
   * If the testbed's DUT does not meet the version requirement of the image,
   * the image with the highest minimum DUT version requirement that the DUT
   * meets should be used instead. If the testbed's DUT does not meet any of the
   * available images' version requirements, the image with the lowest version
   * requirement should be used.
   */
  readonly currentImageUuid: string;
  /**
   * The image that should be used by routers in testbeds whose primary DUT is
   * in the next_image_verification_dut_pool, irregardless of the image's DUT
   * version requirement and overriding current_image_uuid for these routers.
   */
  readonly nextImageUuid: string;
  /**
   * Wifi routers that are in testbeds with these primary DUT hostnames should
   * use the next_image_uuid instead of the current_image_uuid for image
   * selection.
   */
  readonly nextImageVerificationDutPool: readonly string[];
  /** All available OpenWrt OS images for this build profile. */
  readonly images: readonly OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage[];
}

/** OpenWrtOSImage describes an available OpenWrt OS image. */
export interface OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
  /** The unique ID of the image. */
  readonly imageUuid: string;
  /**
   * The path to the image archive file, relative to the config file this
   * data was parsed from.
   */
  readonly archivePath: string;
  /** The minimum DUT CHROMEOS_RELEASE_VERSION this image should be used with. */
  readonly minDutReleaseVersion: string;
}

/**
 * OpenWrtImageBuildInfo is the format of the build info JSON file included in
 * in all OpenWrt OS images built for ChromeOS test WiFi routers by the
 * cros_openwrt_image_builder tool.
 *
 * These fields identify the image and describe the deviations made from the
 * official OpenWrt image build profile so that it may be used for test WiFi
 * routers in ChromeOS testbeds.
 */
export interface CrosOpenWrtImageBuildInfo {
  /** Unique ID generated for this image. */
  readonly imageUuid: string;
  /**
   * The custom extra part of the final image name added to the image name
   * created by the OpenWrt image builder.
   */
  readonly customImageName: string;
  readonly osRelease: CrosOpenWrtImageBuildInfo_OSRelease | undefined;
  readonly standardBuildConfig:
    | CrosOpenWrtImageBuildInfo_StandardBuildConfig
    | undefined;
  /** The features that devices with this image can support for testing. */
  readonly routerFeatures: readonly WifiRouterFeature[];
  /** The time the image was built. */
  readonly buildTime:
    | string
    | undefined;
  /** The version of cros_openwrt_image_builder used to build this image. */
  readonly crosOpenwrtImageBuilderVersion: string;
  /**
   * Custom files included in the image.
   *
   * The key is the path of the file on the device when the image is installed
   * and the value is a hash of the file contents.
   */
  readonly customIncludedFiles: { [key: string]: string };
  /**
   * Custom packages included in the image.
   *
   * The key is the name of the custom package IPK file and the value is a
   * hash of the file contents.
   */
  readonly customPackages: { [key: string]: string };
  /**
   * Names of the official OpenWrt packages included in the image that
   * supplement the packages included in the official build profile.
   */
  readonly extraIncludedPackages: readonly string[];
  /**
   * Names of the official OpenWrt packages that are excluded in the image
   * that would otherwise be included in the official build profile.
   */
  readonly excludedPackages: readonly string[];
  /** Names of the services that are disabled by default upon image install. */
  readonly disabledServices: readonly string[];
  /**
   * Names of networking interfaces that are not expected to be removed or
   * changed by users.
   */
  readonly reservedInterfaces: readonly string[];
}

/**
 * Build information as returned by the OpenWrt image builder used by
 * cros_openwrt_image_builder to package the image.
 */
export interface CrosOpenWrtImageBuildInfo_StandardBuildConfig {
  /** The openwrt repository revision used to compile builder. */
  readonly openwrtRevision: string;
  /** The builders' board build target. */
  readonly openwrtBuildTarget: string;
  /** The name of the build profile used to build the image. */
  readonly buildProfile: string;
  /**
   * Human-readable device name, as specified by OpenWrt.
   *
   * Expected to be unique by manufacturer, model, and version.
   */
  readonly deviceName: string;
  /** Default OpenWrt packages included for this build target. */
  readonly buildTargetPackages: readonly string[];
  /** Package customizations made by the build profile used to build the image. */
  readonly profilePackages: readonly string[];
  /** Devices supported by the build profile used to build the image. */
  readonly supportedDevices: readonly string[];
}

/**
 * Useful information describing the image, as parsed from the os-release
 * file included in the image.
 */
export interface CrosOpenWrtImageBuildInfo_OSRelease {
  readonly version: string;
  readonly buildId: string;
  readonly openwrtBoard: string;
  readonly openwrtArch: string;
  readonly openwrtRelease: string;
}

export interface CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
  readonly key: string;
  readonly value: string;
}

export interface CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
  readonly key: string;
  readonly value: string;
}

function createBaseWifiRouterConfig(): WifiRouterConfig {
  return { openwrt: {} };
}

export const WifiRouterConfig: MessageFns<WifiRouterConfig> = {
  encode(message: WifiRouterConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    Object.entries(message.openwrt).forEach(([key, value]) => {
      WifiRouterConfig_OpenwrtEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).join();
    });
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WifiRouterConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWifiRouterConfig() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          const entry1 = WifiRouterConfig_OpenwrtEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.openwrt[entry1.key] = entry1.value;
          }
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WifiRouterConfig {
    return {
      openwrt: isObject(object.openwrt)
        ? Object.entries(object.openwrt).reduce<{ [key: string]: OpenWrtWifiRouterDeviceConfig }>(
          (acc, [key, value]) => {
            acc[key] = OpenWrtWifiRouterDeviceConfig.fromJSON(value);
            return acc;
          },
          {},
        )
        : {},
    };
  },

  toJSON(message: WifiRouterConfig): unknown {
    const obj: any = {};
    if (message.openwrt) {
      const entries = Object.entries(message.openwrt);
      if (entries.length > 0) {
        obj.openwrt = {};
        entries.forEach(([k, v]) => {
          obj.openwrt[k] = OpenWrtWifiRouterDeviceConfig.toJSON(v);
        });
      }
    }
    return obj;
  },

  create(base?: DeepPartial<WifiRouterConfig>): WifiRouterConfig {
    return WifiRouterConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WifiRouterConfig>): WifiRouterConfig {
    const message = createBaseWifiRouterConfig() as any;
    message.openwrt = Object.entries(object.openwrt ?? {}).reduce<{ [key: string]: OpenWrtWifiRouterDeviceConfig }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = OpenWrtWifiRouterDeviceConfig.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    return message;
  },
};

function createBaseWifiRouterConfig_OpenwrtEntry(): WifiRouterConfig_OpenwrtEntry {
  return { key: "", value: undefined };
}

export const WifiRouterConfig_OpenwrtEntry: MessageFns<WifiRouterConfig_OpenwrtEntry> = {
  encode(message: WifiRouterConfig_OpenwrtEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      OpenWrtWifiRouterDeviceConfig.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): WifiRouterConfig_OpenwrtEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseWifiRouterConfig_OpenwrtEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = OpenWrtWifiRouterDeviceConfig.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): WifiRouterConfig_OpenwrtEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? OpenWrtWifiRouterDeviceConfig.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: WifiRouterConfig_OpenwrtEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = OpenWrtWifiRouterDeviceConfig.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<WifiRouterConfig_OpenwrtEntry>): WifiRouterConfig_OpenwrtEntry {
    return WifiRouterConfig_OpenwrtEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<WifiRouterConfig_OpenwrtEntry>): WifiRouterConfig_OpenwrtEntry {
    const message = createBaseWifiRouterConfig_OpenwrtEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? OpenWrtWifiRouterDeviceConfig.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseOpenWrtWifiRouterDeviceConfig(): OpenWrtWifiRouterDeviceConfig {
  return { currentImageUuid: "", nextImageUuid: "", nextImageVerificationDutPool: [], images: [] };
}

export const OpenWrtWifiRouterDeviceConfig: MessageFns<OpenWrtWifiRouterDeviceConfig> = {
  encode(message: OpenWrtWifiRouterDeviceConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.currentImageUuid !== "") {
      writer.uint32(10).string(message.currentImageUuid);
    }
    if (message.nextImageUuid !== "") {
      writer.uint32(18).string(message.nextImageUuid);
    }
    for (const v of message.nextImageVerificationDutPool) {
      writer.uint32(26).string(v!);
    }
    for (const v of message.images) {
      OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.encode(v!, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenWrtWifiRouterDeviceConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenWrtWifiRouterDeviceConfig() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.currentImageUuid = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.nextImageUuid = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.nextImageVerificationDutPool.push(reader.string());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.images.push(OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenWrtWifiRouterDeviceConfig {
    return {
      currentImageUuid: isSet(object.currentImageUuid) ? globalThis.String(object.currentImageUuid) : "",
      nextImageUuid: isSet(object.nextImageUuid) ? globalThis.String(object.nextImageUuid) : "",
      nextImageVerificationDutPool: globalThis.Array.isArray(object?.nextImageVerificationDutPool)
        ? object.nextImageVerificationDutPool.map((e: any) => globalThis.String(e))
        : [],
      images: globalThis.Array.isArray(object?.images)
        ? object.images.map((e: any) => OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.fromJSON(e))
        : [],
    };
  },

  toJSON(message: OpenWrtWifiRouterDeviceConfig): unknown {
    const obj: any = {};
    if (message.currentImageUuid !== "") {
      obj.currentImageUuid = message.currentImageUuid;
    }
    if (message.nextImageUuid !== "") {
      obj.nextImageUuid = message.nextImageUuid;
    }
    if (message.nextImageVerificationDutPool?.length) {
      obj.nextImageVerificationDutPool = message.nextImageVerificationDutPool;
    }
    if (message.images?.length) {
      obj.images = message.images.map((e) => OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<OpenWrtWifiRouterDeviceConfig>): OpenWrtWifiRouterDeviceConfig {
    return OpenWrtWifiRouterDeviceConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<OpenWrtWifiRouterDeviceConfig>): OpenWrtWifiRouterDeviceConfig {
    const message = createBaseOpenWrtWifiRouterDeviceConfig() as any;
    message.currentImageUuid = object.currentImageUuid ?? "";
    message.nextImageUuid = object.nextImageUuid ?? "";
    message.nextImageVerificationDutPool = object.nextImageVerificationDutPool?.map((e) => e) || [];
    message.images = object.images?.map((e) => OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.fromPartial(e)) || [];
    return message;
  },
};

function createBaseOpenWrtWifiRouterDeviceConfig_OpenWrtOSImage(): OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
  return { imageUuid: "", archivePath: "", minDutReleaseVersion: "" };
}

export const OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage: MessageFns<OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage> = {
  encode(
    message: OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.imageUuid !== "") {
      writer.uint32(10).string(message.imageUuid);
    }
    if (message.archivePath !== "") {
      writer.uint32(18).string(message.archivePath);
    }
    if (message.minDutReleaseVersion !== "") {
      writer.uint32(26).string(message.minDutReleaseVersion);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseOpenWrtWifiRouterDeviceConfig_OpenWrtOSImage() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.imageUuid = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.archivePath = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.minDutReleaseVersion = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
    return {
      imageUuid: isSet(object.imageUuid) ? globalThis.String(object.imageUuid) : "",
      archivePath: isSet(object.archivePath) ? globalThis.String(object.archivePath) : "",
      minDutReleaseVersion: isSet(object.minDutReleaseVersion) ? globalThis.String(object.minDutReleaseVersion) : "",
    };
  },

  toJSON(message: OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage): unknown {
    const obj: any = {};
    if (message.imageUuid !== "") {
      obj.imageUuid = message.imageUuid;
    }
    if (message.archivePath !== "") {
      obj.archivePath = message.archivePath;
    }
    if (message.minDutReleaseVersion !== "") {
      obj.minDutReleaseVersion = message.minDutReleaseVersion;
    }
    return obj;
  },

  create(
    base?: DeepPartial<OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage>,
  ): OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
    return OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage>,
  ): OpenWrtWifiRouterDeviceConfig_OpenWrtOSImage {
    const message = createBaseOpenWrtWifiRouterDeviceConfig_OpenWrtOSImage() as any;
    message.imageUuid = object.imageUuid ?? "";
    message.archivePath = object.archivePath ?? "";
    message.minDutReleaseVersion = object.minDutReleaseVersion ?? "";
    return message;
  },
};

function createBaseCrosOpenWrtImageBuildInfo(): CrosOpenWrtImageBuildInfo {
  return {
    imageUuid: "",
    customImageName: "",
    osRelease: undefined,
    standardBuildConfig: undefined,
    routerFeatures: [],
    buildTime: undefined,
    crosOpenwrtImageBuilderVersion: "",
    customIncludedFiles: {},
    customPackages: {},
    extraIncludedPackages: [],
    excludedPackages: [],
    disabledServices: [],
    reservedInterfaces: [],
  };
}

export const CrosOpenWrtImageBuildInfo: MessageFns<CrosOpenWrtImageBuildInfo> = {
  encode(message: CrosOpenWrtImageBuildInfo, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.imageUuid !== "") {
      writer.uint32(10).string(message.imageUuid);
    }
    if (message.customImageName !== "") {
      writer.uint32(18).string(message.customImageName);
    }
    if (message.osRelease !== undefined) {
      CrosOpenWrtImageBuildInfo_OSRelease.encode(message.osRelease, writer.uint32(26).fork()).join();
    }
    if (message.standardBuildConfig !== undefined) {
      CrosOpenWrtImageBuildInfo_StandardBuildConfig.encode(message.standardBuildConfig, writer.uint32(34).fork())
        .join();
    }
    writer.uint32(42).fork();
    for (const v of message.routerFeatures) {
      writer.int32(v);
    }
    writer.join();
    if (message.buildTime !== undefined) {
      Timestamp.encode(toTimestamp(message.buildTime), writer.uint32(50).fork()).join();
    }
    if (message.crosOpenwrtImageBuilderVersion !== "") {
      writer.uint32(58).string(message.crosOpenwrtImageBuilderVersion);
    }
    Object.entries(message.customIncludedFiles).forEach(([key, value]) => {
      CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry.encode({ key: key as any, value }, writer.uint32(66).fork())
        .join();
    });
    Object.entries(message.customPackages).forEach(([key, value]) => {
      CrosOpenWrtImageBuildInfo_CustomPackagesEntry.encode({ key: key as any, value }, writer.uint32(74).fork()).join();
    });
    for (const v of message.extraIncludedPackages) {
      writer.uint32(82).string(v!);
    }
    for (const v of message.excludedPackages) {
      writer.uint32(90).string(v!);
    }
    for (const v of message.disabledServices) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.reservedInterfaces) {
      writer.uint32(106).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CrosOpenWrtImageBuildInfo {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrosOpenWrtImageBuildInfo() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.imageUuid = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.customImageName = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.osRelease = CrosOpenWrtImageBuildInfo_OSRelease.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.standardBuildConfig = CrosOpenWrtImageBuildInfo_StandardBuildConfig.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag === 40) {
            message.routerFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.routerFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.buildTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.crosOpenwrtImageBuilderVersion = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          const entry8 = CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.customIncludedFiles[entry8.key] = entry8.value;
          }
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          const entry9 = CrosOpenWrtImageBuildInfo_CustomPackagesEntry.decode(reader, reader.uint32());
          if (entry9.value !== undefined) {
            message.customPackages[entry9.key] = entry9.value;
          }
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.extraIncludedPackages.push(reader.string());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.excludedPackages.push(reader.string());
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.disabledServices.push(reader.string());
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.reservedInterfaces.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrosOpenWrtImageBuildInfo {
    return {
      imageUuid: isSet(object.imageUuid) ? globalThis.String(object.imageUuid) : "",
      customImageName: isSet(object.customImageName) ? globalThis.String(object.customImageName) : "",
      osRelease: isSet(object.osRelease) ? CrosOpenWrtImageBuildInfo_OSRelease.fromJSON(object.osRelease) : undefined,
      standardBuildConfig: isSet(object.standardBuildConfig)
        ? CrosOpenWrtImageBuildInfo_StandardBuildConfig.fromJSON(object.standardBuildConfig)
        : undefined,
      routerFeatures: globalThis.Array.isArray(object?.routerFeatures)
        ? object.routerFeatures.map((e: any) => wifiRouterFeatureFromJSON(e))
        : [],
      buildTime: isSet(object.buildTime) ? globalThis.String(object.buildTime) : undefined,
      crosOpenwrtImageBuilderVersion: isSet(object.crosOpenwrtImageBuilderVersion)
        ? globalThis.String(object.crosOpenwrtImageBuilderVersion)
        : "",
      customIncludedFiles: isObject(object.customIncludedFiles)
        ? Object.entries(object.customIncludedFiles).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      customPackages: isObject(object.customPackages)
        ? Object.entries(object.customPackages).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      extraIncludedPackages: globalThis.Array.isArray(object?.extraIncludedPackages)
        ? object.extraIncludedPackages.map((e: any) => globalThis.String(e))
        : [],
      excludedPackages: globalThis.Array.isArray(object?.excludedPackages)
        ? object.excludedPackages.map((e: any) => globalThis.String(e))
        : [],
      disabledServices: globalThis.Array.isArray(object?.disabledServices)
        ? object.disabledServices.map((e: any) => globalThis.String(e))
        : [],
      reservedInterfaces: globalThis.Array.isArray(object?.reservedInterfaces)
        ? object.reservedInterfaces.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: CrosOpenWrtImageBuildInfo): unknown {
    const obj: any = {};
    if (message.imageUuid !== "") {
      obj.imageUuid = message.imageUuid;
    }
    if (message.customImageName !== "") {
      obj.customImageName = message.customImageName;
    }
    if (message.osRelease !== undefined) {
      obj.osRelease = CrosOpenWrtImageBuildInfo_OSRelease.toJSON(message.osRelease);
    }
    if (message.standardBuildConfig !== undefined) {
      obj.standardBuildConfig = CrosOpenWrtImageBuildInfo_StandardBuildConfig.toJSON(message.standardBuildConfig);
    }
    if (message.routerFeatures?.length) {
      obj.routerFeatures = message.routerFeatures.map((e) => wifiRouterFeatureToJSON(e));
    }
    if (message.buildTime !== undefined) {
      obj.buildTime = message.buildTime;
    }
    if (message.crosOpenwrtImageBuilderVersion !== "") {
      obj.crosOpenwrtImageBuilderVersion = message.crosOpenwrtImageBuilderVersion;
    }
    if (message.customIncludedFiles) {
      const entries = Object.entries(message.customIncludedFiles);
      if (entries.length > 0) {
        obj.customIncludedFiles = {};
        entries.forEach(([k, v]) => {
          obj.customIncludedFiles[k] = v;
        });
      }
    }
    if (message.customPackages) {
      const entries = Object.entries(message.customPackages);
      if (entries.length > 0) {
        obj.customPackages = {};
        entries.forEach(([k, v]) => {
          obj.customPackages[k] = v;
        });
      }
    }
    if (message.extraIncludedPackages?.length) {
      obj.extraIncludedPackages = message.extraIncludedPackages;
    }
    if (message.excludedPackages?.length) {
      obj.excludedPackages = message.excludedPackages;
    }
    if (message.disabledServices?.length) {
      obj.disabledServices = message.disabledServices;
    }
    if (message.reservedInterfaces?.length) {
      obj.reservedInterfaces = message.reservedInterfaces;
    }
    return obj;
  },

  create(base?: DeepPartial<CrosOpenWrtImageBuildInfo>): CrosOpenWrtImageBuildInfo {
    return CrosOpenWrtImageBuildInfo.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CrosOpenWrtImageBuildInfo>): CrosOpenWrtImageBuildInfo {
    const message = createBaseCrosOpenWrtImageBuildInfo() as any;
    message.imageUuid = object.imageUuid ?? "";
    message.customImageName = object.customImageName ?? "";
    message.osRelease = (object.osRelease !== undefined && object.osRelease !== null)
      ? CrosOpenWrtImageBuildInfo_OSRelease.fromPartial(object.osRelease)
      : undefined;
    message.standardBuildConfig = (object.standardBuildConfig !== undefined && object.standardBuildConfig !== null)
      ? CrosOpenWrtImageBuildInfo_StandardBuildConfig.fromPartial(object.standardBuildConfig)
      : undefined;
    message.routerFeatures = object.routerFeatures?.map((e) => e) || [];
    message.buildTime = object.buildTime ?? undefined;
    message.crosOpenwrtImageBuilderVersion = object.crosOpenwrtImageBuilderVersion ?? "";
    message.customIncludedFiles = Object.entries(object.customIncludedFiles ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.customPackages = Object.entries(object.customPackages ?? {}).reduce<{ [key: string]: string }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = globalThis.String(value);
        }
        return acc;
      },
      {},
    );
    message.extraIncludedPackages = object.extraIncludedPackages?.map((e) => e) || [];
    message.excludedPackages = object.excludedPackages?.map((e) => e) || [];
    message.disabledServices = object.disabledServices?.map((e) => e) || [];
    message.reservedInterfaces = object.reservedInterfaces?.map((e) => e) || [];
    return message;
  },
};

function createBaseCrosOpenWrtImageBuildInfo_StandardBuildConfig(): CrosOpenWrtImageBuildInfo_StandardBuildConfig {
  return {
    openwrtRevision: "",
    openwrtBuildTarget: "",
    buildProfile: "",
    deviceName: "",
    buildTargetPackages: [],
    profilePackages: [],
    supportedDevices: [],
  };
}

export const CrosOpenWrtImageBuildInfo_StandardBuildConfig: MessageFns<CrosOpenWrtImageBuildInfo_StandardBuildConfig> =
  {
    encode(
      message: CrosOpenWrtImageBuildInfo_StandardBuildConfig,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.openwrtRevision !== "") {
        writer.uint32(10).string(message.openwrtRevision);
      }
      if (message.openwrtBuildTarget !== "") {
        writer.uint32(18).string(message.openwrtBuildTarget);
      }
      if (message.buildProfile !== "") {
        writer.uint32(26).string(message.buildProfile);
      }
      if (message.deviceName !== "") {
        writer.uint32(34).string(message.deviceName);
      }
      for (const v of message.buildTargetPackages) {
        writer.uint32(42).string(v!);
      }
      for (const v of message.profilePackages) {
        writer.uint32(50).string(v!);
      }
      for (const v of message.supportedDevices) {
        writer.uint32(58).string(v!);
      }
      return writer;
    },

    decode(input: BinaryReader | Uint8Array, length?: number): CrosOpenWrtImageBuildInfo_StandardBuildConfig {
      const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCrosOpenWrtImageBuildInfo_StandardBuildConfig() as any;
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.openwrtRevision = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.openwrtBuildTarget = reader.string();
            continue;
          }
          case 3: {
            if (tag !== 26) {
              break;
            }

            message.buildProfile = reader.string();
            continue;
          }
          case 4: {
            if (tag !== 34) {
              break;
            }

            message.deviceName = reader.string();
            continue;
          }
          case 5: {
            if (tag !== 42) {
              break;
            }

            message.buildTargetPackages.push(reader.string());
            continue;
          }
          case 6: {
            if (tag !== 50) {
              break;
            }

            message.profilePackages.push(reader.string());
            continue;
          }
          case 7: {
            if (tag !== 58) {
              break;
            }

            message.supportedDevices.push(reader.string());
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CrosOpenWrtImageBuildInfo_StandardBuildConfig {
      return {
        openwrtRevision: isSet(object.openwrtRevision) ? globalThis.String(object.openwrtRevision) : "",
        openwrtBuildTarget: isSet(object.openwrtBuildTarget) ? globalThis.String(object.openwrtBuildTarget) : "",
        buildProfile: isSet(object.buildProfile) ? globalThis.String(object.buildProfile) : "",
        deviceName: isSet(object.deviceName) ? globalThis.String(object.deviceName) : "",
        buildTargetPackages: globalThis.Array.isArray(object?.buildTargetPackages)
          ? object.buildTargetPackages.map((e: any) => globalThis.String(e))
          : [],
        profilePackages: globalThis.Array.isArray(object?.profilePackages)
          ? object.profilePackages.map((e: any) => globalThis.String(e))
          : [],
        supportedDevices: globalThis.Array.isArray(object?.supportedDevices)
          ? object.supportedDevices.map((e: any) => globalThis.String(e))
          : [],
      };
    },

    toJSON(message: CrosOpenWrtImageBuildInfo_StandardBuildConfig): unknown {
      const obj: any = {};
      if (message.openwrtRevision !== "") {
        obj.openwrtRevision = message.openwrtRevision;
      }
      if (message.openwrtBuildTarget !== "") {
        obj.openwrtBuildTarget = message.openwrtBuildTarget;
      }
      if (message.buildProfile !== "") {
        obj.buildProfile = message.buildProfile;
      }
      if (message.deviceName !== "") {
        obj.deviceName = message.deviceName;
      }
      if (message.buildTargetPackages?.length) {
        obj.buildTargetPackages = message.buildTargetPackages;
      }
      if (message.profilePackages?.length) {
        obj.profilePackages = message.profilePackages;
      }
      if (message.supportedDevices?.length) {
        obj.supportedDevices = message.supportedDevices;
      }
      return obj;
    },

    create(
      base?: DeepPartial<CrosOpenWrtImageBuildInfo_StandardBuildConfig>,
    ): CrosOpenWrtImageBuildInfo_StandardBuildConfig {
      return CrosOpenWrtImageBuildInfo_StandardBuildConfig.fromPartial(base ?? {});
    },
    fromPartial(
      object: DeepPartial<CrosOpenWrtImageBuildInfo_StandardBuildConfig>,
    ): CrosOpenWrtImageBuildInfo_StandardBuildConfig {
      const message = createBaseCrosOpenWrtImageBuildInfo_StandardBuildConfig() as any;
      message.openwrtRevision = object.openwrtRevision ?? "";
      message.openwrtBuildTarget = object.openwrtBuildTarget ?? "";
      message.buildProfile = object.buildProfile ?? "";
      message.deviceName = object.deviceName ?? "";
      message.buildTargetPackages = object.buildTargetPackages?.map((e) => e) || [];
      message.profilePackages = object.profilePackages?.map((e) => e) || [];
      message.supportedDevices = object.supportedDevices?.map((e) => e) || [];
      return message;
    },
  };

function createBaseCrosOpenWrtImageBuildInfo_OSRelease(): CrosOpenWrtImageBuildInfo_OSRelease {
  return { version: "", buildId: "", openwrtBoard: "", openwrtArch: "", openwrtRelease: "" };
}

export const CrosOpenWrtImageBuildInfo_OSRelease: MessageFns<CrosOpenWrtImageBuildInfo_OSRelease> = {
  encode(message: CrosOpenWrtImageBuildInfo_OSRelease, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.version !== "") {
      writer.uint32(10).string(message.version);
    }
    if (message.buildId !== "") {
      writer.uint32(18).string(message.buildId);
    }
    if (message.openwrtBoard !== "") {
      writer.uint32(26).string(message.openwrtBoard);
    }
    if (message.openwrtArch !== "") {
      writer.uint32(34).string(message.openwrtArch);
    }
    if (message.openwrtRelease !== "") {
      writer.uint32(42).string(message.openwrtRelease);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CrosOpenWrtImageBuildInfo_OSRelease {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrosOpenWrtImageBuildInfo_OSRelease() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.version = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.buildId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.openwrtBoard = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.openwrtArch = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.openwrtRelease = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrosOpenWrtImageBuildInfo_OSRelease {
    return {
      version: isSet(object.version) ? globalThis.String(object.version) : "",
      buildId: isSet(object.buildId) ? globalThis.String(object.buildId) : "",
      openwrtBoard: isSet(object.openwrtBoard) ? globalThis.String(object.openwrtBoard) : "",
      openwrtArch: isSet(object.openwrtArch) ? globalThis.String(object.openwrtArch) : "",
      openwrtRelease: isSet(object.openwrtRelease) ? globalThis.String(object.openwrtRelease) : "",
    };
  },

  toJSON(message: CrosOpenWrtImageBuildInfo_OSRelease): unknown {
    const obj: any = {};
    if (message.version !== "") {
      obj.version = message.version;
    }
    if (message.buildId !== "") {
      obj.buildId = message.buildId;
    }
    if (message.openwrtBoard !== "") {
      obj.openwrtBoard = message.openwrtBoard;
    }
    if (message.openwrtArch !== "") {
      obj.openwrtArch = message.openwrtArch;
    }
    if (message.openwrtRelease !== "") {
      obj.openwrtRelease = message.openwrtRelease;
    }
    return obj;
  },

  create(base?: DeepPartial<CrosOpenWrtImageBuildInfo_OSRelease>): CrosOpenWrtImageBuildInfo_OSRelease {
    return CrosOpenWrtImageBuildInfo_OSRelease.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<CrosOpenWrtImageBuildInfo_OSRelease>): CrosOpenWrtImageBuildInfo_OSRelease {
    const message = createBaseCrosOpenWrtImageBuildInfo_OSRelease() as any;
    message.version = object.version ?? "";
    message.buildId = object.buildId ?? "";
    message.openwrtBoard = object.openwrtBoard ?? "";
    message.openwrtArch = object.openwrtArch ?? "";
    message.openwrtRelease = object.openwrtRelease ?? "";
    return message;
  },
};

function createBaseCrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry(): CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
  return { key: "", value: "" };
}

export const CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry: MessageFns<
  CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry
> = {
  encode(
    message: CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry,
    writer: BinaryWriter = new BinaryWriter(),
  ): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(
    base?: DeepPartial<CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry>,
  ): CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
    return CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry.fromPartial(base ?? {});
  },
  fromPartial(
    object: DeepPartial<CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry>,
  ): CrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry {
    const message = createBaseCrosOpenWrtImageBuildInfo_CustomIncludedFilesEntry() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseCrosOpenWrtImageBuildInfo_CustomPackagesEntry(): CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
  return { key: "", value: "" };
}

export const CrosOpenWrtImageBuildInfo_CustomPackagesEntry: MessageFns<CrosOpenWrtImageBuildInfo_CustomPackagesEntry> =
  {
    encode(
      message: CrosOpenWrtImageBuildInfo_CustomPackagesEntry,
      writer: BinaryWriter = new BinaryWriter(),
    ): BinaryWriter {
      if (message.key !== "") {
        writer.uint32(10).string(message.key);
      }
      if (message.value !== "") {
        writer.uint32(18).string(message.value);
      }
      return writer;
    },

    decode(input: BinaryReader | Uint8Array, length?: number): CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
      const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
      let end = length === undefined ? reader.len : reader.pos + length;
      const message = createBaseCrosOpenWrtImageBuildInfo_CustomPackagesEntry() as any;
      while (reader.pos < end) {
        const tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (tag !== 10) {
              break;
            }

            message.key = reader.string();
            continue;
          }
          case 2: {
            if (tag !== 18) {
              break;
            }

            message.value = reader.string();
            continue;
          }
        }
        if ((tag & 7) === 4 || tag === 0) {
          break;
        }
        reader.skip(tag & 7);
      }
      return message;
    },

    fromJSON(object: any): CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
      return {
        key: isSet(object.key) ? globalThis.String(object.key) : "",
        value: isSet(object.value) ? globalThis.String(object.value) : "",
      };
    },

    toJSON(message: CrosOpenWrtImageBuildInfo_CustomPackagesEntry): unknown {
      const obj: any = {};
      if (message.key !== "") {
        obj.key = message.key;
      }
      if (message.value !== "") {
        obj.value = message.value;
      }
      return obj;
    },

    create(
      base?: DeepPartial<CrosOpenWrtImageBuildInfo_CustomPackagesEntry>,
    ): CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
      return CrosOpenWrtImageBuildInfo_CustomPackagesEntry.fromPartial(base ?? {});
    },
    fromPartial(
      object: DeepPartial<CrosOpenWrtImageBuildInfo_CustomPackagesEntry>,
    ): CrosOpenWrtImageBuildInfo_CustomPackagesEntry {
      const message = createBaseCrosOpenWrtImageBuildInfo_CustomPackagesEntry() as any;
      message.key = object.key ?? "";
      message.value = object.value ?? "";
      return message;
    },
  };

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
