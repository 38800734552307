// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/deployment.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models";

/** Next tag: 3 */
export enum PushConfigType {
  PUSH_CONFIG_TYPE_UNSPECIFIED = 0,
  PUSH_CONFIG_TYPE_MOBILECONFIG = 1,
  PUSH_CONFIG_TYPE_PACKAGE = 2,
}

export function pushConfigTypeFromJSON(object: any): PushConfigType {
  switch (object) {
    case 0:
    case "PUSH_CONFIG_TYPE_UNSPECIFIED":
      return PushConfigType.PUSH_CONFIG_TYPE_UNSPECIFIED;
    case 1:
    case "PUSH_CONFIG_TYPE_MOBILECONFIG":
      return PushConfigType.PUSH_CONFIG_TYPE_MOBILECONFIG;
    case 2:
    case "PUSH_CONFIG_TYPE_PACKAGE":
      return PushConfigType.PUSH_CONFIG_TYPE_PACKAGE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PushConfigType");
  }
}

export function pushConfigTypeToJSON(object: PushConfigType): string {
  switch (object) {
    case PushConfigType.PUSH_CONFIG_TYPE_UNSPECIFIED:
      return "PUSH_CONFIG_TYPE_UNSPECIFIED";
    case PushConfigType.PUSH_CONFIG_TYPE_MOBILECONFIG:
      return "PUSH_CONFIG_TYPE_MOBILECONFIG";
    case PushConfigType.PUSH_CONFIG_TYPE_PACKAGE:
      return "PUSH_CONFIG_TYPE_PACKAGE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PushConfigType");
  }
}

/**
 * Payload describes the payload needed in deployment.
 *
 * Next tag: 3
 */
export interface Payload {
  /**
   * It refers to the unique payload name,
   * e.g. energy_saver, disable_gatekeeper, puppet3_package, ..., etc.
   */
  readonly name: string;
  /** e.g. PUSH_CONFIG_TYPE_MOBILECONFIG */
  readonly config: PushConfigType;
  /**
   * It refers to a g3 path for a profile, e.g.
   *      //depot/google3/ops/macops/mdm/mega/chrome/profiles/energy_saver.mobileconfig
   * or refers to a server link for a package, e.g.
   *      https://macos-server-1.golo.chromium.org/manifests/clpuppet3.plist
   */
  readonly path: string;
}

function createBasePayload(): Payload {
  return { name: "", config: 0, path: "" };
}

export const Payload: MessageFns<Payload> = {
  encode(message: Payload, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.config !== 0) {
      writer.uint32(16).int32(message.config);
    }
    if (message.path !== "") {
      writer.uint32(26).string(message.path);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Payload {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePayload() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.config = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.path = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Payload {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      config: isSet(object.config) ? pushConfigTypeFromJSON(object.config) : 0,
      path: isSet(object.path) ? globalThis.String(object.path) : "",
    };
  },

  toJSON(message: Payload): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.config !== 0) {
      obj.config = pushConfigTypeToJSON(message.config);
    }
    if (message.path !== "") {
      obj.path = message.path;
    }
    return obj;
  },

  create(base?: DeepPartial<Payload>): Payload {
    return Payload.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Payload>): Payload {
    const message = createBasePayload() as any;
    message.name = object.name ?? "";
    message.config = object.config ?? 0;
    message.path = object.path ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
