// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/lab/chameleon.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { OSRPM } from "./rpm.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 12 */
export enum ChameleonType {
  CHAMELEON_TYPE_INVALID = 0,
  /** @deprecated */
  CHAMELEON_TYPE_DP = 2,
  /** @deprecated */
  CHAMELEON_TYPE_DP_HDMI = 3,
  /** @deprecated */
  CHAMELEON_TYPE_VGA = 4,
  /** @deprecated */
  CHAMELEON_TYPE_HDMI = 5,
  CHAMELEON_TYPE_V2 = 9,
  CHAMELEON_TYPE_V3 = 10,
  /** CHAMELEON_TYPE_RPI - RaspberryPi 4B */
  CHAMELEON_TYPE_RPI = 11,
}

export function chameleonTypeFromJSON(object: any): ChameleonType {
  switch (object) {
    case 0:
    case "CHAMELEON_TYPE_INVALID":
      return ChameleonType.CHAMELEON_TYPE_INVALID;
    case 2:
    case "CHAMELEON_TYPE_DP":
      return ChameleonType.CHAMELEON_TYPE_DP;
    case 3:
    case "CHAMELEON_TYPE_DP_HDMI":
      return ChameleonType.CHAMELEON_TYPE_DP_HDMI;
    case 4:
    case "CHAMELEON_TYPE_VGA":
      return ChameleonType.CHAMELEON_TYPE_VGA;
    case 5:
    case "CHAMELEON_TYPE_HDMI":
      return ChameleonType.CHAMELEON_TYPE_HDMI;
    case 9:
    case "CHAMELEON_TYPE_V2":
      return ChameleonType.CHAMELEON_TYPE_V2;
    case 10:
    case "CHAMELEON_TYPE_V3":
      return ChameleonType.CHAMELEON_TYPE_V3;
    case 11:
    case "CHAMELEON_TYPE_RPI":
      return ChameleonType.CHAMELEON_TYPE_RPI;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChameleonType");
  }
}

export function chameleonTypeToJSON(object: ChameleonType): string {
  switch (object) {
    case ChameleonType.CHAMELEON_TYPE_INVALID:
      return "CHAMELEON_TYPE_INVALID";
    case ChameleonType.CHAMELEON_TYPE_DP:
      return "CHAMELEON_TYPE_DP";
    case ChameleonType.CHAMELEON_TYPE_DP_HDMI:
      return "CHAMELEON_TYPE_DP_HDMI";
    case ChameleonType.CHAMELEON_TYPE_VGA:
      return "CHAMELEON_TYPE_VGA";
    case ChameleonType.CHAMELEON_TYPE_HDMI:
      return "CHAMELEON_TYPE_HDMI";
    case ChameleonType.CHAMELEON_TYPE_V2:
      return "CHAMELEON_TYPE_V2";
    case ChameleonType.CHAMELEON_TYPE_V3:
      return "CHAMELEON_TYPE_V3";
    case ChameleonType.CHAMELEON_TYPE_RPI:
      return "CHAMELEON_TYPE_RPI";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChameleonType");
  }
}

export enum ChameleonConnectionType {
  CHAMELEON_CONNECTION_TYPE_INVALID = 0,
  CHAMELEON_CONNECTION_TYPE_AUDIOJACK = 1,
  CHAMELEON_CONNECTION_TYPE_USB = 2,
  CHAMELEON_CONNECTION_TYPE_HDMI = 3,
  CHAMELEON_CONNECTION_TYPE_DP = 4,
}

export function chameleonConnectionTypeFromJSON(object: any): ChameleonConnectionType {
  switch (object) {
    case 0:
    case "CHAMELEON_CONNECTION_TYPE_INVALID":
      return ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_INVALID;
    case 1:
    case "CHAMELEON_CONNECTION_TYPE_AUDIOJACK":
      return ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_AUDIOJACK;
    case 2:
    case "CHAMELEON_CONNECTION_TYPE_USB":
      return ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_USB;
    case 3:
    case "CHAMELEON_CONNECTION_TYPE_HDMI":
      return ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_HDMI;
    case 4:
    case "CHAMELEON_CONNECTION_TYPE_DP":
      return ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_DP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChameleonConnectionType");
  }
}

export function chameleonConnectionTypeToJSON(object: ChameleonConnectionType): string {
  switch (object) {
    case ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_INVALID:
      return "CHAMELEON_CONNECTION_TYPE_INVALID";
    case ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_AUDIOJACK:
      return "CHAMELEON_CONNECTION_TYPE_AUDIOJACK";
    case ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_USB:
      return "CHAMELEON_CONNECTION_TYPE_USB";
    case ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_HDMI:
      return "CHAMELEON_CONNECTION_TYPE_HDMI";
    case ChameleonConnectionType.CHAMELEON_CONNECTION_TYPE_DP:
      return "CHAMELEON_CONNECTION_TYPE_DP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ChameleonConnectionType");
  }
}

/** Next Tag: 9 */
export interface Chameleon {
  readonly chameleonPeripherals: readonly ChameleonType[];
  readonly chameleonConnectionTypes: readonly ChameleonConnectionType[];
  /** Indicate if there's audio_board in the chameleon. */
  readonly audioBoard: boolean;
  readonly hostname: string;
  /** Remote Power Management for chameleon device. */
  readonly rpm: OSRPM | undefined;
  readonly audioboxJackplugger: Chameleon_AudioBoxJackPlugger;
  /** Indicate the type of audio cable */
  readonly trrsType: Chameleon_TRRSType;
}

/**
 * Indicate the audio box jack plugger state
 * Next Tag: 4
 */
export enum Chameleon_AudioBoxJackPlugger {
  AUDIOBOX_JACKPLUGGER_UNSPECIFIED = 0,
  AUDIOBOX_JACKPLUGGER_WORKING = 1,
  AUDIOBOX_JACKPLUGGER_BROKEN = 2,
  AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE = 3,
}

export function chameleon_AudioBoxJackPluggerFromJSON(object: any): Chameleon_AudioBoxJackPlugger {
  switch (object) {
    case 0:
    case "AUDIOBOX_JACKPLUGGER_UNSPECIFIED":
      return Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_UNSPECIFIED;
    case 1:
    case "AUDIOBOX_JACKPLUGGER_WORKING":
      return Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_WORKING;
    case 2:
    case "AUDIOBOX_JACKPLUGGER_BROKEN":
      return Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_BROKEN;
    case 3:
    case "AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE":
      return Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chameleon_AudioBoxJackPlugger");
  }
}

export function chameleon_AudioBoxJackPluggerToJSON(object: Chameleon_AudioBoxJackPlugger): string {
  switch (object) {
    case Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_UNSPECIFIED:
      return "AUDIOBOX_JACKPLUGGER_UNSPECIFIED";
    case Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_WORKING:
      return "AUDIOBOX_JACKPLUGGER_WORKING";
    case Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_BROKEN:
      return "AUDIOBOX_JACKPLUGGER_BROKEN";
    case Chameleon_AudioBoxJackPlugger.AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE:
      return "AUDIOBOX_JACKPLUGGER_NOT_APPLICABLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chameleon_AudioBoxJackPlugger");
  }
}

/**
 * Indicate the trrs types
 * Next Tag: 3
 */
export enum Chameleon_TRRSType {
  TRRS_TYPE_UNSPECIFIED = 0,
  TRRS_TYPE_CTIA = 1,
  /** TRRS_TYPE_OMTP - Refer "go/wiki/Phone_connector_(audio)#TRRS_standards" for more types */
  TRRS_TYPE_OMTP = 2,
}

export function chameleon_TRRSTypeFromJSON(object: any): Chameleon_TRRSType {
  switch (object) {
    case 0:
    case "TRRS_TYPE_UNSPECIFIED":
      return Chameleon_TRRSType.TRRS_TYPE_UNSPECIFIED;
    case 1:
    case "TRRS_TYPE_CTIA":
      return Chameleon_TRRSType.TRRS_TYPE_CTIA;
    case 2:
    case "TRRS_TYPE_OMTP":
      return Chameleon_TRRSType.TRRS_TYPE_OMTP;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chameleon_TRRSType");
  }
}

export function chameleon_TRRSTypeToJSON(object: Chameleon_TRRSType): string {
  switch (object) {
    case Chameleon_TRRSType.TRRS_TYPE_UNSPECIFIED:
      return "TRRS_TYPE_UNSPECIFIED";
    case Chameleon_TRRSType.TRRS_TYPE_CTIA:
      return "TRRS_TYPE_CTIA";
    case Chameleon_TRRSType.TRRS_TYPE_OMTP:
      return "TRRS_TYPE_OMTP";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Chameleon_TRRSType");
  }
}

function createBaseChameleon(): Chameleon {
  return {
    chameleonPeripherals: [],
    chameleonConnectionTypes: [],
    audioBoard: false,
    hostname: "",
    rpm: undefined,
    audioboxJackplugger: 0,
    trrsType: 0,
  };
}

export const Chameleon: MessageFns<Chameleon> = {
  encode(message: Chameleon, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    writer.uint32(26).fork();
    for (const v of message.chameleonPeripherals) {
      writer.int32(v);
    }
    writer.join();
    writer.uint32(66).fork();
    for (const v of message.chameleonConnectionTypes) {
      writer.int32(v);
    }
    writer.join();
    if (message.audioBoard !== false) {
      writer.uint32(16).bool(message.audioBoard);
    }
    if (message.hostname !== "") {
      writer.uint32(34).string(message.hostname);
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(42).fork()).join();
    }
    if (message.audioboxJackplugger !== 0) {
      writer.uint32(48).int32(message.audioboxJackplugger);
    }
    if (message.trrsType !== 0) {
      writer.uint32(56).int32(message.trrsType);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Chameleon {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChameleon() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 3: {
          if (tag === 24) {
            message.chameleonPeripherals.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.chameleonPeripherals.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 8: {
          if (tag === 64) {
            message.chameleonConnectionTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 66) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.chameleonConnectionTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.audioBoard = reader.bool();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.audioboxJackplugger = reader.int32() as any;
          continue;
        }
        case 7: {
          if (tag !== 56) {
            break;
          }

          message.trrsType = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Chameleon {
    return {
      chameleonPeripherals: globalThis.Array.isArray(object?.chameleonPeripherals)
        ? object.chameleonPeripherals.map((e: any) => chameleonTypeFromJSON(e))
        : [],
      chameleonConnectionTypes: globalThis.Array.isArray(object?.chameleonConnectionTypes)
        ? object.chameleonConnectionTypes.map((e: any) => chameleonConnectionTypeFromJSON(e))
        : [],
      audioBoard: isSet(object.audioBoard) ? globalThis.Boolean(object.audioBoard) : false,
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
      audioboxJackplugger: isSet(object.audioboxJackplugger)
        ? chameleon_AudioBoxJackPluggerFromJSON(object.audioboxJackplugger)
        : 0,
      trrsType: isSet(object.trrsType) ? chameleon_TRRSTypeFromJSON(object.trrsType) : 0,
    };
  },

  toJSON(message: Chameleon): unknown {
    const obj: any = {};
    if (message.chameleonPeripherals?.length) {
      obj.chameleonPeripherals = message.chameleonPeripherals.map((e) => chameleonTypeToJSON(e));
    }
    if (message.chameleonConnectionTypes?.length) {
      obj.chameleonConnectionTypes = message.chameleonConnectionTypes.map((e) => chameleonConnectionTypeToJSON(e));
    }
    if (message.audioBoard !== false) {
      obj.audioBoard = message.audioBoard;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    if (message.audioboxJackplugger !== 0) {
      obj.audioboxJackplugger = chameleon_AudioBoxJackPluggerToJSON(message.audioboxJackplugger);
    }
    if (message.trrsType !== 0) {
      obj.trrsType = chameleon_TRRSTypeToJSON(message.trrsType);
    }
    return obj;
  },

  create(base?: DeepPartial<Chameleon>): Chameleon {
    return Chameleon.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Chameleon>): Chameleon {
    const message = createBaseChameleon() as any;
    message.chameleonPeripherals = object.chameleonPeripherals?.map((e) => e) || [];
    message.chameleonConnectionTypes = object.chameleonConnectionTypes?.map((e) => e) || [];
    message.audioBoard = object.audioBoard ?? false;
    message.hostname = object.hostname ?? "";
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    message.audioboxJackplugger = object.audioboxJackplugger ?? 0;
    message.trrsType = object.trrsType ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
