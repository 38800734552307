// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/lab/device.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Cbi } from "../../../../../../../chromiumos/config/proto/chromiumos/test/lab/api/cbi.pb";
import { ConfigId } from "../device/config_id.pb";
import { ConfigID } from "../manufacturing/config_id.pb";
import { ChromeOSDeviceID } from "./chromeos_device_id.pb";
import { License } from "./license.pb";
import { ModemInfo } from "./modeminfo.pb";
import { Peripherals } from "./peripherals.pb";
import { OSRPM } from "./rpm.pb";
import { Servo } from "./servo.pb";
import { SIMInfo } from "./siminfo.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.lab";

/** Next Tag: 7 */
export interface ChromeOSDevice {
  /** A unique ID for chromeos device, a randomly generated uuid or AssetTag. */
  readonly id: ChromeOSDeviceID | undefined;
  readonly serialNumber: string;
  readonly manufacturingId:
    | ConfigID
    | undefined;
  /**
   * Device config identifiers.
   * These values will be extracted from DUT and joinable to device config.
   */
  readonly deviceConfigId: ConfigId | undefined;
  readonly dut?: DeviceUnderTest | undefined;
  readonly labstation?: Labstation | undefined;
}

/** Next Tag: 12 */
export interface DeviceUnderTest {
  readonly hostname: string;
  readonly peripherals: Peripherals | undefined;
  readonly criticalPools: readonly DeviceUnderTest_DUTPool[];
  readonly pools: readonly string[];
  readonly licenses: readonly License[];
  readonly modeminfo: ModemInfo | undefined;
  readonly siminfo: readonly SIMInfo[];
  readonly roVpdMap: { [key: string]: string };
  /** CBI contents on the DUT */
  readonly cbi:
    | Cbi
    | undefined;
  /** Capability to support Chromebook X features */
  readonly cbx: boolean;
  /**
   * Provide DUT/drone affinity
   * Example: satlab-abc123
   */
  readonly hive: string;
  /** Power subrail config file */
  readonly subrailConfig: string;
}

/**
 * critical_pools are pool labels that the builders are dependent on, and
 * that the cros-infra team is responsible for managing explicitly. All other
 * pool labels used for adhoc labeling of DUTs go into self_serve_pools.
 * TO BE DELETED
 */
export enum DeviceUnderTest_DUTPool {
  DUT_POOL_INVALID = 0,
  DUT_POOL_CQ = 1,
  DUT_POOL_BVT = 2,
  DUT_POOL_SUITES = 3,
  DUT_POOL_CTS = 4,
  DUT_POOL_CTS_PERBUILD = 5,
  DUT_POOL_CONTINUOUS = 6,
  DUT_POOL_ARC_PRESUBMIT = 7,
  DUT_POOL_QUOTA = 8,
}

export function deviceUnderTest_DUTPoolFromJSON(object: any): DeviceUnderTest_DUTPool {
  switch (object) {
    case 0:
    case "DUT_POOL_INVALID":
      return DeviceUnderTest_DUTPool.DUT_POOL_INVALID;
    case 1:
    case "DUT_POOL_CQ":
      return DeviceUnderTest_DUTPool.DUT_POOL_CQ;
    case 2:
    case "DUT_POOL_BVT":
      return DeviceUnderTest_DUTPool.DUT_POOL_BVT;
    case 3:
    case "DUT_POOL_SUITES":
      return DeviceUnderTest_DUTPool.DUT_POOL_SUITES;
    case 4:
    case "DUT_POOL_CTS":
      return DeviceUnderTest_DUTPool.DUT_POOL_CTS;
    case 5:
    case "DUT_POOL_CTS_PERBUILD":
      return DeviceUnderTest_DUTPool.DUT_POOL_CTS_PERBUILD;
    case 6:
    case "DUT_POOL_CONTINUOUS":
      return DeviceUnderTest_DUTPool.DUT_POOL_CONTINUOUS;
    case 7:
    case "DUT_POOL_ARC_PRESUBMIT":
      return DeviceUnderTest_DUTPool.DUT_POOL_ARC_PRESUBMIT;
    case 8:
    case "DUT_POOL_QUOTA":
      return DeviceUnderTest_DUTPool.DUT_POOL_QUOTA;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceUnderTest_DUTPool");
  }
}

export function deviceUnderTest_DUTPoolToJSON(object: DeviceUnderTest_DUTPool): string {
  switch (object) {
    case DeviceUnderTest_DUTPool.DUT_POOL_INVALID:
      return "DUT_POOL_INVALID";
    case DeviceUnderTest_DUTPool.DUT_POOL_CQ:
      return "DUT_POOL_CQ";
    case DeviceUnderTest_DUTPool.DUT_POOL_BVT:
      return "DUT_POOL_BVT";
    case DeviceUnderTest_DUTPool.DUT_POOL_SUITES:
      return "DUT_POOL_SUITES";
    case DeviceUnderTest_DUTPool.DUT_POOL_CTS:
      return "DUT_POOL_CTS";
    case DeviceUnderTest_DUTPool.DUT_POOL_CTS_PERBUILD:
      return "DUT_POOL_CTS_PERBUILD";
    case DeviceUnderTest_DUTPool.DUT_POOL_CONTINUOUS:
      return "DUT_POOL_CONTINUOUS";
    case DeviceUnderTest_DUTPool.DUT_POOL_ARC_PRESUBMIT:
      return "DUT_POOL_ARC_PRESUBMIT";
    case DeviceUnderTest_DUTPool.DUT_POOL_QUOTA:
      return "DUT_POOL_QUOTA";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeviceUnderTest_DUTPool");
  }
}

export interface DeviceUnderTest_RoVpdMapEntry {
  readonly key: string;
  readonly value: string;
}

/** Next Tag: 6 */
export interface Labstation {
  readonly hostname: string;
  readonly servos: readonly Servo[];
  readonly rpm: OSRPM | undefined;
  readonly pools: readonly string[];
  readonly hive: string;
}

/** Next Tag: 3 */
export interface Devboard {
  readonly servo: Servo | undefined;
  readonly pools: readonly string[];
}

function createBaseChromeOSDevice(): ChromeOSDevice {
  return {
    id: undefined,
    serialNumber: "",
    manufacturingId: undefined,
    deviceConfigId: undefined,
    dut: undefined,
    labstation: undefined,
  };
}

export const ChromeOSDevice: MessageFns<ChromeOSDevice> = {
  encode(message: ChromeOSDevice, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      ChromeOSDeviceID.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.serialNumber !== "") {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.manufacturingId !== undefined) {
      ConfigID.encode(message.manufacturingId, writer.uint32(26).fork()).join();
    }
    if (message.deviceConfigId !== undefined) {
      ConfigId.encode(message.deviceConfigId, writer.uint32(34).fork()).join();
    }
    if (message.dut !== undefined) {
      DeviceUnderTest.encode(message.dut, writer.uint32(42).fork()).join();
    }
    if (message.labstation !== undefined) {
      Labstation.encode(message.labstation, writer.uint32(50).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): ChromeOSDevice {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChromeOSDevice() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = ChromeOSDeviceID.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.manufacturingId = ConfigID.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.deviceConfigId = ConfigId.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.dut = DeviceUnderTest.decode(reader, reader.uint32());
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.labstation = Labstation.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChromeOSDevice {
    return {
      id: isSet(object.id) ? ChromeOSDeviceID.fromJSON(object.id) : undefined,
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      manufacturingId: isSet(object.manufacturingId) ? ConfigID.fromJSON(object.manufacturingId) : undefined,
      deviceConfigId: isSet(object.deviceConfigId) ? ConfigId.fromJSON(object.deviceConfigId) : undefined,
      dut: isSet(object.dut) ? DeviceUnderTest.fromJSON(object.dut) : undefined,
      labstation: isSet(object.labstation) ? Labstation.fromJSON(object.labstation) : undefined,
    };
  },

  toJSON(message: ChromeOSDevice): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = ChromeOSDeviceID.toJSON(message.id);
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.manufacturingId !== undefined) {
      obj.manufacturingId = ConfigID.toJSON(message.manufacturingId);
    }
    if (message.deviceConfigId !== undefined) {
      obj.deviceConfigId = ConfigId.toJSON(message.deviceConfigId);
    }
    if (message.dut !== undefined) {
      obj.dut = DeviceUnderTest.toJSON(message.dut);
    }
    if (message.labstation !== undefined) {
      obj.labstation = Labstation.toJSON(message.labstation);
    }
    return obj;
  },

  create(base?: DeepPartial<ChromeOSDevice>): ChromeOSDevice {
    return ChromeOSDevice.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<ChromeOSDevice>): ChromeOSDevice {
    const message = createBaseChromeOSDevice() as any;
    message.id = (object.id !== undefined && object.id !== null) ? ChromeOSDeviceID.fromPartial(object.id) : undefined;
    message.serialNumber = object.serialNumber ?? "";
    message.manufacturingId = (object.manufacturingId !== undefined && object.manufacturingId !== null)
      ? ConfigID.fromPartial(object.manufacturingId)
      : undefined;
    message.deviceConfigId = (object.deviceConfigId !== undefined && object.deviceConfigId !== null)
      ? ConfigId.fromPartial(object.deviceConfigId)
      : undefined;
    message.dut = (object.dut !== undefined && object.dut !== null)
      ? DeviceUnderTest.fromPartial(object.dut)
      : undefined;
    message.labstation = (object.labstation !== undefined && object.labstation !== null)
      ? Labstation.fromPartial(object.labstation)
      : undefined;
    return message;
  },
};

function createBaseDeviceUnderTest(): DeviceUnderTest {
  return {
    hostname: "",
    peripherals: undefined,
    criticalPools: [],
    pools: [],
    licenses: [],
    modeminfo: undefined,
    siminfo: [],
    roVpdMap: {},
    cbi: undefined,
    cbx: false,
    hive: "",
    subrailConfig: "",
  };
}

export const DeviceUnderTest: MessageFns<DeviceUnderTest> = {
  encode(message: DeviceUnderTest, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.peripherals !== undefined) {
      Peripherals.encode(message.peripherals, writer.uint32(18).fork()).join();
    }
    writer.uint32(26).fork();
    for (const v of message.criticalPools) {
      writer.int32(v);
    }
    writer.join();
    for (const v of message.pools) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.licenses) {
      License.encode(v!, writer.uint32(42).fork()).join();
    }
    if (message.modeminfo !== undefined) {
      ModemInfo.encode(message.modeminfo, writer.uint32(50).fork()).join();
    }
    for (const v of message.siminfo) {
      SIMInfo.encode(v!, writer.uint32(58).fork()).join();
    }
    Object.entries(message.roVpdMap).forEach(([key, value]) => {
      DeviceUnderTest_RoVpdMapEntry.encode({ key: key as any, value }, writer.uint32(66).fork()).join();
    });
    if (message.cbi !== undefined) {
      Cbi.encode(message.cbi, writer.uint32(74).fork()).join();
    }
    if (message.cbx !== false) {
      writer.uint32(80).bool(message.cbx);
    }
    if (message.hive !== "") {
      writer.uint32(90).string(message.hive);
    }
    if (message.subrailConfig !== "") {
      writer.uint32(98).string(message.subrailConfig);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceUnderTest {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceUnderTest() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.peripherals = Peripherals.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag === 24) {
            message.criticalPools.push(reader.int32() as any);

            continue;
          }

          if (tag === 26) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.criticalPools.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.pools.push(reader.string());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.licenses.push(License.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.modeminfo = ModemInfo.decode(reader, reader.uint32());
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.siminfo.push(SIMInfo.decode(reader, reader.uint32()));
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          const entry8 = DeviceUnderTest_RoVpdMapEntry.decode(reader, reader.uint32());
          if (entry8.value !== undefined) {
            message.roVpdMap[entry8.key] = entry8.value;
          }
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.cbi = Cbi.decode(reader, reader.uint32());
          continue;
        }
        case 10: {
          if (tag !== 80) {
            break;
          }

          message.cbx = reader.bool();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.hive = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.subrailConfig = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceUnderTest {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      peripherals: isSet(object.peripherals) ? Peripherals.fromJSON(object.peripherals) : undefined,
      criticalPools: globalThis.Array.isArray(object?.criticalPools)
        ? object.criticalPools.map((e: any) => deviceUnderTest_DUTPoolFromJSON(e))
        : [],
      pools: globalThis.Array.isArray(object?.pools) ? object.pools.map((e: any) => globalThis.String(e)) : [],
      licenses: globalThis.Array.isArray(object?.licenses) ? object.licenses.map((e: any) => License.fromJSON(e)) : [],
      modeminfo: isSet(object.modeminfo) ? ModemInfo.fromJSON(object.modeminfo) : undefined,
      siminfo: globalThis.Array.isArray(object?.siminfo) ? object.siminfo.map((e: any) => SIMInfo.fromJSON(e)) : [],
      roVpdMap: isObject(object.roVpdMap)
        ? Object.entries(object.roVpdMap).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      cbi: isSet(object.cbi) ? Cbi.fromJSON(object.cbi) : undefined,
      cbx: isSet(object.cbx) ? globalThis.Boolean(object.cbx) : false,
      hive: isSet(object.hive) ? globalThis.String(object.hive) : "",
      subrailConfig: isSet(object.subrailConfig) ? globalThis.String(object.subrailConfig) : "",
    };
  },

  toJSON(message: DeviceUnderTest): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.peripherals !== undefined) {
      obj.peripherals = Peripherals.toJSON(message.peripherals);
    }
    if (message.criticalPools?.length) {
      obj.criticalPools = message.criticalPools.map((e) => deviceUnderTest_DUTPoolToJSON(e));
    }
    if (message.pools?.length) {
      obj.pools = message.pools;
    }
    if (message.licenses?.length) {
      obj.licenses = message.licenses.map((e) => License.toJSON(e));
    }
    if (message.modeminfo !== undefined) {
      obj.modeminfo = ModemInfo.toJSON(message.modeminfo);
    }
    if (message.siminfo?.length) {
      obj.siminfo = message.siminfo.map((e) => SIMInfo.toJSON(e));
    }
    if (message.roVpdMap) {
      const entries = Object.entries(message.roVpdMap);
      if (entries.length > 0) {
        obj.roVpdMap = {};
        entries.forEach(([k, v]) => {
          obj.roVpdMap[k] = v;
        });
      }
    }
    if (message.cbi !== undefined) {
      obj.cbi = Cbi.toJSON(message.cbi);
    }
    if (message.cbx !== false) {
      obj.cbx = message.cbx;
    }
    if (message.hive !== "") {
      obj.hive = message.hive;
    }
    if (message.subrailConfig !== "") {
      obj.subrailConfig = message.subrailConfig;
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceUnderTest>): DeviceUnderTest {
    return DeviceUnderTest.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceUnderTest>): DeviceUnderTest {
    const message = createBaseDeviceUnderTest() as any;
    message.hostname = object.hostname ?? "";
    message.peripherals = (object.peripherals !== undefined && object.peripherals !== null)
      ? Peripherals.fromPartial(object.peripherals)
      : undefined;
    message.criticalPools = object.criticalPools?.map((e) => e) || [];
    message.pools = object.pools?.map((e) => e) || [];
    message.licenses = object.licenses?.map((e) => License.fromPartial(e)) || [];
    message.modeminfo = (object.modeminfo !== undefined && object.modeminfo !== null)
      ? ModemInfo.fromPartial(object.modeminfo)
      : undefined;
    message.siminfo = object.siminfo?.map((e) => SIMInfo.fromPartial(e)) || [];
    message.roVpdMap = Object.entries(object.roVpdMap ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.cbi = (object.cbi !== undefined && object.cbi !== null) ? Cbi.fromPartial(object.cbi) : undefined;
    message.cbx = object.cbx ?? false;
    message.hive = object.hive ?? "";
    message.subrailConfig = object.subrailConfig ?? "";
    return message;
  },
};

function createBaseDeviceUnderTest_RoVpdMapEntry(): DeviceUnderTest_RoVpdMapEntry {
  return { key: "", value: "" };
}

export const DeviceUnderTest_RoVpdMapEntry: MessageFns<DeviceUnderTest_RoVpdMapEntry> = {
  encode(message: DeviceUnderTest_RoVpdMapEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceUnderTest_RoVpdMapEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceUnderTest_RoVpdMapEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceUnderTest_RoVpdMapEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: DeviceUnderTest_RoVpdMapEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceUnderTest_RoVpdMapEntry>): DeviceUnderTest_RoVpdMapEntry {
    return DeviceUnderTest_RoVpdMapEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceUnderTest_RoVpdMapEntry>): DeviceUnderTest_RoVpdMapEntry {
    const message = createBaseDeviceUnderTest_RoVpdMapEntry() as any;
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseLabstation(): Labstation {
  return { hostname: "", servos: [], rpm: undefined, pools: [], hive: "" };
}

export const Labstation: MessageFns<Labstation> = {
  encode(message: Labstation, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    for (const v of message.servos) {
      Servo.encode(v!, writer.uint32(18).fork()).join();
    }
    if (message.rpm !== undefined) {
      OSRPM.encode(message.rpm, writer.uint32(26).fork()).join();
    }
    for (const v of message.pools) {
      writer.uint32(34).string(v!);
    }
    if (message.hive !== "") {
      writer.uint32(42).string(message.hive);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Labstation {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLabstation() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.servos.push(Servo.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.rpm = OSRPM.decode(reader, reader.uint32());
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.pools.push(reader.string());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.hive = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Labstation {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      servos: globalThis.Array.isArray(object?.servos) ? object.servos.map((e: any) => Servo.fromJSON(e)) : [],
      rpm: isSet(object.rpm) ? OSRPM.fromJSON(object.rpm) : undefined,
      pools: globalThis.Array.isArray(object?.pools) ? object.pools.map((e: any) => globalThis.String(e)) : [],
      hive: isSet(object.hive) ? globalThis.String(object.hive) : "",
    };
  },

  toJSON(message: Labstation): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.servos?.length) {
      obj.servos = message.servos.map((e) => Servo.toJSON(e));
    }
    if (message.rpm !== undefined) {
      obj.rpm = OSRPM.toJSON(message.rpm);
    }
    if (message.pools?.length) {
      obj.pools = message.pools;
    }
    if (message.hive !== "") {
      obj.hive = message.hive;
    }
    return obj;
  },

  create(base?: DeepPartial<Labstation>): Labstation {
    return Labstation.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Labstation>): Labstation {
    const message = createBaseLabstation() as any;
    message.hostname = object.hostname ?? "";
    message.servos = object.servos?.map((e) => Servo.fromPartial(e)) || [];
    message.rpm = (object.rpm !== undefined && object.rpm !== null) ? OSRPM.fromPartial(object.rpm) : undefined;
    message.pools = object.pools?.map((e) => e) || [];
    message.hive = object.hive ?? "";
    return message;
  },
};

function createBaseDevboard(): Devboard {
  return { servo: undefined, pools: [] };
}

export const Devboard: MessageFns<Devboard> = {
  encode(message: Devboard, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.servo !== undefined) {
      Servo.encode(message.servo, writer.uint32(10).fork()).join();
    }
    for (const v of message.pools) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Devboard {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDevboard() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.servo = Servo.decode(reader, reader.uint32());
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.pools.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Devboard {
    return {
      servo: isSet(object.servo) ? Servo.fromJSON(object.servo) : undefined,
      pools: globalThis.Array.isArray(object?.pools) ? object.pools.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: Devboard): unknown {
    const obj: any = {};
    if (message.servo !== undefined) {
      obj.servo = Servo.toJSON(message.servo);
    }
    if (message.pools?.length) {
      obj.pools = message.pools;
    }
    return obj;
  },

  create(base?: DeepPartial<Devboard>): Devboard {
    return Devboard.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Devboard>): Devboard {
    const message = createBaseDevboard() as any;
    message.servo = (object.servo !== undefined && object.servo !== null) ? Servo.fromPartial(object.servo) : undefined;
    message.pools = object.pools?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
