// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/chromiumos/config/proto/chromiumos/test/lab/api/pasit_host.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "chromiumos.test.lab.api";

/**
 * PASIT is a connected set of peripheral components used in interop testing.
 * The components connected are controlled via an external host. PasitHost
 * describes the devices in the testbed and their connection topology.
 */
export interface PasitHost {
  /** The name of the host that controls the devices (e.g. switches) */
  readonly hostname: string;
  /**
   * The components/devices in the PASIT testbed (e.g. docks, switches, cameras,
   * monitors, etc)
   */
  readonly devices: readonly PasitHost_Device[];
  /** The connections between devices/components in the testbed. */
  readonly connections: readonly PasitHost_Connection[];
}

/** A single connection between two components in the topology. */
export interface PasitHost_Connection {
  /**
   * If the parent device has multiple connection ports/slots,
   * this is the name or id of the port.
   */
  readonly parentPort: string;
  /** The ID of the parent component; */
  readonly parentId: string;
  /** The ID of the child component; */
  readonly childId: string;
  /** The speed of the connection. */
  readonly speed: number;
  /** The physical port type/technology, e.g. "USBA", "USBC", "HDMI". */
  readonly type: string;
}

/** A single device/component in the testbed. */
export interface PasitHost_Device {
  /**
   * The unique ID of the device. This is not unique across all devices,
   * in the lab but is unique within a testbed.
   */
  readonly id: string;
  /** The the make/model of the device. */
  readonly model: string;
  /** The type of device represented. */
  readonly type: PasitHost_Device_Type;
  /**
   * Additional power supply information for devices that provide
   * power to the DUT.
   */
  readonly powerSupply: PasitHost_Device_PowerSupply | undefined;
}

/** The type of device represented. */
export enum PasitHost_Device_Type {
  UNKNOWN = 0,
  DUT = 1,
  SWITCH_FIXTURE = 2,
  DOCKING_STATION = 3,
  MONITOR = 4,
  CAMERA = 5,
  STORAGE = 6,
  HID = 7,
  NETWORK = 8,
  HEADPHONE = 9,
  SPEAKER = 10,
}

export function pasitHost_Device_TypeFromJSON(object: any): PasitHost_Device_Type {
  switch (object) {
    case 0:
    case "UNKNOWN":
      return PasitHost_Device_Type.UNKNOWN;
    case 1:
    case "DUT":
      return PasitHost_Device_Type.DUT;
    case 2:
    case "SWITCH_FIXTURE":
      return PasitHost_Device_Type.SWITCH_FIXTURE;
    case 3:
    case "DOCKING_STATION":
      return PasitHost_Device_Type.DOCKING_STATION;
    case 4:
    case "MONITOR":
      return PasitHost_Device_Type.MONITOR;
    case 5:
    case "CAMERA":
      return PasitHost_Device_Type.CAMERA;
    case 6:
    case "STORAGE":
      return PasitHost_Device_Type.STORAGE;
    case 7:
    case "HID":
      return PasitHost_Device_Type.HID;
    case 8:
    case "NETWORK":
      return PasitHost_Device_Type.NETWORK;
    case 9:
    case "HEADPHONE":
      return PasitHost_Device_Type.HEADPHONE;
    case 10:
    case "SPEAKER":
      return PasitHost_Device_Type.SPEAKER;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PasitHost_Device_Type");
  }
}

export function pasitHost_Device_TypeToJSON(object: PasitHost_Device_Type): string {
  switch (object) {
    case PasitHost_Device_Type.UNKNOWN:
      return "UNKNOWN";
    case PasitHost_Device_Type.DUT:
      return "DUT";
    case PasitHost_Device_Type.SWITCH_FIXTURE:
      return "SWITCH_FIXTURE";
    case PasitHost_Device_Type.DOCKING_STATION:
      return "DOCKING_STATION";
    case PasitHost_Device_Type.MONITOR:
      return "MONITOR";
    case PasitHost_Device_Type.CAMERA:
      return "CAMERA";
    case PasitHost_Device_Type.STORAGE:
      return "STORAGE";
    case PasitHost_Device_Type.HID:
      return "HID";
    case PasitHost_Device_Type.NETWORK:
      return "NETWORK";
    case PasitHost_Device_Type.HEADPHONE:
      return "HEADPHONE";
    case PasitHost_Device_Type.SPEAKER:
      return "SPEAKER";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum PasitHost_Device_Type");
  }
}

/** Information on the power capabilities of the device. */
export interface PasitHost_Device_PowerSupply {
  readonly current: number;
  readonly voltage: number;
  readonly power: number;
}

function createBasePasitHost(): PasitHost {
  return { hostname: "", devices: [], connections: [] };
}

export const PasitHost: MessageFns<PasitHost> = {
  encode(message: PasitHost, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    for (const v of message.devices) {
      PasitHost_Device.encode(v!, writer.uint32(18).fork()).join();
    }
    for (const v of message.connections) {
      PasitHost_Connection.encode(v!, writer.uint32(26).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PasitHost {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasitHost() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.devices.push(PasitHost_Device.decode(reader, reader.uint32()));
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.connections.push(PasitHost_Connection.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasitHost {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      devices: globalThis.Array.isArray(object?.devices)
        ? object.devices.map((e: any) => PasitHost_Device.fromJSON(e))
        : [],
      connections: globalThis.Array.isArray(object?.connections)
        ? object.connections.map((e: any) => PasitHost_Connection.fromJSON(e))
        : [],
    };
  },

  toJSON(message: PasitHost): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.devices?.length) {
      obj.devices = message.devices.map((e) => PasitHost_Device.toJSON(e));
    }
    if (message.connections?.length) {
      obj.connections = message.connections.map((e) => PasitHost_Connection.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<PasitHost>): PasitHost {
    return PasitHost.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PasitHost>): PasitHost {
    const message = createBasePasitHost() as any;
    message.hostname = object.hostname ?? "";
    message.devices = object.devices?.map((e) => PasitHost_Device.fromPartial(e)) || [];
    message.connections = object.connections?.map((e) => PasitHost_Connection.fromPartial(e)) || [];
    return message;
  },
};

function createBasePasitHost_Connection(): PasitHost_Connection {
  return { parentPort: "", parentId: "", childId: "", speed: 0, type: "" };
}

export const PasitHost_Connection: MessageFns<PasitHost_Connection> = {
  encode(message: PasitHost_Connection, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.parentPort !== "") {
      writer.uint32(10).string(message.parentPort);
    }
    if (message.parentId !== "") {
      writer.uint32(18).string(message.parentId);
    }
    if (message.childId !== "") {
      writer.uint32(26).string(message.childId);
    }
    if (message.speed !== 0) {
      writer.uint32(37).float(message.speed);
    }
    if (message.type !== "") {
      writer.uint32(42).string(message.type);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PasitHost_Connection {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasitHost_Connection() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.parentPort = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.parentId = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.childId = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 37) {
            break;
          }

          message.speed = reader.float();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.type = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasitHost_Connection {
    return {
      parentPort: isSet(object.parentPort) ? globalThis.String(object.parentPort) : "",
      parentId: isSet(object.parentId) ? globalThis.String(object.parentId) : "",
      childId: isSet(object.childId) ? globalThis.String(object.childId) : "",
      speed: isSet(object.speed) ? globalThis.Number(object.speed) : 0,
      type: isSet(object.type) ? globalThis.String(object.type) : "",
    };
  },

  toJSON(message: PasitHost_Connection): unknown {
    const obj: any = {};
    if (message.parentPort !== "") {
      obj.parentPort = message.parentPort;
    }
    if (message.parentId !== "") {
      obj.parentId = message.parentId;
    }
    if (message.childId !== "") {
      obj.childId = message.childId;
    }
    if (message.speed !== 0) {
      obj.speed = message.speed;
    }
    if (message.type !== "") {
      obj.type = message.type;
    }
    return obj;
  },

  create(base?: DeepPartial<PasitHost_Connection>): PasitHost_Connection {
    return PasitHost_Connection.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PasitHost_Connection>): PasitHost_Connection {
    const message = createBasePasitHost_Connection() as any;
    message.parentPort = object.parentPort ?? "";
    message.parentId = object.parentId ?? "";
    message.childId = object.childId ?? "";
    message.speed = object.speed ?? 0;
    message.type = object.type ?? "";
    return message;
  },
};

function createBasePasitHost_Device(): PasitHost_Device {
  return { id: "", model: "", type: 0, powerSupply: undefined };
}

export const PasitHost_Device: MessageFns<PasitHost_Device> = {
  encode(message: PasitHost_Device, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.model !== "") {
      writer.uint32(18).string(message.model);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.powerSupply !== undefined) {
      PasitHost_Device_PowerSupply.encode(message.powerSupply, writer.uint32(34).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PasitHost_Device {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasitHost_Device() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.model = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.powerSupply = PasitHost_Device_PowerSupply.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasitHost_Device {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      model: isSet(object.model) ? globalThis.String(object.model) : "",
      type: isSet(object.type) ? pasitHost_Device_TypeFromJSON(object.type) : 0,
      powerSupply: isSet(object.powerSupply) ? PasitHost_Device_PowerSupply.fromJSON(object.powerSupply) : undefined,
    };
  },

  toJSON(message: PasitHost_Device): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.model !== "") {
      obj.model = message.model;
    }
    if (message.type !== 0) {
      obj.type = pasitHost_Device_TypeToJSON(message.type);
    }
    if (message.powerSupply !== undefined) {
      obj.powerSupply = PasitHost_Device_PowerSupply.toJSON(message.powerSupply);
    }
    return obj;
  },

  create(base?: DeepPartial<PasitHost_Device>): PasitHost_Device {
    return PasitHost_Device.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PasitHost_Device>): PasitHost_Device {
    const message = createBasePasitHost_Device() as any;
    message.id = object.id ?? "";
    message.model = object.model ?? "";
    message.type = object.type ?? 0;
    message.powerSupply = (object.powerSupply !== undefined && object.powerSupply !== null)
      ? PasitHost_Device_PowerSupply.fromPartial(object.powerSupply)
      : undefined;
    return message;
  },
};

function createBasePasitHost_Device_PowerSupply(): PasitHost_Device_PowerSupply {
  return { current: 0, voltage: 0, power: 0 };
}

export const PasitHost_Device_PowerSupply: MessageFns<PasitHost_Device_PowerSupply> = {
  encode(message: PasitHost_Device_PowerSupply, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.current !== 0) {
      writer.uint32(13).float(message.current);
    }
    if (message.voltage !== 0) {
      writer.uint32(21).float(message.voltage);
    }
    if (message.power !== 0) {
      writer.uint32(29).float(message.power);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): PasitHost_Device_PowerSupply {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePasitHost_Device_PowerSupply() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 13) {
            break;
          }

          message.current = reader.float();
          continue;
        }
        case 2: {
          if (tag !== 21) {
            break;
          }

          message.voltage = reader.float();
          continue;
        }
        case 3: {
          if (tag !== 29) {
            break;
          }

          message.power = reader.float();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PasitHost_Device_PowerSupply {
    return {
      current: isSet(object.current) ? globalThis.Number(object.current) : 0,
      voltage: isSet(object.voltage) ? globalThis.Number(object.voltage) : 0,
      power: isSet(object.power) ? globalThis.Number(object.power) : 0,
    };
  },

  toJSON(message: PasitHost_Device_PowerSupply): unknown {
    const obj: any = {};
    if (message.current !== 0) {
      obj.current = message.current;
    }
    if (message.voltage !== 0) {
      obj.voltage = message.voltage;
    }
    if (message.power !== 0) {
      obj.power = message.power;
    }
    return obj;
  },

  create(base?: DeepPartial<PasitHost_Device_PowerSupply>): PasitHost_Device_PowerSupply {
    return PasitHost_Device_PowerSupply.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<PasitHost_Device_PowerSupply>): PasitHost_Device_PowerSupply {
    const message = createBasePasitHost_Device_PowerSupply() as any;
    message.current = object.current ?? 0;
    message.voltage = object.voltage ?? 0;
    message.power = object.power ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
