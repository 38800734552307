// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/device_labels.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "unifiedfleet.api.v1.models";

/** Next Tag: 5 */
export enum ResourceType {
  RESOURCE_TYPE_UNSPECIFIED = 0,
  RESOURCE_TYPE_SCHEDULING_UNIT = 1,
  RESOURCE_TYPE_CHROMEOS_DEVICE = 2,
  RESOURCE_TYPE_ATTACHED_DEVICE = 3,
  RESOURCE_TYPE_BROWSER_DEVICE = 4,
}

export function resourceTypeFromJSON(object: any): ResourceType {
  switch (object) {
    case 0:
    case "RESOURCE_TYPE_UNSPECIFIED":
      return ResourceType.RESOURCE_TYPE_UNSPECIFIED;
    case 1:
    case "RESOURCE_TYPE_SCHEDULING_UNIT":
      return ResourceType.RESOURCE_TYPE_SCHEDULING_UNIT;
    case 2:
    case "RESOURCE_TYPE_CHROMEOS_DEVICE":
      return ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE;
    case 3:
    case "RESOURCE_TYPE_ATTACHED_DEVICE":
      return ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE;
    case 4:
    case "RESOURCE_TYPE_BROWSER_DEVICE":
      return ResourceType.RESOURCE_TYPE_BROWSER_DEVICE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceType");
  }
}

export function resourceTypeToJSON(object: ResourceType): string {
  switch (object) {
    case ResourceType.RESOURCE_TYPE_UNSPECIFIED:
      return "RESOURCE_TYPE_UNSPECIFIED";
    case ResourceType.RESOURCE_TYPE_SCHEDULING_UNIT:
      return "RESOURCE_TYPE_SCHEDULING_UNIT";
    case ResourceType.RESOURCE_TYPE_CHROMEOS_DEVICE:
      return "RESOURCE_TYPE_CHROMEOS_DEVICE";
    case ResourceType.RESOURCE_TYPE_ATTACHED_DEVICE:
      return "RESOURCE_TYPE_ATTACHED_DEVICE";
    case ResourceType.RESOURCE_TYPE_BROWSER_DEVICE:
      return "RESOURCE_TYPE_BROWSER_DEVICE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum ResourceType");
  }
}

/**
 * DeviceLabels represents a map of device labels for scheduling purposes
 *
 * Next Tag: 5
 */
export interface DeviceLabels {
  readonly name: string;
  readonly resourceType: ResourceType;
  readonly labels: { [key: string]: DeviceLabelValues };
  readonly realm: string;
}

export interface DeviceLabels_LabelsEntry {
  readonly key: string;
  readonly value: DeviceLabelValues | undefined;
}

/** DeviceLabelValues is an array of device label values. */
export interface DeviceLabelValues {
  readonly labelValues: readonly string[];
}

function createBaseDeviceLabels(): DeviceLabels {
  return { name: "", resourceType: 0, labels: {}, realm: "" };
}

export const DeviceLabels: MessageFns<DeviceLabels> = {
  encode(message: DeviceLabels, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.resourceType !== 0) {
      writer.uint32(16).int32(message.resourceType);
    }
    Object.entries(message.labels).forEach(([key, value]) => {
      DeviceLabels_LabelsEntry.encode({ key: key as any, value }, writer.uint32(26).fork()).join();
    });
    if (message.realm !== "") {
      writer.uint32(34).string(message.realm);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceLabels {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceLabels() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.resourceType = reader.int32() as any;
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          const entry3 = DeviceLabels_LabelsEntry.decode(reader, reader.uint32());
          if (entry3.value !== undefined) {
            message.labels[entry3.key] = entry3.value;
          }
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.realm = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceLabels {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      resourceType: isSet(object.resourceType) ? resourceTypeFromJSON(object.resourceType) : 0,
      labels: isObject(object.labels)
        ? Object.entries(object.labels).reduce<{ [key: string]: DeviceLabelValues }>((acc, [key, value]) => {
          acc[key] = DeviceLabelValues.fromJSON(value);
          return acc;
        }, {})
        : {},
      realm: isSet(object.realm) ? globalThis.String(object.realm) : "",
    };
  },

  toJSON(message: DeviceLabels): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.resourceType !== 0) {
      obj.resourceType = resourceTypeToJSON(message.resourceType);
    }
    if (message.labels) {
      const entries = Object.entries(message.labels);
      if (entries.length > 0) {
        obj.labels = {};
        entries.forEach(([k, v]) => {
          obj.labels[k] = DeviceLabelValues.toJSON(v);
        });
      }
    }
    if (message.realm !== "") {
      obj.realm = message.realm;
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceLabels>): DeviceLabels {
    return DeviceLabels.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceLabels>): DeviceLabels {
    const message = createBaseDeviceLabels() as any;
    message.name = object.name ?? "";
    message.resourceType = object.resourceType ?? 0;
    message.labels = Object.entries(object.labels ?? {}).reduce<{ [key: string]: DeviceLabelValues }>(
      (acc, [key, value]) => {
        if (value !== undefined) {
          acc[key] = DeviceLabelValues.fromPartial(value);
        }
        return acc;
      },
      {},
    );
    message.realm = object.realm ?? "";
    return message;
  },
};

function createBaseDeviceLabels_LabelsEntry(): DeviceLabels_LabelsEntry {
  return { key: "", value: undefined };
}

export const DeviceLabels_LabelsEntry: MessageFns<DeviceLabels_LabelsEntry> = {
  encode(message: DeviceLabels_LabelsEntry, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== undefined) {
      DeviceLabelValues.encode(message.value, writer.uint32(18).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceLabels_LabelsEntry {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceLabels_LabelsEntry() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.value = DeviceLabelValues.decode(reader, reader.uint32());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceLabels_LabelsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? DeviceLabelValues.fromJSON(object.value) : undefined,
    };
  },

  toJSON(message: DeviceLabels_LabelsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== undefined) {
      obj.value = DeviceLabelValues.toJSON(message.value);
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceLabels_LabelsEntry>): DeviceLabels_LabelsEntry {
    return DeviceLabels_LabelsEntry.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceLabels_LabelsEntry>): DeviceLabels_LabelsEntry {
    const message = createBaseDeviceLabels_LabelsEntry() as any;
    message.key = object.key ?? "";
    message.value = (object.value !== undefined && object.value !== null)
      ? DeviceLabelValues.fromPartial(object.value)
      : undefined;
    return message;
  },
};

function createBaseDeviceLabelValues(): DeviceLabelValues {
  return { labelValues: [] };
}

export const DeviceLabelValues: MessageFns<DeviceLabelValues> = {
  encode(message: DeviceLabelValues, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.labelValues) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DeviceLabelValues {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeviceLabelValues() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.labelValues.push(reader.string());
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DeviceLabelValues {
    return {
      labelValues: globalThis.Array.isArray(object?.labelValues)
        ? object.labelValues.map((e: any) => globalThis.String(e))
        : [],
    };
  },

  toJSON(message: DeviceLabelValues): unknown {
    const obj: any = {};
    if (message.labelValues?.length) {
      obj.labelValues = message.labelValues;
    }
    return obj;
  },

  create(base?: DeepPartial<DeviceLabelValues>): DeviceLabelValues {
    return DeviceLabelValues.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DeviceLabelValues>): DeviceLabelValues {
    const message = createBaseDeviceLabelValues() as any;
    message.labelValues = object.labelValues?.map((e) => e) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
