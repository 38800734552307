// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/chromeos/device/config.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { ConfigId } from "./config_id.pb";

export const protobufPackage = "unifiedfleet.api.v1.models.chromeos.device";

/** Next tag: 22 */
export interface Config {
  /** Required. Unique ID of the device config. */
  readonly id: ConfigId | undefined;
  readonly formFactor: Config_FormFactor;
  /** e.g: "haswell", "tegra",... */
  readonly gpuFamily: string;
  readonly graphics: Config_Graphics;
  /**
   * If a hardware feature isn't specified, one can assume that it doesn't
   * exist on the device.
   */
  readonly hardwareFeatures: readonly Config_HardwareFeature[];
  readonly power: Config_PowerSupply;
  /** Indicate the device's storage type. */
  readonly storage: Config_Storage;
  readonly videoAccelerationSupports: readonly Config_VideoAcceleration[];
  readonly soc: Config_SOC;
  /** Full email address for TAMs responsible for device */
  readonly tam: readonly string[];
  /** Full email address for Google EEs responsible for device */
  readonly ee: readonly string[];
  /** ODM for device */
  readonly odm: Config_ODM;
  /**
   * Group email address for this device's odm contact in buganizer
   * (@google.com)
   */
  readonly odmEmailGroup: string;
  /** OEM for device */
  readonly oem: Config_OEM;
  /**
   * Group email address for this device's oem contact in buganizer
   * (@google.com)
   */
  readonly oemEmailGroup: string;
  /**
   * Group email address for this device's SoC contact in buganizer
   * (@google.com)
   */
  readonly socEmailGroup: string;
  /**
   * A bit field used by firmware to make decisions. The definition of this
   * firmware configuration field is per firmware build. This does not have
   * 1st-class definitions for values in the top-level configuration proto since
   * each firmware build target can appropriate the bits within this 32-bit
   * field differently. Each firmware build can vary significantly in the
   * configuration space that needs customization.
   */
  readonly firmwareConfiguration: number;
  readonly cpu: Config_Architecture;
  /** If an EC is not specified, one can assume the device has no EC. */
  readonly ec: Config_EC;
}

/** Next tag: 8 */
export enum Config_FormFactor {
  FORM_FACTOR_UNSPECIFIED = 0,
  FORM_FACTOR_CLAMSHELL = 1,
  FORM_FACTOR_CONVERTIBLE = 2,
  FORM_FACTOR_DETACHABLE = 3,
  FORM_FACTOR_CHROMEBASE = 4,
  FORM_FACTOR_CHROMEBOX = 5,
  FORM_FACTOR_CHROMEBIT = 6,
  FORM_FACTOR_CHROMESLATE = 7,
}

export function config_FormFactorFromJSON(object: any): Config_FormFactor {
  switch (object) {
    case 0:
    case "FORM_FACTOR_UNSPECIFIED":
      return Config_FormFactor.FORM_FACTOR_UNSPECIFIED;
    case 1:
    case "FORM_FACTOR_CLAMSHELL":
      return Config_FormFactor.FORM_FACTOR_CLAMSHELL;
    case 2:
    case "FORM_FACTOR_CONVERTIBLE":
      return Config_FormFactor.FORM_FACTOR_CONVERTIBLE;
    case 3:
    case "FORM_FACTOR_DETACHABLE":
      return Config_FormFactor.FORM_FACTOR_DETACHABLE;
    case 4:
    case "FORM_FACTOR_CHROMEBASE":
      return Config_FormFactor.FORM_FACTOR_CHROMEBASE;
    case 5:
    case "FORM_FACTOR_CHROMEBOX":
      return Config_FormFactor.FORM_FACTOR_CHROMEBOX;
    case 6:
    case "FORM_FACTOR_CHROMEBIT":
      return Config_FormFactor.FORM_FACTOR_CHROMEBIT;
    case 7:
    case "FORM_FACTOR_CHROMESLATE":
      return Config_FormFactor.FORM_FACTOR_CHROMESLATE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_FormFactor");
  }
}

export function config_FormFactorToJSON(object: Config_FormFactor): string {
  switch (object) {
    case Config_FormFactor.FORM_FACTOR_UNSPECIFIED:
      return "FORM_FACTOR_UNSPECIFIED";
    case Config_FormFactor.FORM_FACTOR_CLAMSHELL:
      return "FORM_FACTOR_CLAMSHELL";
    case Config_FormFactor.FORM_FACTOR_CONVERTIBLE:
      return "FORM_FACTOR_CONVERTIBLE";
    case Config_FormFactor.FORM_FACTOR_DETACHABLE:
      return "FORM_FACTOR_DETACHABLE";
    case Config_FormFactor.FORM_FACTOR_CHROMEBASE:
      return "FORM_FACTOR_CHROMEBASE";
    case Config_FormFactor.FORM_FACTOR_CHROMEBOX:
      return "FORM_FACTOR_CHROMEBOX";
    case Config_FormFactor.FORM_FACTOR_CHROMEBIT:
      return "FORM_FACTOR_CHROMEBIT";
    case Config_FormFactor.FORM_FACTOR_CHROMESLATE:
      return "FORM_FACTOR_CHROMESLATE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_FormFactor");
  }
}

/** Next Tag: 3 */
export enum Config_Graphics {
  GRAPHICS_UNSPECIFIED = 0,
  GRAPHICS_GL = 1,
  GRAPHICS_GLE = 2,
}

export function config_GraphicsFromJSON(object: any): Config_Graphics {
  switch (object) {
    case 0:
    case "GRAPHICS_UNSPECIFIED":
      return Config_Graphics.GRAPHICS_UNSPECIFIED;
    case 1:
    case "GRAPHICS_GL":
      return Config_Graphics.GRAPHICS_GL;
    case 2:
    case "GRAPHICS_GLE":
      return Config_Graphics.GRAPHICS_GLE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Graphics");
  }
}

export function config_GraphicsToJSON(object: Config_Graphics): string {
  switch (object) {
    case Config_Graphics.GRAPHICS_UNSPECIFIED:
      return "GRAPHICS_UNSPECIFIED";
    case Config_Graphics.GRAPHICS_GL:
      return "GRAPHICS_GL";
    case Config_Graphics.GRAPHICS_GLE:
      return "GRAPHICS_GLE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Graphics");
  }
}

/** Next Tag: 12 */
export enum Config_HardwareFeature {
  HARDWARE_FEATURE_UNSPECIFIED = 0,
  HARDWARE_FEATURE_BLUETOOTH = 1,
  HARDWARE_FEATURE_FLASHROM = 2,
  /**
   * HARDWARE_FEATURE_HOTWORDING - Indicate if the device support audio hotwording, an audio listening
   * module included with Google Chrome and Chromium to support Google
   * Assistant.
   * https://chromeos.google.com/partner/dlm/docs/latest-requirements/chromebook.html#audio-hotwording-always-on-listening
   */
  HARDWARE_FEATURE_HOTWORDING = 3,
  HARDWARE_FEATURE_INTERNAL_DISPLAY = 4,
  /**
   * HARDWARE_FEATURE_LUCID_SLEEP - Indicate if the device has support for lucid sleep (a connected lower
   * power state, in which the device should still has some network
   * communications)
   * https://chromeos.google.com/partner/dlm/docs/p-hardware-specs/lucidsleep.html
   */
  HARDWARE_FEATURE_LUCID_SLEEP = 5,
  HARDWARE_FEATURE_WEBCAM = 6,
  HARDWARE_FEATURE_STYLUS = 7,
  HARDWARE_FEATURE_TOUCHPAD = 8,
  HARDWARE_FEATURE_TOUCHSCREEN = 9,
  /**
   * HARDWARE_FEATURE_DETACHABLE_KEYBOARD - Indicate if the device has detachable keyboard.
   * https://chromeos.google.com/partner/dlm/docs/latest-requirements/detachable.html#keyboard-external
   */
  HARDWARE_FEATURE_DETACHABLE_KEYBOARD = 10,
  /** HARDWARE_FEATURE_FINGERPRINT - Indicate if the device has fingerprint sensor. */
  HARDWARE_FEATURE_FINGERPRINT = 11,
}

export function config_HardwareFeatureFromJSON(object: any): Config_HardwareFeature {
  switch (object) {
    case 0:
    case "HARDWARE_FEATURE_UNSPECIFIED":
      return Config_HardwareFeature.HARDWARE_FEATURE_UNSPECIFIED;
    case 1:
    case "HARDWARE_FEATURE_BLUETOOTH":
      return Config_HardwareFeature.HARDWARE_FEATURE_BLUETOOTH;
    case 2:
    case "HARDWARE_FEATURE_FLASHROM":
      return Config_HardwareFeature.HARDWARE_FEATURE_FLASHROM;
    case 3:
    case "HARDWARE_FEATURE_HOTWORDING":
      return Config_HardwareFeature.HARDWARE_FEATURE_HOTWORDING;
    case 4:
    case "HARDWARE_FEATURE_INTERNAL_DISPLAY":
      return Config_HardwareFeature.HARDWARE_FEATURE_INTERNAL_DISPLAY;
    case 5:
    case "HARDWARE_FEATURE_LUCID_SLEEP":
      return Config_HardwareFeature.HARDWARE_FEATURE_LUCID_SLEEP;
    case 6:
    case "HARDWARE_FEATURE_WEBCAM":
      return Config_HardwareFeature.HARDWARE_FEATURE_WEBCAM;
    case 7:
    case "HARDWARE_FEATURE_STYLUS":
      return Config_HardwareFeature.HARDWARE_FEATURE_STYLUS;
    case 8:
    case "HARDWARE_FEATURE_TOUCHPAD":
      return Config_HardwareFeature.HARDWARE_FEATURE_TOUCHPAD;
    case 9:
    case "HARDWARE_FEATURE_TOUCHSCREEN":
      return Config_HardwareFeature.HARDWARE_FEATURE_TOUCHSCREEN;
    case 10:
    case "HARDWARE_FEATURE_DETACHABLE_KEYBOARD":
      return Config_HardwareFeature.HARDWARE_FEATURE_DETACHABLE_KEYBOARD;
    case 11:
    case "HARDWARE_FEATURE_FINGERPRINT":
      return Config_HardwareFeature.HARDWARE_FEATURE_FINGERPRINT;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_HardwareFeature");
  }
}

export function config_HardwareFeatureToJSON(object: Config_HardwareFeature): string {
  switch (object) {
    case Config_HardwareFeature.HARDWARE_FEATURE_UNSPECIFIED:
      return "HARDWARE_FEATURE_UNSPECIFIED";
    case Config_HardwareFeature.HARDWARE_FEATURE_BLUETOOTH:
      return "HARDWARE_FEATURE_BLUETOOTH";
    case Config_HardwareFeature.HARDWARE_FEATURE_FLASHROM:
      return "HARDWARE_FEATURE_FLASHROM";
    case Config_HardwareFeature.HARDWARE_FEATURE_HOTWORDING:
      return "HARDWARE_FEATURE_HOTWORDING";
    case Config_HardwareFeature.HARDWARE_FEATURE_INTERNAL_DISPLAY:
      return "HARDWARE_FEATURE_INTERNAL_DISPLAY";
    case Config_HardwareFeature.HARDWARE_FEATURE_LUCID_SLEEP:
      return "HARDWARE_FEATURE_LUCID_SLEEP";
    case Config_HardwareFeature.HARDWARE_FEATURE_WEBCAM:
      return "HARDWARE_FEATURE_WEBCAM";
    case Config_HardwareFeature.HARDWARE_FEATURE_STYLUS:
      return "HARDWARE_FEATURE_STYLUS";
    case Config_HardwareFeature.HARDWARE_FEATURE_TOUCHPAD:
      return "HARDWARE_FEATURE_TOUCHPAD";
    case Config_HardwareFeature.HARDWARE_FEATURE_TOUCHSCREEN:
      return "HARDWARE_FEATURE_TOUCHSCREEN";
    case Config_HardwareFeature.HARDWARE_FEATURE_DETACHABLE_KEYBOARD:
      return "HARDWARE_FEATURE_DETACHABLE_KEYBOARD";
    case Config_HardwareFeature.HARDWARE_FEATURE_FINGERPRINT:
      return "HARDWARE_FEATURE_FINGERPRINT";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_HardwareFeature");
  }
}

/**
 * Indicate the device's power supply.
 * Next Tag: 3
 */
export enum Config_PowerSupply {
  POWER_SUPPLY_UNSPECIFIED = 0,
  POWER_SUPPLY_BATTERY = 1,
  POWER_SUPPLY_AC_ONLY = 2,
}

export function config_PowerSupplyFromJSON(object: any): Config_PowerSupply {
  switch (object) {
    case 0:
    case "POWER_SUPPLY_UNSPECIFIED":
      return Config_PowerSupply.POWER_SUPPLY_UNSPECIFIED;
    case 1:
    case "POWER_SUPPLY_BATTERY":
      return Config_PowerSupply.POWER_SUPPLY_BATTERY;
    case 2:
    case "POWER_SUPPLY_AC_ONLY":
      return Config_PowerSupply.POWER_SUPPLY_AC_ONLY;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_PowerSupply");
  }
}

export function config_PowerSupplyToJSON(object: Config_PowerSupply): string {
  switch (object) {
    case Config_PowerSupply.POWER_SUPPLY_UNSPECIFIED:
      return "POWER_SUPPLY_UNSPECIFIED";
    case Config_PowerSupply.POWER_SUPPLY_BATTERY:
      return "POWER_SUPPLY_BATTERY";
    case Config_PowerSupply.POWER_SUPPLY_AC_ONLY:
      return "POWER_SUPPLY_AC_ONLY";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_PowerSupply");
  }
}

/** Next Tag: 6 */
export enum Config_Storage {
  STORAGE_UNSPECIFIED = 0,
  STORAGE_SSD = 1,
  STORAGE_HDD = 2,
  STORAGE_MMC = 3,
  STORAGE_NVME = 4,
  STORAGE_UFS = 5,
}

export function config_StorageFromJSON(object: any): Config_Storage {
  switch (object) {
    case 0:
    case "STORAGE_UNSPECIFIED":
      return Config_Storage.STORAGE_UNSPECIFIED;
    case 1:
    case "STORAGE_SSD":
      return Config_Storage.STORAGE_SSD;
    case 2:
    case "STORAGE_HDD":
      return Config_Storage.STORAGE_HDD;
    case 3:
    case "STORAGE_MMC":
      return Config_Storage.STORAGE_MMC;
    case 4:
    case "STORAGE_NVME":
      return Config_Storage.STORAGE_NVME;
    case 5:
    case "STORAGE_UFS":
      return Config_Storage.STORAGE_UFS;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Storage");
  }
}

export function config_StorageToJSON(object: Config_Storage): string {
  switch (object) {
    case Config_Storage.STORAGE_UNSPECIFIED:
      return "STORAGE_UNSPECIFIED";
    case Config_Storage.STORAGE_SSD:
      return "STORAGE_SSD";
    case Config_Storage.STORAGE_HDD:
      return "STORAGE_HDD";
    case Config_Storage.STORAGE_MMC:
      return "STORAGE_MMC";
    case Config_Storage.STORAGE_NVME:
      return "STORAGE_NVME";
    case Config_Storage.STORAGE_UFS:
      return "STORAGE_UFS";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Storage");
  }
}

/** Next tag: 13 */
export enum Config_VideoAcceleration {
  VIDEO_UNSPECIFIED = 0,
  VIDEO_ACCELERATION_H264 = 1,
  VIDEO_ACCELERATION_ENC_H264 = 2,
  VIDEO_ACCELERATION_VP8 = 3,
  VIDEO_ACCELERATION_ENC_VP8 = 4,
  VIDEO_ACCELERATION_VP9 = 5,
  VIDEO_ACCELERATION_ENC_VP9 = 6,
  VIDEO_ACCELERATION_VP9_2 = 7,
  VIDEO_ACCELERATION_ENC_VP9_2 = 8,
  VIDEO_ACCELERATION_H265 = 9,
  VIDEO_ACCELERATION_ENC_H265 = 10,
  VIDEO_ACCELERATION_MJPG = 11,
  VIDEO_ACCELERATION_ENC_MJPG = 12,
}

export function config_VideoAccelerationFromJSON(object: any): Config_VideoAcceleration {
  switch (object) {
    case 0:
    case "VIDEO_UNSPECIFIED":
      return Config_VideoAcceleration.VIDEO_UNSPECIFIED;
    case 1:
    case "VIDEO_ACCELERATION_H264":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_H264;
    case 2:
    case "VIDEO_ACCELERATION_ENC_H264":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_H264;
    case 3:
    case "VIDEO_ACCELERATION_VP8":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_VP8;
    case 4:
    case "VIDEO_ACCELERATION_ENC_VP8":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP8;
    case 5:
    case "VIDEO_ACCELERATION_VP9":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_VP9;
    case 6:
    case "VIDEO_ACCELERATION_ENC_VP9":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9;
    case 7:
    case "VIDEO_ACCELERATION_VP9_2":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_VP9_2;
    case 8:
    case "VIDEO_ACCELERATION_ENC_VP9_2":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9_2;
    case 9:
    case "VIDEO_ACCELERATION_H265":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_H265;
    case 10:
    case "VIDEO_ACCELERATION_ENC_H265":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_H265;
    case 11:
    case "VIDEO_ACCELERATION_MJPG":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_MJPG;
    case 12:
    case "VIDEO_ACCELERATION_ENC_MJPG":
      return Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_MJPG;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_VideoAcceleration");
  }
}

export function config_VideoAccelerationToJSON(object: Config_VideoAcceleration): string {
  switch (object) {
    case Config_VideoAcceleration.VIDEO_UNSPECIFIED:
      return "VIDEO_UNSPECIFIED";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_H264:
      return "VIDEO_ACCELERATION_H264";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_H264:
      return "VIDEO_ACCELERATION_ENC_H264";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_VP8:
      return "VIDEO_ACCELERATION_VP8";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP8:
      return "VIDEO_ACCELERATION_ENC_VP8";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_VP9:
      return "VIDEO_ACCELERATION_VP9";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9:
      return "VIDEO_ACCELERATION_ENC_VP9";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_VP9_2:
      return "VIDEO_ACCELERATION_VP9_2";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_VP9_2:
      return "VIDEO_ACCELERATION_ENC_VP9_2";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_H265:
      return "VIDEO_ACCELERATION_H265";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_H265:
      return "VIDEO_ACCELERATION_ENC_H265";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_MJPG:
      return "VIDEO_ACCELERATION_MJPG";
    case Config_VideoAcceleration.VIDEO_ACCELERATION_ENC_MJPG:
      return "VIDEO_ACCELERATION_ENC_MJPG";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_VideoAcceleration");
  }
}

/** Next Tag: 50 */
export enum Config_SOC {
  SOC_UNSPECIFIED = 0,
  /** SOC_AMBERLAKE_Y - Aka AML-Y */
  SOC_AMBERLAKE_Y = 1,
  SOC_APOLLO_LAKE = 2,
  SOC_BAY_TRAIL = 3,
  SOC_BRASWELL = 4,
  SOC_BROADWELL = 5,
  SOC_CANNON_LAKE_Y = 6,
  SOC_COMET_LAKE_U = 7,
  SOC_EXYNOS_5250 = 8,
  SOC_EXYNOS_5420 = 9,
  /** SOC_GEMINI_LAKE - Aka GLK */
  SOC_GEMINI_LAKE = 10,
  SOC_HASWELL = 11,
  SOC_ICE_LAKE_Y = 12,
  SOC_IVY_BRIDGE = 13,
  SOC_KABYLAKE_U = 14,
  /** SOC_KABYLAKE_U_R - KabyLake U refresh */
  SOC_KABYLAKE_U_R = 15,
  SOC_KABYLAKE_Y = 16,
  SOC_MT8173 = 17,
  SOC_MT8176 = 18,
  SOC_MT8183 = 19,
  SOC_PICASSO = 20,
  SOC_PINE_TRAIL = 21,
  SOC_RK3288 = 22,
  SOC_RK3399 = 23,
  SOC_SANDY_BRIDGE = 24,
  SOC_SDM845 = 25,
  SOC_SKYLAKE_U = 26,
  SOC_SKYLAKE_Y = 27,
  SOC_STONEY_RIDGE = 28,
  SOC_TEGRA_K1 = 29,
  SOC_WHISKEY_LAKE_U = 30,
  SOC_SC7180 = 31,
  SOC_JASPER_LAKE = 32,
  SOC_TIGER_LAKE = 33,
  SOC_MT8192 = 34,
  SOC_ALDER_LAKE = 35,
  SOC_SC7280 = 36,
  SOC_MT8195 = 37,
  SOC_QCS40X = 38,
  SOC_METEOR_LAKE = 39,
  SOC_CEZANNE = 40,
  SOC_ALDER_LAKE_N = 41,
  SOC_RAPTOR_LAKE_N = 42,
  SOC_MT8186 = 43,
  SOC_MT8188G = 44,
  SOC_PHOENIX = 45,
  SOC_MENDOCINO = 46,
  SOC_RAPTOR_LAKE = 47,
  SOC_MT8196 = 48,
  SOC_MT8189 = 49,
  SOC_TWIN_LAKE = 50,
  SOC_PANTHER_LAKE = 51,
  SOC_WILDCAT_LAKE = 52,
}

export function config_SOCFromJSON(object: any): Config_SOC {
  switch (object) {
    case 0:
    case "SOC_UNSPECIFIED":
      return Config_SOC.SOC_UNSPECIFIED;
    case 1:
    case "SOC_AMBERLAKE_Y":
      return Config_SOC.SOC_AMBERLAKE_Y;
    case 2:
    case "SOC_APOLLO_LAKE":
      return Config_SOC.SOC_APOLLO_LAKE;
    case 3:
    case "SOC_BAY_TRAIL":
      return Config_SOC.SOC_BAY_TRAIL;
    case 4:
    case "SOC_BRASWELL":
      return Config_SOC.SOC_BRASWELL;
    case 5:
    case "SOC_BROADWELL":
      return Config_SOC.SOC_BROADWELL;
    case 6:
    case "SOC_CANNON_LAKE_Y":
      return Config_SOC.SOC_CANNON_LAKE_Y;
    case 7:
    case "SOC_COMET_LAKE_U":
      return Config_SOC.SOC_COMET_LAKE_U;
    case 8:
    case "SOC_EXYNOS_5250":
      return Config_SOC.SOC_EXYNOS_5250;
    case 9:
    case "SOC_EXYNOS_5420":
      return Config_SOC.SOC_EXYNOS_5420;
    case 10:
    case "SOC_GEMINI_LAKE":
      return Config_SOC.SOC_GEMINI_LAKE;
    case 11:
    case "SOC_HASWELL":
      return Config_SOC.SOC_HASWELL;
    case 12:
    case "SOC_ICE_LAKE_Y":
      return Config_SOC.SOC_ICE_LAKE_Y;
    case 13:
    case "SOC_IVY_BRIDGE":
      return Config_SOC.SOC_IVY_BRIDGE;
    case 14:
    case "SOC_KABYLAKE_U":
      return Config_SOC.SOC_KABYLAKE_U;
    case 15:
    case "SOC_KABYLAKE_U_R":
      return Config_SOC.SOC_KABYLAKE_U_R;
    case 16:
    case "SOC_KABYLAKE_Y":
      return Config_SOC.SOC_KABYLAKE_Y;
    case 17:
    case "SOC_MT8173":
      return Config_SOC.SOC_MT8173;
    case 18:
    case "SOC_MT8176":
      return Config_SOC.SOC_MT8176;
    case 19:
    case "SOC_MT8183":
      return Config_SOC.SOC_MT8183;
    case 20:
    case "SOC_PICASSO":
      return Config_SOC.SOC_PICASSO;
    case 21:
    case "SOC_PINE_TRAIL":
      return Config_SOC.SOC_PINE_TRAIL;
    case 22:
    case "SOC_RK3288":
      return Config_SOC.SOC_RK3288;
    case 23:
    case "SOC_RK3399":
      return Config_SOC.SOC_RK3399;
    case 24:
    case "SOC_SANDY_BRIDGE":
      return Config_SOC.SOC_SANDY_BRIDGE;
    case 25:
    case "SOC_SDM845":
      return Config_SOC.SOC_SDM845;
    case 26:
    case "SOC_SKYLAKE_U":
      return Config_SOC.SOC_SKYLAKE_U;
    case 27:
    case "SOC_SKYLAKE_Y":
      return Config_SOC.SOC_SKYLAKE_Y;
    case 28:
    case "SOC_STONEY_RIDGE":
      return Config_SOC.SOC_STONEY_RIDGE;
    case 29:
    case "SOC_TEGRA_K1":
      return Config_SOC.SOC_TEGRA_K1;
    case 30:
    case "SOC_WHISKEY_LAKE_U":
      return Config_SOC.SOC_WHISKEY_LAKE_U;
    case 31:
    case "SOC_SC7180":
      return Config_SOC.SOC_SC7180;
    case 32:
    case "SOC_JASPER_LAKE":
      return Config_SOC.SOC_JASPER_LAKE;
    case 33:
    case "SOC_TIGER_LAKE":
      return Config_SOC.SOC_TIGER_LAKE;
    case 34:
    case "SOC_MT8192":
      return Config_SOC.SOC_MT8192;
    case 35:
    case "SOC_ALDER_LAKE":
      return Config_SOC.SOC_ALDER_LAKE;
    case 36:
    case "SOC_SC7280":
      return Config_SOC.SOC_SC7280;
    case 37:
    case "SOC_MT8195":
      return Config_SOC.SOC_MT8195;
    case 38:
    case "SOC_QCS40X":
      return Config_SOC.SOC_QCS40X;
    case 39:
    case "SOC_METEOR_LAKE":
      return Config_SOC.SOC_METEOR_LAKE;
    case 40:
    case "SOC_CEZANNE":
      return Config_SOC.SOC_CEZANNE;
    case 41:
    case "SOC_ALDER_LAKE_N":
      return Config_SOC.SOC_ALDER_LAKE_N;
    case 42:
    case "SOC_RAPTOR_LAKE_N":
      return Config_SOC.SOC_RAPTOR_LAKE_N;
    case 43:
    case "SOC_MT8186":
      return Config_SOC.SOC_MT8186;
    case 44:
    case "SOC_MT8188G":
      return Config_SOC.SOC_MT8188G;
    case 45:
    case "SOC_PHOENIX":
      return Config_SOC.SOC_PHOENIX;
    case 46:
    case "SOC_MENDOCINO":
      return Config_SOC.SOC_MENDOCINO;
    case 47:
    case "SOC_RAPTOR_LAKE":
      return Config_SOC.SOC_RAPTOR_LAKE;
    case 48:
    case "SOC_MT8196":
      return Config_SOC.SOC_MT8196;
    case 49:
    case "SOC_MT8189":
      return Config_SOC.SOC_MT8189;
    case 50:
    case "SOC_TWIN_LAKE":
      return Config_SOC.SOC_TWIN_LAKE;
    case 51:
    case "SOC_PANTHER_LAKE":
      return Config_SOC.SOC_PANTHER_LAKE;
    case 52:
    case "SOC_WILDCAT_LAKE":
      return Config_SOC.SOC_WILDCAT_LAKE;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_SOC");
  }
}

export function config_SOCToJSON(object: Config_SOC): string {
  switch (object) {
    case Config_SOC.SOC_UNSPECIFIED:
      return "SOC_UNSPECIFIED";
    case Config_SOC.SOC_AMBERLAKE_Y:
      return "SOC_AMBERLAKE_Y";
    case Config_SOC.SOC_APOLLO_LAKE:
      return "SOC_APOLLO_LAKE";
    case Config_SOC.SOC_BAY_TRAIL:
      return "SOC_BAY_TRAIL";
    case Config_SOC.SOC_BRASWELL:
      return "SOC_BRASWELL";
    case Config_SOC.SOC_BROADWELL:
      return "SOC_BROADWELL";
    case Config_SOC.SOC_CANNON_LAKE_Y:
      return "SOC_CANNON_LAKE_Y";
    case Config_SOC.SOC_COMET_LAKE_U:
      return "SOC_COMET_LAKE_U";
    case Config_SOC.SOC_EXYNOS_5250:
      return "SOC_EXYNOS_5250";
    case Config_SOC.SOC_EXYNOS_5420:
      return "SOC_EXYNOS_5420";
    case Config_SOC.SOC_GEMINI_LAKE:
      return "SOC_GEMINI_LAKE";
    case Config_SOC.SOC_HASWELL:
      return "SOC_HASWELL";
    case Config_SOC.SOC_ICE_LAKE_Y:
      return "SOC_ICE_LAKE_Y";
    case Config_SOC.SOC_IVY_BRIDGE:
      return "SOC_IVY_BRIDGE";
    case Config_SOC.SOC_KABYLAKE_U:
      return "SOC_KABYLAKE_U";
    case Config_SOC.SOC_KABYLAKE_U_R:
      return "SOC_KABYLAKE_U_R";
    case Config_SOC.SOC_KABYLAKE_Y:
      return "SOC_KABYLAKE_Y";
    case Config_SOC.SOC_MT8173:
      return "SOC_MT8173";
    case Config_SOC.SOC_MT8176:
      return "SOC_MT8176";
    case Config_SOC.SOC_MT8183:
      return "SOC_MT8183";
    case Config_SOC.SOC_PICASSO:
      return "SOC_PICASSO";
    case Config_SOC.SOC_PINE_TRAIL:
      return "SOC_PINE_TRAIL";
    case Config_SOC.SOC_RK3288:
      return "SOC_RK3288";
    case Config_SOC.SOC_RK3399:
      return "SOC_RK3399";
    case Config_SOC.SOC_SANDY_BRIDGE:
      return "SOC_SANDY_BRIDGE";
    case Config_SOC.SOC_SDM845:
      return "SOC_SDM845";
    case Config_SOC.SOC_SKYLAKE_U:
      return "SOC_SKYLAKE_U";
    case Config_SOC.SOC_SKYLAKE_Y:
      return "SOC_SKYLAKE_Y";
    case Config_SOC.SOC_STONEY_RIDGE:
      return "SOC_STONEY_RIDGE";
    case Config_SOC.SOC_TEGRA_K1:
      return "SOC_TEGRA_K1";
    case Config_SOC.SOC_WHISKEY_LAKE_U:
      return "SOC_WHISKEY_LAKE_U";
    case Config_SOC.SOC_SC7180:
      return "SOC_SC7180";
    case Config_SOC.SOC_JASPER_LAKE:
      return "SOC_JASPER_LAKE";
    case Config_SOC.SOC_TIGER_LAKE:
      return "SOC_TIGER_LAKE";
    case Config_SOC.SOC_MT8192:
      return "SOC_MT8192";
    case Config_SOC.SOC_ALDER_LAKE:
      return "SOC_ALDER_LAKE";
    case Config_SOC.SOC_SC7280:
      return "SOC_SC7280";
    case Config_SOC.SOC_MT8195:
      return "SOC_MT8195";
    case Config_SOC.SOC_QCS40X:
      return "SOC_QCS40X";
    case Config_SOC.SOC_METEOR_LAKE:
      return "SOC_METEOR_LAKE";
    case Config_SOC.SOC_CEZANNE:
      return "SOC_CEZANNE";
    case Config_SOC.SOC_ALDER_LAKE_N:
      return "SOC_ALDER_LAKE_N";
    case Config_SOC.SOC_RAPTOR_LAKE_N:
      return "SOC_RAPTOR_LAKE_N";
    case Config_SOC.SOC_MT8186:
      return "SOC_MT8186";
    case Config_SOC.SOC_MT8188G:
      return "SOC_MT8188G";
    case Config_SOC.SOC_PHOENIX:
      return "SOC_PHOENIX";
    case Config_SOC.SOC_MENDOCINO:
      return "SOC_MENDOCINO";
    case Config_SOC.SOC_RAPTOR_LAKE:
      return "SOC_RAPTOR_LAKE";
    case Config_SOC.SOC_MT8196:
      return "SOC_MT8196";
    case Config_SOC.SOC_MT8189:
      return "SOC_MT8189";
    case Config_SOC.SOC_TWIN_LAKE:
      return "SOC_TWIN_LAKE";
    case Config_SOC.SOC_PANTHER_LAKE:
      return "SOC_PANTHER_LAKE";
    case Config_SOC.SOC_WILDCAT_LAKE:
      return "SOC_WILDCAT_LAKE";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_SOC");
  }
}

/** Next Tag: 6 */
export enum Config_ODM {
  ODM_UNSPECIFIED = 0,
  ODM_QUANTA = 1,
  ODM_BITLAND = 2,
  ODM_SAMSUNG = 3,
  ODM_PEGATRON = 4,
  ODM_COMPAL = 5,
}

export function config_ODMFromJSON(object: any): Config_ODM {
  switch (object) {
    case 0:
    case "ODM_UNSPECIFIED":
      return Config_ODM.ODM_UNSPECIFIED;
    case 1:
    case "ODM_QUANTA":
      return Config_ODM.ODM_QUANTA;
    case 2:
    case "ODM_BITLAND":
      return Config_ODM.ODM_BITLAND;
    case 3:
    case "ODM_SAMSUNG":
      return Config_ODM.ODM_SAMSUNG;
    case 4:
    case "ODM_PEGATRON":
      return Config_ODM.ODM_PEGATRON;
    case 5:
    case "ODM_COMPAL":
      return Config_ODM.ODM_COMPAL;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_ODM");
  }
}

export function config_ODMToJSON(object: Config_ODM): string {
  switch (object) {
    case Config_ODM.ODM_UNSPECIFIED:
      return "ODM_UNSPECIFIED";
    case Config_ODM.ODM_QUANTA:
      return "ODM_QUANTA";
    case Config_ODM.ODM_BITLAND:
      return "ODM_BITLAND";
    case Config_ODM.ODM_SAMSUNG:
      return "ODM_SAMSUNG";
    case Config_ODM.ODM_PEGATRON:
      return "ODM_PEGATRON";
    case Config_ODM.ODM_COMPAL:
      return "ODM_COMPAL";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_ODM");
  }
}

export enum Config_OEM {
  OEM_UNSPECIFIED = 0,
  OEM_ACER = 1,
  OEM_DELL = 2,
  OEM_SAMSUNG = 3,
  OEM_HP = 4,
  OEM_LENOVO = 5,
  OEM_ASUS = 6,
  OEM_NEC = 7,
}

export function config_OEMFromJSON(object: any): Config_OEM {
  switch (object) {
    case 0:
    case "OEM_UNSPECIFIED":
      return Config_OEM.OEM_UNSPECIFIED;
    case 1:
    case "OEM_ACER":
      return Config_OEM.OEM_ACER;
    case 2:
    case "OEM_DELL":
      return Config_OEM.OEM_DELL;
    case 3:
    case "OEM_SAMSUNG":
      return Config_OEM.OEM_SAMSUNG;
    case 4:
    case "OEM_HP":
      return Config_OEM.OEM_HP;
    case 5:
    case "OEM_LENOVO":
      return Config_OEM.OEM_LENOVO;
    case 6:
    case "OEM_ASUS":
      return Config_OEM.OEM_ASUS;
    case 7:
    case "OEM_NEC":
      return Config_OEM.OEM_NEC;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_OEM");
  }
}

export function config_OEMToJSON(object: Config_OEM): string {
  switch (object) {
    case Config_OEM.OEM_UNSPECIFIED:
      return "OEM_UNSPECIFIED";
    case Config_OEM.OEM_ACER:
      return "OEM_ACER";
    case Config_OEM.OEM_DELL:
      return "OEM_DELL";
    case Config_OEM.OEM_SAMSUNG:
      return "OEM_SAMSUNG";
    case Config_OEM.OEM_HP:
      return "OEM_HP";
    case Config_OEM.OEM_LENOVO:
      return "OEM_LENOVO";
    case Config_OEM.OEM_ASUS:
      return "OEM_ASUS";
    case Config_OEM.OEM_NEC:
      return "OEM_NEC";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_OEM");
  }
}

export enum Config_Architecture {
  ARCHITECTURE_UNDEFINED = 0,
  X86 = 1,
  X86_64 = 2,
  ARM = 3,
  ARM64 = 4,
}

export function config_ArchitectureFromJSON(object: any): Config_Architecture {
  switch (object) {
    case 0:
    case "ARCHITECTURE_UNDEFINED":
      return Config_Architecture.ARCHITECTURE_UNDEFINED;
    case 1:
    case "X86":
      return Config_Architecture.X86;
    case 2:
    case "X86_64":
      return Config_Architecture.X86_64;
    case 3:
    case "ARM":
      return Config_Architecture.ARM;
    case 4:
    case "ARM64":
      return Config_Architecture.ARM64;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Architecture");
  }
}

export function config_ArchitectureToJSON(object: Config_Architecture): string {
  switch (object) {
    case Config_Architecture.ARCHITECTURE_UNDEFINED:
      return "ARCHITECTURE_UNDEFINED";
    case Config_Architecture.X86:
      return "X86";
    case Config_Architecture.X86_64:
      return "X86_64";
    case Config_Architecture.ARM:
      return "ARM";
    case Config_Architecture.ARM64:
      return "ARM64";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_Architecture");
  }
}

/**
 * Indicate the type of EC present on a device.
 * Next tag: 3
 */
export enum Config_EC {
  EC_UNSPECIFIED = 0,
  EC_CHROME = 1,
  EC_WILCO = 2,
}

export function config_ECFromJSON(object: any): Config_EC {
  switch (object) {
    case 0:
    case "EC_UNSPECIFIED":
      return Config_EC.EC_UNSPECIFIED;
    case 1:
    case "EC_CHROME":
      return Config_EC.EC_CHROME;
    case 2:
    case "EC_WILCO":
      return Config_EC.EC_WILCO;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_EC");
  }
}

export function config_ECToJSON(object: Config_EC): string {
  switch (object) {
    case Config_EC.EC_UNSPECIFIED:
      return "EC_UNSPECIFIED";
    case Config_EC.EC_CHROME:
      return "EC_CHROME";
    case Config_EC.EC_WILCO:
      return "EC_WILCO";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum Config_EC");
  }
}

/** Message contains all ChromeOS device configs. */
export interface AllConfigs {
  readonly configs: readonly Config[];
}

function createBaseConfig(): Config {
  return {
    id: undefined,
    formFactor: 0,
    gpuFamily: "",
    graphics: 0,
    hardwareFeatures: [],
    power: 0,
    storage: 0,
    videoAccelerationSupports: [],
    soc: 0,
    tam: [],
    ee: [],
    odm: 0,
    odmEmailGroup: "",
    oem: 0,
    oemEmailGroup: "",
    socEmailGroup: "",
    firmwareConfiguration: 0,
    cpu: 0,
    ec: 0,
  };
}

export const Config: MessageFns<Config> = {
  encode(message: Config, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== undefined) {
      ConfigId.encode(message.id, writer.uint32(10).fork()).join();
    }
    if (message.formFactor !== 0) {
      writer.uint32(24).int32(message.formFactor);
    }
    if (message.gpuFamily !== "") {
      writer.uint32(34).string(message.gpuFamily);
    }
    if (message.graphics !== 0) {
      writer.uint32(40).int32(message.graphics);
    }
    writer.uint32(50).fork();
    for (const v of message.hardwareFeatures) {
      writer.int32(v);
    }
    writer.join();
    if (message.power !== 0) {
      writer.uint32(64).int32(message.power);
    }
    if (message.storage !== 0) {
      writer.uint32(72).int32(message.storage);
    }
    writer.uint32(82).fork();
    for (const v of message.videoAccelerationSupports) {
      writer.int32(v);
    }
    writer.join();
    if (message.soc !== 0) {
      writer.uint32(88).int32(message.soc);
    }
    for (const v of message.tam) {
      writer.uint32(98).string(v!);
    }
    for (const v of message.ee) {
      writer.uint32(106).string(v!);
    }
    if (message.odm !== 0) {
      writer.uint32(112).int32(message.odm);
    }
    if (message.odmEmailGroup !== "") {
      writer.uint32(122).string(message.odmEmailGroup);
    }
    if (message.oem !== 0) {
      writer.uint32(128).int32(message.oem);
    }
    if (message.oemEmailGroup !== "") {
      writer.uint32(138).string(message.oemEmailGroup);
    }
    if (message.socEmailGroup !== "") {
      writer.uint32(146).string(message.socEmailGroup);
    }
    if (message.firmwareConfiguration !== 0) {
      writer.uint32(152).uint32(message.firmwareConfiguration);
    }
    if (message.cpu !== 0) {
      writer.uint32(160).int32(message.cpu);
    }
    if (message.ec !== 0) {
      writer.uint32(168).int32(message.ec);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Config {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseConfig() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = ConfigId.decode(reader, reader.uint32());
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.formFactor = reader.int32() as any;
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.gpuFamily = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 40) {
            break;
          }

          message.graphics = reader.int32() as any;
          continue;
        }
        case 6: {
          if (tag === 48) {
            message.hardwareFeatures.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.hardwareFeatures.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.power = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 72) {
            break;
          }

          message.storage = reader.int32() as any;
          continue;
        }
        case 10: {
          if (tag === 80) {
            message.videoAccelerationSupports.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.videoAccelerationSupports.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.soc = reader.int32() as any;
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.tam.push(reader.string());
          continue;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.ee.push(reader.string());
          continue;
        }
        case 14: {
          if (tag !== 112) {
            break;
          }

          message.odm = reader.int32() as any;
          continue;
        }
        case 15: {
          if (tag !== 122) {
            break;
          }

          message.odmEmailGroup = reader.string();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.oem = reader.int32() as any;
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.oemEmailGroup = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.socEmailGroup = reader.string();
          continue;
        }
        case 19: {
          if (tag !== 152) {
            break;
          }

          message.firmwareConfiguration = reader.uint32();
          continue;
        }
        case 20: {
          if (tag !== 160) {
            break;
          }

          message.cpu = reader.int32() as any;
          continue;
        }
        case 21: {
          if (tag !== 168) {
            break;
          }

          message.ec = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Config {
    return {
      id: isSet(object.id) ? ConfigId.fromJSON(object.id) : undefined,
      formFactor: isSet(object.formFactor) ? config_FormFactorFromJSON(object.formFactor) : 0,
      gpuFamily: isSet(object.gpuFamily) ? globalThis.String(object.gpuFamily) : "",
      graphics: isSet(object.graphics) ? config_GraphicsFromJSON(object.graphics) : 0,
      hardwareFeatures: globalThis.Array.isArray(object?.hardwareFeatures)
        ? object.hardwareFeatures.map((e: any) => config_HardwareFeatureFromJSON(e))
        : [],
      power: isSet(object.power) ? config_PowerSupplyFromJSON(object.power) : 0,
      storage: isSet(object.storage) ? config_StorageFromJSON(object.storage) : 0,
      videoAccelerationSupports: globalThis.Array.isArray(object?.videoAccelerationSupports)
        ? object.videoAccelerationSupports.map((e: any) => config_VideoAccelerationFromJSON(e))
        : [],
      soc: isSet(object.soc) ? config_SOCFromJSON(object.soc) : 0,
      tam: globalThis.Array.isArray(object?.tam) ? object.tam.map((e: any) => globalThis.String(e)) : [],
      ee: globalThis.Array.isArray(object?.ee) ? object.ee.map((e: any) => globalThis.String(e)) : [],
      odm: isSet(object.odm) ? config_ODMFromJSON(object.odm) : 0,
      odmEmailGroup: isSet(object.odmEmailGroup) ? globalThis.String(object.odmEmailGroup) : "",
      oem: isSet(object.oem) ? config_OEMFromJSON(object.oem) : 0,
      oemEmailGroup: isSet(object.oemEmailGroup) ? globalThis.String(object.oemEmailGroup) : "",
      socEmailGroup: isSet(object.socEmailGroup) ? globalThis.String(object.socEmailGroup) : "",
      firmwareConfiguration: isSet(object.firmwareConfiguration) ? globalThis.Number(object.firmwareConfiguration) : 0,
      cpu: isSet(object.cpu) ? config_ArchitectureFromJSON(object.cpu) : 0,
      ec: isSet(object.ec) ? config_ECFromJSON(object.ec) : 0,
    };
  },

  toJSON(message: Config): unknown {
    const obj: any = {};
    if (message.id !== undefined) {
      obj.id = ConfigId.toJSON(message.id);
    }
    if (message.formFactor !== 0) {
      obj.formFactor = config_FormFactorToJSON(message.formFactor);
    }
    if (message.gpuFamily !== "") {
      obj.gpuFamily = message.gpuFamily;
    }
    if (message.graphics !== 0) {
      obj.graphics = config_GraphicsToJSON(message.graphics);
    }
    if (message.hardwareFeatures?.length) {
      obj.hardwareFeatures = message.hardwareFeatures.map((e) => config_HardwareFeatureToJSON(e));
    }
    if (message.power !== 0) {
      obj.power = config_PowerSupplyToJSON(message.power);
    }
    if (message.storage !== 0) {
      obj.storage = config_StorageToJSON(message.storage);
    }
    if (message.videoAccelerationSupports?.length) {
      obj.videoAccelerationSupports = message.videoAccelerationSupports.map((e) => config_VideoAccelerationToJSON(e));
    }
    if (message.soc !== 0) {
      obj.soc = config_SOCToJSON(message.soc);
    }
    if (message.tam?.length) {
      obj.tam = message.tam;
    }
    if (message.ee?.length) {
      obj.ee = message.ee;
    }
    if (message.odm !== 0) {
      obj.odm = config_ODMToJSON(message.odm);
    }
    if (message.odmEmailGroup !== "") {
      obj.odmEmailGroup = message.odmEmailGroup;
    }
    if (message.oem !== 0) {
      obj.oem = config_OEMToJSON(message.oem);
    }
    if (message.oemEmailGroup !== "") {
      obj.oemEmailGroup = message.oemEmailGroup;
    }
    if (message.socEmailGroup !== "") {
      obj.socEmailGroup = message.socEmailGroup;
    }
    if (message.firmwareConfiguration !== 0) {
      obj.firmwareConfiguration = Math.round(message.firmwareConfiguration);
    }
    if (message.cpu !== 0) {
      obj.cpu = config_ArchitectureToJSON(message.cpu);
    }
    if (message.ec !== 0) {
      obj.ec = config_ECToJSON(message.ec);
    }
    return obj;
  },

  create(base?: DeepPartial<Config>): Config {
    return Config.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Config>): Config {
    const message = createBaseConfig() as any;
    message.id = (object.id !== undefined && object.id !== null) ? ConfigId.fromPartial(object.id) : undefined;
    message.formFactor = object.formFactor ?? 0;
    message.gpuFamily = object.gpuFamily ?? "";
    message.graphics = object.graphics ?? 0;
    message.hardwareFeatures = object.hardwareFeatures?.map((e) => e) || [];
    message.power = object.power ?? 0;
    message.storage = object.storage ?? 0;
    message.videoAccelerationSupports = object.videoAccelerationSupports?.map((e) => e) || [];
    message.soc = object.soc ?? 0;
    message.tam = object.tam?.map((e) => e) || [];
    message.ee = object.ee?.map((e) => e) || [];
    message.odm = object.odm ?? 0;
    message.odmEmailGroup = object.odmEmailGroup ?? "";
    message.oem = object.oem ?? 0;
    message.oemEmailGroup = object.oemEmailGroup ?? "";
    message.socEmailGroup = object.socEmailGroup ?? "";
    message.firmwareConfiguration = object.firmwareConfiguration ?? 0;
    message.cpu = object.cpu ?? 0;
    message.ec = object.ec ?? 0;
    return message;
  },
};

function createBaseAllConfigs(): AllConfigs {
  return { configs: [] };
}

export const AllConfigs: MessageFns<AllConfigs> = {
  encode(message: AllConfigs, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.configs) {
      Config.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AllConfigs {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllConfigs() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.configs.push(Config.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllConfigs {
    return {
      configs: globalThis.Array.isArray(object?.configs) ? object.configs.map((e: any) => Config.fromJSON(e)) : [],
    };
  },

  toJSON(message: AllConfigs): unknown {
    const obj: any = {};
    if (message.configs?.length) {
      obj.configs = message.configs.map((e) => Config.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<AllConfigs>): AllConfigs {
    return AllConfigs.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AllConfigs>): AllConfigs {
    const message = createBaseAllConfigs() as any;
    message.configs = object.configs?.map((e) => Config.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
