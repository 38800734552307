// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/machine_lse_deployment.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../../google/protobuf/timestamp.pb";
import { Payload } from "./deployment.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

export enum DeploymentEnv {
  /**
   * DEPLOYMENTENV_UNDEFINED - Only add DeploymentEnv prefix to undefined as there're still discussions about whether to add
   * prefix to all enums to reduce the code readability.
   */
  DEPLOYMENTENV_UNDEFINED = 0,
  PROD = 1,
  AUTOPUSH = 2,
}

export function deploymentEnvFromJSON(object: any): DeploymentEnv {
  switch (object) {
    case 0:
    case "DEPLOYMENTENV_UNDEFINED":
      return DeploymentEnv.DEPLOYMENTENV_UNDEFINED;
    case 1:
    case "PROD":
      return DeploymentEnv.PROD;
    case 2:
    case "AUTOPUSH":
      return DeploymentEnv.AUTOPUSH;
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeploymentEnv");
  }
}

export function deploymentEnvToJSON(object: DeploymentEnv): string {
  switch (object) {
    case DeploymentEnv.DEPLOYMENTENV_UNDEFINED:
      return "DEPLOYMENTENV_UNDEFINED";
    case DeploymentEnv.PROD:
      return "PROD";
    case DeploymentEnv.AUTOPUSH:
      return "AUTOPUSH";
    default:
      throw new globalThis.Error("Unrecognized enum value " + object + " for enum DeploymentEnv");
  }
}

/**
 * MachineLSEDeployment includes all info related to deployment of a machine LSE (host).
 *
 * This deployment record will be updated in 3 ways:
 *
 * 1. `shivas add machine`, a deployment record will be added to this machine even if it's
 *    not deployed yet. It usually happens when users add DHCP records for this machine to
 *    verify if it's physically set up well before adding the same hostname into UFS.
 *          hostname: "no-host-yet-<serial_number>"
 *          serial_number: from `shivas add machine`
 *          deployment_identifier: ""
 *          configs_to_push: nil
 *
 * 2. StartActivation phase in Chrome MDM service. When Chrome MDM gots a request from a mac
 *    to activate itself, it will always update back this deployment record no matter whether
 *    there's already a record existing or not. It usually happens when a mac automatically
 *    connects to Google Guest WiFi network in the DC before anyone touches it yet. In this case,
 *    the record here would be:
 *          hostname: "no-host-yet-<serial_number>"
 *          serial_number: from Chrome MDM
 *          deployment_identifier: from Chrome MDM
 *          configs_to_push: from Chrome MDM
 *
 * 3. `shivas add host`, a deployment record will be updated to reflect the real hostname given
 *    by users.
 *
 * Next tag: 7
 */
export interface MachineLSEDeployment {
  /** The name of the host which contains this deployment record. */
  readonly hostname: string;
  /** Refer to Machine.serial_number in machine.proto */
  readonly serialNumber: string;
  /**
   * Usually it is empty by default.
   * If it's a mac host, the deployment_identifier here refers to the UUID generated by MegaMDM.
   */
  readonly deploymentIdentifier: string;
  /** It refers to all configs which is gonna to be pushed to this host. */
  readonly configsToPush: readonly Payload[];
  /** Record the last update timestamp of this host deployment (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** Specify the deployment environment of the MegaMDM service which enrolls this host. */
  readonly deploymentEnv: DeploymentEnv;
}

function createBaseMachineLSEDeployment(): MachineLSEDeployment {
  return {
    hostname: "",
    serialNumber: "",
    deploymentIdentifier: "",
    configsToPush: [],
    updateTime: undefined,
    deploymentEnv: 0,
  };
}

export const MachineLSEDeployment: MessageFns<MachineLSEDeployment> = {
  encode(message: MachineLSEDeployment, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.hostname !== "") {
      writer.uint32(10).string(message.hostname);
    }
    if (message.serialNumber !== "") {
      writer.uint32(18).string(message.serialNumber);
    }
    if (message.deploymentIdentifier !== "") {
      writer.uint32(26).string(message.deploymentIdentifier);
    }
    for (const v of message.configsToPush) {
      Payload.encode(v!, writer.uint32(34).fork()).join();
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(42).fork()).join();
    }
    if (message.deploymentEnv !== 0) {
      writer.uint32(48).int32(message.deploymentEnv);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): MachineLSEDeployment {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMachineLSEDeployment() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.deploymentIdentifier = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.configsToPush.push(Payload.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.deploymentEnv = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): MachineLSEDeployment {
    return {
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      serialNumber: isSet(object.serialNumber) ? globalThis.String(object.serialNumber) : "",
      deploymentIdentifier: isSet(object.deploymentIdentifier) ? globalThis.String(object.deploymentIdentifier) : "",
      configsToPush: globalThis.Array.isArray(object?.configsToPush)
        ? object.configsToPush.map((e: any) => Payload.fromJSON(e))
        : [],
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      deploymentEnv: isSet(object.deploymentEnv) ? deploymentEnvFromJSON(object.deploymentEnv) : 0,
    };
  },

  toJSON(message: MachineLSEDeployment): unknown {
    const obj: any = {};
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.serialNumber !== "") {
      obj.serialNumber = message.serialNumber;
    }
    if (message.deploymentIdentifier !== "") {
      obj.deploymentIdentifier = message.deploymentIdentifier;
    }
    if (message.configsToPush?.length) {
      obj.configsToPush = message.configsToPush.map((e) => Payload.toJSON(e));
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.deploymentEnv !== 0) {
      obj.deploymentEnv = deploymentEnvToJSON(message.deploymentEnv);
    }
    return obj;
  },

  create(base?: DeepPartial<MachineLSEDeployment>): MachineLSEDeployment {
    return MachineLSEDeployment.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<MachineLSEDeployment>): MachineLSEDeployment {
    const message = createBaseMachineLSEDeployment() as any;
    message.hostname = object.hostname ?? "";
    message.serialNumber = object.serialNumber ?? "";
    message.deploymentIdentifier = object.deploymentIdentifier ?? "";
    message.configsToPush = object.configsToPush?.map((e) => Payload.fromPartial(e)) || [];
    message.updateTime = object.updateTime ?? undefined;
    message.deploymentEnv = object.deploymentEnv ?? 0;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
