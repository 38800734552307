// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.2.5
//   protoc               v6.30.1
// source: go.chromium.org/infra/unifiedfleet/api/v1/models/network.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";
import { Timestamp } from "../../../../../../google/protobuf/timestamp.pb";
import { Zone, zoneFromJSON, zoneToJSON } from "./location.pb";
import { SwitchInterface } from "./peripherals.pb";
import { State, stateFromJSON, stateToJSON } from "./state.pb";

export const protobufPackage = "unifiedfleet.api.v1.models";

export interface Nic {
  /**
   * Unique serial_number or asset tag
   * The format will be nics/XXX
   */
  readonly name: string;
  readonly macAddress: string;
  /** Record the last update timestamp of this machine (In UTC timezone) */
  readonly updateTime: string | undefined;
  readonly switchInterface:
    | SwitchInterface
    | undefined;
  /** Refers to Machine name */
  readonly machine: string;
  /** Refers to Rack name */
  readonly rack: string;
  /** tags user can attach for easy querying/searching */
  readonly tags: readonly string[];
  /** Refers to Zone */
  readonly zone: string;
  /** State of the nic */
  readonly resourceState: State;
}

export interface Vlan {
  /**
   * Unique name for the Vlan
   * The format will be vlans/XXX
   */
  readonly name: string;
  /** The format will be subnet/CIDR. */
  readonly vlanAddress: string;
  /** The number of IPs that in this vlan */
  readonly capacityIp: number;
  /** Record the last update timestamp of this Vlan (In UTC timezone) */
  readonly updateTime:
    | string
    | undefined;
  /** The description of the vlan. */
  readonly description: string;
  /**
   * The ips that cannot be used by normal host in this vlan.
   * By default, the first 10 and last 1 ip in the vlan is automatically reserved.
   * No need to specify them here.
   */
  readonly reservedIps: readonly string[];
  /**
   * Define the free ip range of this vlan that can be automatically assigned.
   * Any ips between the two ips are regarded as available ips.
   * Any ips that not in this range will be marked as reserved.
   * If they're empty, it means no ips in this vlan can be used as dhcp configs.
   * The format of the ip ranges are ipv4 string format, e.g. 192.168.1.23.
   * User can update reserved_ips to remove some of the available ips in the free range.
   *
   * @deprecated
   */
  readonly freeStartIpv4Str: string;
  /** @deprecated */
  readonly freeEndIpv4Str: string;
  /** The start of the free IP range, in IPv4 or IPv6. */
  readonly freeStartIp: string;
  /** The end of the free IP range (inclusive), in IPv4 or IPv6. */
  readonly freeEndIp: string;
  /** State of the vlan */
  readonly resourceState: State;
  /** vlan_number is same as the vlan name without prefix(browser/atl/cros) */
  readonly vlanNumber: string;
  /** The zone that this vlan serves. One vlan can serve multiple zones. */
  readonly zones: readonly Zone[];
  /** Record the ACL info of the vlan */
  readonly realm: string;
  /** Tags user can attach for easy querying/searching */
  readonly tags: readonly string[];
  /** Reserved IP number */
  readonly reservedIpNum: string;
  /** Allocated IP number */
  readonly allocatedIpNum: string;
}

export interface DHCPConfig {
  readonly macAddress: string;
  readonly hostname: string;
  readonly ip: string;
  readonly updateTime: string | undefined;
  readonly vlan: string;
}

/** Message contains all dhcp configs. */
export interface AllDHCPConfigs {
  readonly configs: readonly DHCPConfig[];
}

/** IP is an intermediate record object, not an object to be exposed by API. */
export interface IP {
  readonly id: string;
  /** can be converted to and from the string ip address */
  readonly ipv4: number;
  /** ipv6 is the ipv6 address. It must contain exactly sixteen bytes. */
  readonly ipv6: Uint8Array;
  readonly vlan: string;
  /** store the string ip address for ipv4. */
  readonly ipv4Str: string;
  /** store the string address for ipv6. */
  readonly ipv6Str: string;
  /** Indicate whether this IP is occupied. */
  readonly occupied: boolean;
  /** Indicate if this IP is reserved (cannot be auto-assigned to any host) */
  readonly reserve: boolean;
}

function createBaseNic(): Nic {
  return {
    name: "",
    macAddress: "",
    updateTime: undefined,
    switchInterface: undefined,
    machine: "",
    rack: "",
    tags: [],
    zone: "",
    resourceState: 0,
  };
}

export const Nic: MessageFns<Nic> = {
  encode(message: Nic, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.macAddress !== "") {
      writer.uint32(18).string(message.macAddress);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(26).fork()).join();
    }
    if (message.switchInterface !== undefined) {
      SwitchInterface.encode(message.switchInterface, writer.uint32(34).fork()).join();
    }
    if (message.machine !== "") {
      writer.uint32(42).string(message.machine);
    }
    if (message.rack !== "") {
      writer.uint32(58).string(message.rack);
    }
    for (const v of message.tags) {
      writer.uint32(66).string(v!);
    }
    if (message.zone !== "") {
      writer.uint32(82).string(message.zone);
    }
    if (message.resourceState !== 0) {
      writer.uint32(88).int32(message.resourceState);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Nic {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseNic() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.switchInterface = SwitchInterface.decode(reader, reader.uint32());
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.machine = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.rack = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.zone = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 88) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Nic {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      switchInterface: isSet(object.switchInterface) ? SwitchInterface.fromJSON(object.switchInterface) : undefined,
      machine: isSet(object.machine) ? globalThis.String(object.machine) : "",
      rack: isSet(object.rack) ? globalThis.String(object.rack) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      zone: isSet(object.zone) ? globalThis.String(object.zone) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
    };
  },

  toJSON(message: Nic): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.switchInterface !== undefined) {
      obj.switchInterface = SwitchInterface.toJSON(message.switchInterface);
    }
    if (message.machine !== "") {
      obj.machine = message.machine;
    }
    if (message.rack !== "") {
      obj.rack = message.rack;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.zone !== "") {
      obj.zone = message.zone;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    return obj;
  },

  create(base?: DeepPartial<Nic>): Nic {
    return Nic.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Nic>): Nic {
    const message = createBaseNic() as any;
    message.name = object.name ?? "";
    message.macAddress = object.macAddress ?? "";
    message.updateTime = object.updateTime ?? undefined;
    message.switchInterface = (object.switchInterface !== undefined && object.switchInterface !== null)
      ? SwitchInterface.fromPartial(object.switchInterface)
      : undefined;
    message.machine = object.machine ?? "";
    message.rack = object.rack ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.zone = object.zone ?? "";
    message.resourceState = object.resourceState ?? 0;
    return message;
  },
};

function createBaseVlan(): Vlan {
  return {
    name: "",
    vlanAddress: "",
    capacityIp: 0,
    updateTime: undefined,
    description: "",
    reservedIps: [],
    freeStartIpv4Str: "",
    freeEndIpv4Str: "",
    freeStartIp: "",
    freeEndIp: "",
    resourceState: 0,
    vlanNumber: "",
    zones: [],
    realm: "",
    tags: [],
    reservedIpNum: "0",
    allocatedIpNum: "0",
  };
}

export const Vlan: MessageFns<Vlan> = {
  encode(message: Vlan, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.vlanAddress !== "") {
      writer.uint32(18).string(message.vlanAddress);
    }
    if (message.capacityIp !== 0) {
      writer.uint32(24).int32(message.capacityIp);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(34).fork()).join();
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    for (const v of message.reservedIps) {
      writer.uint32(58).string(v!);
    }
    if (message.freeStartIpv4Str !== "") {
      writer.uint32(90).string(message.freeStartIpv4Str);
    }
    if (message.freeEndIpv4Str !== "") {
      writer.uint32(98).string(message.freeEndIpv4Str);
    }
    if (message.freeStartIp !== "") {
      writer.uint32(138).string(message.freeStartIp);
    }
    if (message.freeEndIp !== "") {
      writer.uint32(146).string(message.freeEndIp);
    }
    if (message.resourceState !== 0) {
      writer.uint32(64).int32(message.resourceState);
    }
    if (message.vlanNumber !== "") {
      writer.uint32(74).string(message.vlanNumber);
    }
    writer.uint32(82).fork();
    for (const v of message.zones) {
      writer.int32(v);
    }
    writer.join();
    if (message.realm !== "") {
      writer.uint32(106).string(message.realm);
    }
    for (const v of message.tags) {
      writer.uint32(114).string(v!);
    }
    if (message.reservedIpNum !== "0") {
      writer.uint32(120).int64(message.reservedIpNum);
    }
    if (message.allocatedIpNum !== "0") {
      writer.uint32(128).int64(message.allocatedIpNum);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): Vlan {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVlan() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.vlanAddress = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 24) {
            break;
          }

          message.capacityIp = reader.int32();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.reservedIps.push(reader.string());
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.freeStartIpv4Str = reader.string();
          continue;
        }
        case 12: {
          if (tag !== 98) {
            break;
          }

          message.freeEndIpv4Str = reader.string();
          continue;
        }
        case 17: {
          if (tag !== 138) {
            break;
          }

          message.freeStartIp = reader.string();
          continue;
        }
        case 18: {
          if (tag !== 146) {
            break;
          }

          message.freeEndIp = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 64) {
            break;
          }

          message.resourceState = reader.int32() as any;
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.vlanNumber = reader.string();
          continue;
        }
        case 10: {
          if (tag === 80) {
            message.zones.push(reader.int32() as any);

            continue;
          }

          if (tag === 82) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.zones.push(reader.int32() as any);
            }

            continue;
          }

          break;
        }
        case 13: {
          if (tag !== 106) {
            break;
          }

          message.realm = reader.string();
          continue;
        }
        case 14: {
          if (tag !== 114) {
            break;
          }

          message.tags.push(reader.string());
          continue;
        }
        case 15: {
          if (tag !== 120) {
            break;
          }

          message.reservedIpNum = reader.int64().toString();
          continue;
        }
        case 16: {
          if (tag !== 128) {
            break;
          }

          message.allocatedIpNum = reader.int64().toString();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vlan {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      vlanAddress: isSet(object.vlanAddress) ? globalThis.String(object.vlanAddress) : "",
      capacityIp: isSet(object.capacityIp) ? globalThis.Number(object.capacityIp) : 0,
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
      reservedIps: globalThis.Array.isArray(object?.reservedIps)
        ? object.reservedIps.map((e: any) => globalThis.String(e))
        : [],
      freeStartIpv4Str: isSet(object.freeStartIpv4Str) ? globalThis.String(object.freeStartIpv4Str) : "",
      freeEndIpv4Str: isSet(object.freeEndIpv4Str) ? globalThis.String(object.freeEndIpv4Str) : "",
      freeStartIp: isSet(object.freeStartIp) ? globalThis.String(object.freeStartIp) : "",
      freeEndIp: isSet(object.freeEndIp) ? globalThis.String(object.freeEndIp) : "",
      resourceState: isSet(object.resourceState) ? stateFromJSON(object.resourceState) : 0,
      vlanNumber: isSet(object.vlanNumber) ? globalThis.String(object.vlanNumber) : "",
      zones: globalThis.Array.isArray(object?.zones) ? object.zones.map((e: any) => zoneFromJSON(e)) : [],
      realm: isSet(object.realm) ? globalThis.String(object.realm) : "",
      tags: globalThis.Array.isArray(object?.tags) ? object.tags.map((e: any) => globalThis.String(e)) : [],
      reservedIpNum: isSet(object.reservedIpNum) ? globalThis.String(object.reservedIpNum) : "0",
      allocatedIpNum: isSet(object.allocatedIpNum) ? globalThis.String(object.allocatedIpNum) : "0",
    };
  },

  toJSON(message: Vlan): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.vlanAddress !== "") {
      obj.vlanAddress = message.vlanAddress;
    }
    if (message.capacityIp !== 0) {
      obj.capacityIp = Math.round(message.capacityIp);
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    if (message.reservedIps?.length) {
      obj.reservedIps = message.reservedIps;
    }
    if (message.freeStartIpv4Str !== "") {
      obj.freeStartIpv4Str = message.freeStartIpv4Str;
    }
    if (message.freeEndIpv4Str !== "") {
      obj.freeEndIpv4Str = message.freeEndIpv4Str;
    }
    if (message.freeStartIp !== "") {
      obj.freeStartIp = message.freeStartIp;
    }
    if (message.freeEndIp !== "") {
      obj.freeEndIp = message.freeEndIp;
    }
    if (message.resourceState !== 0) {
      obj.resourceState = stateToJSON(message.resourceState);
    }
    if (message.vlanNumber !== "") {
      obj.vlanNumber = message.vlanNumber;
    }
    if (message.zones?.length) {
      obj.zones = message.zones.map((e) => zoneToJSON(e));
    }
    if (message.realm !== "") {
      obj.realm = message.realm;
    }
    if (message.tags?.length) {
      obj.tags = message.tags;
    }
    if (message.reservedIpNum !== "0") {
      obj.reservedIpNum = message.reservedIpNum;
    }
    if (message.allocatedIpNum !== "0") {
      obj.allocatedIpNum = message.allocatedIpNum;
    }
    return obj;
  },

  create(base?: DeepPartial<Vlan>): Vlan {
    return Vlan.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<Vlan>): Vlan {
    const message = createBaseVlan() as any;
    message.name = object.name ?? "";
    message.vlanAddress = object.vlanAddress ?? "";
    message.capacityIp = object.capacityIp ?? 0;
    message.updateTime = object.updateTime ?? undefined;
    message.description = object.description ?? "";
    message.reservedIps = object.reservedIps?.map((e) => e) || [];
    message.freeStartIpv4Str = object.freeStartIpv4Str ?? "";
    message.freeEndIpv4Str = object.freeEndIpv4Str ?? "";
    message.freeStartIp = object.freeStartIp ?? "";
    message.freeEndIp = object.freeEndIp ?? "";
    message.resourceState = object.resourceState ?? 0;
    message.vlanNumber = object.vlanNumber ?? "";
    message.zones = object.zones?.map((e) => e) || [];
    message.realm = object.realm ?? "";
    message.tags = object.tags?.map((e) => e) || [];
    message.reservedIpNum = object.reservedIpNum ?? "0";
    message.allocatedIpNum = object.allocatedIpNum ?? "0";
    return message;
  },
};

function createBaseDHCPConfig(): DHCPConfig {
  return { macAddress: "", hostname: "", ip: "", updateTime: undefined, vlan: "" };
}

export const DHCPConfig: MessageFns<DHCPConfig> = {
  encode(message: DHCPConfig, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.macAddress !== "") {
      writer.uint32(10).string(message.macAddress);
    }
    if (message.hostname !== "") {
      writer.uint32(18).string(message.hostname);
    }
    if (message.ip !== "") {
      writer.uint32(26).string(message.ip);
    }
    if (message.updateTime !== undefined) {
      Timestamp.encode(toTimestamp(message.updateTime), writer.uint32(34).fork()).join();
    }
    if (message.vlan !== "") {
      writer.uint32(42).string(message.vlan);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): DHCPConfig {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDHCPConfig() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.macAddress = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.hostname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.ip = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 34) {
            break;
          }

          message.updateTime = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.vlan = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): DHCPConfig {
    return {
      macAddress: isSet(object.macAddress) ? globalThis.String(object.macAddress) : "",
      hostname: isSet(object.hostname) ? globalThis.String(object.hostname) : "",
      ip: isSet(object.ip) ? globalThis.String(object.ip) : "",
      updateTime: isSet(object.updateTime) ? globalThis.String(object.updateTime) : undefined,
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
    };
  },

  toJSON(message: DHCPConfig): unknown {
    const obj: any = {};
    if (message.macAddress !== "") {
      obj.macAddress = message.macAddress;
    }
    if (message.hostname !== "") {
      obj.hostname = message.hostname;
    }
    if (message.ip !== "") {
      obj.ip = message.ip;
    }
    if (message.updateTime !== undefined) {
      obj.updateTime = message.updateTime;
    }
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    return obj;
  },

  create(base?: DeepPartial<DHCPConfig>): DHCPConfig {
    return DHCPConfig.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<DHCPConfig>): DHCPConfig {
    const message = createBaseDHCPConfig() as any;
    message.macAddress = object.macAddress ?? "";
    message.hostname = object.hostname ?? "";
    message.ip = object.ip ?? "";
    message.updateTime = object.updateTime ?? undefined;
    message.vlan = object.vlan ?? "";
    return message;
  },
};

function createBaseAllDHCPConfigs(): AllDHCPConfigs {
  return { configs: [] };
}

export const AllDHCPConfigs: MessageFns<AllDHCPConfigs> = {
  encode(message: AllDHCPConfigs, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    for (const v of message.configs) {
      DHCPConfig.encode(v!, writer.uint32(10).fork()).join();
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): AllDHCPConfigs {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAllDHCPConfigs() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.configs.push(DHCPConfig.decode(reader, reader.uint32()));
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AllDHCPConfigs {
    return {
      configs: globalThis.Array.isArray(object?.configs) ? object.configs.map((e: any) => DHCPConfig.fromJSON(e)) : [],
    };
  },

  toJSON(message: AllDHCPConfigs): unknown {
    const obj: any = {};
    if (message.configs?.length) {
      obj.configs = message.configs.map((e) => DHCPConfig.toJSON(e));
    }
    return obj;
  },

  create(base?: DeepPartial<AllDHCPConfigs>): AllDHCPConfigs {
    return AllDHCPConfigs.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<AllDHCPConfigs>): AllDHCPConfigs {
    const message = createBaseAllDHCPConfigs() as any;
    message.configs = object.configs?.map((e) => DHCPConfig.fromPartial(e)) || [];
    return message;
  },
};

function createBaseIP(): IP {
  return {
    id: "",
    ipv4: 0,
    ipv6: new Uint8Array(0),
    vlan: "",
    ipv4Str: "",
    ipv6Str: "",
    occupied: false,
    reserve: false,
  };
}

export const IP: MessageFns<IP> = {
  encode(message: IP, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    if (message.ipv4 !== 0) {
      writer.uint32(16).uint32(message.ipv4);
    }
    if (message.ipv6.length !== 0) {
      writer.uint32(58).bytes(message.ipv6);
    }
    if (message.vlan !== "") {
      writer.uint32(26).string(message.vlan);
    }
    if (message.ipv4Str !== "") {
      writer.uint32(42).string(message.ipv4Str);
    }
    if (message.ipv6Str !== "") {
      writer.uint32(66).string(message.ipv6Str);
    }
    if (message.occupied !== false) {
      writer.uint32(32).bool(message.occupied);
    }
    if (message.reserve !== false) {
      writer.uint32(48).bool(message.reserve);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): IP {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseIP() as any;
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
        }
        case 2: {
          if (tag !== 16) {
            break;
          }

          message.ipv4 = reader.uint32();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.ipv6 = reader.bytes();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.vlan = reader.string();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.ipv4Str = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.ipv6Str = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.occupied = reader.bool();
          continue;
        }
        case 6: {
          if (tag !== 48) {
            break;
          }

          message.reserve = reader.bool();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): IP {
    return {
      id: isSet(object.id) ? globalThis.String(object.id) : "",
      ipv4: isSet(object.ipv4) ? globalThis.Number(object.ipv4) : 0,
      ipv6: isSet(object.ipv6) ? bytesFromBase64(object.ipv6) : new Uint8Array(0),
      vlan: isSet(object.vlan) ? globalThis.String(object.vlan) : "",
      ipv4Str: isSet(object.ipv4Str) ? globalThis.String(object.ipv4Str) : "",
      ipv6Str: isSet(object.ipv6Str) ? globalThis.String(object.ipv6Str) : "",
      occupied: isSet(object.occupied) ? globalThis.Boolean(object.occupied) : false,
      reserve: isSet(object.reserve) ? globalThis.Boolean(object.reserve) : false,
    };
  },

  toJSON(message: IP): unknown {
    const obj: any = {};
    if (message.id !== "") {
      obj.id = message.id;
    }
    if (message.ipv4 !== 0) {
      obj.ipv4 = Math.round(message.ipv4);
    }
    if (message.ipv6.length !== 0) {
      obj.ipv6 = base64FromBytes(message.ipv6);
    }
    if (message.vlan !== "") {
      obj.vlan = message.vlan;
    }
    if (message.ipv4Str !== "") {
      obj.ipv4Str = message.ipv4Str;
    }
    if (message.ipv6Str !== "") {
      obj.ipv6Str = message.ipv6Str;
    }
    if (message.occupied !== false) {
      obj.occupied = message.occupied;
    }
    if (message.reserve !== false) {
      obj.reserve = message.reserve;
    }
    return obj;
  },

  create(base?: DeepPartial<IP>): IP {
    return IP.fromPartial(base ?? {});
  },
  fromPartial(object: DeepPartial<IP>): IP {
    const message = createBaseIP() as any;
    message.id = object.id ?? "";
    message.ipv4 = object.ipv4 ?? 0;
    message.ipv6 = object.ipv6 ?? new Uint8Array(0);
    message.vlan = object.vlan ?? "";
    message.ipv4Str = object.ipv4Str ?? "";
    message.ipv6Str = object.ipv6Str ?? "";
    message.occupied = object.occupied ?? false;
    message.reserve = object.reserve ?? false;
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  if ((globalThis as any).Buffer) {
    return Uint8Array.from(globalThis.Buffer.from(b64, "base64"));
  } else {
    const bin = globalThis.atob(b64);
    const arr = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; ++i) {
      arr[i] = bin.charCodeAt(i);
    }
    return arr;
  }
}

function base64FromBytes(arr: Uint8Array): string {
  if ((globalThis as any).Buffer) {
    return globalThis.Buffer.from(arr).toString("base64");
  } else {
    const bin: string[] = [];
    arr.forEach((byte) => {
      bin.push(globalThis.String.fromCharCode(byte));
    });
    return globalThis.btoa(bin.join(""));
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

function toTimestamp(dateStr: string): Timestamp {
  const date = new globalThis.Date(dateStr);
  const seconds = Math.trunc(date.getTime() / 1_000).toString();
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): string {
  let millis = (globalThis.Number(t.seconds) || 0) * 1_000;
  millis += (t.nanos || 0) / 1_000_000;
  return new globalThis.Date(millis).toISOString();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create(base?: DeepPartial<T>): T;
  fromPartial(object: DeepPartial<T>): T;
}
